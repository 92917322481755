import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  StyledLegalRepresentativeInformation,
  StyledLegalRepresentativeInformationSegmented,
  StyledLegalRepresentativeInformationCopyField,
  StyledLegalRepresentativeInformationLinkButton
} from './StyledLegalRepresentativeInformation';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import LegalRepresentativeInvitationForm from '../../stepsComponents/LegalRepresentativeInvitationForm';
import LegalRepresentativeInformationForm from '../../stepsComponents/LegalRepresentativeInformationForm';
import {alertActions, bankingActions} from '../../../../../../state/actions';
import {kycHelpers} from '../../kycHelpers';
import {bankingUserTypesConstants} from '../../../../../../constants';
import {cardsHelpers} from '../../../../../pages/CardsPage/cardsHelpers';

const formKey = 'lrInformation';

const COPY_TAB = 'copy';
const FORM_TAB = 'form';
const INVITATION_TAB = 'invitation';

const {LEGAL_REPRESENTATIVE} = bankingUserTypesConstants;

const LegalRepresentativeInformation = React.forwardRef(({
  handleUpdateOkEnable,
  handleLoadUser,
  onFinish,

  invitedUser,
  user,

  createUser,
  createTaxResidence,
  getCompanyState,
  getInvitationDetails,
  sendInvitation,
  updateUser,
  ...rest
}, ref) => {
  const [t] = useTranslation(['main', 'quickStart']);
  const [selectedSegment, setSelectedSegment] = useState(INVITATION_TAB);
  const [prepopulatedUserDetails, setPrepopulatedUserDetails] = useState({});
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [treezorRulesAccepted, setTreezorRulesAccepted] = useState(false);

  const informationFormRef = useRef(null);
  const invitationFormRef = useRef(null);

  useEffect(() => {
    handleUpdateOkEnable && handleUpdateOkEnable(submitEnabled);
  }, [submitEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let enabled = true;
    if (selectedSegment === FORM_TAB) {
      enabled = treezorRulesAccepted;
    } else if (selectedSegment === COPY_TAB) {
      enabled = false;
    }
    if (enabled !== submitEnabled) {
      setSubmitEnabled(enabled);
    }
  }, [selectedSegment, treezorRulesAccepted]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    description,
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey}), [t]);

  const segmentedOption = useMemo(() => {
    const tabTitle = (tabKey) => t(`quickStart:kyc.steps.${formKey}.tabs.${tabKey}.title`);

    return [
      {value: INVITATION_TAB, label: tabTitle('invitation'), disabled: Boolean(invitedUser)},
      {value: FORM_TAB, label: tabTitle('fillTheForm')},
      {value: COPY_TAB, label: tabTitle('shareLink')}
    ]
  }, [t, invitedUser]);

  const invitationLink = useMemo(() => invitedUser?.key ? kycHelpers.getKYCInvitePageUrl(invitedUser.key) : undefined, [invitedUser]);

  const tabDescription = (tabKey) => t(`quickStart:kyc.steps.${formKey}.tabs.${tabKey}.description`);

  const updatePrepopulatedUserDetails = (user) => {
    setPrepopulatedUserDetails({
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname
    });
  }

  useEffect(() => {
    if (invitedUser && selectedSegment !== FORM_TAB) setSelectedSegment(FORM_TAB);
  }, [invitedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) updatePrepopulatedUserDetails(user);
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSendInvitation = (fields, successCallback, errorCallback) => {
    sendInvitation(
      {
        first_name: fields.first_name || fields.firstname,
        last_name: fields.last_name || fields.lastname,
        email: fields.email,
        user_type: LEGAL_REPRESENTATIVE
      },
      (user) => successCallback && successCallback(user),
      (err) => {
        const response = err.response.data;
        const formErrors = typeof (response.detail) === 'object'
          ? cardsHelpers.getBankingResponseErrorFields(response)
          : [{name: 'email', errors: [response.detail]}];
        errorCallback && errorCallback(formErrors);
      }
    );
  }

  const handleSubmitInvitationForm = (fields, successCallback, errorCallback) => {
    handleSendInvitation(
      fields,
      (user) => {
        successCallback && successCallback();
        handleLoadUser && handleLoadUser();
        updatePrepopulatedUserDetails(user);
      },
      errorCallback
    );
  }

  const handleSubmitInformationForm = (fields, successCallback, errorCallback) => {
    const data = {
      ...fields,
      birthday: fields.birthday.format('YYYY-MM-DD'),
      user_type: LEGAL_REPRESENTATIVE
    }

    const successFunc = (user) => {
      createTaxResidence(
        {
          ...kycHelpers.getTaxResidenceFormData(data),
          user_id: user.id
        },
        () => {
          successCallback && successCallback();
          onFinish && onFinish({user});
          getCompanyState();
        },
        (response) => {
          const formErrors = kycHelpers.getTaxNumberFormErrors(response);
          errorCallback && errorCallback(formErrors);
        }
      );
    }

    const errorFunc = (response) => {
      let formErrors = cardsHelpers.getBankingResponseErrorFields(response, t);
      errorCallback && errorCallback(formErrors);
    }

    const handleUpdateUser = (user) => {
      updateUser(
        user.id,
        data,
        successFunc,
        errorFunc
      );
    }

    if (user) {
      handleUpdateUser(user);
    } else {
      handleSendInvitation(fields, handleUpdateUser, errorCallback);
    }
  }

  const selectedComponent = useMemo(() => {
    const components = {
      'copy': invitationLink
        ? (
          <StyledLegalRepresentativeInformationCopyField
            buttonType='icon'
            onCopy={() => alertActions.success(`${t('copied')}!`)}
            value={invitationLink}
          />
        ) : (
          <StyledLegalRepresentativeInformationLinkButton
            onClick={() => setSelectedSegment('invitation')}
            size='large'
            type='link'
          >
            {tabDescription('shareLink')}
          </StyledLegalRepresentativeInformationLinkButton>
        ),
      'invitation': (
        <LegalRepresentativeInvitationForm
          ref={invitationFormRef}
          onSubmit={handleSubmitInvitationForm}
        />
      ),
      'form': (
        <LegalRepresentativeInformationForm
          defaultFormValues={prepopulatedUserDetails}
          onSubmit={handleSubmitInformationForm}
          handleEnableSubmit={setTreezorRulesAccepted}
          ref={informationFormRef}
        />
      ),
    }
    return components[selectedSegment] || undefined;
  }, [selectedSegment, prepopulatedUserDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    const actions = {
      [INVITATION_TAB]: () => invitationFormRef.current.submit(),
      [FORM_TAB]: () => informationFormRef.current.submit()
    }
    if (actions.hasOwnProperty(selectedSegment)) actions[selectedSegment]();
  }

  return (
    <StyledLegalRepresentativeInformation
      direction='vertical'
      size={32}
      {...rest}
    >
      <Space
        direction='vertical'
        size='middle'
      >
        <StyledKYCModalContentStepTitle>
          {title}
        </StyledKYCModalContentStepTitle>
        <p>{description}</p>
      </Space>
      <Space
        direction='vertical'
        size='large'
      >
        <StyledLegalRepresentativeInformationSegmented
          block={true}
          onChange={setSelectedSegment}
          options={segmentedOption}
          size='large'
          value={selectedSegment}
        />
        {selectedComponent}
      </Space>
      <input type='submit' onClick={(e) => (submitEnabled && handleSubmit) && handleSubmit(e)} ref={ref} className='d-none' />
    </StyledLegalRepresentativeInformation>
  );
});

LegalRepresentativeInformation.propTypes = {
  handleUpdateOkEnable: PropTypes.func,
  handleLoadUser: PropTypes.func,
  onFinish: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string
  })
}

const mapStateToProps = state => {
  const {invitedUser} = state.banking;
  return {
    invitedUser
  }
}

const mapDispatchToProps = {
  createUser: bankingActions.createUser,
  createTaxResidence: bankingActions.createTaxResidence,
  getCompanyState: bankingActions.getCompanyState,
  getInvitationDetails: bankingActions.getInvitationDetails,
  sendInvitation: bankingActions.sendJoinInvitation,
  updateUser: bankingActions.updateUser,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegalRepresentativeInformation);
