const userStatusesConstants = Object.freeze({
  ACTIVE: 0,
  PENDING: 1,
  DISABLED: 2,
  EXTERNAL: 3
});

const userRolesConstants = Object.freeze({
  USER: 0,
  MANAGER: 1,
  ADMIN: 2,
  SUPPORT: 3
});

const userViewTypesConstants = Object.freeze({
  ALL: 0,
  ACTIVE: 1,
  DISABLED: 3,
  EXTERNAL: 2
});

export {
  userRolesConstants,
  userStatusesConstants,
  userViewTypesConstants
};
