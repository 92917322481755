import React from 'react';

const TransactionDeclinedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="10" width="14" height="14" rx="7" fill="#FF4D4F"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M19.9816 14.0421C20.1864 14.2474 20.1859 14.5799 19.9806 14.7846L17.7508 17.0086L19.9698 19.2276C20.1749 19.4327 20.1749 19.7651 19.9698 19.9701C19.7648 20.1751 19.4324 20.1751 19.2274 19.9701L17.0074 17.7501L14.7766 19.975C14.5713 20.1798 14.2389 20.1794 14.0341 19.9741C13.8294 19.7688 13.8298 19.4364 14.0351 19.2316L16.2649 17.0076L14.0302 14.7729C13.8251 14.5678 13.8251 14.2354 14.0302 14.0304C14.2352 13.8254 14.5676 13.8254 14.7726 14.0304L17.0083 16.2661L19.2391 14.0412C19.4444 13.8364 19.7768 13.8369 19.9816 14.0421Z"
          fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M10 8.075H16C16.2652 8.075 16.5196 7.96833 16.7071 7.77844C16.8946 7.58856 17 7.33103 17 7.0625C17 6.79397 16.8946 6.53643 16.7071 6.34655C16.5196 6.15667 16.2652 6.05 16 6.05H10C9.73478 6.05 9.48043 6.15667 9.29289 6.34655C9.10536 6.53643 9 6.79397 9 7.0625C9 7.33103 9.10536 7.58856 9.29289 7.77844C9.48043 7.96833 9.73478 8.075 10 8.075ZM8 12.125H10C10.2652 12.125 10.5196 12.0183 10.7071 11.8284C10.8946 11.6386 11 11.381 11 11.1125C11 10.844 10.8946 10.5864 10.7071 10.3966C10.5196 10.2067 10.2652 10.1 10 10.1H8C7.73478 10.1 7.48043 10.2067 7.29289 10.3966C7.10536 10.5864 7 10.844 7 11.1125C7 11.381 7.10536 11.6386 7.29289 11.8284C7.48043 12.0183 7.73478 12.125 8 12.125ZM8 16.175C8.26522 16.175 8.51957 16.0683 8.70711 15.8784C8.89464 15.6886 9 15.431 9 15.1625C9 14.894 8.89464 14.6364 8.70711 14.4466C8.51957 14.2567 8.26522 14.15 8 14.15C7.73478 14.15 7.48043 14.2567 7.29289 14.4466C7.10536 14.6364 7 14.894 7 15.1625C7 15.431 7.10536 15.6886 7.29289 15.8784C7.48043 16.0683 7.73478 16.175 8 16.175Z"
      fill="#B5B8BF"/>
    <path
      d="M20 2H4C3.73478 2 3.48043 2.10667 3.29289 2.29655C3.10536 2.48643 3 2.74397 3 3.0125V21.2375C3 21.4255 3.05171 21.6098 3.14935 21.7698C3.24698 21.9298 3.38668 22.059 3.55279 22.1431C3.71889 22.2272 3.90484 22.2628 4.08981 22.2459C4.27477 22.229 4.45143 22.1603 4.6 22.0475L6.67 20.4781L7.83143 21.2518C8.47215 21.6786 9.33 21.2192 9.33 20.4494C9.33 20.1485 9.18951 19.8648 8.95015 19.6825L7.27 18.4025C7.0969 18.2711 6.88637 18.2 6.67 18.2C6.45363 18.2 6.2431 18.2711 6.07 18.4025L5 19.2125V4.025H19V8.01786C19 8.57014 19.4477 9.01786 20 9.01786C20.5523 9.01786 21 8.57014 21 8.01786V3.0125C21 2.74397 20.8946 2.48643 20.7071 2.29655C20.5196 2.10667 20.2652 2 20 2Z"
      fill="#B5B8BF"/>
  </svg>
);

export default TransactionDeclinedIcon;
