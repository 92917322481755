import styled from 'styled-components';
import Tabs from '../../components/Tabs';

const StyledSubscriptionPageContainer = styled.div`
  padding: 16px 24px 24px 24px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    background-color: unset;
    border: unset;
    min-height: unset;
    padding: unset;
  }
`;

export {
  StyledSubscriptionPageContainer,
  StyledTabs
};
