import React from 'react';
import {Avatar, Badge} from 'antd';
import PropTypes from 'prop-types';
import {helpers} from '../../helpers';
import {
  StyledNotification,
  StyledNotificationContent,
} from './StyledNotifications';

const getDate = (date) => helpers.getDate(new Date(date * 1000), 'DD/MM/YY')

const Notification = ({
  avatar,
  isRead,
  message,
  timestamp,
  ...rest
}) => {

  const getAvatarProps = (propName) => helpers.getObjProp(avatar, propName);

  const src = getAvatarProps('src');
  const initials = helpers.getInitials(getAvatarProps('name'));

  return (
    <StyledNotification {...rest}>
      <StyledNotificationContent>
        <Badge
          className='d-flex align-items-start'
          color='#00C887'
          dot={!isRead}
        >
          <Avatar src={src}>
            {initials}
          </Avatar>
          <div className='description'>
            {message}
            <br/>
            {timestamp && (
              <span className='date'>
                {getDate(timestamp)}
              </span>
            )}
          </div>
        </Badge>
      </StyledNotificationContent>
    </StyledNotification>
  );
}

Notification.propTypes = {
  avatar: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string
  }),
  isRead: PropTypes.bool,
  message: PropTypes.string.isRequired,
  src: PropTypes.string,
  timestamp: PropTypes.number
}

Notification.defaultProps = {
  isRead: true
}

export default Notification;
