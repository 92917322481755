import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledLegalRepresentativeVerifyIdentity,
  StyledLegalRepresentativeVerifyIdentitySpace,
  StyledLegalRepresentativeVerifyIdentityInformation
} from './StyledLegalRepresentativeVerifyIdentity';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import {kycHelpers} from '../../kycHelpers';
import SelectionPanel from '../../stepsComponents/SelectionPanel';
import {ReactComponent as DrivingLicenseSvgImage} from '../../../../../../static/images/pages/kyc/verify-identity-driving-license.svg';
import {ReactComponent as IdCardSvgImage} from '../../../../../../static/images/pages/kyc/verify-identity-idcard.svg';
import {ReactComponent as PassportSvgImage} from '../../../../../../static/images/pages/kyc/verify-identity-passport.svg';

const formKey = 'lrVerifyIdentity';

const LegalRepresentativeVerifyIdentity = ({
  onSelect,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'quickStart']);

  const trans = (key) => t(`quickStart:kyc.steps.${formKey}.documentTypes.${key}`);

  const {
    description,
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey}), [t]);

  const getItemDetails = (key) => ({
    key,
    description: trans(`${key}.description`),
    onClick: (e) => onSelect && onSelect({e, key}),
    title: trans(`${key}.title`)
  });

  const items = useMemo(() => [
    {
      ...getItemDetails('passport'),
      image: <PassportSvgImage />,
    },
    {
      ...getItemDetails('id-card'),
      image: <IdCardSvgImage />,
    },
    {
      ...getItemDetails('driving-license'),
      image: <DrivingLicenseSvgImage />,
    }
  ], []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledLegalRepresentativeVerifyIdentity {...rest}>
      <StyledLegalRepresentativeVerifyIdentitySpace
        direction='vertical'
        size='large'
      >
        <StyledLegalRepresentativeVerifyIdentityInformation
          direction='vertical'
          size='middle'
        >
          <StyledKYCModalContentStepTitle>
            {title}
          </StyledKYCModalContentStepTitle>
          <p>
            {description}
          </p>
        </StyledLegalRepresentativeVerifyIdentityInformation>
        <SelectionPanel items={items} />
      </StyledLegalRepresentativeVerifyIdentitySpace>
    </StyledLegalRepresentativeVerifyIdentity>
  );
}

LegalRepresentativeVerifyIdentity.propTypes = {
  onSelect: PropTypes.func
}

export default LegalRepresentativeVerifyIdentity;
