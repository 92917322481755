import {Space} from 'antd';
import styled from 'styled-components';
import Table from '../../../Table';

const StyledTransactionsTable = styled(Table)`
  &.collapsed {
    .card-number {
      display: none;
    }
  }

  .transaction-type {
    align-items: center;
    display: flex;

    svg {
      height: 24px;
      width: 24px;

      path:not([fill-rule="evenodd"]) {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }

  .transaction-icon {
    svg {
      height: 24px;
      width: 24px;

      path {
        &:not([fill-rule="evenodd"]) {
          fill: ${props => props.theme.colors.neutral_6};
        }
      }
    }
  }
  
  .returned-price {
    color: ${props => props.theme.colors.neutral_7};
    text-decoration: line-through;
  }
`;

const StyledTransactionTableDownloadSpace = styled(Space)`
  & > .ant-space-item {
    display: flex;
  }
  
  button {
    background-color: transparent;
    border: none;

    &:hover {
      background-color: ${props => props.theme.colors.light_darker};

      svg {
        path {
          fill: ${props => props.theme.colors.primary_6};
        }
      }
    }

    svg {
      path {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

export {
  StyledTransactionsTable,
  StyledTransactionTableDownloadSpace
};
