export const titleOptions = [
  {label: 'Mr', value: 'M'},
  {label: 'Mrs', value: 'MME'},
  {label: 'Miss', value: 'MLLE'},
];

export const incomeRangeOptions = [
  {label: '€0 - €18.000', value: '0-18'},
  {label: '€19.000 - €23.000', value: '19-23'},
  {label: '€24.000 - €27.000', value: '24-27'},
  {label: '€28.000 - €35.000', value: '28-35'},
  {label: '€36.000 - €56.000', value: '36-56'},
  {label: '€57.000 - *', value: '57-*'}
];

export const personalAssetsOptions = [
  {label: '€0 - €2.000', value: '0-2'},
  {label: '€3.000 - €22.000', value: '3-22'},
  {label: '€23.000 - €128.000', value: '23-128'},
  {label: '€129.000 - €319.000', value: '129-319'},
  {label: '€320.000 - €464.000', value: '320-464'},
  {label: '€465.000 - *', value: '465-'}
];
