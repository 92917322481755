import styled from 'styled-components';
import {Card} from 'antd';
import {Grow} from '@mui/material';
import {TransitionGroup} from 'react-transition-group';
import SubscriptionServiceSelect from '../SubscriptionServiceSelect';

const StyledTransferListItem = styled(Card)`
  .ant-card-body {
    padding: 24px 16px;
    > .ant-space {
      width: 100%;
    }
  }

  > .ant-card-head {
    border-bottom: unset;
    font-size: 16px;
    font-weight: 400;
    padding: 0 12px;
  }

  > .ant-card-extra {
    button {
      color: ${props => props.theme.colors.neutral_6};
      padding: 2px 4px;
    }
  }
`;

const StyledSearchResults = styled.div`
  position: relative;
`;

const StyledCardOption = styled(Card)`
  width: calc(50% - 4px);
  margin-bottom: 8px;
  &:nth-of-type(2n) {
    margin-left: 8px;
  }

  > .ant-card-head {
    background-color: ${props => props.theme.colors.neutral_2};
    color: ${props => props.theme.colors.neutral_10};
    font-weight: 400;
    font-size: 14px;
    padding: 0 12px;
  }

  > .ant-card-body {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 12px;
    padding: 12px;
  }

  > .ant-card-extra {
    margin-left: 8px;
  }
`;

const StyledEmptyGrow = styled(Grow)`
  position: absolute;
  top: 0;
  width: 100%;
`;

const StyledListTransitionGroup = styled(TransitionGroup)`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: start;
  padding: 4px 0;
  height: 400px;
  overflow: hidden auto;

  &.single-option {
    .service-option {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const StyledSearch = styled(SubscriptionServiceSelect)`
  & > * {
    width: 100%;
  }
`;

export {
  StyledTransferListItem,
  StyledSearchResults,
  StyledCardOption,
  StyledEmptyGrow,
  StyledListTransitionGroup,
  StyledSearch
}
