import styled from 'styled-components';
import {Space} from 'antd';

const StyledSubscriptionSelect = styled.div`
  position: relative;
  width: 100%;
  
  input[type="search"] {
    font-size: 16px;
  }
`;

const StyledSubscriptionSelectDetailsSpace = styled(Space)`
  justify-content: space-between;
  width: 100%;

  .ant-avatar {
    border: 1px solid ${props => props.theme.colors.neutral_3};
  }

  h3, p {
    margin-bottom: 0;
  }

  h3 {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 14px;
  }
  
  .ant-btn-circle {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const StyledSubscriptionSelectDropdownHint = styled(Space)`
  border-bottom: 1px solid ${props => props.theme.colors.neutral_5};
  padding: 5px 12px;
  width: 100%;

  & > .ant-space-item {
    display: flex;
  }
  
  span[role="img"] {
    color: ${props => props.theme.colors.primary_6};
  }
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 13px;
    line-height: 22px;
    margin: 0;
    padding: 0;
  }
`;


export {
  StyledSubscriptionSelect,
  StyledSubscriptionSelectDetailsSpace,
  StyledSubscriptionSelectDropdownHint
}
