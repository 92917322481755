import styled from 'styled-components';
import {Card} from 'antd';

const StyledPersonalInfo = styled(Card)`
  .ant-card-head {
    flex-direction: unset;
    padding: 24px;
  }
  .user-info {
    padding: 0;
  }
  
  .title-error-message {
    color: ${props => props.theme.colors.functional_danger} !important;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
  }
`;

const StyledPasswordItem = styled.span`
  background-color: ${props => props.theme.colors.neutral_4};
  border: 1px solid ${props => props.theme.colors.neutral_6};
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
`;

const StyledTitle = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
`;

export {
  StyledPersonalInfo,
  StyledPasswordItem,
  StyledTitle
}
