import styled from 'styled-components';
import {Button, Space} from 'antd';

const StyledLegalRepresentativeConfirmation = styled(Space)`
  width: 100%;
  
  .ant-alert {
    align-items: baseline;
  }
`;

const StyledLegalRepresentativeConfirmationButtonsSpace = styled(Space)`
  width: 100%;
`;

const StyledLegalRepresentativeConfirmationButton = styled(Button)`
  width: 100%;
  
  &.ant-btn.ant-btn-lg {
    height: 88px;
  }
`;

export {
  StyledLegalRepresentativeConfirmation,
  StyledLegalRepresentativeConfirmationButton,
  StyledLegalRepresentativeConfirmationButtonsSpace
};
