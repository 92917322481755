import styled from 'styled-components';
import {Button, Space} from 'antd';

const StyledInvoiceTabContainer = styled(Space)`
  position: relative;
  width: 100%;
  
  .amount {
    text-wrap: nowrap;
  }
  
  .invoice-match {
    align-items: center;
    justify-content: center;
    display: flex;
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;


export {
  StyledInvoiceTabContainer,
  StyledButton,
};
