import {request} from '../services/request';
import {backendEndpoints} from '../../api';

const subscriptionRequestTemplate = (url, success, error, query) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url,
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data)
    });
  }
}

const getOverview = (query, success, error) => subscriptionRequestTemplate(backendEndpoints.GET_OVERVIEW, success, error, query);

const getOverviewSubscriptionsTotals = (query, success, error) => subscriptionRequestTemplate(backendEndpoints.GET_OVERVIEW_SUBSCRIPTIONS_TOTALS, success, error, query);

const getYearlyOverview = (query, success, error) => subscriptionRequestTemplate(backendEndpoints.GET_YEAR_OVERVIEW, success, error, query);

export const overviewActions = {
  getOverview,
  getOverviewSubscriptionsTotals,
  getYearlyOverview
};
