import { mainConstants } from '../constants';
import {localStorageKeysConstants, KYCModalModeTypes} from '../../constants';

const {COMPANY} = KYCModalModeTypes;

const mainState = {
  currencies: [],
  initialLoading: !!localStorage.getItem(localStorageKeysConstants.TOKEN),
  isOpenMenu: true,
  kycWindowProps: {closeIcon: true, open: false, mode: COMPANY},
  lightKycWindowProps: {open: false},
  loading: false,
}

const mainReducer = (state = mainState, action) => {
  const {type, data, loading} = action;
  switch (type) {
    case mainConstants.SET_LOADING:
      return {
        ...state,
        loading
      }
    case mainConstants.SET_INITIAL_LOADING:
      return {
        ...state,
        initialLoading: loading
      }
    case mainConstants.GET_CURRENCIES:
      return {
        ...state,
        currencies: data
      }
    case mainConstants.COLLAPSE_NAVIGATION_MENU:
      return {
        ...state,
        isOpenMenu: !state.isOpenMenu
      }
    case mainConstants.OPEN_KYC_WINDOW:
      return {
        ...state,
        kycWindowProps: {
          closeIcon: data.closeIcon || true,
          mode: data.mode || COMPANY,
          open: true
        }
      }
    case mainConstants.CLOSE_KYC_WINDOW:
      return {
        ...state,
        kycWindowProps: {...state.kycWindowProps, open: false}
      }
    case mainConstants.OPEN_LIGHT_KYC_WINDOW:
      return {
        ...state,
        lightKycWindowProps: {...state.lightKycWindowProps, open: true}
      }
    case mainConstants.CLOSE_LIGHT_KYC_WINDOW:
      return {
        ...state,
        lightKycWindowProps: {...state.lightKycWindowProps, open: false}
      }
    default:
      return state
  }
};

export default mainReducer;
