import styled, {css} from 'styled-components';
import {Alert, Button} from 'antd';
import {typography} from "../../utils/css";

const enableLimitClassName = '.enable-limit'

const StyledCreditCard = styled.div`
  border-radius: ${props => props.error ? '16px' : '16px 16px 0 0'};
  height: ${props => props.error ? '177px' : '197px'};
  padding: ${props => props.error ? '72px 24px 28px 24px' : '32px 24px 28px 24px'};
  width: 400px;
  position: relative;
  
  ${props => props.variant === 'light' ? css`
    background-color: ${props => props.theme.colors.additional_apple};
    color: ${props => props.theme.colors.neutral_10};
  ` : css`
    background-color: #40B0D5;
    color: ${props => props.theme.colors.neutral_1};
  `}
  
  &${enableLimitClassName} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const StyledShowButton = styled(Button)`
  &.ant-btn-circle.ant-btn-lg {
    background-color: ${props => props.theme.colors.primary_8};
    border-color: ${props => props.theme.colors.primary_8};
    color: ${props => props.theme.colors.neutral_1};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    left: 0;
    min-width: 48px;
    position: absolute;
    top: 0;
    transform: translateX(-6px);
    width: 48px;

    &:not(:disabled):not(.ant-btn-disabled) {
      &:hover, &:active, &:focus {
        background-color: ${props => props.theme.colors.primary_7};
        border-color: ${props => props.theme.colors.primary_7};
        color: ${props => props.theme.colors.neutral_1};
      }
    }

    .ant-btn-icon .anticon {
      font-size: 28px;
    }
  }
`;

const StyledCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding-top: 24px;
  position: relative;
  text-transform: uppercase;
  
  .card-logo {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const StyledCardOwner = styled.div`
  ${typography.body.bold};
  margin-bottom: 10px;
`;

const StyledCardNumber = styled.div`
  ${typography.heading.h4};
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  margin-top: ${props => props.isPlaceholder ? 28 : 0}px;
  & > span {
    line-height: 21px;
    display: inline-block;
    
    &:not(:last-of-type) {
      margin-right: 12px;
    }
    
    &.top {
      line-height: 26px;
      height: 26px;
    }
  }
`;

const StyledCardFooter = styled.footer`
  display: flex;
  justify-content: space-between;
`;

const StyledCVVCode = styled.div`
  ${typography.body.medium};
  display: flex;
  flex-direction: column;
  
  span {
    ${typography.body.regular};
    margin-bottom: 8px;
  }
`;

const StyledAlert = styled(Alert)`
  align-items: start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: absolute;
  width: 100%;
  left: 0;
  top: -1px;
  z-index: 2;
  
  span[role="img"] {
    margin-top: 3px;
  }
`;

const StyledBlockedWindow = styled.div`
  background-color: rgba(217, 217, 217, 0.6);
  height: 100%;
  border-radius: ${props => props.fullBordered ? '16px' : props.isPlaceholder ? '16px 16px 0 0' : '16px'};
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  
  &${enableLimitClassName} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const StyledCreditCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const StyledLimit = styled.div`
  background-color: ${props => props.theme.colors.neutral_3};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px;
  width: 100%;
  
  .ant-progress-line {
    margin-bottom: 0;
  }
`;

const StyledLimitHelper = styled.div`
  ${typography.body.regular};
  align-items: center;
  color: ${props => props.theme.colors.neutral_8};
  display: flex;
  justify-content: space-between;

  .ant-progress {
    &.ant-progress-status-success {
      background-color: ${props => props.theme.colors.primary_4};
    }
    &.ant-progress-status-exception {
      background-color: ${props => props.theme.colors.functional_danger};
    }
  }
`;

const StyledErrorMessage = styled.div`
  font-size: 16px;
  text-align: center;
`;

export {
  StyledAlert,
  StyledCreditCard,
  StyledCardDetails,
  StyledShowButton,
  StyledCardOwner,
  StyledCardNumber,
  StyledCardFooter,
  StyledCVVCode,
  StyledBlockedWindow,
  StyledCreditCardContainer,
  StyledLimit,
  StyledLimitHelper,
  StyledErrorMessage
}
