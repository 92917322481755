import React from 'react';

const AvatarFilledIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4595 19.0163C21.5887 17.6588 22.3741 16.0495 22.7492 14.3246C23.1243 12.5997 23.0781 10.8099 22.6145 9.10667C22.1509 7.40339 21.2835 5.83672 20.0858 4.53921C18.888 3.2417 17.3952 2.25152 15.7334 1.65242C14.0717 1.05332 12.29 0.862936 10.5391 1.09737C8.78812 1.3318 7.11944 1.98415 5.67418 2.99924C4.22893 4.01433 3.04962 5.36229 2.23601 6.9291C1.42241 8.4959 0.998449 10.2355 0.999997 12.0006C1.00059 14.5666 1.90559 17.0505 3.55618 19.0163L3.54045 19.0296C3.59549 19.0956 3.6584 19.1522 3.71501 19.2174C3.78577 19.2983 3.86204 19.3745 3.93516 19.4531C4.15532 19.6919 4.38177 19.9213 4.61922 20.1366C4.69156 20.2026 4.76626 20.2639 4.83938 20.3267C5.09099 20.5436 5.34967 20.7494 5.61779 20.9411C5.65239 20.9647 5.68384 20.9953 5.71844 21.0196V21.0102C7.55998 22.3051 9.75683 23 12.0086 23C14.2605 23 16.4573 22.3051 18.2989 21.0102V21.0196C18.3334 20.9953 18.3641 20.9647 18.3995 20.9411C18.6668 20.7486 18.9263 20.5436 19.1779 20.3267C19.251 20.2639 19.3257 20.2018 19.3981 20.1366C19.6355 19.9205 19.862 19.6919 20.0821 19.4531C20.1552 19.3745 20.2307 19.2983 20.3023 19.2174C20.3581 19.1522 20.4218 19.0956 20.4768 19.0289L20.4595 19.0163ZM12.0079 5.71556C12.7077 5.71556 13.3917 5.9229 13.9736 6.31137C14.5555 6.69984 15.009 7.25198 15.2768 7.89798C15.5446 8.54398 15.6146 9.25482 15.4781 9.94061C15.3416 10.6264 15.0046 11.2563 14.5098 11.7508C14.0149 12.2452 13.3845 12.5819 12.6981 12.7183C12.0118 12.8547 11.3004 12.7847 10.6538 12.5171C10.0073 12.2495 9.45471 11.7964 9.06592 11.215C8.67713 10.6336 8.46962 9.95012 8.46962 9.2509C8.46962 8.31327 8.8424 7.41404 9.50595 6.75103C10.1695 6.08803 11.0695 5.71556 12.0079 5.71556ZM5.72316 19.0163C5.7368 17.9847 6.15635 17 6.89106 16.2752C7.62576 15.5503 8.61654 15.1437 9.64903 15.1431H14.3667C15.3992 15.1437 16.39 15.5503 17.1247 16.2752C17.8594 17 18.2789 17.9847 18.2926 19.0163C16.5681 20.569 14.3291 21.4282 12.0079 21.4282C9.68657 21.4282 7.44758 20.569 5.72316 19.0163Z"
      fill="#000"/>
  </svg>
);

export default AvatarFilledIcon;
