import styled from 'styled-components';
import {Space} from 'antd';

const StyledSubscriptionUsers = styled.div`
  position: relative;
`;

const StyledSubscriptionUsersTooltipContent = styled(Space)`
  .ant-btn {
    padding-left: 0;
    padding-right: 0;
    
    color: ${props => props.theme.colors.primary_5};
    
    &:hover {
      color: ${props => props.theme.colors.primary_3};
    }
  }
`;


export {
  StyledSubscriptionUsers,
  StyledSubscriptionUsersTooltipContent,
}