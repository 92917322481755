import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';
import TransferListItem from '../TransferListItem';
import {StyledTransferList, StyledEmptyState} from './StyledTransferList';
import {ArrowLeftIcon, ArrowRightIcon} from '../../../../../icons';


const TransferList = ({
  onChange,
  onSearch,
  selected,
  services,
  optionPropName,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unselectedItems, setUnselectedItems] = useState([]);

  const emptyT = (key) => t(`subscriptions:modal.addMultipleSubscription.empty.${key}`);

  useEffect(() => {
    setUnselectedItems(services);
  }, [services]);

  useEffect(() => {
    const selectedUrls = selected.map(s => s[optionPropName]);
    setSelectedItems(selected);
    setUnselectedItems(services.filter(s => s[optionPropName] === null || !selectedUrls.includes(s[optionPropName])))
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (items) => onChange && onChange(items);

  const onUnselectedItemClick = (item) => {
    const updateSelectedItems = [...selectedItems, {...item, id: uuidv4()}];
    setSelectedItems(updateSelectedItems);
    const index = unselectedItems.findIndex(i => i[optionPropName] === item[optionPropName]);
    if (index >= 0) {
      const arrayCopy = [...unselectedItems];
      arrayCopy.splice(index, 1);
      setUnselectedItems(arrayCopy);
    }
    handleChange(updateSelectedItems);
  }

  const onSelectedItemClick = (item) => {
    setUnselectedItems([...unselectedItems, item]);
    const index = selectedItems.findIndex(i => i.url === item.url);
    if (index >= 0) {
      const arrayCopy = [...selectedItems];
      arrayCopy.splice(index, 1);
      setSelectedItems(arrayCopy);
      handleChange(arrayCopy);
    }
  }

  const handleSelectAll = () => {
    setUnselectedItems([]);
    setSelectedItems(services);
    handleChange(services);
  }

  const handleUnSelectAll = () => {
    setUnselectedItems(services);
    setSelectedItems([]);
    handleChange([]);
  }

  const emptyState = (
    <StyledEmptyState>
      {emptyT('description1')}
      <br/>
      {emptyT('description2')}
    </StyledEmptyState>
  )

  return (
    <StyledTransferList
      size='middle'
      {...rest}
    >
      <TransferListItem
        emptyState={emptyState}
        icon={<ArrowRightIcon /> }
        items={unselectedItems}
        isEnabledAddNew={true}
        onBulkButtonClick={handleSelectAll}
        onItemClick={onUnselectedItemClick}
        onSearch={onSearch}
        title={`${t('all')} ${t('subscriptions')}`}
        variant='external'
      />
      <TransferListItem
        bulkButtonText={`${t('remove')} ${t('all')}`}
        icon={<ArrowLeftIcon /> }
        items={selectedItems}
        isEnabledBulkButton={false}
        onBulkButtonClick={handleUnSelectAll}
        onItemClick={onSelectedItemClick}
        title={`${t('Selected')} ${t('subscriptions')}`}
        variant='internal'
      />
    </StyledTransferList>
  );
}

TransferList.propTypes = {
  onChange: PropTypes.func,
  optionPropName: PropTypes.string,
  onSearch: PropTypes.func,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  )
}

TransferList.defaultProps = {
  optionPropName: 'name',
  services: [],
}

export default TransferList;

