import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import {StyledCompanyInviteRegisterModal} from './StyledCompanyInviteRegisterModal';
import {ReactComponent as InvitedSvgImage} from '../../../static/images/auth/invited.svg';

const CompanyInviteRegisterModal = ({
  open,
  handleOk,
  handleSignIn,
  handleClose,
  isValid,
  ...rest
}) => {
  const [trans] = useTranslation(['quickStart', 'main', 'settings']);
  const t = (key) => trans(`companyInviteRegister.${key}`);

  const onCancel = () => !isValid && handleClose && handleClose();

  return (
    <StyledCompanyInviteRegisterModal
      closeIcon={!isValid}
      onCancel={onCancel}
      open={open}
      footer={false}
      width={408}
      {...rest}
    >
      <InvitedSvgImage />
      <h3>{t('title')}</h3>
      {isValid ? (
        <>
          <p>{t('description')}</p>
          <Button
            onClick={handleOk}
            type='primary'
            size='large'
          >
            {t('imNewButton')}
          </Button>
          <Button
            onClick={handleSignIn}
            type='default'
            size='large'
          >
            {t('alreadyHaveButton')}
          </Button>
        </>
      ) : (
        <p>{trans('settings:pages.invite.linkExpired')}</p>
      )}
    </StyledCompanyInviteRegisterModal>
  );
}

CompanyInviteRegisterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  handleSignIn: PropTypes.func,
  isValid: PropTypes.bool
}

CompanyInviteRegisterModal.defaultProps = {
  isValid: true
}

export default CompanyInviteRegisterModal;
