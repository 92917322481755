const notificationTypesConstants = Object.freeze({
  ADD_USERS: 0,
  USER_REGISTERED: 1,

  MANAGE_EXPENSES: 2,
  EXPENSE_REJECTED: 3,
  EXPENSE_PAID: 4,
  EXPENSE_NEW_NOTE: 5,
  EXPENSE_BUDGET_OWNER_NEW_NOTE: 6,
  EXPENSE_SCHEDULED_PAID: 7,

  MANAGER_REPORTS: 8,
  REPORT_CLOSED: 9,

  BUDGET_EXCEEDED: 10,
  BUDGET_ASSIGNED: 11,
  BUDGET_INCREASE_REQUEST: 12,
  BUDGET_CARD_IS_ACTIVATED: 13,
  BUDGET_CARD_IS_DEACTIVATED: 14,

  BANK_CARD_IS_BLOCKED: 15,
  BANK_FUNDS_IS_CLOSE_TO_ZERO: 16,
  BANK_SUSPICIOUS_TRANSACTIONS: 17,
  BANK_CARD_IS_ABOUT_TO_EXPIRE: 18,
  BANK_H1_CARD_IS_ABOUT_TO_EXPIRE: 19,

  COMPANY_DOCUMENTS_ARE_VALIDATED: 20,

  CARD_TRANSACTION_CREATE: 21,
  CARD_CREATE: 22,
  CARD_ACTIVATE: 23,
  CARD_LIMITS_CHANGE: 24,
  CARD_UNBLOCK_PIN: 25,
  CARD_LOST: 26,
  CARD_STOLEN: 27,
  CARD_DESTROYED: 28,
  CARD_BLOCK: 29,
  CARD_UNBLOCK: 30,
  PAY_OUT_PENDING: 31,
  PAY_OUT_CANCELLED: 32,
  PAY_OUT_VALIDATED: 33,
  PAY_IN_PENDING: 34,
  PAY_IN_CANCELLED: 35,
  PAY_IN_VALIDATED: 36,

  NOT_ENOUGH_WALLET_LIMIT: 37,

  SUBSCRIPTION_CARD_CREATE: 38,
  SUBSCRIPTION_CARD_BLOCKED: 39,
  SUBSCRIPTION_CARD_UNBLOCKED: 40,

  SUBSCRIPTION_TRANSACTION_REFUSED: 41,
  SUBSCRIPTION_TRANSACTION_PROCESSED: 42,

  SUBSCRIPTION_DUE_PAYMENT: 43,
  SUBSCRIPTION_NO_INVOICE: 44,
});

export {notificationTypesConstants};
