import styled from 'styled-components';

const StyledUserDetailsForm = styled.div`
  position: relative;
  width: 100%;
`;

export {
  StyledUserDetailsForm
};
