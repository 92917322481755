import styled from 'styled-components';

const RIGHT_DETAILS_CONTENT_WIDTH = '450px';

const StyledOverviewTabContainer = styled.div`
`;

const StyledDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDetailsContent = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-of-type(1) {
    width: calc(100% - 24px - ${RIGHT_DETAILS_CONTENT_WIDTH});
  }

  &:nth-of-type(2) {
    width: ${RIGHT_DETAILS_CONTENT_WIDTH};
  }

  & > div {
    &:not(:nth-of-type(1)) {
      margin-top: 24px;
    }
  }
`;

export {
  StyledOverviewTabContainer,
  StyledDetailsContainer,
  StyledDetailsContent
}
