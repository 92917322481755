export const expenseConstants = {
  EXPORT_EXPENSES: 'EXPORT_EXPENSES',
  GET_EXPENSE: 'GET_EXPENSE',
  GET_EXPENSES: 'GET_EXPENSES',
  GET_EXPENSES_TOTAL: 'GET_EXPENSES_TOTAL',
  UPDATE_EXPENSE: 'UPDATE_EXPENSE',

  UPDATE_EXPENSE_ATTACHMENT_FILE: 'UPDATE_EXPENSE_ATTACHMENT_FILE',
  UPDATE_EXPENSE_STATUS: 'UPDATE_EXPENSE_STATUS',
};
