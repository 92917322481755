export const subscriptionConstants = {
  GET_SUBSCRIPTION_DETAILS: 'GET_SUBSCRIPTION_DETAILS',
  GET_SUBSCRIPTION_LIST: 'GET_SUBSCRIPTION_LIST',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  SET_SUBSCRIPTION_CARD_HOLD: 'SET_SUBSCRIPTION_CARD_HOLD',
  GET_SUBSCRIPTION_VIEWS_STATUSES: 'GET_SUBSCRIPTION_VIEWS_STATUSES',

  CLEAR_SUBSCRIPTIONS: 'CLEAR_SUBSCRIPTIONS',
  CLEAR_SUBSCRIPTION: 'CLEAR_SUBSCRIPTION',

  STORE_SUBSCRIPTIONS: 'STORE_SUBSCRIPTIONS'
};
