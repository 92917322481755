import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCounter,
} from './StyledCounter';

const Counter = ({
  value,
  variant,
  ...rest
}) => {

  return (
    <StyledCounter
      variant={variant}
      {...rest}
    >
      {value}
    </StyledCounter>
  );
}

Counter.propTypes = {
  variant: PropTypes.oneOf(['default', 'error', 'success'])
}

Counter.defaultProps = {
  variant: 'default'
}

export default Counter;
