import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Space, Tooltip} from 'antd'
import {connect} from 'react-redux';
import {helpers} from '../../../../helpers';
import {
  StyledCardPaymentType,
  StyledCardPaymentTypeCardIconSpace,
  StyledCardPaymentTypeEyeIconSpace,
} from './StyledCardPaymentType';
import CardDetailsButton from '../CardDetailsButton';
import {CreditCardWarningIcon, WithLessCreditCardIcon} from '../../../../icons';
import {bankingActions} from '../../../../state/actions';
import unusedCardIcon from '../../../../static/images/pages/subscriptions/unused-card-icon.png';

const tooltipOverlayInnerStyle = {
  maxWidth: 400,
  width: 'max-content'
};

const CardPaymentType = ({
  data,
  dispatch,
  employeeId,
  getUserCardImages,
  getCompanyInformation,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards']);

  const trans = (key, options) => t(`cards:${key}`, options);

  const gObjProp = (key) => helpers.getObjProp(data, key);

  const cardId = gObjProp('card_id');
  const cardMaskedPan = gObjProp('masked_pan');
  const isUnused = gObjProp('is_unused');
  const cardOwnerId = gObjProp('owner', {})?.id || undefined;
  const isCardOwner = cardOwnerId === employeeId;

  const getTooltip = () => {
    let message = undefined;
    if (cardId && cardMaskedPan) {
      if (isUnused) {
        message = (
          <span className='d-flex align-items-center'>
            <span className='d-inline-flex align-items-center'>
              <img src={unusedCardIcon} alt='unused card icon' />&nbsp;&nbsp;{trans('unusedCard')}
            </span>
          </span>
        );
      }
    }
    return message;
  }

  const cardTooltipTitle = getTooltip();

  const getIcon = () => {
    const warning = !(cardId && !isUnused);
    let spaceClassName = gObjProp('subscription_id') ? 'for-subscription' : 'for-personal';
    if (warning) spaceClassName += ` warning`;
    return (
      <StyledCardPaymentTypeCardIconSpace
        className={spaceClassName}
      >
        {warning ? <CreditCardWarningIcon /> : <WithLessCreditCardIcon />}
      </StyledCardPaymentTypeCardIconSpace>
    )
  }

  return (
    <StyledCardPaymentType {...rest}>
      <Space {...rest}>
        <Tooltip
          overlayInnerStyle={tooltipOverlayInnerStyle}
          placement='bottom'
          title={cardTooltipTitle}
        >
          {getIcon()}
        </Tooltip>
        {(isCardOwner && cardId) && (
          <StyledCardPaymentTypeEyeIconSpace>
            <CardDetailsButton
              cardId={cardId}
              cardOwnerId={cardOwnerId}
            />
          </StyledCardPaymentTypeEyeIconSpace>
        )}
      </Space>
    </StyledCardPaymentType>
  );
}

CardPaymentType.propTypes = {
  data: PropTypes.shape({
    card_id: PropTypes.string,
    is_unused: PropTypes.bool,
    masked_pan: PropTypes.string,
    subscription_id: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })
}

const mapStateToProps = state => {
  const {id: employeeId} = state.user.employee;
  return {
    employeeId,
  }
}

const mapDispatchToProps = {
  getUserCardImages: bankingActions.getUserCardImages
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentType);
