import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Avatar, Form, Input, Select, Space} from 'antd';
import {connect} from 'react-redux';
import SubscriptionStatusMark from '../../../../SubscriptionsPage/SubscriptionStatusMark';
import {StyledSubscriptionDetails, StyledTag} from './StyledSubscriptionDetails';
import DetailsTable from '../../../../TransactionsPage/DetailsTable';
import CompanyTagSelect from '../../../../TransactionsPage/CompanyTagSelect/CompanyTagSelect';
import {subscriptionStatusesConstants, subscriptionPaymentTypesConstants} from '../../../../../../constants';
import {helpers} from '../../../../../../helpers';
import {subscriptionsHelpers} from '../../../../SubscriptionsPage/subscriptionsHelpers';

const gObjProp = helpers.getObjProp;
const isAuditedStatus = subscriptionsHelpers.isAuditedStatus;

const {Item} = Form;
const {Option} = Select;

const {ACTIVE, AUDITED, CARD_ERROR, INACTIVE, DELETED, PENDING,
  RESTRICTED, TRIAL, UNMANAGED} = subscriptionStatusesConstants;

const cardRelatedFields = ['card_owner', 'expected_first_payment'];

const SubscriptionDetails = ({
  onSubmit,
  subscription,
  employees,
  employeeEmail,
  dispatch,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    note: '',
    owner: '',
    status: '',
    reason: '',
    tags: []
  });
  const [ownerOptions, setOwnerOptions] = useState([]);

  const tags = Form.useWatch('tags', form);

  const statusOptions = useMemo(() => {
    const status = subscription?.status || undefined;
    const paymentType = subscription?.payment_type || undefined;
    let options = [
      {value: ACTIVE, key: t('subscriptionStatuses.active')},
      {value: TRIAL, key: t('subscriptionStatuses.trial')},
      {value: AUDITED, key: t('subscriptionStatuses.audited')},
      {value: RESTRICTED, key: t('subscriptionStatuses.restricted')},
      {value: UNMANAGED, key: t('subscriptionStatuses.unmanaged')},
      {value: INACTIVE, key: t('subscriptionStatuses.inactive')},
      {value: DELETED, key: t('subscriptionStatuses.deleted')}
    ];
    if (isAuditedStatus(status)) {
      options = options.filter(o => [ACTIVE, AUDITED, TRIAL, RESTRICTED, UNMANAGED, DELETED].includes(o.value));
    } else if (paymentType === subscriptionPaymentTypesConstants.CARD) {
      options = options.filter(o => !isAuditedStatus(o.value));
    }
    return options;
  }, [t, subscription]);

  const gDataProp = (subscription, key, defaultValue) => helpers.getObjProp(subscription, key, defaultValue);

  const trans = (key) => t(`subscriptions:${key}`);

  const owner = gDataProp(subscription, 'owner', {});
  const statusCode = gDataProp(subscription, 'status');
  const isPaused = statusCode === INACTIVE;
  const {note, reason} = initialFormValues;

  const placeholder = (key) => trans(`placeholders.${key}`);

  const ownerAvatarData = {
    label: gObjProp(owner, 'full_name'),
    src: gObjProp(owner, 'logo') || undefined
  }

  useEffect(() => {
    const fieldValues = {
      ...initialFormValues,
      status: gDataProp(subscription, 'status'),
      reason: gDataProp(subscription, 'reason'),
      owner: subscription ? subscription.owner?.email || '' : '',
      tags: gDataProp(subscription, 'tags', []),
      note: gDataProp(subscription, 'note', '')
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    isEditMode && setIsEditMode(false);
  }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = helpers.getEmployeeOptions({employees, employeeEmail, t});
    setOwnerOptions(options);
  }, [employees, employeeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEnableEdit = ![CARD_ERROR, PENDING].includes(statusCode);

  const getStatusCodeMark = (statusCode) => (
    <SubscriptionStatusMark
      statusCode={statusCode}
    />
  );

  const details = [
    {
      key: 'status',
      label: t('status'),
      value: getStatusCodeMark(statusCode)
    },
    {
      key: 'owner',
      label: `${trans('subscription')} ${t('Owner')}`,
      value: (
        <Space>
          {ownerAvatarData.label}
          <Avatar src={ownerAvatarData.src}>
            {helpers.getInitials(ownerAvatarData.label)}
          </Avatar>
        </Space>
      )
    },
    {
      key: 'reason',
      label: t('whatIsItFor'),
      value: reason
    },
    {
      key: 'cost-center',
      label: trans('costCentre'),
      value: tags && tags.map((tag, key) => <StyledTag key={key}>{tag}</StyledTag>)
    },
    {
      direction: 'vertical',
      key: 'note',
      label: t('howToUseIt'),
      value: note
    }
  ];

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const onChangeTags = (tags) => form.setFieldValue('tags', tags);

  const handleSubmit = (fields) => {
    const data = helpers.getUpdatedFormValues({
      initialValues: initialFormValues,
      excludedFields: isPaused ? cardRelatedFields : [],
      submittedValues: fields
    });
    if (Object.keys(data).length > 0 && onSubmit) {
      onSubmit(
        data,
        handleOnCancel,
        handleOnCancel
      );
    } else {
      handleOnCancel();
    }
  }

  const extra = subscriptionsHelpers.getDetailsFormExtra(({t, isEnableEdit, isEditMode, setIsEditMode, handleOnSave}));

  return (
    <StyledSubscriptionDetails
      title={t('details')}
      {...rest}
      extra={extra}
    >
      <Form
        className={!isEditMode && 'd-none'}
        initialValues={initialFormValues}
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Item
          label={t('status')}
          name='status'
        >
          <Select size='large'>
            {statusOptions.map(option => {
              const {value} = option;
              return (
                <Option
                  key={value}
                  value={value}
                >
                  {getStatusCodeMark(value)}
                </Option>
              )
            })}
          </Select>
        </Item>
        <Item
          label={`${trans('subscription')} ${t('Owner')}`}
          name='owner'
        >
          <Select
            className='owner-select'
            options={ownerOptions}
            size='large'
          />
        </Item>
        <Item
          label={t('whatIsItFor')}
          name='reason'
        >
          <Input
            placeholder={placeholder('reason')}
            size='large'
          />
        </Item>
        <Item
          label={t('costCentre')}
          name='tags'
        >
          <CompanyTagSelect
            inputProps={{placeholder: placeholder('costCentre'), size: 'large'}}
            onChange={onChangeTags}
            selected={initialFormValues.tags}
          />
        </Item>
        <Item
          label={t('howToUseIt')}
          name='note'
        >
          <Input.TextArea
            autoSize={{ minRows: 4 }}
            placeholder={placeholder('note')}
            size='large'
          />
        </Item>
      </Form>
      <DetailsTable
        className={isEditMode && 'd-none'}
        data={details}
      />
    </StyledSubscriptionDetails>
  );
}

SubscriptionDetails.propTypes = {
  subscription: PropTypes.object,
  onSubmit: PropTypes.func
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {employee} = state.user;
  return {
    employees,
    employeeEmail: employee.email
  }
}

export default connect(mapStateToProps, null)(SubscriptionDetails);
