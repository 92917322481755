import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {StyledKeylessQRCode, StyledKeylessQRCodeContainer} from './StyledKeylessQRCode';
import ComponentLoading from '../../../ComponentLoading';
import {helpers} from '../../../../helpers';
import {bankingWebsocketActionsConstants} from '../../../../constants';

const {
  AUTH,
  KEYLESS_ENROLL,
  KEYLESS_ENROLLMENT_FAILURE,
  KEYLESS_ENROLLMENT_SUCCESS,
  KEYLESS_ENROLL_SMS_VERIFICATION_FAILURE
} = bankingWebsocketActionsConstants;

const socketUrl = helpers.getWebsocketUrl('authentication');

const defaultSocketOptions = helpers.getWebsocketOptions();

const KeylessQRCode = ({
  children,
  onFinish,
  smsSessionKey,
  ...rest
}) => {
  const {
    getWebSocket,
    lastJsonMessage,
    readyState,
    sendJsonMessage
  } = useWebSocket(socketUrl, defaultSocketOptions);
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [qrCode, setQrCode] = useState(null);

  // close websocket after unmount
  useEffect(() => () => {
    getWebSocket().close()
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
    // mark that user is authorized
    if (!authorized && lastJsonMessage && typeof lastJsonMessage === 'object') {
      const isAuthAction = lastJsonMessage.action === AUTH;
      if (isAuthAction) {
        const authorized = typeof lastJsonMessage.message !== 'object'
        setAuthorized(authorized);
        if (authorized) {
          const {companyId, token} = helpers.getAuthDetails();
          sendJsonMessage({
            'action': KEYLESS_ENROLL,
            'access_token': token,
            'company_id': companyId,
            'message': {
              'sms_session_key': smsSessionKey
            }
          });
        }
      }
    }
    if (authorized) {
      if (lastJsonMessage && typeof lastJsonMessage === 'object') {
        let action = lastJsonMessage.action;
        let message = lastJsonMessage.message;
        const actions = {
          [KEYLESS_ENROLL]: () => {
            setLoading(false);
            setQrCode(helpers.getObjProp(message, 'qr_code'))
          },
          [KEYLESS_ENROLLMENT_FAILURE]: () => handleFinish('error', message),
          [KEYLESS_ENROLLMENT_SUCCESS]: () => handleFinish('success', message),
          [KEYLESS_ENROLL_SMS_VERIFICATION_FAILURE]: () => handleFinish('errorSms', message)
        }
        if (actions.hasOwnProperty(action)) actions[action]();
      }
    }
  }, [lastJsonMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFinish = (status, message) => onFinish && onFinish(status, message);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const {companyId, token} = helpers.getAuthDetails();
      sendJsonMessage({
        'action': AUTH,
        'access_token': token,
        'company_id': companyId,
      });
    }
  }, [readyState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledKeylessQRCodeContainer {...rest}>
      {
        loading
          ? <ComponentLoading className='loader' />
          : (
            <>
              <StyledKeylessQRCode
                bordered={false}
                size={232}
                value={qrCode || ''}
              />
              {children}
            </>
          )
      }
    </StyledKeylessQRCodeContainer>
  );
}

KeylessQRCode.propTypes = {
  onFinish: PropTypes.func,
  smsSessionKey: PropTypes.string
}

export default KeylessQRCode;
