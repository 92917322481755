import {useSelector} from 'react-redux';
import { useState, useEffect } from 'react';

export default function useIsCompanyChanged() {
  const [companyId, setCompanyId] = useState(null);
  const currentCompanyId = useSelector(state => state.company.id);

  useEffect(() => currentCompanyId && setCompanyId(currentCompanyId), [currentCompanyId]);

  return companyId;
}
