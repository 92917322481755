import styled from 'styled-components';
import {Space} from 'antd';

const StyledCardDetailsButton = styled(Space)`
  svg {
    height: 24px;
    width: 24px;
  }
`;

export default StyledCardDetailsButton;
