import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyledLegalInformation} from './StyledLegalInformation';
import CompanyInfo from '../../tabComponents/CompanyInfo';

const descriptionValues = {
  companyAddress: 'Via Amilcare Ponchielli 51, Bergamo, Italy',
  companyCapital: '10,000.00 euros',
  companyEmail: 'h1finance@legalmail.it',
  companyName: 'H1 Finance Srl',
  companyReaN: 'REA n. BG-470381',
  companyVat: '04537180160',

  contactDirector: 'Gianluca Treu',
  contactEmail: 'support@h1card.com',
  legalEmail: 'legal@hubspot.com',
  legalNoticeLink: 'https://www.treezor.com/legal-notice/',

  serviceHostedBy: 'Amazon Web Services',
  siteHostedBy: 'HubSpot Ireland Ltd, Ground Floor, Two Dockland Central, Guild Street, Dublin 1, Co. Dublin, Ireland (VAT: IE9849471F)',
  siteHostedEmail: 'legal@hubspot.com'

}

const LegalInformation = ({
  email,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'settings']);
  const t = (key) => trans(`settings:tabs.legal.${key}`);

  const getValue = (key, variant = 'text') => {
    let value = descriptionValues[key];
    if (['email', 'link'].includes(variant)) {
      value = (
        <a href={`${variant === 'email' ? 'mailto:' : ''}${value}`} target='_blank' rel='noreferrer'>
          {value}
        </a>
      );
    }
    return value;
  }

  return (
    <StyledLegalInformation
      title={<CompanyInfo />}
      {...rest}
    >
      {t('description1')}
      <br/>
      {getValue('companyName')}
      <br/>
      {trans('address')}: {getValue('companyAddress')}
      <br/>
      E-mail: {getValue('companyEmail', 'email')}
      <br/>
      {getValue('companyReaN')}
      <br/>
      {t('capital')}: {getValue('companyCapital')}
      <br/>
      {t('vatNumber')}: {getValue('companyVat')}
      <br/>
      <br/>
      {t('description2')}
      <br/>
      <br/>
      {t('description3')}
      <br/>
      <br/>
      <a href={getValue('legalNoticeLink')} target='_blank' rel='noreferrer'>
        {t('legalMentionTreezor')}
      </a>
      <br/>
      <br/>
      {t('directorOfPublication')}: {getValue('contactDirector')}
      <br/>
      {t('contact')}: {getValue('contactEmail', 'email')}
      <br/>
      <br/>
      {t('ourServiceIsHostedBy')}: {getValue('serviceHostedBy')}
      <br/>
      {t('ourSiteIsHostedBy')}: {getValue('siteHostedBy')} - {getValue('siteHostedEmail', 'email')}
    </StyledLegalInformation>
  );
}

export default LegalInformation;
