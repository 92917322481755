import React, {useEffect, useMemo, useState} from 'react';
import {Space} from 'antd';
import PropTypes from 'prop-types';
import './OverviewDropdown.css';
import {
  StyledOverviewDropdown,
} from './StyledOverviewDropdown';
import {DatePickerIcon} from '../../../../icons';


const OverviewDropdown = ({
  defaultSelected,
  menuItems,
  menuProps,
  onSelect,
  ...rest
}) => {
  const [selectedKey, setSelectedKey] = useState(defaultSelected);

  useEffect(() => menuItems.length && setSelectedKey(menuItems[0].key), [menuItems]);

  const title = useMemo(() => {
    let titleItem = '';
    if (selectedKey) {
      titleItem = menuItems.find(i => i.key === selectedKey);
    } else if (menuItems.length) {
      titleItem = menuItems[0];
    }
    return titleItem ? titleItem.label : '';
  }, [selectedKey, menuItems]);

  const handleOnClick = ({key}) => {
    setSelectedKey(key);
    onSelect && onSelect(key);
  }

  const menu = {
    items: menuItems,
    onClick: handleOnClick,
    selectable: true,
    selectedKeys: [selectedKey],
    ...menuProps
  }

  return (
    <StyledOverviewDropdown
      {...rest}
      menu={menu}
      overlayClassName='overview-dropdown-overlay'
    >
      <Space
        align='center'
        direction='horizontal'
        size='small'
      >
        <span>{title}</span>
        <DatePickerIcon />
      </Space>
    </StyledOverviewDropdown>
  );
}

OverviewDropdown.propTypes = {
  menuItems: PropTypes.array,
  menuProps: PropTypes.object,
  onSelect: PropTypes.func
}

OverviewDropdown.defaultProps = {
  menuItems: []
}

export default OverviewDropdown;
