import styled from 'styled-components';

const StyledNotFoundPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 152px;
  
  h1 {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 0;
  }
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    line-height: 24px;
    max-width: 423px;
    margin-bottom: 0;
    text-align: center;
  }
`;

export {
  StyledNotFoundPage
}