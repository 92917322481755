import React from 'react';
import {connect} from 'react-redux';
import {StyledUserInfo} from './StyledUserInfo';

const UserInfo = ({
  dispatch,
  logo,
  name,
  ...rest
}) => {
  return (
    <StyledUserInfo
      logo={logo}
      name={name}
      {...rest}
    />
  );
}

const mapStateToProps = state => {
  const {logo, full_name: name} = state.user.settings;

  return {
    logo,
    name
  }
}


export default connect(mapStateToProps, null)(UserInfo);
