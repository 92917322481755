import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {ReactComponent as SvgImage} from '../../../static/images/verify-email.svg';
import StyledVerifyModal from './StyledVerifyModal';

const VerifyModal = ({
  open,
  onCancel,
  email,
  ...rest
}) => {
  const [trans] = useTranslation('quickStart');

  const t = (key) => trans(`verify.${key}`);

  return (
    <StyledVerifyModal
      onCancel={onCancel}
      footer={false}
      open={open}
      title={t('title')}
      {...rest}
    >
      <SvgImage />
      <p>
        {t('weHaveSentEmail')} <span className='email'>{email}</span>.
        <br/>
        {t('afterReceiving')}
      </p>
    </StyledVerifyModal>
  );
}

VerifyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  email: PropTypes.string
}

export default VerifyModal;
