import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledTransactionsPanel, StyledTransactionsPanelTable} from './StyledTransactionsPanel';
import Card from '../../../Card';
import LoadMoreButton from '../../TransactionsPage/LoadMoreButton';
import {expenseInvoicesStatusConstants} from '../../../../constants';
import {helpers} from '../../../../helpers';
import {transactionsHelpers} from '../../TransactionsPage/transactionsHelpers';

const isGroupedRow = transactionsHelpers.isMonthlyGroupedRow;

const gMV = helpers.getMoneyView;

const TransactionsPanel = ({
  expenses,
  loading,
  loadMoreProps,
  onRowClick,
  onInvoiceEdit,
  transactions,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);

  const tableData = useMemo(() => {
    const transactionsList = transactions.map(transaction => ({
      ...transaction,
      expense: expenses.find(e => e.id === transaction.expense_id)
    }));
    return transactionsHelpers.getGroupedTransactions(transactionsList);
  }, [expenses, transactions]);

  const onAttachmentClick = ({e, transaction}) => {
    e.stopPropagation();
    if (transaction && transaction.expense) {
      const invoiceStatus = transaction.expense.expense_invoice_status;
      if (!(expenseInvoicesStatusConstants.PENDING === invoiceStatus)) return;
      onInvoiceEdit && onInvoiceEdit(transaction);
    }
  }

  const columns = [
    {
      dataIndex: 'created_date',
      title: t('date'),
      render: (date, record) => {
        const isGrouped = isGroupedRow(record);
        return helpers.getDateWithMonth(date, isGrouped ? 'MMM YYYY' : 'DD MMM');
      }
    },
    {
      dataIndex: 'source',
      title: t('vendor'),
    },
    {
      dataIndex: 'payment_status',
      title: t('status'),
      render: (status, record) => {
        if (isGroupedRow(record)) return;
        const transactionStatus = transactionsHelpers.getTransactionStatus(status);
        return t(transactionStatus.key);
      }
    },
    {
      dataIndex: 'amount',
      title: t('amount'),
      render: (_, record) => {
        let amount;
        const getRecordNumberValue = (key) => record[key] || 0;
        if (isGroupedRow(record)) {
          amount = getRecordNumberValue('income') - getRecordNumberValue('outcome');
          return <span className='total-amount danger-text grouped-total'>{gMV(amount)}</span>;
        } else {
          let className = '';
          const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(record);
          const isIncoming = Boolean(record.is_incoming);
          amount = getRecordNumberValue('amount');
          if (!isIncoming && !isReturnedTransaction) className = `${className} danger-text`;
          if (isReturnedTransaction) className = `${className} returned-price`;
          return amount ? isIncoming ? gMV(amount) : <span className={className}>-{gMV(Math.abs(amount))}</span> : null;
        }
      }
    },
    {
      dataIndex: 'attachment',
      render: (_, transaction) => transactionsHelpers.getTransactionAttachmentIcon({transaction, onClick: onAttachmentClick}),
      width: 32
    }
  ];

  const handleOnRowClick = (e, record) => {
    const {id} = record;
    if (isGroupedRow(record)) return;

    onRowClick && onRowClick(id);
  };

  return (
    <StyledTransactionsPanel {...rest}>
      <Card
        title={t('transactions')}
      >
        <StyledTransactionsPanelTable
          columns={columns}
          dataSource={tableData}
          loading={loading}
          onRow={(record) => ({
            onClick: (e) => handleOnRowClick(e, record)
          })}
          pagination={false}
          rowClassName={(record) => isGroupedRow(record) && 'grouped-row'}
          rowKey='id'
        />
        <LoadMoreButton
          {...loadMoreProps}
        >
          {t('loadMore')}
        </LoadMoreButton>
      </Card>
    </StyledTransactionsPanel>
  );
}

TransactionsPanel.propTypes = {
  expenses: PropTypes.array,
  loading: PropTypes.bool,
  loadMoreProps: PropTypes.object,
  onRowClick: PropTypes.func,
  onInvoiceEdit: PropTypes.func,
  totalSpend: PropTypes.number,
  transactions: PropTypes.array,
}

TransactionsPanel.defaultProps = {
  expenses: [],
  loading: false,
  transactions: [],
}

export default TransactionsPanel;

