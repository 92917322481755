import { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {helpers} from '../helpers';

export default function useIsEmployeeLoaded () {
  const currentUser = useSelector(state => state.user.employee);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!helpers.isEmptyObject(currentUser)) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [currentUser]);

  return isLoaded;
}

