import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import {DeleteOutlined} from '@ant-design/icons';
import {
  StyledSubscriptionRowActions,
  StyledSubscriptionRowActionsButton
} from './StyledSubscriptionRowActions';
import {CheckIcon, HandIcon, MinusIcon} from '../../../../../icons';
import {subscriptionRowActionConstants} from '../../../../../constants';

const defaultButtonProps = {
  size: 'small',
  type: 'link'
};

const {AUTHORIZE, DELETE, IGNORE, RESTRICT} = subscriptionRowActionConstants;

const defaultActions = [AUTHORIZE, IGNORE, RESTRICT, DELETE];

const SubscriptionRowActions = ({
  actions,
  buttonProps,
  subscription,
  handleAction,
  ...rest
}) => {

  const [t] = useTranslation('subscriptions');

  const getActionButtonProps = () => {
    const actionsProps = {
      [DELETE]: {
        icon: <DeleteOutlined />,
      },
      [IGNORE]: {
        icon: <MinusIcon />
      },
      [RESTRICT]: {
        icon: <HandIcon />
      },
      [AUTHORIZE]: {
        icon: <CheckIcon />
      }
    };
    return actions.map(action => ({
      key: action,
      ...(actionsProps[action] || {})
    }));
  }

  const actionButtonProps = getActionButtonProps();

  return (
    <StyledSubscriptionRowActions
      align='center'
      size='middle'
      {...rest}
    >
      {actionButtonProps.map(action => {
        const key = action.key.toLowerCase();
        return (
          <Tooltip key={key} title={t(`actions.${key}`)}>
            <StyledSubscriptionRowActionsButton
              {...defaultButtonProps}
              {...buttonProps}
              className={key}
              icon={action.icon}
              onClick={(e) => handleAction && handleAction({action: key, id: subscription.id, e})}
            />
          </Tooltip>
        )
      })}
    </StyledSubscriptionRowActions>
  )
}

SubscriptionRowActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(defaultActions)),
  subscription: PropTypes.object,
  buttonProps: PropTypes.object,
  handleAction: PropTypes.func
}

SubscriptionRowActions.defaultProps = {
  actions: defaultActions
}

export default SubscriptionRowActions;
