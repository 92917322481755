import styled from 'styled-components';
import {Tag} from 'antd';
import Card from '../../../Card';

const StyledTransactionDetailsForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  margin-top: 16px;

  .transaction-type {
    align-items: center;
    display: flex;

    svg {
      height: 24px;
      width: 24px;

      path:not([fill-rule="evenodd"]) {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;

    > svg {
      height: 24px;
      width: 24px;
      margin-right: 8px;
      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
    
    .ant-space-item > span:not(.ant-avatar) {
      color: unset;
    }

    .ant-select-selection-item-content {
      font-size: 14px;
    }
  }
`;

const StyledTag = styled(Tag)`
  font-size: 14px;
  padding: 5px 8px;
`;

const StyledTransactionDetailsFormPaidDetails = styled.span`
  display: inline-flex;
  &.flex-reverse {
    & > div {
      flex-direction: row-reverse;
    }
  }
  & > div {
    .ant-space-item {
      display: flex;
    }
  }
`;

export {
  StyledCard,
  StyledTransactionDetailsForm,
  StyledTransactionDetailsFormPaidDetails,
  StyledTag
};
