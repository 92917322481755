import React from 'react';

const CreditCardRoundedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 6.875V11.75C16 12.612 15.6629 13.4386 15.0627 14.0481C14.4626 14.6576 13.6487 15 12.8 15H3.2C2.35131 15 1.53737 14.6576 0.937258 14.0481C0.337142 13.4386 0 12.612 0 11.75V6.875H16ZM4.008 10.125H4C3.89494 10.1255 3.79102 10.1471 3.69416 10.1884C3.5973 10.2297 3.5094 10.29 3.43549 10.3658C3.28621 10.519 3.20294 10.7261 3.204 10.9416C3.20453 11.0483 3.22574 11.1538 3.26643 11.2522C3.30712 11.3506 3.36648 11.4398 3.44114 11.5149C3.5158 11.59 3.60429 11.6494 3.70155 11.6897C3.79881 11.73 3.90294 11.7505 4.008 11.75C4.22017 11.75 4.42366 11.6644 4.57369 11.512C4.72371 11.3597 4.808 11.153 4.808 10.9375C4.808 10.722 4.72371 10.5153 4.57369 10.363C4.42366 10.2106 4.22017 10.125 4.008 10.125ZM8.8 10.125H7.2C6.98783 10.125 6.78434 10.2106 6.63432 10.363C6.48429 10.5153 6.4 10.722 6.4 10.9375C6.4 11.153 6.48429 11.3597 6.63432 11.512C6.78434 11.6644 6.98783 11.75 7.2 11.75H8.8C9.01217 11.75 9.21566 11.6644 9.36569 11.512C9.51571 11.3597 9.6 11.153 9.6 10.9375C9.6 10.722 9.51571 10.5153 9.36569 10.363C9.21566 10.2106 9.01217 10.125 8.8 10.125ZM12.8 2C13.6487 2 14.4626 2.34241 15.0627 2.9519C15.6629 3.5614 16 4.38805 16 5.25H0C0 4.38805 0.337142 3.5614 0.937258 2.9519C1.53737 2.34241 2.35131 2 3.2 2H12.8Z"
      fill="#000" />
  </svg>
);

export default CreditCardRoundedIcon;
