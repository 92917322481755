import React from 'react';

const TransactionOrderIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.99935 0.666992C6.36754 0.666992 6.66602 0.965469 6.66602 1.33366V2.00033H9.33268V1.33366C9.33268 0.965469 9.63116 0.666992 9.99935 0.666992C10.3675 0.666992 10.666 0.965469 10.666 1.33366V2.00033H12.666C13.4024 2.00033 13.9993 2.59728 13.9993 3.33366V14.0003C13.9993 14.7367 13.4024 15.3337 12.666 15.3337H3.99935C3.26297 15.3337 2.66602 14.7367 2.66602 14.0003V3.33366C2.66602 2.59728 3.26297 2.00033 3.99935 2.00033H5.33268V1.33366C5.33268 0.965469 5.63116 0.666992 5.99935 0.666992ZM5.33268 11.0003C4.96449 11.0003 4.66602 11.2988 4.66602 11.667C4.66602 12.0352 4.96449 12.3337 5.33268 12.3337H7.99935C8.36754 12.3337 8.66602 12.0352 8.66602 11.667C8.66602 11.2988 8.36754 11.0003 7.99935 11.0003H5.33268ZM5.33268 8.33366C4.96449 8.33366 4.66602 8.63214 4.66602 9.00033C4.66602 9.36852 4.96449 9.66699 5.33268 9.66699H9.33268C9.70087 9.66699 9.99935 9.36852 9.99935 9.00033C9.99935 8.63214 9.70087 8.33366 9.33268 8.33366H5.33268ZM5.33268 5.66699C4.96449 5.66699 4.66602 5.96547 4.66602 6.33366C4.66602 6.70185 4.96449 7.00033 5.33268 7.00033H10.666C11.0342 7.00033 11.3327 6.70185 11.3327 6.33366C11.3327 5.96547 11.0342 5.66699 10.666 5.66699H5.33268Z"
          fill="#262626"/>
  </svg>
);

export default TransactionOrderIcon;
