import styled from 'styled-components';
import {Checkbox} from 'antd';
import {typography} from '../../../../../../utils/css';

const StyledLegalRepresentativeInformationForm = styled.div`
  position: relative;
  width: 100%;
  
  .checkbox-item {
    margin-bottom: 16px !important;
  }
`;

const StyledLegalRepresentativeInformationFormCheckbox = styled(Checkbox)`
  ${typography.body.regular};
  display: flex;
  align-items: start;
  justify-content: start;
  color: ${props => props.theme.colors.neutral_7};
  
  a {
    color: ${props => props.theme.colors.neutral_7};
    text-decoration: underline;
  }
  
  .ant-checkbox {
    height: 18px;
    min-width: 18px;
    width: 18px;
    align-self: unset;
    margin-top: 2px;
    
    input {
      width: 18px !important;
      height: 18px !important;
    }
    .ant-checkbox-inner {
      height: 18px;
      width: 18px;
    }
    
    .ant-checkbox-inner {
      &::after {
        inset-inline-start: calc(25%);
        inset-block-start: 45%;
      }
    }
  }
`;

export {
  StyledLegalRepresentativeInformationForm,
  StyledLegalRepresentativeInformationFormCheckbox
};
