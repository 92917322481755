import React from 'react';

const WithLessCreditCardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9441 4.99997H5.29389C4.19618 4.99997 3.30322 5.89293 3.30322 6.99063V16.0908C3.30322 17.1885 4.19618 18.0815 5.29389 18.0815H18.9441C20.0418 18.0815 20.9348 17.1885 20.9348 16.0908V6.99063C20.9348 5.89293 20.0418 4.99997 18.9441 4.99997ZM8.37086 15.2377H6.63616C6.16411 15.2377 5.78301 14.8566 5.78301 14.3845C5.78301 13.9124 6.16411 13.5314 6.63616 13.5314H8.37087C8.84295 13.5314 9.22401 13.9124 9.22401 14.3845C9.22401 14.8566 8.84295 15.2377 8.37086 15.2377ZM19.2285 7.84377H5.0095V6.99063C5.0095 6.83136 5.13462 6.70625 5.29389 6.70625H18.9441C19.1034 6.70625 19.2285 6.83136 19.2285 6.99063V7.84377Z"
      fill="#000"/>
    <rect x="16.5" y="11.6137" width="2.25" height="0.9" fill="white"/>
    <rect x="14.25" y="12.9679" width="2.25" height="0.9" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.375 12.75V10.5H16.275V12.75H15.375Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7251 15V12.75H17.6251V15H16.7251Z" fill="white"/>
  </svg>
);

export default WithLessCreditCardIcon;
