import React from 'react';

const CheckmarkCheckedCircleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_1787_80321" fill="white">
      <path
        d="M27.7683 15.178C28.4308 15.1317 29.0112 15.632 28.9962 16.2959C28.9418 18.6926 28.2252 21.0363 26.9146 23.0622C25.3855 25.4259 23.1304 27.2293 20.4884 28.2014C17.8464 29.1735 14.9602 29.2616 12.2638 28.4527C9.56742 27.6437 7.20646 25.9813 5.5359 23.7154C3.86533 21.4495 2.97544 18.7026 3.00001 15.8875C3.02457 13.0725 3.96226 10.3415 5.67211 8.1051C7.38196 5.86871 9.77157 4.24777 12.4817 3.48599C14.8046 2.83305 17.2553 2.84153 19.5609 3.49831C20.1997 3.68026 20.5059 4.3827 20.2658 5.00188C20.0256 5.62106 19.3299 5.9211 18.6875 5.75261C16.8715 5.27628 14.9537 5.28932 13.1325 5.80126C10.9237 6.42212 8.97621 7.74318 7.58268 9.56584C6.18915 11.3885 5.42493 13.6143 5.40491 15.9085C5.3849 18.2028 6.11016 20.4416 7.47167 22.2883C8.83318 24.135 10.7574 25.4898 12.9549 26.1491C15.1525 26.8084 17.5047 26.7365 19.6579 25.9443C21.8112 25.1521 23.6491 23.6823 24.8953 21.7559C25.9229 20.1675 26.5024 18.3394 26.5844 16.4637C26.6134 15.8002 27.1058 15.2244 27.7683 15.178Z"/>
    </mask>
    <path
      d="M27.7683 15.178C28.4308 15.1317 29.0112 15.632 28.9962 16.2959C28.9418 18.6926 28.2252 21.0363 26.9146 23.0622C25.3855 25.4259 23.1304 27.2293 20.4884 28.2014C17.8464 29.1735 14.9602 29.2616 12.2638 28.4527C9.56742 27.6437 7.20646 25.9813 5.5359 23.7154C3.86533 21.4495 2.97544 18.7026 3.00001 15.8875C3.02457 13.0725 3.96226 10.3415 5.67211 8.1051C7.38196 5.86871 9.77157 4.24777 12.4817 3.48599C14.8046 2.83305 17.2553 2.84153 19.5609 3.49831C20.1997 3.68026 20.5059 4.3827 20.2658 5.00188C20.0256 5.62106 19.3299 5.9211 18.6875 5.75261C16.8715 5.27628 14.9537 5.28932 13.1325 5.80126C10.9237 6.42212 8.97621 7.74318 7.58268 9.56584C6.18915 11.3885 5.42493 13.6143 5.40491 15.9085C5.3849 18.2028 6.11016 20.4416 7.47167 22.2883C8.83318 24.135 10.7574 25.4898 12.9549 26.1491C15.1525 26.8084 17.5047 26.7365 19.6579 25.9443C21.8112 25.1521 23.6491 23.6823 24.8953 21.7559C25.9229 20.1675 26.5024 18.3394 26.5844 16.4637C26.6134 15.8002 27.1058 15.2244 27.7683 15.178Z"
      stroke="#000" strokeWidth="4.8" mask="url(#path-1-inside-1_1787_80321)"/>
    <path
      d="M13.285 14.0107C13.0339 13.7596 12.6934 13.6186 12.3383 13.6186C11.9832 13.6186 11.6427 13.7596 11.3916 14.0107C11.1406 14.2618 10.9995 14.6023 10.9995 14.9573C10.9995 15.3124 11.1406 15.6529 11.3916 15.904L15.3916 19.904C15.5162 20.0276 15.664 20.1254 15.8264 20.1917C15.9889 20.2581 16.1628 20.2917 16.3383 20.2907C16.5208 20.2849 16.7002 20.2417 16.8654 20.1637C17.0305 20.0857 17.1779 19.9746 17.2983 19.8373L26.6316 9.17068C26.8471 8.90312 26.9511 8.56269 26.9217 8.22039C26.8923 7.87809 26.732 7.56032 26.4741 7.33336C26.2162 7.10639 25.8806 6.98774 25.5373 7.00215C25.1941 7.01656 24.8696 7.1629 24.6316 7.41068L16.3383 17.0107L13.285 14.0107Z"
      fill="#000"/>
  </svg>
);



export default CheckmarkCheckedCircleIcon;
