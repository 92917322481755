const expenseCategoriesConstants = Object.freeze({
  ACCOMMODATION: 1,
  ADVERTISING: 2,
  BENEFITS: 3,
  BILLS_FEES: 4,
  CAR: 5,
  CLOUD_SERVICES: 6,
  ENTERTAINMENT: 7,
  EQUIPMENT: 8,
  INSURANCE: 9,
  IT_SERVICES: 10,
  MATERIALS: 11,
  MEALS: 12,
  OFFICE_SUPPLIES: 13,
  OTHER: 14,
  PROFESSIONAL_SERVICES: 15,
  REMOTE_WORK: 16,
  SHIPPING: 17,
  SUBSCRIPTIONS: 18,
  TEAM_ENABLEMENT: 19,
  TRAVEL: 20,
});

const subscriptionCategoriesConstants = Object.freeze({
  SALES_TOOLS: 1,
  MARKETING_SOFTWARE: 2,
  ANALYTICS_TOOLS_SOFTWARE: 3,
  ARTIFICIAL_INTELLIGENCE_SOFTWARE: 4,
  AR_VR: 5,
  B2B_MARKETPLACES: 6,
  CAD_PLM_SOFTWARE: 7,
  COLLABORATION_PRODUCTIVITY_SOFTWARE: 8,
  COMMERCE_SOFTWARE: 9,
  CONTENT_MANAGEMENT_SYSTEMS: 10,
  CUSTOMER_SERVICE_SOFTWARE: 11,
  DATA_PRIVACY_SOFTWARE: 12,
  DESIGN_SOFTWARE: 13,
  DEVELOPMENT_SOFTWARE: 14,
  DIGITAL_ADVERTISING_PLATFORMS: 15,
  ERP_SOFTWARE: 16,
  GOVERNANCE_RISK: 17,
  HOSTING_PROVIDERS: 18,
  HR_SOFTWARE: 19,
  IOT_MANAGEMENT_PLATFORMS: 20,
  IT_INFRASTRUCTURE_SOFTWARE: 21,
  IT_MANAGEMENT_SOFTWARE: 22,
  OFFICE_SOFTWARE: 23,
  SECURITY_SOFTWARE: 24,
  SUPPY_CHAIN_SOFTWARE: 25,
  VERTICAL_INDUSTRY_SOFTWARE: 26,
});

export {
  expenseCategoriesConstants,
  subscriptionCategoriesConstants
};

