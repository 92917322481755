import styled from 'styled-components';
import {Space} from 'antd';

const StyledCompanyInformationForm = styled(Space)`
  position: relative;
  width: 100%;
`;

const StyledCompanyInformationFormAlert = styled(Space)`
  color: ${props => props.theme.colors.neutral_7};
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
  
  p {
    margin-bottom: 0;
    
    a {
      color: ${props => props.theme.colors.primary_6};
      text-decoration: none;
      transition: .2s ease-in-out;
      
      &:hover,&:focus {
        color: ${props => props.theme.colors.primary_9};
      }
    }
  }
`;

export {
  StyledCompanyInformationForm,
  StyledCompanyInformationFormAlert
};
