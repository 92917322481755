import styled from 'styled-components';
import {Button, Checkbox, Form} from 'antd';

const MD_WIDTH = '1024px';
const LG_HEIGHT = '56px';
const MD_HEIGHT = '48px';

const StyledPhoneAuthForm = styled(Form)`
  .ant-form-item-control-input {
    min-height: ${LG_HEIGHT};
    @media screen and (max-width: ${MD_WIDTH}) {
      min-height: ${MD_HEIGHT};
    }
  }
  .ant-input-password {
    padding: 12px 16px;
    height: ${LG_HEIGHT};
    @media screen and (max-width: ${MD_WIDTH}) {
      padding: 10px 13px;
      height: ${MD_HEIGHT};
    }
  }
  
  .ant-input-group-wrapper {
    .ant-select {
      .ant-select-selector {
        height: ${LG_HEIGHT};
        .ant-select-selection-search-input {
          height: calc(${LG_HEIGHT} - 2px);
        }
      }
      .ant-select-selection-item {
        line-height: calc(${LG_HEIGHT} - 2px);
      }
    }
    .ant-input {
      padding: 12px 16px;
      height: ${LG_HEIGHT};
    }
    @media screen and (max-width: ${MD_WIDTH}) {
      .ant-select {
        .ant-select-selector {
          height: ${MD_HEIGHT};
          .ant-select-selection-search-input {
            height: calc(${MD_HEIGHT} - 2px);
          }
        }
        .ant-select-selection-item {
          line-height: calc(${MD_HEIGHT} - 2px);
        }
      }
      .ant-input {
        padding: 10px 13px;
        height: ${MD_HEIGHT};
      }
    }
  }
  button {
    height: ${LG_HEIGHT};
    @media screen and (max-width: ${MD_WIDTH}) {
      height: ${MD_HEIGHT};
    }
  }
  
  .confirm-item {
    margin-bottom: 24px !important;
    .ant-form-item-control-input {
      min-height: unset;
      
      .ant-form-item-control-input-content {
        display: flex;
        
        label {
          color: ${props => props.theme.colors.neutral_7};
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
        
        a {
          color: ${props => props.theme.colors.neutral_7};
          font-weight: 400;
          font-size: 16px;
          text-decoration: underline;
        }
      }
    }
  }
`;


const StyledPhoneAuthFormButton = styled(Button)`
  &.ant-btn {
    height: ${LG_HEIGHT};
    @media screen and (max-width: ${MD_WIDTH}) {
      height: ${MD_HEIGHT};
    }
  }
  width: 100%;
`;

const StyledPhoneAuthFormCheckbox = styled(Checkbox)`
  display: flex;
  align-items: start;
  justify-content: start;
  
  .ant-checkbox {
    height: 18px;
    min-width: 18px;
    width: 18px;
    align-self: unset;
    margin-top: 4px;
    
    input {
      width: 18px !important;
      height: 18px !important;
    }
    .ant-checkbox-inner {
      height: 18px;
      width: 18px;
    }
    
    .ant-checkbox-inner {
      &::after {
        inset-inline-start: calc(25%);
        inset-block-start: 45%;
      }
    }
  }
`;

export {
  StyledPhoneAuthForm,
  StyledPhoneAuthFormCheckbox,
  StyledPhoneAuthFormButton
}
