import styled from 'styled-components';

const StyledStepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledStep = styled.span`
  background-color: #E9E9E9;
  border-radius: 3px;
  height: 4px;
  width: ${props => `calc(100% / ${props.quantity} - 8px)`};
  &.filled {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

export { StyledStepsContainer, StyledStep };
