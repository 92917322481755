import React from 'react';

const CreditCardIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.2589 6.66672H7.05851C5.59491 6.66672 4.4043 7.85733 4.4043 9.32093V21.4545C4.4043 22.9181 5.59491 24.1087 7.05851 24.1087H25.2589C26.7225 24.1087 27.9131 22.9181 27.9131 21.4545V9.32093C27.9131 7.85733 26.7225 6.66672 25.2589 6.66672ZM11.1612 20.317H8.84821C8.21881 20.317 7.71069 19.8089 7.71069 19.1795C7.71069 18.55 8.21881 18.0419 8.84821 18.0419H11.1612C11.7906 18.0419 12.2987 18.55 12.2987 19.1795C12.2987 19.8089 11.7906 20.317 11.1612 20.317ZM25.638 10.4585H6.67934V9.32093C6.67934 9.10857 6.84615 8.94176 7.05851 8.94176H25.2589C25.4712 8.94176 25.638 9.10857 25.638 9.32093V10.4585Z"
      fill="#000"/>
  </svg>
);

export default CreditCardIcon;
