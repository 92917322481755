import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {QuestionCircleFilled} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';
import {StyledIncreaseLimitAlert} from './StyledIncreaseLimitAlert';
import {helpers} from '../../../../helpers';
import routes from '../../../../routes/routes.json';

const linkRoute = routes.support;

const IncreaseLimitAlert = ({
  limit,
  handleBeforeRedirect,
  ...rest
}) => {
  const [trans] = useTranslation(['cards', 'main']);
  const navigate = useNavigate();

  const t = (key) => trans(`increaseLimitAlert.${key}`);

  const onLinkClick = (e) => {
    if (handleBeforeRedirect) {
      e.preventDefault();
      handleBeforeRedirect(e, () => navigate(linkRoute));
    }
    e.preventDefault();
  }

  const message = (
    <>
      {t('part1')}&nbsp;
      <span className='limit'>
        {helpers.getMoneyView(limit, undefined, undefined, true, false)}
      </span> {t('part2')}&nbsp;
      <Link to={linkRoute} onClick={onLinkClick}>
        <QuestionCircleFilled /> {trans('main:section')}
      </Link>
    </>
  );

  return (
    <StyledIncreaseLimitAlert
      message={message}
      showIcon
      type='warning'
      {...rest}
    />
  );
}

IncreaseLimitAlert.propTypes = {
  handleBeforeRedirect: PropTypes.func,
  limit: PropTypes.number
}
export default IncreaseLimitAlert;
