import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import parse from 'html-react-parser';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ErrorSvgImage} from '../../../static/images/error.svg';
import {ReactComponent as SuccessSvgImage} from '../../../static/images/success.svg';
import {ReactComponent as VerifySvgImage} from '../../../static/images/verify-email.svg';
import {authActions, companyActions, userActions} from '../../../state/actions';
import {helpers} from '../../../helpers';
import useIsCompanyChanged from '../../../hooks/useIsCompanyChanged';
import {StyledEmailVerificationModal} from './StyledEmailVerificationModal';
import SpinSmall from '../../SpinSmall';

const EmailVerificationModal = ({
  dispatch,
  handleCancel,
  open,
  token,
  userVerification,
  companyVerification,
  setEmailVerificationToken,
  companiesExists,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [trans] = useTranslation(['settings', 'quickStart', 'main']);
  const companyId = useIsCompanyChanged();

  const t = (key, options) => trans(`pages.emailVerification.${key}`, options);

  const finishVerification = (isSuccess) => {
    setLoading(false);
    setIsSuccess(isSuccess);
  }

  const verification = (verificationMethod) => {
    verificationMethod(
      getTokenProp('token'),
      () => finishVerification(true),
      () => finishVerification(false)
    );
  }

  useEffect(() => {
    if (token && getTokenProp('owner') !== 'company') {
      verification(userVerification);
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (token && companyId && getTokenProp('owner') === 'company') {
      verification(companyVerification);
    }
  }, [companyId, token]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancel = () => {
    handleCancel && handleCancel(!companiesExists);
    setEmailVerificationToken(null);
  }

  const getTokenProp = (key) => helpers.getObjProp(token, key);

  const getModalDetails = () => {
    let state = 'loading';
    let image = <VerifySvgImage />;
    if (!loading) {
      if (isSuccess) {
        state = 'success';
        image = <SuccessSvgImage />;
      } else {
        state = 'failed';
        image = <ErrorSvgImage />;
      }
    }
    return {
      description: t(`${state}.description`, {email: getTokenProp('email')}),
      image,
      modalTitle: t(`${state}.modalTitle`),
      title: t(`${state}.title`),
    }
  }

  const {description, image, modalTitle, title} = getModalDetails();

  return (
    <StyledEmailVerificationModal
      footer={null}
      onCancel={onCancel}
      open={open}
      title={modalTitle}
      width={410}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        {image}
        <h3>{title}</h3>
        <p>{parse(description)}</p>
      </SpinSmall>
    </StyledEmailVerificationModal>
  );
}

EmailVerificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
}

const mapStateToProps = state => {
  const {emailVerificationToken} = state.auth;
  const {companies} = state.user;
  return {
    token: emailVerificationToken,
    companiesExists: companies.length > 0
  }
}

const mapDispatchToProps = {
  userVerification: userActions.finishEmailVerification,
  companyVerification: companyActions.finishEmailVerification,
  setEmailVerificationToken: authActions.setEmailVerificationToken
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationModal);
