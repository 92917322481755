import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Avatar, Space} from 'antd';
import {helpers} from '../../../../../helpers';
import {StyledCompanies, StyledCompanyItem} from './StyledCompanies';
import DetailsTable from '../../../TransactionsPage/DetailsTable';


const Companies = ({
  dispatch,
  companies,
  settings,
  ...rest
}) => {
  const [companiesItems, setCompaniesItems] = useState([]);

  useEffect(() => {
    if (!helpers.isEmptyObject(settings)) {
      const companiesEmails = settings.user_companies_emails;
      setCompaniesItems(companies)
      if (companiesEmails.length > 0) updateCompaniesItems(companiesEmails);
    }
  }, [settings, companies]); // eslint-disable-line react-hooks/exhaustive-deps

  const [t] = useTranslation(['main', 'profile']);

  const trans = (key) => t(`profile:tabs.companies.${key}`);

  const updateCompaniesItems = (companiesEmails) => {
    const items = [];
    companiesEmails.forEach(i => {
      const company = companies.find(c => c.company_id === i.company_id && c.account_enabled);
      if (company) items.push({...company, email: i.email});
    });
    setCompaniesItems(items.sort((a, b) => a.name.localeCompare(b.name)));
  }

  const getTableData = (company) => {
    return [
      {
        key: 'company-id',
        label: `${t('company')} ID`,
        value: company.company_id
      },
      {
        key: 'email',
        label: t('email'),
        value: company.email
      },
    ]
  }

  return (
    <StyledCompanies
      title={trans('title')}
      {...rest}
    >
      {companiesItems.map(company => {
        const {company_id: id, logo_url: logo, name} = company;
        const tableData = getTableData(company);
        return (
          <StyledCompanyItem
            key={id}
            direction='vertical'
            size={12}
          >
            <Space size={12}>
              <Avatar src={logo}>
                {helpers.getInitials(name)}
              </Avatar>
              {name}
            </Space>
            <DetailsTable data={tableData} />
          </StyledCompanyItem>
        );
      })}
    </StyledCompanies>
  );
}

const mapStateToProps = state => {
  let {settings, companies} = state.user;

  return {
    settings,
    companies
  }
}

export default connect(mapStateToProps, null)(Companies);
