import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../Table';


const CardsTable = ({
  columns,
  data,
  isCollapsed,
  onInvoiceEdit,
  onRowClick,

  ...rest
}) => {
  const handleOnRowClick = (e, record) => onRowClick && onRowClick(e, record);

  return (
    <Table
      columns={columns}
      dataSource={data}
      onRow={(record) => ({
        onClick: (e) => handleOnRowClick(e, record)
      })}
      pagination={false}
      rowKey='card_id'
      {...rest}
    />
  );
}

CardsTable.propTypes = {
  data: PropTypes.array,
  onRowClick: PropTypes.func
}

CardsTable.defaultProps = {
  data: [],
}

export default CardsTable;
