import React from 'react';

const InvoiceIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M3.21739 1C2.54504 1 2 1.54504 2 2.21739V11.9565C2 12.6289 2.54504 13.1739 3.21739 13.1739H3.82609V13.7826C3.82609 14.455 4.37113 15 5.04348 15H12.9565C13.6289 15 14.1739 14.455 14.1739 13.7826V4.04348C14.1739 3.37113 13.6289 2.82609 12.9565 2.82609H12.3478V2.21739C12.3478 1.54504 11.8028 1 11.1304 1H3.21739ZM4.73913 13.1739H11.1304C11.8028 13.1739 12.3478 12.6289 12.3478 11.9565V3.73913H12.9565C13.1246 3.73913 13.2609 3.87539 13.2609 4.04348V13.7826C13.2609 13.9507 13.1246 14.087 12.9565 14.087H5.04348C4.87539 14.087 4.73913 13.9507 4.73913 13.7826V13.1739ZM3.82609 4.04348C3.82609 3.7073 4.09861 3.43478 4.43478 3.43478H9.30435C9.64052 3.43478 9.91304 3.7073 9.91304 4.04348C9.91304 4.37965 9.64052 4.65217 9.30435 4.65217H4.43478C4.09861 4.65217 3.82609 4.37965 3.82609 4.04348ZM4.43478 5.86957C4.09861 5.86957 3.82609 6.14209 3.82609 6.47826C3.82609 6.81444 4.09861 7.08696 4.43478 7.08696H8.08696C8.42313 7.08696 8.69565 6.81444 8.69565 6.47826C8.69565 6.14209 8.42313 5.86957 8.08696 5.86957H4.43478ZM3.82609 8.91304C3.82609 8.57687 4.09861 8.30435 4.43478 8.30435H6.86956C7.20574 8.30435 7.47826 8.57687 7.47826 8.91304C7.47826 9.24922 7.20574 9.52174 6.86956 9.52174H4.43478C4.09861 9.52174 3.82609 9.24922 3.82609 8.91304Z"
          fill="#000"/>
  </svg>
);

export default InvoiceIcon;
