import styled from 'styled-components';
import {Space} from 'antd';

const StyledUserInformationForm = styled(Space)`
  position: relative;
  width: 100%;
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    margin-bottom: 0;
  }
`;

export {
  StyledUserInformationForm
};
