import React from 'react';
import PropTypes from 'prop-types';
import {Space} from 'antd';
import {
  StyledHistory, StyledHistoryItem, StyledHistoryItemDetailsSpace, StyledHistoryItemSpace,
  StyledHistoryItemIcon, StyledHistoryItemLine
} from './StyledHistory';

const History = ({
  data,
  ...rest
}) => {

  return (
    <StyledHistory {...rest}>
      {data.map(({date, message, ...rest}, key) => (
        <StyledHistoryItem key={key} {...rest}>
          <StyledHistoryItemSpace
            align='start'
            size='middle'
          >
            <Space
              size={6}
              direction='vertical'
            >
              <StyledHistoryItemIcon />
              <StyledHistoryItemLine />
            </Space>
            <StyledHistoryItemDetailsSpace direction='vertical'>
              {date && (
                <span className='date'>
                  {date}
                </span>
              )}
              {message && (
                <div className='message'>
                  {message}
                </div>
              )}
            </StyledHistoryItemDetailsSpace>
          </StyledHistoryItemSpace>
        </StyledHistoryItem>
      ))}
    </StyledHistory>
  );
}

History.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      message: PropTypes.any
    })
  )
}

History.defaultProps = {
  data: []
}

export default History;
