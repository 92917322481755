import styled from 'styled-components';
import {typography} from '../../../utils/css';

const StyledPieChart = styled.div`
`;

const BarLegend = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 20px;

  & > div {
    ${typography.footnote.description};
    color: ${props => props.theme.colors.neutral_7};
    
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
  
  .price {
    ${typography.footnote.medium};
    
    color: ${props => props.theme.colors.neutral_10};
    margin-left: 8px;
    text-wrap: nowrap;
  }
`;

const ChartContainer = styled.div`
  position: relative;
`;

const StyledTotal = styled.div`
  ${typography.heading.h4};

  color: ${props => props.theme.colors.neutral_10};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  
  .pie-chart-total-value {
    text-wrap: nowrap;
  }


  label {
    ${typography.footnote.medium};
    color: ${props => props.theme.colors.neutral_7};
  }
`;

const StyledLegendDot = styled.span`
  display: inline-block;
  margin-right: 8px;
  height: 100%;
  position: relative;
  width: 6px;

  &:before {
    background-color: ${props => props.color};
    border-radius: 50%;
    content: ' ';
    display: inline-block;
    height: 6px;
    width: 6px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}`;


export {
  BarLegend,
  ChartContainer,
  StyledPieChart,
  StyledTotal,
  StyledLegendDot
}
