import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import {StyledSpinner} from './StyledSpinner';
import animationData from '../../static/images/spinner-logo.json';

const Spinner = ({
  ...rest
}) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg', // Change the renderer type if needed (canvas, html)
      loop: true,
      autoplay: true,
      animationData: animationData  // Your own JSON animation data
    });

    return () => {
      anim.destroy();// Clean up when component unmounts
    };
  }, []);
  return (
    <StyledSpinner {...rest}>
      <div ref={animationContainer} />
    </StyledSpinner>
  );
};

Spinner.defaultProps = {
  size: 'medium'
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large'])
};

export default Spinner;
