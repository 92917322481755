import styled from 'styled-components';
import {InputNumber, Space, Switch} from 'antd';
import Card from '../../../Card';

const StyledCardDetailsPanel = styled.div`
  .ant-alert {
    align-items: baseline;
  }
`;

const StyledCardDetailsPanelAction = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.neutral_7};
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 22px;
  
  &.delete-button {
    svg {
      height: 32px;
      width: 32px;
      path {
        fill: ${props => props.theme.colors.functional_danger};
        transition: .2s ease-in-out;
      }
      
      &:hover {
        cursor: pointer;
      }
      &:active,&:focus,&:hover {
        path {
          fill: ${props => props.theme.colors.functional_danger_d};
        }
      }
    }
  }
`;

const StyledCardDetailsPanelActionsSpace = styled(Space)`
  width: 100%;
  & > div {
    width: 50%;
  }
`;

const StyledCardDetailsPanelCard = styled(Card)`

  .card-details {
    margin-bottom: 16px;
  }
  
  .card-banner {
    margin-bottom: 8px;
  }

  .ant-card-actions {
    justify-content: end;
    padding: 16px 24px;

    li {
      margin: 0;
      width: unset !important;
      &:not(:last-child) {
        border-inline-end: none;
        margin-right: 16px;
      }
    }
  }
`;

const StyledCardDetailsPanelInput = styled(InputNumber)`
  width: 100%;
  height: 38px;
  
  .ant-input-number-group-addon {
    background-color: transparent;
    svg {
      width: 16px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

const StyledCardDetailsPanelSwitch = styled(Switch)`
  &.ant-switch-checked {
    background: ${props => props.theme.colors.primary_6};
    &:hover:not(.ant-switch-disabled) {
      background: ${props => props.theme.colors.primary_5};
    }
  }
`;


export {
  StyledCardDetailsPanel,
  StyledCardDetailsPanelAction,
  StyledCardDetailsPanelActionsSpace,
  StyledCardDetailsPanelCard,
  StyledCardDetailsPanelInput,
  StyledCardDetailsPanelSwitch
}
