import styled from 'styled-components';
import {Space, Tag} from 'antd';

const EXPANDED_COLUMN_MARGIN = '8px';
const EXPANDED_ACTIONS_COLUMN_WIDTH = '100px';
const VENDOR_WIDTH = `27% + 48px - ${EXPANDED_COLUMN_MARGIN}`;

const StyledExpandedSubscriptionRowEmailsTooltipContent = styled(Space)`
  .ant-btn {
    padding-left: 0;
    padding-right: 0;
    
    color: ${props => props.theme.colors.primary_5};
    
    &:hover {
      color: ${props => props.theme.colors.primary_3};
    }
  }
  
  .expanded-subscription-row-dropdown-email {
    word-break: break-word;
  }
`;

const StyledExpandedSubscriptionRowHeader = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  
  & > .ant-space {
    &:nth-of-type(1) {
      min-width: calc(${VENDOR_WIDTH});
      max-width: calc(${VENDOR_WIDTH});
    }
    &:nth-of-type(5) {
      width: ${EXPANDED_ACTIONS_COLUMN_WIDTH};
    }
    &:not(:nth-of-type(1)):not(:nth-of-type(5)) {
      width: calc((100% - ((${VENDOR_WIDTH}) + ${EXPANDED_ACTIONS_COLUMN_WIDTH} + ${EXPANDED_COLUMN_MARGIN} * 3)) / 3);
      min-width: 180px;
    }
    &:not(:last-of-type) {
      margin-right: ${EXPANDED_COLUMN_MARGIN};
    }
  }
`;

const StyledExpandedSubscriptionRowTitle = styled(Space)`
  color: ${props => props.theme.colors.neutral_7};
  font-weight: 500;
`;

const StyledExpandedSubscriptionRowSpace = styled(Space)`
  width: 100%;
  overflow: auto;
  
  & > .ant-space-item {
    width: 100%;
  }
  
  .features {
    width: calc(${VENDOR_WIDTH});
  }
`;

const StyledExpandedSubscriptionRowTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 8px;
  margin: 0 0 4px 0;
`;

export {
  StyledExpandedSubscriptionRowEmailsTooltipContent,
  StyledExpandedSubscriptionRowHeader,
  StyledExpandedSubscriptionRowTitle,
  StyledExpandedSubscriptionRowSpace,
  StyledExpandedSubscriptionRowTag
}