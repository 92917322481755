import styled from 'styled-components';
import {Space} from 'antd';
import AntdModal from '../../../AntdModal';

const StyledSelectCardTypeModal = styled(AntdModal)`
  .ant-modal-content > div {
    &.ant-modal-body {
      padding: 50px 40px;
    }
  }
`;

const StyledSelectCardTypeModalRadio = styled(Space)`
  width: 213px;
  
  h3, p {
    margin: 0;
    padding: 0;
  }
  
  h3 {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
`;

const StyledSelectCardTypeModalRadioImgSpace = styled(Space)`
  border: 1px solid ${props => props.theme.colors.neutral_5};
  border-radius: 16px;
  padding: 16px 16px 40px 16px;
  transition: .2s ease-in-out;
  
  &.checked,
  &:hover {
    background-color: ${props => props.theme.colors.primary_1};
    border: 1px solid ${props => props.theme.colors.primary_4};
    cursor: pointer;
  }
  
  & > .ant-space-item {
    .ant-radio-wrapper {
      margin: 0;
    }
  }
  
  svg {
    height: 180px;
    width: 180px;
  }
`;

export {
  StyledSelectCardTypeModal,
  StyledSelectCardTypeModalRadio,
  StyledSelectCardTypeModalRadioImgSpace
}