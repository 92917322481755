import styled from 'styled-components';
import Card from '../../../../Card';

const StyledLegalDocuments = styled(Card)`
  .ant-card-body {
    & > p {
      color: ${props => props.theme.colors.neutral_8};
    }
    
    a {
      color: ${props => props.theme.colors.neutral_7};
      text-decoration: underline;
    }
  }
`;

export {StyledLegalDocuments};
