import React from 'react';

const CheckmarkCircleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.9996" cy="16.0005" r="11.8" stroke="#000" strokeWidth="2.4"/>
    <circle cx="15.9996" cy="16.0005" r="3" fill="#000"/>
  </svg>
);

export default CheckmarkCircleIcon;
