import styled from 'styled-components';
import {rgba} from 'polished';

const StyledSubscriptionTooltip = styled.div`
  background-color: ${rgba('#272535', 0.8)};
  border-radius: ${props => props.theme.border_radius.standard};
  color: #fff;
  font-size: 10px;
  min-height: 20px;
  min-width: 170px;
  line-height: 0.875rem;
  padding: 8px;
  position: relative;
  transform: translate(${props => props.isPositiveTransform ? 25 : -35}px, 0%);
`;

export {
  StyledSubscriptionTooltip
}