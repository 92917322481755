import React from 'react';

const MinusIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.68174 7.33331H12.6667C13.0349 7.33331 13.3334 7.63179 13.3334 7.99998C13.3334 8.36817 13.0349 8.66665 12.6667 8.66665H7.34666H3.33341C2.96522 8.66665 2.66675 8.36817 2.66675 7.99998C2.66675 7.63179 2.96522 7.33331 3.33341 7.33331H8.68174Z"
      fill="#000"/>
  </svg>
);

export default MinusIcon;
