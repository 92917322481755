import styled from 'styled-components';
import {Space} from 'antd';

const StyledSelectionPanel = styled(Space)`
  position: relative;
  width: 100%;
  font-size: 16px;
`;

const StyledSelectionPanelItem = styled.li`
  border: 1px solid ${props => props.theme.colors.neutral_5};
  border-radius: ${props => props.theme.border_radius.standard};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  transition: .2s ease-in;
  
  svg {
    width: 56px;
  }
  
  .description {
    color: ${props => props.theme.colors.neutral_7};
  }
  
  .title {
    color: ${props => props.theme.colors.neutral_10};
    font-weight: 500;
  }
  
  .move {
    svg {
      width: 24px;
      
      path {
        transition: .2s ease-in;
        fill: ${props => props.theme.colors.primary_6};
        stroke: ${props => props.theme.colors.primary_6};
      }
    }
  }
  
  &:hover {
    cursor: pointer;
  }
  
  &:active,&:focus,&:hover {
    border-color: ${props => props.theme.colors.neutral_7};
    
    .move {
      svg {
        width: 24px;

        path {
          fill: ${props => props.theme.colors.primary_7};
          stroke: ${props => props.theme.colors.primary_7};
        }
      }
    }
  }
`;

export {
  StyledSelectionPanel,
  StyledSelectionPanelItem
};
