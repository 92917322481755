import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import KYCModalScreen from '../../../KYCModalScreen';
import CompanyInformationForm from '../../substeps/CompanyInformationForm';

const CompanyCreation = ({
  onFinish,
  ...rest
}) => {
  const formRef = useRef(null);

  const onSubmit = (props) => onFinish && onFinish(props);

  const onOk = () => formRef.current.submit();

  return (
    <KYCModalScreen
      enabledCancel={false}
      onOk={onOk}
      {...rest}
    >
      <CompanyInformationForm
        className='left-side-content'
        onSubmit={onSubmit}
        ref={formRef}
      />
    </KYCModalScreen>
  );
}

CompanyCreation.propTypes = {
  onFinish: PropTypes.func
}

export default CompanyCreation;

