import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CodeInputField from '../CodeInputField';

const CodeAuth = ({
  onResend,
  onSuccess,
  phoneNumber,
  session,
  errors,
  additionalErrors,
  showResend,
  canStartCount,
  ...rest
}) => {

  const [formErrors, setFormErrors] = useState(undefined);

  useEffect(() => {
    const fieldErrors = errors || additionalErrors ? {...errors, ...additionalErrors} : undefined;
    setFormErrors(fieldErrors);
  }, [errors, additionalErrors])

  const handleSuccess = (data) => {
    if (formErrors !== undefined) setFormErrors(undefined);
    onSuccess && onSuccess({
      ...data,
      phone_number: phoneNumber,
      session: session || ''
    });
  }

  return (
    <CodeInputField
      errors={formErrors}
      onResend={onResend}
      onSuccess={handleSuccess}
      showResend={showResend}
      initialSeconds={179}
      canStartCount={canStartCount}
      {...rest}
    />
  );
}

CodeAuth.propTypes = {
  onResend: PropTypes.func,
  onSuccess: PropTypes.func,
  showResend: PropTypes.bool,
  canStartCount: PropTypes.bool,
  additionalErrors: PropTypes.object
}

CodeAuth.defaultProps = {
  canStartCount: true,
  showResend: true,
}

const mapStateToProps = state => {
  const {verifyErrors, phoneNumber, session} = state.auth;
  return {
    errors: verifyErrors,
    phoneNumber,
    session
  }
}

export default connect(mapStateToProps, null)(CodeAuth);
