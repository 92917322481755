import {css} from 'styled-components';

export const typography = {
  body: {
    bold: css`
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    `,
    medium: css`
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    `,
    regular: css`
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    `,
  },
  heading: {
    h1: css`
      font-size: 38px;
      font-weight: 500;
      line-height: 46px;
    `,
    h2: css`
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
    `,
    h3: css`
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    `,
    h4: css`
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    `,
    h5: css`
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    `,
    h5_1: css`
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    `,
  },
  footnote: {
    description: css`
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    `,
    medium: css`
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    `,
  }
}