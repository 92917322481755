import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import AddNewCardFormModal from '../AddNewCardFormModal';
import SelectCardTypeModal from '../SelectCardTypeModal';

const windowVariants = {
  FORM: 'form',
  SELECT_TYPE: 'select-type'
}

const {FORM, SELECT_TYPE} = windowVariants;

const defaultWindowVariant = SELECT_TYPE;

const AddNewCardModal = ({
  loading,
  onCancel,
  onFinish,
  open,
}) => {
  const [t] = useTranslation('cards');
  const [windowVariant, setWindowVariant] = useState(SELECT_TYPE);
  const [selectedCardType, setSelectedCardType] = useState(undefined);

  const isOpenCardTypeModal = useMemo(() => open && windowVariant === SELECT_TYPE, [windowVariant, open]);

  const isOpenFormModal = useMemo(() => open && windowVariant === FORM, [windowVariant, open]);

  useEffect(() => {
    if (!open && windowVariant !== defaultWindowVariant) setWindowVariant(defaultWindowVariant);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const modalProps = {
    title: t('modal.addNewCard.title')
  }

  const handleOkCardType = (cardType) => {
    setSelectedCardType(cardType);
    setWindowVariant(FORM);
  }

  const handleFinish = (props) => onFinish && onFinish(props);

  return (
    <>
      <SelectCardTypeModal
        {...modalProps}
        onCancel={onCancel}
        onOk={handleOkCardType}
        open={isOpenCardTypeModal}
      />

      <AddNewCardFormModal
        {...modalProps}
        defaultCardType={selectedCardType}
        loading={loading}
        onCancel={onCancel}
        onOk={handleFinish}
        open={isOpenFormModal}
      />
    </>
  );
}

AddNewCardModal.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  open: PropTypes.bool
}

AddNewCardModal.defaultProps = {
  loading: false,
  open: false
}

export default AddNewCardModal;
