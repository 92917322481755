import React from 'react';

const CheckIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0355 6.33466C19.9304 6.22861 19.8052 6.14445 19.6674 6.08701C19.5295 6.02957 19.3816 6 19.2323 6C19.0829 6 18.935 6.02957 18.7972 6.08701C18.6593 6.14445 18.5342 6.22861 18.429 6.33466L10.0004 14.7746L6.45919 11.2221C6.34999 11.1167 6.22108 11.0337 6.07982 10.978C5.93857 10.9224 5.78773 10.8951 5.63592 10.8977C5.48411 10.9003 5.33431 10.9328 5.19506 10.9933C5.05582 11.0539 4.92986 11.1412 4.82437 11.2504C4.71888 11.3596 4.63594 11.4885 4.58027 11.6298C4.5246 11.771 4.4973 11.9219 4.49993 12.0737C4.50256 12.2255 4.53506 12.3753 4.59558 12.5145C4.6561 12.6538 4.74345 12.7798 4.85265 12.8852L9.19708 17.2297C9.30226 17.3357 9.42739 17.4199 9.56526 17.4773C9.70312 17.5348 9.851 17.5643 10.0004 17.5643C10.1497 17.5643 10.2976 17.5348 10.4354 17.4773C10.5733 17.4199 10.6984 17.3357 10.8036 17.2297L20.0355 7.99776C20.1504 7.89181 20.242 7.76323 20.3047 7.62012C20.3674 7.477 20.3998 7.32245 20.3998 7.16621C20.3998 7.00996 20.3674 6.85541 20.3047 6.7123C20.242 6.56918 20.1504 6.4406 20.0355 6.33466Z"
      fill="#000" />
  </svg>

);

export default CheckIcon;
