import FileSaver from 'file-saver';
import {localStorageKeysConstants} from '../../constants';

const {TOKEN} = localStorageKeysConstants;

export const helpers = {
  clearUserStoredData: () => {
    Object.values(localStorageKeysConstants).forEach(key => localStorage.removeItem(key));
  },
  getUrl: (url, expenses, nextPage) => {
    let {cursor} = expenses.pagination;

    if (nextPage && cursor !== null) {
      url += `?cursor=${cursor}`;
    }
    return url;
  },
  getExpensesQueryParams: (query) => {
    return {
      statuses: query.statuses,
      employees: query.employees
    }
  },
  handleCSVExport: (data, filename) => helpers.saveFile(data, `${filename}.csv`, 'text/csv'),
  handlePDFExport: async (data, filename) => {
    filename = `${filename}.pdf`;
    const r = await fetch(`data:application/pdf;base64,${data}`);
    const file = await r.blob();
    FileSaver.saveAs(file, filename);
  },
  parseJWT: (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  },
  saveFile: (data, filename, optionsType) => {
    const csvData = new Blob([data], { type: `${optionsType};charset=utf-8;` });
    FileSaver.saveAs(csvData, filename);
  },
  saveJWT: (data) => {
    if (data.hasOwnProperty('AccessToken') && data.AccessToken) {
      const token = JSON.parse(localStorage.getItem(TOKEN));
      const refreshToken = data && data.RefreshToken ? data.RefreshToken : token && token.refreshToken ? token.refreshToken : '';
      localStorage.setItem(TOKEN, JSON.stringify({
        accessToken: data.AccessToken,
        refreshToken
      }))
    }
  }
}
