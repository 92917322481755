import React from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import {StyledBalanceDetails} from './StyledBalancePageHeader';
import AddFundsDetails from '../../SubscriptionsPage/AddFundsDetails';
import PageHeader from '../../../Header/PageHeader';
import {helpers} from '../../../../helpers';
import {WalletIcon} from '../../../../icons';

const gObjProp = helpers.getObjProp;

const BalancePageHeader = ({
  balanceDetails,
  employees,
  isAdmin,
  isEnabledBanking,
  wireDetails,
  dispatch,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const getHeaderButtons = () => {
    const buttons = [];
    if (balanceDetails.isLoaded) {
      const currency = gObjProp(balanceDetails, 'currency', 'EUR');
      const value = helpers.getMoneyView(gObjProp(balanceDetails, 'value', 0), currency);
      buttons.push(
        <StyledBalanceDetails>
          <Space align='center' size='small'>
            <span>{t('balance')}:</span>
            <Space align='center' size='small'>
              <WalletIcon />
              <span className='balance-value'>
                {value}
              </span>
            </Space>
          </Space>
        </StyledBalanceDetails>
      );
    }
    if (isEnabledBanking && isAdmin) {
      buttons.push((
        <AddFundsDetails
          details={wireDetails}
        />
      ));
    }
    return buttons;
  }

  const headerButtons = getHeaderButtons();


  return (
    <PageHeader
      buttons={headerButtons}
      {...rest}
    />
  );
}

const mapStateToProps = state => {
  const {isAdmin} = state.user;
  const {balanceDetails, wireDetails} = state.transaction;
  const {isEnabledBanking} = state.banking;

  return {
    balanceDetails,
    isEnabledBanking,
    isAdmin,
    wireDetails
  }
}


export default connect(mapStateToProps, null)(BalancePageHeader);
