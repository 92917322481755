import styled from 'styled-components';
import {Menu, Space} from 'antd';
import {borders, typography} from '../../utils/css';

const StyledProfileDropdown = styled.div`
  ${borders.default};
  background-color: ${props => props.theme.colors.neutral_1};
  box-shadow: 0 9px 28px 8px #0000000D,
              0 6px 16px 0 #00000014,
              0 3px 6px -4px #0000001F;
  width: 300px;
`;

const StyledProfileDropdownHeader = styled(Space)`
  border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
  padding: 16px;
  width: 100%;

  h3 {
    ${typography.heading.h5};
    
    color: ${props => props.theme.colors.neutral_10};
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    ${typography.body.regular};

    color: ${props => props.theme.colors.neutral_7};
    line-height: 16px;
    margin-bottom: 0;
  }
`;

const StyledProfileDropdownMenu = styled(Menu)`
  &.ant-dropdown-menu {
    background-color: transparent;
    box-shadow: unset;
    padding: 0;

    li.ant-dropdown-menu-item {
      padding: 16px 20px;
      & > svg {
        margin-right: 16px;
        width: 22px;
        & path {
          transition: .3s ease;
          &[stroke-linecap="round"] {
            stroke: ${props => props.theme.colors.neutral_6}
          }
          &:not([stroke-linecap="round"]) {
            fill: ${props => props.theme.colors.neutral_6}
          }
        }
      }
      &.ant-dropdown-menu-item-selected {
        background-color: ${props => props.theme.colors.primary_2};
        color: ${props => props.theme.colors.primary_6};
        & > svg {
          & path {
            &[stroke-linecap="round"] {
              stroke: ${props => props.theme.colors.primary_6}
            }
            &:not([stroke-linecap="round"]) {
              fill: ${props => props.theme.colors.primary_6}
            }
          }
        }
      }
      &.ant-dropdown-menu-item-disabled {
        & > svg {
          & path {
            fill: rgba(0, 0, 0, 0.25)!important;
          }
        }
      }
    }
  }
`;

export {
  StyledProfileDropdown,
  StyledProfileDropdownHeader,
  StyledProfileDropdownMenu
};
