import React from 'react';
import PropTypes from 'prop-types';
import {StyledTotalCounter, TotalDescription, Extra} from './StyledTotalCounter';

const TotalCounter = ({
  description,
  title,
  value,
  extra,
  ...rest
}) => {
  return (
    <StyledTotalCounter {...rest}>
      <TotalDescription>
        {title && (
          <div className='title'>
            {title}
          </div>
        )}
        <div className='value'>{value}</div>
        {description && <div className='description'>{description}</div>}
      </TotalDescription>
      {extra && (
        <Extra>
          {extra}
        </Extra>
      )}
    </StyledTotalCounter>
  );
}

TotalCounter.propTypes = {
  description: PropTypes.string,
  extra: PropTypes.any,
  title: PropTypes.any,
  value: PropTypes.any.isRequired
}

export default TotalCounter;

