import React, {useState} from 'react';
import {Dropdown, Space} from 'antd';
import PropTypes from 'prop-types';
import './AddButton.css';
import {StyledAddButton} from './StyledAddButton';
import {ChevronDownIcon} from '../../../../icons';

const AddButton = ({
  children,
  menuItems,
  onClick,
  total,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const menuProps = {
    items: menuItems,
    onClick: (e) => onClick && onClick(e),
  };

  const onOpenChange = (open) => setIsOpen(open);

  return (
    <Dropdown
      menu={menuProps}
      overlayClassName='add-button-overlay'
      trigger='click'
      onOpenChange={onOpenChange}
    >
      <StyledAddButton
        {...rest}
        open={isOpen}
      >
        <Space>
          {children}
          <ChevronDownIcon />
        </Space>
      </StyledAddButton>
    </Dropdown>
  )
}

AddButton.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  onClick: PropTypes.func
}

export default AddButton;
