import styled from 'styled-components';
import {Modal} from 'antd';

const StyledCompanyInviteRegisterModal = styled(Modal)`
  text-align: center;
  
  .ant-modal-content {
    padding: 24px 24px 48px 24px;
  }
  
  .ant-modal-body {
    svg {
      margin-bottom: 24px;
    }
  }
  
  h3 {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 21px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 16px;
  }
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
    
    & + button {
      margin-top: 24px;
    }
  }
  
  button {
    display: block;
    width: 100%;
    &.ant-btn-lg {
      height: 48px;
    }
    
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`;

export {
  StyledCompanyInviteRegisterModal
}