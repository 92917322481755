import styled from 'styled-components';
import AntdModal from '../../../AntdModal';

const StyledExportModal = styled(AntdModal)`
  .ant-picker {
    width: 100%;
  }
  .ant-space {
    text-align: center;
    width: 100%;
  }
`;

export {
  StyledExportModal,
}
