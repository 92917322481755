import styled from 'styled-components';
import {Space} from 'antd';

const MD_WIDTH = '1024px';
const MD_HEIGHT = '48px';

const StyledCodeAuthForm = styled.div`
  margin-top: 40px;
  
  .inputs {
    justify-content: center;
    & > div:not(:last-of-type) {
      margin-right: 14px;
    }
  }
  
  .reset {
    display: none;
  }
  
  @media screen and (max-width: ${MD_WIDTH}) {
    margin-top: 32px;
    .inputs {
      & > div {
        height: ${MD_HEIGHT};
        width: ${MD_HEIGHT};
        input {
          height: ${MD_HEIGHT};
          width: ${MD_HEIGHT};
        }
        &:not(:last-of-type) {
          margin-right: 12px;
        }
      }
    }
  }
`;

const StyledButtonsSpace = styled(Space)`
  width: 100%;
  
  .ant-space-item {
    &:not(:last-of-type) {
      button {
        width: 100%;
      }
    }
    &:last-of-type {
      button {
        &:hover {
          background: unset;
          color: ${props => props.theme.colors.primary_6};
        }
      }
    }
  }
  
  button {
    font-weight: 500;
    @media screen and (max-width: ${MD_WIDTH}) {
      height: ${MD_HEIGHT};
      &.large-btn {
        height: ${MD_HEIGHT};
      }
    }
  }
`;

export {
  StyledCodeAuthForm,
  StyledButtonsSpace
}
