import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Space, Form, Input, Select, DatePicker} from 'antd';
import {EditFilled} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  StyledInvoiceDetailsForm,
  StyledInvoiceDetailsFormCard,
} from './StyledInvoiceDetailsForm';
import DetailsTable from '../../../TransactionsPage/DetailsTable';
import CreditCardDetails from '../../../CardsPage/CreditCardDetails';
import SpinSmall from '../../../../SpinSmall';
import {StyledPaymentDetailsInput} from '../../../SubscriptionPage/tabComponents/Overview/PaymentDetails/StyledPaymentDetails';
import {EuroIcon} from '../../../../../icons';
import {helpers} from '../../../../../helpers';
import {invoiceStatusConstants} from '../../../../../constants';

const {Item} = Form;

const InvoiceDetailsForm = ({
  dispatch,

  onSubmit,
  invoice,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    amount: '',
    card_number: '',
    date: undefined,
    vendor: '',
    uploaded_by: ''
  });

  const isProcessed = useMemo(() => invoice && [invoiceStatusConstants.MATCHED, invoiceStatusConstants.PARSED].includes(invoice.status), [invoice])

  const invoiceDetails = useMemo(() => {

    const gObjProp = (key, defaultValue) => helpers.getObjProp(invoice, key, defaultValue);
    const cardMaskedPan = gObjProp('card_masked_pan');
    const date = gObjProp('date');
    const recipient = gObjProp('recipient');

    return [
      {
        key: 'vendor',
        label: t('vendor'),
        value: gObjProp('vendor')
      },
      {
        key: 'amount',
        label: t('amount'),
        value: helpers.getMoneyView(gObjProp('amount'))
      },
      {
        key: 'date',
        label: t('date'),
        value: date ? helpers.getDate(date, 'DD/MM/YYYY') : ''
      },
      {
        key: 'card',
        label: t('card'),
        value: cardMaskedPan && (
          <CreditCardDetails cardNumber={cardMaskedPan} />
        )
      },
      {
        key: 'uploaded_by',
        label: t('invoices:uploadedBy'),
        value: gObjProp('uploaded_by')
      },
      {
        key: 'issued_to',
        label: t('invoices:issuedTo'),
        value: recipient
      }
    ]
  }, [t, invoice]);

  useEffect(() => {
    const gObjProp = (key, defaultValue) => helpers.getObjProp(invoice, key, defaultValue);
    const cardMaskedPan = gObjProp('card_masked_pan');
    const date = gObjProp('date');

    const fieldValues = {
      ...initialFormValues,
      amount: gObjProp('amount'),
      card_number: helpers.hideCardNumber(cardMaskedPan),
      date: date ? dayjs(date) : undefined,
      vendor: gObjProp('vendor'),
      uploaded_by: gObjProp('uploaded_by'),
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    isEditMode && setIsEditMode(false);
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEdit = () => setIsEditMode(true);

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const handleSubmit = (fields) => {
    if (onSubmit) {
      const {date} = fields;
      const data = {
        amount: fields.amount,
        date: date ? date.format('YYYY-MM-DD') : undefined,
        vendor: fields.vendor,
      }
      setLoading(true);
      onSubmit(
        data,
        () => {
          setLoading(false);
          handleOnCancel();
        },
        (error) => {
          setLoading(false);
          let fields = helpers.getFormServerErrorFields(error.response.data);
          form.setFields(fields);
        }
      )
    } else {
      handleOnCancel();
    }
  }

  const extra = isEditMode ? (
    <Space size='middle'>
      <Button
        disabled={loading}
        onClick={handleOnCancel}
      >
        {t('cancel')}
      </Button>
      <Button
        disabled={loading}
        onClick={handleOnSave}
        type='primary'
      >
        {t('save')}
      </Button>
    </Space>
  ) : (
    <Button
      icon={<EditFilled />}
      onClick={handleEdit}
      size='small'
      type='text'
    />
  );

  const {card_number: cardNumber} = initialFormValues;

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}];

  return (
    <StyledInvoiceDetailsForm {...rest}>
      <StyledInvoiceDetailsFormCard
        extra={isProcessed && extra}
        title={`${t('invoice')} ${t('details')}`}
        type='inner'
      >
        <SpinSmall spinning={loading}>
          <Form
            className={!isEditMode && 'd-none'}
            initialValues={initialFormValues}
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark={false}
          >
            <Item
              label={t('vendor')}
              name='vendor'
              rules={requiredRules}
            >
              <Input size='large' />
            </Item>
            <Item
              label={t('amount')}
              name='amount'
              rules={requiredRules}
            >
              <StyledPaymentDetailsInput
                addonBefore={<EuroIcon />}
                min={0.01}
                size='large'
                type='number'
              />
            </Item>
            <Item
              label={t('date')}
              name='date'
              rules={requiredRules}
            >
              <DatePicker
                format='DD/MM/YYYY'
                size='large'
              />
            </Item>
            <Item
              label={t('card')}
              name='card_number'
            >
              <Select
                disabled
                size='large'
              >
                <Select.Option
                  value={cardNumber}
                >
                  <CreditCardDetails cardNumber={cardNumber} />
                </Select.Option>
              </Select>
            </Item>
            <Item
              label={t('invoices:uploadedBy')}
              name='uploaded_by'
            >
              <Input size='large' disabled />
            </Item>
          </Form>
        </SpinSmall>
        <DetailsTable
          className={isEditMode && 'd-none'}
          data={invoiceDetails}
        />
      </StyledInvoiceDetailsFormCard>
    </StyledInvoiceDetailsForm>
  );
}

InvoiceDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  invoice: PropTypes.object
}
export default InvoiceDetailsForm;
