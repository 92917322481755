import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Popover} from 'antd';
import {
  StyledCostCenterContainer,
  StyledCostCenterTag,
  StyledCostCenterCounter,
  StyledCostCenterTagsList
} from './StyledCostCenter';
import './CostCenter.css';

const textWidth = 28;
const tagsComponentMaxWidth = 300;

const CostCenter = ({
  tags,
  ...rest
}) => {
  const [lastVisibleIndex, setLastVisibleIndex] = useState(undefined);
  const [componentNodesWidth, setComponentNodesWidth] = useState(0);
  const contentRef = useRef(null);
  const tagsContentRef = useRef(null);

  const tagComponentWidth = useMemo(() => {
    return componentNodesWidth > tagsComponentMaxWidth ? tagsComponentMaxWidth : componentNodesWidth;
  }, [componentNodesWidth]);

  useEffect(() => {
    const nodes = tagsContentRef.current.childNodes;
    const offsetWidth = contentRef.current.offsetWidth;
    let width = 300;
    let minWidth = 180;
    let nodesWidth = 0;
    let lastIndex;
    let shouldSkip = false;

    if (offsetWidth < tagsComponentMaxWidth) width = offsetWidth;
    if (width < minWidth) width = minWidth;
    width = width - textWidth;

    nodes.forEach((node, index) => {
      if (shouldSkip) return;
      let nodeWidth = node.offsetWidth + 8;
      if (nodeWidth + nodesWidth < width) {
        nodesWidth += nodeWidth;
      } else {
        lastIndex = index - 1;
        shouldSkip = true;
      }
    });
    nodesWidth !== componentNodesWidth && setComponentNodesWidth(nodesWidth);
    lastIndex !== lastVisibleIndex && setLastVisibleIndex(lastIndex);
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  const tagsLength = tags.length;

  return (
    <StyledCostCenterContainer
      {...rest}
      ref={contentRef}
    >
      <StyledCostCenterTagsList
        ref={tagsContentRef}
        width={tagComponentWidth}
      >
        {tags.map((tag, index) => (
          <StyledCostCenterTag key={`${tag}-${index}`} className={index > lastVisibleIndex && 'd-none'}>
            {tag}
          </StyledCostCenterTag>
        ))}
      </StyledCostCenterTagsList>

      {tagsLength - lastVisibleIndex > 1 ? (
        <Popover
          overlayClassName='cost-center-popover-overlay'
          content={tags
            .filter((_, index) => index > lastVisibleIndex)
            .map((tag, index) => (
              <StyledCostCenterTag key={`${tag}-${index}`}>
                {tag}
              </StyledCostCenterTag>
            ))}
          placement='bottomRight'
        >
          <StyledCostCenterCounter>
            {`+${tagsLength - (lastVisibleIndex + 1)}`}
          </StyledCostCenterCounter>
        </Popover>
      ) : ''}
    </StyledCostCenterContainer>

  )
}

CostCenter.propTypes = {
  tags: PropTypes.array
}

CostCenter.defaultProps = {
  tags: []
}

export default CostCenter;
