import styled from 'styled-components';
import Table from '../../../Table';

const StyledTransactionsPanel = styled.div`
`;

const StyledTransactionsPanelTable = styled(Table)`
  .total-amount {
    color: ${props => props.theme.colors.neutral_8};
    font-size: 16px;
    text-wrap: nowrap;
  }
  
  .grouped-row {
    background: linear-gradient(0deg, ${props => props.theme.colors.neutral_2}, ${props => props.theme.colors.neutral_2}), linear-gradient(0deg, ${props => props.theme.colors.neutral_3}, ${props => props.theme.colors.neutral_3});
  }
  
  .grouped-total {
    font-weight: 700;
  }

  .transaction-type {
    align-items: center;
    display: flex;

    svg {
      height: 24px;
      width: 24px;

      path:not([fill-rule="evenodd"]) {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }

  .transaction-icon {
    svg {
      height: 24px;
      width: 24px;

      path {
        &:not([fill-rule="evenodd"]) {
          fill: ${props => props.theme.colors.neutral_6};
        }
      }
    }
  }
  
  .returned-price {
    color: ${props => props.theme.colors.neutral_7};
    text-decoration: line-through;
  }
`;

export {
  StyledTransactionsPanel,
  StyledTransactionsPanelTable
}
