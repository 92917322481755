import styled from 'styled-components';

const StyledCompanyInvitePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  
  min-height: 100vh;
  
  & h3 {
    font-size: 2rem;
    margin-top: 0;
  }
  & p {
    font-size: 0.875rem;
    margin-bottom: 1.75rem;
    margin-top: 0
  }
  & button {
    width: 132px;
    height: 40px;
  }
  & a {
    font-size: 0.875rem;
    display: block;
    margin-top: 1.25rem;
    color: ${props => props.theme.colors.secondary};
  }
`;

const StyledCompanyInvitePageContent = styled.div`
  margin: auto;
  text-align: center;
  padding: 0 16px;
`;

export {
  StyledCompanyInvitePage,
  StyledCompanyInvitePageContent
}