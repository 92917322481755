import styled from 'styled-components';

const StyledComponentLoading = styled.div`
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0 !important;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  text-align: center;
  & img {
    width: 28px;
  }
`;

export { StyledComponentLoading };
