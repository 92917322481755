import {Select} from 'antd';
import styled from 'styled-components';

const StyledUserRoleSelectContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledUserRoleSelect = styled(Select)`
  width: 100%;
  min-width: 145px;
  &.ant-select-single {
    .ant-select-selector {
      padding: 0;
    }
  }
`;


export {
  StyledUserRoleSelectContainer,
  StyledUserRoleSelect,
};
