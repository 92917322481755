import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {cardsHelpers} from '../cardsHelpers';
import StyledSubscriptionStatusMark from '../../SubscriptionsPage/SubscriptionStatusMark/StyledSubscriptionStatusMark';
import StatusLine from '../../SubscriptionsPage/StatusLine';

const CardStatusMark = ({
  statusCode,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const status = cardsHelpers.getCardStatus({statusCode});

  return (
    <StyledSubscriptionStatusMark {...rest}>
      <StatusLine color={status.color} /> {t(status.key)}
    </StyledSubscriptionStatusMark>
  );
}

CardStatusMark.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default CardStatusMark;
