import React from 'react';
import PropTypes from 'prop-types';
import {CloseOutlined} from '@ant-design/icons';
import {StyledTabTableMultipleSelection, StyledTabTableMultipleSelectionButton, StyledTabTableMultipleSelectionActions}
  from './StyledTabTableMultipleSelection';

const TabTableMultipleSelection = ({
  actions,
  count,
  onCancel,
  ...rest
}) => {
  return (
    <StyledTabTableMultipleSelection
      {...rest}
    >
      <StyledTabTableMultipleSelectionButton
        icon={<CloseOutlined />}
        onClick={onCancel}
        type='text'
      >
        {count} {count > 1 ? 'items' : 'item'} selected
      </StyledTabTableMultipleSelectionButton>

      {actions && (
        <StyledTabTableMultipleSelectionActions>
          {actions.map(({label, ...rest}, key) => (
            <StyledTabTableMultipleSelectionButton
              {...rest}
              key={`action-${key}`}
              type='text'
            >
              {label}
            </StyledTabTableMultipleSelectionButton>
          ))}
        </StyledTabTableMultipleSelectionActions>
      )}
    </StyledTabTableMultipleSelection>
  );
}

TabTableMultipleSelection.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  count: PropTypes.number.isRequired,
  onCancel: PropTypes.func
}
export default TabTableMultipleSelection;
