import styled from 'styled-components';
import ComponentLoading from '../ComponentLoading';

const StyledPageLoading = styled(ComponentLoading)`
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  width: calc(100% - 225px);
  & img {
    width: 46px;
  }
`;

export {
  StyledPageLoading
}