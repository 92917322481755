import styled from 'styled-components';
import {Space} from 'antd';

const StyledCreditCardDetails = styled(Space)`
  display: flex;
  align-items: center;
  
  & > .ant-space-item {
    display: flex;
  }
  
  svg {
    height: 24px;
    width: 24px;

    path:not([fill-rule="evenodd"]) {
      fill: ${props => props.theme.colors.neutral_6};
    }
  }
`;

export {
  StyledCreditCardDetails,
};
