import styled from 'styled-components';
import {Space} from 'antd';

const StyledScreenExtensionBlockerWindow = styled.div`
  background-color: ${props => props.theme.colors.primary_1};
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: calc(50% + (-141px / 2)) 27px;
  color: ${props => props.theme.colors.neutral_10};
  min-height: 100vh;
  padding: 0 10px;
  text-align: center;
`;

const StyledScreenExtensionBlockerWindowSpace = styled(Space)`
  margin-top: max(11.21%, 116px);
  max-width: 350px;
`;

const StyledScreenExtensionBlockerWindowDescription = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  padding: 0;
`;

const StyledScreenExtensionBlockerWindowTitle = styled.div`
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
`;

export {
  StyledScreenExtensionBlockerWindow,
  StyledScreenExtensionBlockerWindowDescription,
  StyledScreenExtensionBlockerWindowSpace,
  StyledScreenExtensionBlockerWindowTitle
}