import ContentLoader from 'react-content-loader';
import React from 'react';
import PropTypes from 'prop-types';

const TextLoader = ({height, width, y, ...rest}) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      {...rest}
    >
      <rect x='0' y={y} rx='3' ry='3' width='100%' height={height} role='textbox' />
    </ContentLoader>
  )
}

TextLoader.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  height: PropTypes.number,
  y: PropTypes.number,
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string
};

TextLoader.defaultProps = {
  width: '50%',
  height: 24,
  y: 8,
  backgroundColor: '#f3f3f3',
  foregroundColor: '#ecebeb'
}

export default TextLoader;
