import styled from 'styled-components';
import {Button} from 'antd';

const StyledTabTableMultipleSelection = styled.div`
  background-color: ${props => props.theme.colors.primary_6};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${props => props.theme.colors.neutral_1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 24px;
  width: 100%;
  z-index: 2;
`;

const StyledTabTableMultipleSelectionButton = styled(Button)`
  align-items: center;
  color: ${props => props.theme.colors.neutral_1};
  display: flex;
  flex-direction: row-reverse;
  font-weight: 500;
  height: 28px;
  padding: 0 8px;
  
  &:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
      color: ${props => props.theme.colors.neutral_1};
    }
  }
  
  .ant-btn-icon {
    margin-inline-start: 8px;
    margin-inline-end: 0 !important;
  }
`;

const StyledTabTableMultipleSelectionActions = styled.div`
  display: flex;
  flex-direction: row;
  
  ${StyledTabTableMultipleSelectionButton} {
    &:not(:last-of-type) {
      margin-right: 1px;
      &:after {
        content: '';
        height: 16px;
        background: ${props => props.theme.colors.neutral_1};
        position: absolute;
        right: -2px;
        width: 1px;
      }
    }
  }
`;


export {
  StyledTabTableMultipleSelection,
  StyledTabTableMultipleSelectionButton,
  StyledTabTableMultipleSelectionActions
};
