import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Avatar, Space, Tag} from 'antd';
import {
  StyledTransactionDetailsHeader,
  StyledTransactionDetailsHeaderItem
} from './StyledTransactionDetailsHeader';
import {helpers} from '../../../../helpers';
import {transactionsHelpers} from '../transactionsHelpers';
import routes from '../../../../routes/routes.json';

const gObjProp = helpers.getObjProp;

const TransactionDetailsHeader = ({
  subscription,
  transaction,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);

  const getTransactionProp = (propName, defaultValue) => gObjProp(transaction, propName, defaultValue);
  const getSubscriptionProp = (propName, defaultValue) => gObjProp(subscription, propName, defaultValue);

  const amount = helpers.getMoneyView(getTransactionProp('amount'));
  const isIncoming = getTransactionProp('is_incoming', false);
  const date = helpers.getDateFromISO(getTransactionProp('created_date'), 'DD MMM YYYY, HH:mm');
  const source = transactionsHelpers.getTransactionSource(transaction);
  const subscriptionId = getSubscriptionProp('id');
  const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
  const paymentStatus = getTransactionProp('payment_status');
  const status = transactionsHelpers.getTransactionStatus(paymentStatus);

  const getTitle = () => {
    const service = getSubscriptionProp('service');
    const serviceLogo = gObjProp(service, 'logo');
    const serviceName = gObjProp(service, 'name') || gObjProp(service, 'url');
    const initials = helpers.getInitials(serviceName || source);

    return (
      <Space size='small'>
        <Avatar src={serviceLogo}>
          {initials}
        </Avatar>
        {source}
      </Space>
    );
  }

  const title = getTitle();

  const tagStyle = {
    backgroundColor: status.bgColor || 'unset',
    borderColor: status.color,
    color: status.color
  };

  return (
    <StyledTransactionDetailsHeader {...rest}>
      <StyledTransactionDetailsHeaderItem>
        {paymentStatus && (
          <Tag style={tagStyle}>
            {t(status.key)}
          </Tag>
        )}
        <span className='date'>
          {date}
        </span>
      </StyledTransactionDetailsHeaderItem>
      <StyledTransactionDetailsHeaderItem>
        {subscriptionId ? (
          <Link to={`${routes.subscriptionsList}/${subscriptionId}`}>
            {title}
          </Link>
        ) : title}
        <span className={`price ${isReturnedTransaction ? 'returned' : ''}`}>
          {!isIncoming && '-'}{amount}
        </span>
      </StyledTransactionDetailsHeaderItem>
    </StyledTransactionDetailsHeader>
  );
}

TransactionDetailsHeader.propTypes = {
  subscription: PropTypes.object,
  transaction: PropTypes.object
}

export default TransactionDetailsHeader;
