import React, {useEffect, useMemo, useState} from 'react';
import {Alert} from 'antd';
import {Collapse} from 'react-collapse';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
  StyledLegalRepresentativeConfirmation,
  StyledLegalRepresentativeConfirmationButton,
  StyledLegalRepresentativeConfirmationButtonsSpace
} from './StyledLegalRepresentativeConfirmation';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import {kycHelpers} from '../../kycHelpers';

const LegalRepresentativeConfirmation = ({
  cancelText,
  error,
  okText,
  onCancel,
  onOk,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'quickStart']);
  const [errorProps, setErrorProps] = useState({isOpen: false, message: ''});

  useEffect(() => {
    if (error) {
      setErrorProps({...errorProps, isOpen: true, message: error});
    } else {
      if (errorProps.isOpen) setErrorProps({...errorProps, isOpen: false});
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'lrConfirmation'}), [t]);

  return (
    <StyledLegalRepresentativeConfirmation
      direction='vertical'
      size={32}
      {...rest}
    >
      <StyledKYCModalContentStepTitle>
        {title}
      </StyledKYCModalContentStepTitle>
      <StyledLegalRepresentativeConfirmationButtonsSpace
        direction='vertical'
        size='middle'
      >
        <StyledLegalRepresentativeConfirmationButton
          onClick={onOk}
          size='large'
        >
          {okText || t('yes')}
        </StyledLegalRepresentativeConfirmationButton>
        <StyledLegalRepresentativeConfirmationButton
          onClick={onCancel}
          size='large'
        >
          {cancelText || t('no')}
        </StyledLegalRepresentativeConfirmationButton>
      </StyledLegalRepresentativeConfirmationButtonsSpace>
      <Collapse isOpened={errorProps.isOpen}>
        <Alert message={errorProps.message} type='error' showIcon />
      </Collapse>
    </StyledLegalRepresentativeConfirmation>
  );
}

LegalRepresentativeConfirmation.propTypes = {
  cancelText: PropTypes.string,
  error: PropTypes.string,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
}

export default LegalRepresentativeConfirmation;
