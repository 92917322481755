import React from 'react';

const FileCsvIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2182 1.09082H6.46182C5.75637 1.09082 5.18182 1.67264 5.18182 2.38537V29.4363C5.18182 30.149 5.75637 30.7308 6.46182 30.7308H25.5382C26.2436 30.7308 26.8182 30.149 26.8182 29.4363V8.69082L19.2182 1.09082ZM11.76 27.4945C12.3164 27.4945 12.7636 27.1854 12.8764 26.6363H13.7236C13.5964 27.5345 12.8546 28.2508 11.7455 28.2508C10.5127 28.2508 9.60364 27.3563 9.60728 25.8472C9.60364 24.3345 10.52 23.4435 11.7455 23.4435C12.7782 23.4435 13.5782 24.0435 13.7236 25.0835H12.8764C12.7746 24.5236 12.3273 24.1999 11.76 24.1999C10.9964 24.1999 10.4509 24.789 10.4546 25.8472C10.4509 26.9235 10.9964 27.4945 11.76 27.4945ZM16.0909 28.2581C15.0036 28.2581 14.3127 27.7345 14.2764 26.8108H15.1018C15.1418 27.2981 15.5491 27.5381 16.0836 27.5417C16.6364 27.5381 17.0182 27.269 17.0218 26.869C17.0182 26.5017 16.6836 26.3454 16.1746 26.2181L15.6436 26.0835C14.8764 25.8835 14.3964 25.4945 14.3964 24.8254C14.3964 23.9999 15.1309 23.4508 16.1127 23.4508C17.1055 23.4508 17.7782 24.0108 17.7927 24.7999H16.9855C16.9418 24.3963 16.6036 24.1635 16.1018 24.1672C15.5673 24.1635 15.2509 24.4217 15.2509 24.7745C15.2473 25.1672 15.6655 25.3308 16.0509 25.4217L16.4909 25.5381C17.1891 25.6981 17.8618 26.069 17.8618 26.8799C17.8618 27.6945 17.2109 28.2581 16.0909 28.2581ZM20.9346 28.1854H19.9018L18.2546 23.509H19.1782L20.3927 27.1963H20.4436L21.6655 23.509H22.5891L20.9346 28.1854ZM25.7709 20.5926H6.2291V2.38537C6.2291 2.25446 6.33455 2.149 6.46182 2.149H18.8182V8.44718C18.8182 8.90173 19.1891 9.27264 19.6436 9.27264H25.7709V20.5926Z"
      fill="#000"/>
  </svg>
);

export default FileCsvIcon;
