import React from 'react';

const ArrowDownIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.52827 13.138C7.78862 13.3983 8.21073 13.3983 8.47108 13.138L12.4711 9.13799C12.7314 8.87764 12.7314 8.45553 12.4711 8.19518C12.2107 7.93483 11.7886 7.93483 11.5283 8.19518L8.66634 11.0571V3.33325C8.66634 2.96506 8.36786 2.66658 7.99967 2.66658C7.63148 2.66658 7.33301 2.96506 7.33301 3.33325V11.0571L4.47108 8.19518C4.21073 7.93483 3.78862 7.93483 3.52827 8.19518C3.26792 8.45553 3.26792 8.87764 3.52827 9.13799L7.52827 13.138Z"
          fill="#000"/>
  </svg>
);

export default ArrowDownIcon;
