import {Space} from 'antd';
import styled from 'styled-components';
import Card from '../../../../Card';

const StyledServicesInformationCard = styled(Card)`
  .ant-card-body {
    & > p {
      color: ${props => props.theme.colors.neutral_8};
    }
    & > h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .ant-card-head {
    flex-direction: unset;
    padding: 0 24px;
  }
`;

const StyledServicesListSpace = styled(Space)`
  width: 100%;
  & > div {
    flex: 0 0 calc(50% - 8px);
  }
`;

const StyledService = styled.div`
  align-items: center;
  border: 1px solid ${props => props.theme.colors.neutral_5};
  border-radius: ${props => props.theme.border_radius.standard};
  color: ${props => props.theme.colors.neutral_10};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  line-height: 24px;
  padding: 16px;
  transition: .2s ease;

  &:hover {
    border-color: ${props => props.theme.colors.neutral_7};
    cursor: pointer;
  }

  img {
    height: 32px;
    width: 32px;
    margin-bottom: 8px;
  }

  &.disabled {
    cursor: unset;
    opacity: 0.4;
    pointer-events: none;
  }
`;

const StyledConnectedService = styled.div`
  color: ${props => props.theme.colors.neutral_10};
  display: flex;
  justify-content: space-between;
  padding: 36px 16px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
  }

  &.disabled {
    cursor: unset;
    opacity: 0.4;
    pointer-events: none;
  }
`;

const StyledConnectedServicesListCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

export {
  StyledServicesInformationCard,
  StyledService,
  StyledServicesListSpace,
  StyledConnectedService,
  StyledConnectedServicesListCard
}
