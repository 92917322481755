import styled from 'styled-components';
import {Space} from 'antd';

const StyledLegalRepresentativeDocuments = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLegalRepresentativeDocumentsSpace = styled.div`
  display: inline-flex;
  width: 100%;
  gap: 48px;
  
  & > * {
    width: 100%;
  }
  
  & > div {
    max-width: 390px;
  }
  
  & > form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .ant-form-item {
      margin-bottom: 0;
      width: 100%;
      
      [aria-required="true"] > div {
        transition: .2s ease;
      }
    }
  }
  
  .ant-form-item-has-error {
    [aria-required="true"] > div {
      background: linear-gradient(to right, ${props => props.theme.colors.functional_danger} 50%, transparent 0%) top repeat-x,
                  linear-gradient(${props => props.theme.colors.functional_danger} 50%, transparent 0%) right repeat-y,
                  linear-gradient(to right, ${props => props.theme.colors.functional_danger} 50%, transparent 0%) bottom repeat-x,
                  linear-gradient(${props => props.theme.colors.functional_danger} 50%, transparent 0%) left repeat-y;
      background-size: 24px 1px,1px 24px;
    }
  }
`;

const StyledLegalRepresentativeDocumentsInformation = styled(Space)`
  width: 100%;
  max-width: 390px;
`;

const StyledLegalRepresentativeDocumentsUploadContainer = styled.div`
  min-height: 272px;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  
  .ant-upload-wrapper {
    display: flex;
    width: 100%;
  }
`;

const StyledLegalRepresentativeDocumentsUploadNumber = styled.span`
  background-color: ${props => props.theme.colors.neutral_1};
  color: ${props => props.theme.colors.neutral_7};
  font-weight: 500;
  font-size: 16px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  
  height: 32px;
  width: 32px;
`;


export {
  StyledLegalRepresentativeDocuments,
  StyledLegalRepresentativeDocumentsSpace,
  StyledLegalRepresentativeDocumentsInformation,
  StyledLegalRepresentativeDocumentsUploadContainer,
  StyledLegalRepresentativeDocumentsUploadNumber
};
