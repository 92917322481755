import React from 'react';

const SubtractIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M8.48247 0.5931L13.3827 5.50216C13.7759 5.89664 13.9968 6.43137 13.9968 6.98891C13.9968 7.54644 13.7759 8.08117 13.3827 8.47565L8.48247 13.3847C8.0887 13.7787 7.55494 14 6.99841 14C6.44189 14 5.90812 13.7787 5.51436 13.3847L0.614175 8.47565C0.220899 8.08117 0 7.54644 0 6.98891C0 6.43137 0.220899 5.89664 0.614175 5.50216L5.51436 0.5931C5.91497 0.212292 6.44617 0 6.99841 0C7.55066 0 8.08186 0.212292 8.48247 0.5931ZM6.1332 4.68412C6.1332 4.19387 6.53001 3.79634 7.01937 3.79634C7.50874 3.79634 7.90555 4.19387 7.90555 4.68412V6.78913C7.90555 7.27938 7.50874 7.67691 7.01937 7.67691C6.53001 7.67691 6.1332 7.27938 6.1332 6.78913V4.68412ZM6.1332 9.13286C6.1332 8.64261 6.53001 8.24509 7.01937 8.24509C7.50874 8.24509 7.90555 8.64261 7.90555 9.13286V9.31042C7.90555 9.80067 7.50874 10.1982 7.01937 10.1982C6.53001 10.1982 6.1332 9.80067 6.1332 9.31042V9.13286Z"
          fill="#000"/>
  </svg>
);

export default SubtractIcon;
