import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {Collapse, UnmountClosed} from 'react-collapse';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Divider, Form, Input, Select, Switch, Tooltip} from 'antd';
import {InfoCircleOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {EuroIcon} from '../../../../icons';
import {helpers} from '../../../../helpers';
import {
  cardTypesConstants,
  subscriptionFormFields,
  subscriptionFormValues,
  subscriptionPaymentTypesConstants,
  subscriptionStatusesConstants,
} from '../../../../constants';
import {
  StyledAddNewCardFormModal,
  StyledAddSingleSubscriptionModalSegmented,
  StyledAddNewCardFormModalSwitchSpace
} from './StyledAddNewCardFormModal';
import {
  StyledAddSingleSubscriptionModalAccordion,
  StyledAddSingleSubscriptionModalSelectOptionSpace
} from '../../SubscriptionsPage/AddSingleSubscriptionModal/StyledAddSingleSubscriptionModal';
import {StyledBudgetDetailsFormSpace} from '../../SubscriptionPage/tabComponents/Overview/BudgetDetails/StyledBudgetDetails';
import {StyledPaymentDetailsInput} from '../../SubscriptionPage/tabComponents/Overview/PaymentDetails/StyledPaymentDetails';
import CompanyTagSelect from '../../TransactionsPage/CompanyTagSelect/CompanyTagSelect';
import IncreaseLimitAlert from '../../SubscriptionsPage/IncreaseLimitAlert';
import SubscriptionSelect from '../../SubscriptionsPage/SubscriptionSelect';
import SpinSmall from '../../../SpinSmall';
import CardBanner from '../CardBanner';
import {
  BillOptions,
  CardLimitPeriodTypeOptions,
  StatusOptions,
  SubscriptionsCategoryOptions,
  subscriptionsHelpers
} from '../../SubscriptionsPage/subscriptionsHelpers';
import {cardsHelpers} from '../cardsHelpers';
import {serviceStatsActions} from '../../../../state/actions';

const {Item} = Form;

const {FOR_PERSONAL_USE, FOR_SUBSCRIPTION} = cardTypesConstants;

const defaultCardType = FOR_PERSONAL_USE;

const {
  billedFieldName,
  budgetLimitFieldName,
  cardLimitFieldName,
  cardLimitPeriodFieldName,
  statusFieldName,
} = subscriptionFormFields;
const {
  defaultBilled,
  defaultBudgetLimitSubscription,
  defaultCardLimitPeriod
} = subscriptionFormValues;

const defaultFormValues = {
  [billedFieldName]: defaultBilled,
  card_type: defaultCardType,
  [budgetLimitFieldName]: defaultBudgetLimitSubscription,
  [cardLimitFieldName]: defaultBudgetLimitSubscription,
  [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
  [statusFieldName]: subscriptionStatusesConstants.ACTIVE,
  category: undefined,
  cost_centers: [],
  name: '',
  owner: null,
  reason: '',
  service: ''
};

const minServiceSearchSymbolsLength = 3;

const getLimit = (cardType, paymentCardLimits) => {
  const {personalCardLimit, subscriptionCardLimit} = paymentCardLimits;
  return cardType === FOR_PERSONAL_USE ? personalCardLimit : subscriptionCardLimit;
}

const AddNewCardFormModal = ({
  dispatch,
  defaultCardType,
  getSuggestServices,
  loading,
  employees,
  employeeEmail,
  paymentCardLimits,
  onCancel,
  onOk,
  onSearch,
  open,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards', 'subscriptions']);
  const [billOptions, ] = useState(BillOptions());
  const [cardLimitPeriodTypeOptions, ] = useState(CardLimitPeriodTypeOptions());
  const [cardType, setCardType] = useState(defaultCardType);
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues);
  const [categoryOptions, ] = useState(SubscriptionsCategoryOptions());
  const [statusOptions, ] = useState(StatusOptions());
  const [services, setServices] = useState({data: [], loading: false});
  const [isOpenDetails, setIsOpenDetails] = useState({subscriptions: false, user: true});
  const [isSelectedHardLimit, setIsSelectedHardLimit] = useState(false);

  const isPersonalCardType = useMemo(() => cardType === FOR_PERSONAL_USE, [cardType]);

  const maxLimit = useMemo(() => getLimit(cardType, paymentCardLimits), [cardType, paymentCardLimits]);

  const [form] = Form.useForm();

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}]

  const budgetLimit = Form.useWatch(budgetLimitFieldName, {form});
  const cardLimit = Form.useWatch(cardLimitFieldName, {form});
  const cardLimitPeriod = Form.useWatch(cardLimitPeriodFieldName, {form});
  const name = Form.useWatch('name', {form});
  const service = Form.useWatch('service', {form});

  const isTooHighLimit = useMemo(() => {
    return cardsHelpers.isTooHighLimit({
      limit: cardLimit,
      maxLimit,
      period: cardLimitPeriod
    });
  }, [cardLimit, cardLimitPeriod, maxLimit]);

  useEffect(() => {
    if (isPersonalCardType && !isSelectedHardLimit && budgetLimit !== cardLimit) {
      form.setFieldValue(cardLimitFieldName, budgetLimit);
    }
  }, [budgetLimit]); // eslint-disable-line react-hooks/exhaustive-deps

  const vendorProps = useMemo(() => subscriptionsHelpers.getSubscriptionVendorProps({isLoadingServices: services.loading, t}), [services, t]);

  const disabledCreateButton = useMemo(() => {
    const cardTypeName = isPersonalCardType ? name : service;
    return cardTypeName === '' || loading || isTooHighLimit
  }, [isPersonalCardType, isTooHighLimit, loading, name, service]);

  const ownerOptions = useMemo(() => helpers.getEmployeeOptions({employees, employeeEmail, t}), [employees]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardTypeOptions = [
    {
      label: t(`cardTypes.forSubscriptions`),
      value: FOR_SUBSCRIPTION
    },
    {
      label: t(`cardTypes.forPersonalUse`),
      value: FOR_PERSONAL_USE
    }
  ];

  const trans = (key) => t(`cards:${key}`);

  const formT = (key) => trans(`modal.addNewCard.${key}`);

  const labelT = (key) => formT(`labels.${key}`);

  const placeholderT = (key) => formT(`placeholders.${key}`);

  const subsPlaceholderT = (key) => t(`subscriptions:placeholders.${key}`);

  const tooltipT = (key) => trans(`tooltips.${key}`);

  const footer = (
    <Button
      disabled={disabledCreateButton}
      onClick={() => !disabledCreateButton && form.submit()}
      type='primary'
      size='large'
    >
      {t('create')}
    </Button>
  );

  useEffect(() => {
    form.setFieldValue('owner', employeeEmail);
  }, [employeeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setFieldValue('card_type', defaultCardType);
    setCardType(defaultCardType);
  }, [defaultCardType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const limit = getLimit(defaultCardType, paymentCardLimits);
      const formFieldValues = {
        ...defaultFormValues,
        [budgetLimitFieldName]: limit,
        [cardLimitFieldName]: limit,
        owner: employeeEmail
      };
      setInitialFormValues(formFieldValues);
      form.setFieldsValue(formFieldValues);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cardType === FOR_SUBSCRIPTION) {
      setIsOpenDetails({
        ...isOpenDetails,
        subscriptions: true
      });
      setTimeout(() => {
        setIsOpenDetails({
          ...isOpenDetails,
          subscriptions: true,
          user: false
        })
      }, 600);
    } else if (cardType === FOR_PERSONAL_USE) {
      setIsOpenDetails({
        ...isOpenDetails,
        user: true
      });
      setTimeout(() => {
        setIsOpenDetails({
          ...isOpenDetails,
          subscriptions: false,
          user: true
        })
      }, 500);
    }
  }, [cardType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeTags = (tags) => {
    form.setFieldValue('cost_centers', tags);
    setInitialFormValues({...initialFormValues, cost_centers: tags});
  }

  const handleSubmit = (formData) => {
    const getFieldValue = (key, defaultValue) => helpers.getObjProp(formData, key, defaultValue);
    const {owner, cost_centers: tags} = formData;
    let data = {
      [budgetLimitFieldName]: getFieldValue(budgetLimitFieldName, defaultBudgetLimitSubscription),
      [cardLimitFieldName]: getFieldValue(cardLimitFieldName, defaultBudgetLimitSubscription),
      [cardLimitPeriodFieldName]: getFieldValue(cardLimitPeriodFieldName, defaultCardLimitPeriod),
      [billedFieldName]: getFieldValue(billedFieldName),
    }
    if (cardType === FOR_SUBSCRIPTION) {
      let category = getFieldValue('category');
      data = {
        ...data,
        card_owner: owner,
        expected_first_payment: helpers.getMomentUnixTimestamp(moment()),
        owner,
        payment_type: subscriptionPaymentTypesConstants.CARD,
        reason: formData.reason,
        service: formData.service,
        status: formData[statusFieldName],
        tags,
      }
      if (category) data = {...data, category};
    } else if (cardType === FOR_PERSONAL_USE) {
      data = {
        ...data,
        cost_centers: tags,
        owner_email: owner,
        name: formData.name,
      }
    }
    onOk && onOk({
      cardType,
      data,
      errorCallback: (err) => {
        const fields = helpers.getFormServerErrorFields(err.response.data);
        form.setFields(fields);
      }
    });
  }

  const handleOnSearchServices = (value) => subscriptionsHelpers.handleSearchSubscriptionService({onSearch: getSuggestServices, services, setServices, value})

  const onClose = (e) => onCancel && onCancel(e);

  const handleChangeHardLimit = (value) => {
    setIsSelectedHardLimit(value);
    if (!value) {
      form.setFieldsValue({
        [cardLimitFieldName]: budgetLimit,
        [cardLimitPeriodFieldName]: defaultCardLimitPeriod
      })
    }
  }

  const budgetFieldRules = subscriptionsHelpers.getBudgetFieldRules(t);

  return (
    <StyledAddNewCardFormModal
      forceRender
      footer={footer}
      onClose={onClose}
      open={open}
      width={448}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          onFinish={handleSubmit}
          layout='vertical'
          form={form}
          requiredMark={false}
        >
          <Item
            label={labelT('cardType')}
          >
            <StyledAddSingleSubscriptionModalSegmented
              block={true}
              onChange={setCardType}
              options={cardTypeOptions}
              size='large'
              value={cardType}
            />
          </Item>
          <UnmountClosed isOpened={isOpenDetails.user}>
            <Item
              label={labelT('cardName')}
              name='name'
            >
              <Input
                placeholder={placeholderT('cardName')}
                size='large'
              />
            </Item>
          </UnmountClosed>
          <UnmountClosed isOpened={isOpenDetails.subscriptions}>
            <Item
              className={service !== '' && 'd-none-label'}
              label={t('vendor')}
              name='service'
              tooltip={vendorProps.tooltip}
            >
              <SubscriptionSelect
                autocompleteProps={vendorProps.autocomplete}
                inputProps={vendorProps.input}
                minSearchSymbolsLength={minServiceSearchSymbolsLength}
                onSearch={handleOnSearchServices}
                services={services.data}
              />
            </Item>
            <Item
              label={t('status')}
              name={statusFieldName}
            >
              <Select
                options={statusOptions.map(option => ({
                  ...option,
                  label: (
                    <StyledAddSingleSubscriptionModalSelectOptionSpace>
                      {option.image}
                      {option.label}
                    </StyledAddSingleSubscriptionModalSelectOptionSpace>
                  )
                }))}
                size='large'
              />
            </Item>
          </UnmountClosed>
          <Item
            label={labelT('owner')}
            name='owner'
          >
            <Select
              optionFilterProp='optionLabel'
              options={ownerOptions}
              size='large'
              showSearch
            />
          </Item>
          <Item
            label={labelT('costCentre')}
            name='cost_centers'
            tooltip={{ icon: <InfoCircleOutlined /> }}
          >
            <CompanyTagSelect
              inputProps={{
                placeholder: placeholderT('costCentre'),
                size: 'large'
              }}
              onChange={onChangeTags}
              selected={initialFormValues.cost_centers}
            />
          </Item>
          <StyledBudgetDetailsFormSpace
            align='start'
            size='middle'
          >
            <Item
              label={labelT('budget')}
              name={budgetLimitFieldName}
              rules={budgetFieldRules}
              required
            >
              <StyledPaymentDetailsInput
                addonBefore={<EuroIcon />}
                size='large'
                type='number'
              />
            </Item>
            <Item
              label=' '
              name={billedFieldName}
              rules={requiredRules}
            >
              <Select
                options={billOptions}
                size='large'
              />
            </Item>
          </StyledBudgetDetailsFormSpace>
          <UnmountClosed isOpened={isPersonalCardType}>
            <Item>
              <StyledAddNewCardFormModalSwitchSpace align='center' size={10}>
                <Switch checked={isSelectedHardLimit} onChange={handleChangeHardLimit} />
                <span>{trans('hardLimit')}</span>
                <Tooltip
                  overlayStyle={{maxWidth: 368}}
                  title={<>{tooltipT('hardCardLimit1')}<br/>{tooltipT('hardCardLimit2')}</>}
                >
                  <InfoCircleOutlined
                    size={14}
                  />
                </Tooltip>
              </StyledAddNewCardFormModalSwitchSpace>
            </Item>
            <StyledBudgetDetailsFormSpace
              align='start'
              size='middle'
            >
              <Item
                label={labelT('cardLimit')}
                name={cardLimitFieldName}
                rules={budgetFieldRules}
                required
              >
                <StyledPaymentDetailsInput
                  addonBefore={<EuroIcon />}
                  disabled={!isSelectedHardLimit}
                  size='large'
                  type='number'
                />
              </Item>
              <Item
                label=' '
                name={cardLimitPeriodFieldName}
                rules={requiredRules}
              >
                <Select
                  disabled={!isSelectedHardLimit}
                  options={cardLimitPeriodTypeOptions}
                  size='large'
                />
              </Item>
            </StyledBudgetDetailsFormSpace>
            <Collapse isOpened={isTooHighLimit}>
              <IncreaseLimitAlert
                limit={maxLimit}
              />
            </Collapse>
          </UnmountClosed>

          {/* Display card banner only for personal card type*/}
          <Collapse isOpened={isPersonalCardType}>
            <CardBanner />
          </Collapse>

          <UnmountClosed isOpened={!isPersonalCardType}>
            <Divider />
            <StyledAddSingleSubscriptionModalAccordion
              ghost
              expandIconPosition='end'
              expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined /> }
              items={[
                {
                  key: '1',
                  label: `${t('advanced')} ${t('settings')}`,
                  children: (
                    <>
                      <StyledBudgetDetailsFormSpace size='middle'>
                        <Item
                          label={`${t('card')} ${t('limit')}`}
                          name={cardLimitFieldName}
                        >
                          <StyledPaymentDetailsInput
                            addonBefore={<EuroIcon />}
                            min={1}
                            size='large'
                            type='number'
                          />
                        </Item>
                        <Item
                          label=' '
                          name={cardLimitPeriodFieldName}
                          rules={requiredRules}
                        >
                          <Select
                            options={cardLimitPeriodTypeOptions}
                            size='large'
                          />
                        </Item>
                      </StyledBudgetDetailsFormSpace>
                      <Collapse isOpened={isTooHighLimit}>
                        <IncreaseLimitAlert
                          limit={maxLimit}
                          handleBeforeRedirect={onClose}
                        />
                      </Collapse>
                      <Item
                        label={t('category')}
                        name='category'
                      >
                        <Select
                          allowClear={true}
                          options={categoryOptions}
                          placeholder={subsPlaceholderT('category')}
                          size='large'
                        />
                      </Item>
                      <Item
                        label={t('whatIsItFor')}
                        name='reason'
                      >
                        <Input
                          placeholder={subsPlaceholderT('reason')}
                          size='large'
                        />
                      </Item>
                    </>
                  )
                }
              ]}
            />
          </UnmountClosed>
        </Form>
      </SpinSmall>
    </StyledAddNewCardFormModal>
  );
}

AddNewCardFormModal.propTypes = {
  defaultCardType: PropTypes.any,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  onSearch: PropTypes.func
}

AddNewCardFormModal.defaultProps = {
  defaultCardType,
  loading: false
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {employee} = state.user;
  const paymentCardLimits = cardsHelpers.getPaymentCardLimit(state);
  return {
    employees,
    employeeEmail: employee.email,
    paymentCardLimits
  }
}

const mapDispatchToProps = {
  getSuggestServices: serviceStatsActions.getSuggestServices,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCardFormModal);

