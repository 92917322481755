import React from 'react';
import PropTypes from 'prop-types';
import {StyledStepsContainer, StyledStep} from './StyledSteps';

const Steps = ({ activeNum, quantity, ...rest}) => (
  <StyledStepsContainer {...rest}>
    {[...Array(quantity).keys()].map(key => {
      const isFilled = key + 1 <= activeNum;
      const dataTest = key + 1 < activeNum ? 'finished-step' : 'not-finished-step';
      const className = isFilled ? 'filled' : '';

      return (
        <StyledStep
          className={className}
          key={key}
          data-testid={dataTest}
          quantity={quantity}
        />
      );
    })}
  </StyledStepsContainer>
);

Steps.propTypes = {

  quantity: PropTypes.number.isRequired,

  activeNum: PropTypes.number
};

Steps.defaultProps = {
  activeNum: 1,
};

export default Steps;
