export const authConstants = {
  ERROR_LOGIN: 'ERROR_LOGIN',
  SUCCESS_LOGIN: 'SUCCESS_LOGIN',

  ERROR_VERIFY_CODE: 'ERROR_VERIFY_CODE',
  SUCCESS_VERIFY_CODE: 'SUCCESS_VERIFY_CODE',
  SUCCESS_PHONE_VERIFICATION: 'SUCCESS_PHONE_VERIFICATION',
  ERROR_PHONE_VERIFICATION: 'ERROR_PHONE_VERIFICATION',
  ERROR_COMPANY_INVITE_FINISH: 'ERROR_COMPANY_INVITE_FINISH',
  SUCCESS_COMPANY_INVITE_FINISH: 'SUCCESS_COMPANY_INVITE_FINISH',
  SUCCESS_PHONE_VERIFY_CODE: 'SUCCESS_PHONE_VERIFY_CODE',
  ERROR_PHONE_VERIFY_CODE: 'ERROR_PHONE_VERIFY_CODE',
  SUCCESS_CODE_RESEND: 'SUCCESS_CODE_RESEND',
  ERROR_CODE_RESEND: 'ERROR_CODE_RESEND',
  SUCCESS_CREATE_PASSWORD: 'SUCCESS_CREATE_PASSWORD',
  ERROR_CREATE_PASSWORD: 'ERROR_CREATE_PASSWORD',
  SUCCESS_FINISH_REGISTRATION: 'SUCCESS_FINISH_REGISTRATION',
  ERROR_FINISH_REGISTRATION: 'ERROR_FINISH_REGISTRATION',

  LOGOUT: 'LOGOUT',
  RESEND_CODE: 'RESEND_CODE',
  GET_INVITATION_DETAILS: 'GET_INVITATION_DETAILS',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  SET_EMAIL_VERIFICATION_TOKEN: 'SET_EMAIL_VERIFICATION_TOKEN',
  SET_COMPANY_INVITE_TOKEN: 'SET_COMPANY_INVITE_TOKEN',
  SET_SHOW_COMPANY_INVITE_REGISTER: 'SET_SHOW_COMPANY_INVITE_REGISTER',

  SET_UBO_INVITE_KEY: 'SET_UBO_INVITE_KEY'
};
