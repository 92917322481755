import styled from 'styled-components';
import {Form} from 'antd';

const StyledSubscriptionInfoForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export {
  StyledSubscriptionInfoForm
}