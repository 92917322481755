import styled from 'styled-components';
import {Space} from 'antd';

const StyledSubscriptionChart = styled(Space)`
  width: 100%;
  
  .recharts-tooltip-wrapper {
    z-index: 3;
  }
`;


export {
  StyledSubscriptionChart,
}