import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledScreenExtensionBlockerWindow,
  StyledScreenExtensionBlockerWindowDescription,
  StyledScreenExtensionBlockerWindowSpace,
  StyledScreenExtensionBlockerWindowTitle
} from './StyledScreenExtensionBlockerWindow';
import backgroundImage from '../../static/images/screen-extension-blocker-window/bg-shape.svg';
import {ReactComponent as SignFloorSvgImage} from '../../static/images/screen-extension-blocker-window/sign-floor.svg';

const ScreenExtensionBlockerWindow = ({
  description,
  title,
  ...rest
}) => {
  return (
    <StyledScreenExtensionBlockerWindow
      backgroundImage={backgroundImage}
      {...rest}
    >
      <StyledScreenExtensionBlockerWindowSpace
        direction='vertical'
        size={32}
      >
        <StyledScreenExtensionBlockerWindowTitle>
          {title}
        </StyledScreenExtensionBlockerWindowTitle>
        <SignFloorSvgImage />
        {description && (
          <StyledScreenExtensionBlockerWindowDescription>
            {description}
          </StyledScreenExtensionBlockerWindowDescription>
        )}
      </StyledScreenExtensionBlockerWindowSpace>
    </StyledScreenExtensionBlockerWindow>
  );
}

ScreenExtensionBlockerWindow.propTypes = {
  description: PropTypes.any,
  title: PropTypes.string.isRequired
}

export default ScreenExtensionBlockerWindow;
