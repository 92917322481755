import styled from 'styled-components';

const FilePreview = styled.div`
  max-height: 400px;
  overflow: auto;
  > * {
    max-width: 100%;
  }
  .react-pdf__Page {
    > * {
      height: auto !important;
      width: 100% !important;
    }
  }
`;

export {
  FilePreview
};
