import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Collapse} from 'react-collapse';
import {Input} from 'antd';
import {helpers} from '../../helpers';
import {
  StyledCodeContainer,
  StyledCodeInputField, StyledHelpText,
  StyledInputContainer,
  StyledInputsList, StyledResendContainer
} from './StyledCodeInputField';

let interval = null;

const getNumObject = (count, value) => {
  let obj = {};
  [...Array(count).keys()].forEach(i => {
    obj[i] = value;
  });
  return obj;
}

const CodeInputField = ({
  autofocus,
  onResend,
  onSuccess,
  errors,
  showResend,
  showReset,
  numberCount,
  initialSeconds,
  canStartCount,
  onKeyChange,
  ...rest
}) => {
  const [t] = useTranslation(['auth', 'main']);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [validation, setValidation] = useState({isValid: true, message: ''});
  const [code, setCode] = useState(getNumObject(numberCount, ''));

  const refs = {
    0: React.useRef(null),
    1: React.useRef(null),
    2: React.useRef(null),
    3: React.useRef(null),
    4: React.useRef(null),
    5: React.useRef(null)
  };

  const startTimer = () => {
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval)
      }
    }, 1000);
  };

  useEffect(() => {
    if (showResend && canStartCount) {
      startTimer();
      return () => {
        if (interval) clearInterval(interval);
      }
    }
    if (showResend && !canStartCount) setSeconds(initialSeconds);
    if (!canStartCount) clearFields();
  }, [seconds, canStartCount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let codeWord = '';
    Object.entries(code).forEach(c => codeWord += c[1]);
    if (codeWord.length === numberCount && validation.isValid) {
      Object.keys(refs).forEach(key => refs[key].current.blur());
      onSuccess && onSuccess({
        code: codeWord
      });
    }
  }, [code, validation.isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errors) {
      let {message} = errors;
      if (errors.hasOwnProperty('errors')) {
        let errorsData = errors.errors;
        if (errorsData.hasOwnProperty('code')) message = errorsData.code;
      }
      setValidation({isValid: false, message});
      if (refs[numberCount - 1]) refs[numberCount - 1].current.focus();
    } else {
      setValidation({...validation, isValid: true});
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (autofocus && refs[0].current) refs[0].current.focus();
  }, [autofocus]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearFields = () => {
    let codes = {};
    Object.keys(code).forEach(key => {
      codes[key] = '';
    });
    setCode(codes);
  }

  const onCodeChange = (e, key) => {
    const {value} = e.target;
    const valueLength = value.length;
    const maxKeyIndex = numberCount - 1;
    let keyIndex = key;
    let isPaste = false;

    if (!validation.isValid) setValidation({...validation, isValid: true});

    // update current code value
    if (valueLength < 2) {
      setCode({...code, [key]: value});
    } else if (valueLength === 2) {
      const diff = helpers.findStringDifference(code[key], value);
      setCode({...code, [key]: diff ? diff : value[1]});
    } else {
      let array = {};
      value.split('').forEach(item => {
        if (keyIndex <= maxKeyIndex) array[keyIndex] = item;
        if (keyIndex < maxKeyIndex) keyIndex += 1;
      });
      isPaste = true;
      setCode({...code, ...array});
    }

    onKeyChange && onKeyChange(value);

    // focus next or prev input field
    if (keyIndex >= 0 && keyIndex <= maxKeyIndex && isPaste) {
      refs[keyIndex].current.focus();
    } else if (valueLength === 0 && key > 0) {
      refs[key - 1].current.focus();
    } else if (valueLength >= 1 && key < maxKeyIndex) {
      refs[key + 1].current.focus();
    } else if (key === 3) {
      refs[key].current.blur();
    }
  };

  const onKeyDown = (e, key) => {
    const {value} = e.target;
    const {keyCode} = e;
    if (value === '' && key > 0 && keyCode === 8) {
      refs[key - 1].current.focus();
    }
  }

  const reloadCode = () => {
    setCode(getNumObject(numberCount, ''));
    onResend && onResend();
    setSeconds(initialSeconds);
    startTimer();
    refs[0].current.focus();
  };

  const resetCode = () => {
    setCode(getNumObject(numberCount, ''));
    setValidation({...validation, isValid: true})
    refs[0].current.focus();
  }

  const {isValid} = validation;

  return (
    <StyledCodeInputField {...rest}>
      <StyledCodeContainer>
        <StyledInputsList className='inputs'>
          {[...Array(numberCount).keys()].map(key => {
            return (
              <StyledInputContainer
                key={key}
                className={isValid ? code[key] !== '' ? 'active': '' : 'not-valid'}>
                <Input
                  autoComplete={false}
                  onChange={(e) => onCodeChange(e, key)}
                  onKeyDown={(e) => onKeyDown(e, key)}
                  readonly={false}
                  size='small'
                  name={`code-${key}`}
                  type='number'
                  value={code[key]}
                  ref={refs[key]}
                />
              </StyledInputContainer>
            )
          })}
        </StyledInputsList>

        <Collapse isOpened={!isValid}>
          <StyledHelpText className='help-text'>
            {validation.message}
          </StyledHelpText>
        </Collapse>
      </StyledCodeContainer>

      {showResend && (
        <StyledResendContainer className='resend'>
          <div
            onClick={() => reloadCode()}
            className={`link ${seconds > 0 ? 'disabled' : ''}`}
          >
            {t('resendCode')}
          </div>
          <span>{helpers.getSecondsTime(seconds)}</span>
        </StyledResendContainer>
      )}

      {showReset && (
        <div
          className='link reset'
          onClick={() => resetCode()}
        >
          {t('main:reset')} {t('code')}
        </div>
      )}


    </StyledCodeInputField>
  );
}

CodeInputField.propTypes = {
  autofocus: PropTypes.bool,
  onResend: PropTypes.func,
  onSuccess: PropTypes.func,
  showResend: PropTypes.bool,
  showReset: PropTypes.bool,
  errors: PropTypes.any,
  numberCount: PropTypes.oneOf([2, 3, 4, 5, 6]),
  initialSeconds: PropTypes.number,
  canStartCount: PropTypes.bool,
  onKeyChange: PropTypes.func
}

CodeInputField.defaultProps = {
  autofocus: false,
  showResend: true,
  numberCount: 6,
  initialSeconds: 59,
  canStartCount: true,
  showReset: false
}

export default CodeInputField;
