import styled from 'styled-components';
import {Space} from 'antd';


const StyledSubscriptionTableActions = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 100%;
  
  .ant-btn {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 20px;
    width: 20px !important;
    &.cancel {
      color: ${props => props.theme.colors.functional_danger};
    }
    &.edit {
      color: ${props => props.theme.colors.primary_6};
    }
    &.success {
      color: ${props => props.theme.colors.functional_success};
    }
    &:active,
    &:hover,
    &:focus {
      &.cancel {
        color: ${props => props.theme.colors.functional_danger};
      }
      &.edit {
        color: ${props => props.theme.colors.primary_6};
      }
      &.success {
        color: ${props => props.theme.colors.functional_success};
      }
    }
    .ant-btn-icon {
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
  
  .ant-dropdown-trigger {
    height: 24px;
    width: 24px;
  }
`;

const StyledSubscriptionTableTabMenuItemSpace = styled(Space)`
  color: ${props => props.theme.colors.neutral_10};
  svg {
    height: 16px;
    width: 16px;
    
    path {
      fill: ${props => props.theme.colors.neutral_10};
    }
  }
  .ant-space-item {
    display: flex;
    font-size: 16px;
  }
  
  &.delete {
    color: ${props => props.theme.colors.functional_danger};
    svg {
      path {
        fill: ${props => props.theme.colors.functional_danger};
      }
    } 
  }
  
  &.gray {
    color: ${props => props.theme.colors.neutral_7};
    svg {
      path {
        fill: ${props => props.theme.colors.neutral_7};
      }
    } 
  }
  
  &.primary {
    color: ${props => props.theme.colors.primary_6};
    svg {
      path {
        fill: ${props => props.theme.colors.primary_6};
      }
    } 
  }
`;


export {
  StyledSubscriptionTableActions,
  StyledSubscriptionTableTabMenuItemSpace
}
