import styled from 'styled-components';
import {Breadcrumb} from 'antd';

const StyledBreadCrumbs = styled(Breadcrumb)`
  li {
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 1.5;
    > a {
      font-weight: 500;
      height: 100%;
    }
    > span {
      font-weight: 600;
    }
  }
`;

export {
  StyledBreadCrumbs
}
