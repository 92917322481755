import styled from 'styled-components';
import {Space} from 'antd';
import Card from '../../../../Card';
import Table from '../../../../Table';

const StyledBillingInformation = styled(Card)`
  .ant-card-body {
    color: ${props => props.theme.colors.neutral_8};
    a {
      color: ${props => props.theme.colors.neutral_8};
      text-decoration: underline;
    }
  }
  .ant-card-head {
    padding: 24px;
    flex-direction: unset;
  }
`;

const StyledBillingInformationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBillingInformationTitleSpace = styled(Space)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  .billing-title {
    color: ${props => props.theme.colors.neutral_6};
  }
  .billing-plan {
    color: ${props => props.theme.colors.neutral_10};
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
  }
  .billing-renews-date {
    color: ${props => props.theme.colors.neutral_8};
  }
`;

const StyledBillingInformationTable = styled(Table)`
  .ant-table-cell::before {
    content: none !important;
  }
  
  .cashout {
    color: ${props => props.theme.colors.functional_danger};
  }

`;

const StyledBillingInformationLoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const StyledBillingInformationTableDownloadContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 38px;
  
  & .ant-space-item {
    &:nth-of-type(2) {
      display: flex;
    }
  }
  
  .ant-spin {
    position: absolute;
    top: 4px;
    left: 0;
    transform: translate(-100%, 37.5%);
    
    .ant-spin-dot {
      font-size: 18px;
    }
    
    svg {
      height: 1rem;
      width: 1rem;
      
      path {
        fill: unset;
      }
    }
  }
  
  svg {
    height: 24px;
    width: 24px;
    
    path {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      fill: ${props => props.theme.colors.neutral_6};
    }
    
    &:hover {
      cursor: pointer;
      path {
        fill: ${props => props.theme.colors.primary_6};
      }
    }
  }
`;

export {
  StyledBillingInformation,
  StyledBillingInformationTitle,
  StyledBillingInformationTitleSpace,
  StyledBillingInformationTable,
  StyledBillingInformationLoadMoreContainer,
  StyledBillingInformationTableDownloadContainer
};
