import styled from 'styled-components';

const StyledSubscriptionServiceSelect = styled.div`
  position: relative;
  width: 100%;
`;


export {
  StyledSubscriptionServiceSelect
}
