import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  StyledBillingDetailsInformation,
  StyledBillingDetailsInformationCashback,
  // StyledBillingDetailsInformationHeader,
  StyledBillingDetailsInformationCashbackPrices,
  // StyledBillingDetailsInformationCashbackPricesButton,
  StyledBillingDetailsInformationCashbackPricesItem
} from './StyledBillingDetailsInformation';
import ClaimModal from '../../tabComponents/ClaimModal';
import {helpers} from '../../../../../helpers';
// import RupeesCoinIcon from '../../../../../icons/RupeesCoinIcon';
import {billingActions} from '../../../../../state/actions';

const BillingDetailsInformation = ({
  dispatch,
  claimAllRewards,

  handleClaimComplete,
  totals,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'settings']);
  const [claimModalProps, setClaimModalProps] = useState({
    open: false,
    value: 0,
    variant: undefined
  });
  const [claimButtonProps, setClaimButtonProps] = useState({
    disabled: true,
    loading: false
  });

  const t = (key) => trans(`settings:tabs.billing.${key}`);

  useEffect(() => {
    const disabled = !Boolean(totals ? totals?.toBeClaimed : 0);
    setClaimButtonProps({...claimButtonProps, disabled});
  }, [totals]); // eslint-disable-line react-hooks/exhaustive-deps

  // const handleOpenClaimModal = () => {
  //   setClaimButtonProps({...claimButtonProps, loading: true});
  //   claimAllRewards({
  //     successCallback: (data) => {
  //       handleClaimComplete();
  //       setClaimButtonProps({...claimButtonProps, loading: false, disabled: true});
  //       setClaimModalProps({
  //         ...claimModalProps,
  //         open: true,
  //         value: totals.toBeClaimed,
  //         variant: data.length ? 'success' : 'error'
  //       });
  //     },
  //     errorCallback: () => {
  //       setClaimButtonProps({...claimButtonProps, loading: false});
  //       setClaimModalProps({
  //         ...claimModalProps,
  //         open: true,
  //         variant: 'error'
  //       });
  //     }
  //   })
  // };

  const handleCloseClaimModal = () => setClaimModalProps({...claimModalProps, open: false});

  const getTotalValue = (key) => totals?.[key] || 0;

  const pricesItems = [
    {
      // button: (
      //   <StyledBillingDetailsInformationCashbackPricesButton
      //     {...claimButtonProps}
      //     onClick={handleOpenClaimModal}
      //     size='large'
      //   >
      //     {t('claim')} <RupeesCoinIcon />
      //   </StyledBillingDetailsInformationCashbackPricesButton>
      // ),
      title: t('cashbackToClaim'),
      value: getTotalValue('toBeClaimed')
    },
    {
      title: t('totalCashback'),
      value: getTotalValue('total')
    },
    {
      title: t('alreadyClaimed'),
      value: getTotalValue('alreadyClaimed')
    },
  ];

  return (
    <StyledBillingDetailsInformation
      direction='vertical'
      size='large'
      {...rest}
    >
      {/*<StyledBillingDetailsInformationHeader direction='vertical' size={4}>*/}
      {/*  <span className='billing-payment-title'>{trans('next')} {trans('payment')}</span>*/}
      {/*  <span className='billing-payment-value'>{helpers.getMoneyView(500)}</span>*/}
      {/*  <span className='billing-payment-due-date'>{t('dueBy')} 15/01/24</span>*/}
      {/*</StyledBillingDetailsInformationHeader>*/}

      <StyledBillingDetailsInformationCashback title={t('cashback')}>
        <p>
          {t('cashbackDetails1')}<br/>{t('cashbackDetails2')}
        </p>
        <StyledBillingDetailsInformationCashbackPrices>
          {pricesItems.map((i, key) => (
            <StyledBillingDetailsInformationCashbackPricesItem key={key}>
              <Space size={4} direction='vertical'>
                <span className='cashback-title'>{i.title}</span>
                <span className='cashback-value'>{helpers.getMoneyView(i.value)}</span>
              </Space>
              {i.button || ''}
            </StyledBillingDetailsInformationCashbackPricesItem>
          ))}
        </StyledBillingDetailsInformationCashbackPrices>
      </StyledBillingDetailsInformationCashback>

      <ClaimModal
        onOk={handleCloseClaimModal}
        onCancel={handleCloseClaimModal}
        {...claimModalProps}
      />
    </StyledBillingDetailsInformation>
  );
}

BillingDetailsInformation.propTypes = {
  handleClaimComplete: PropTypes.func,
  totals: PropTypes.shape({
    alreadyClaimed: PropTypes.number,
    toBeClaimed: PropTypes.number,
    total: PropTypes.number,
  })
};

const mapDispatchToProps = {
  claimAllRewards: billingActions.claimAllRewards
}

export default connect(null, mapDispatchToProps)(BillingDetailsInformation);
