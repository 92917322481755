import styled from 'styled-components';
import {Tag} from 'antd';

const StyledCostCenterContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const StyledCostCenterTag = styled(Tag)`
  align-items: center;
  display: flex;
  font-family: 'Outfit', sans-serif;
  height: 32px;
`;

const StyledCostCenterCounter = styled.span`
  color: ${props => props.theme.colors.neutral_6};
  display: inline-block;
`;

const StyledCostCenterTagsList = styled.div`
  align-items: center;
  display: flex;
  width: ${props => `${props.width}px`};
`;

export {
  StyledCostCenterContainer,
  StyledCostCenterTag,
  StyledCostCenterCounter,
  StyledCostCenterTagsList
};
