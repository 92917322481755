import styled from 'styled-components';
import {Space} from 'antd';

const StyledLegalPersonInformationAddon = styled.div`
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_5};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  
  transition: .2s ease-in;
  
  svg {
    path {
      fill: ${props => props.theme.colors.neutral_6};
    }
  }
`;

const StyledLegalPersonInformation = styled(Space)`
  position: relative;
  width: 100%;
  
  [aria-invalid="true"] {
    ${StyledLegalPersonInformationAddon} {
      border-color: ${props => props.theme.colors.functional_danger_d};
      color: yellow;
    }
  }
`;

const StyledLegalPersonInformationText = styled.div`
  color: ${props => props.theme.colors.functional_danger};
  font-size: 14px;
`;

export {
  StyledLegalPersonInformation,
  StyledLegalPersonInformationAddon,
  StyledLegalPersonInformationText
};
