import styled from 'styled-components';
import {Alert} from 'antd';

const StyledIncreaseLimitAlert = styled(Alert)`
  align-items: flex-start;
  display: flex;
  margin-bottom: 24px;
  
  span[role="img"] {
    margin-top: 4px;
  }
  
  .limit {
    text-wrap: nowrap;
  }
`;

export {StyledIncreaseLimitAlert};
