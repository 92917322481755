import styled from 'styled-components';
import {Space} from 'antd';
import AntdModal from '../../../AntdModal';

const StyledDeleteModal = styled(AntdModal)`
  .ant-modal-body {
    font-size: 16px;
    font-weight: 500;
    padding: 12px 0 20px 0;
  }
  p {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
`;

const StyledDeleteModalSpace = styled(Space)`
  width: 100%;
  
  .ant-space-item {
    text-align: center;
    width: 100%;
  }
  
  .ant-space {
    width: 100%;
  }
  
  .ant-form-item {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledDeleteModalLogoContainer = styled.div`
  position: relative;
  width: 80px;
  margin: auto;
  svg {
    height: 80px;
    width: 80px;
    path {
      fill: ${props => props.theme.colors.functional_danger};
    }
  }
  
  .ant-avatar {
    background: ${props => props.theme.colors.neutral_6};
    border: 3px solid #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    
    .ant-avatar-string {
      top: -3px;
    }
  }
`;

const StyledDeleteModalDetailsSpace = styled(Space)`
  text-align: center;
  h3, p {
    margin: 0;
    padding: 0;
  }
  h3 {
    color: ${props => props.theme.colors.neutral_10};
    font-weight: 500;
    font-size: 20px;
  }
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 14px;
  }
`;

export {
  StyledDeleteModal,
  StyledDeleteModalSpace,
  StyledDeleteModalLogoContainer,
  StyledDeleteModalDetailsSpace
};
