import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
  StyledSubscriptionsDetails,
  StyledSubscriptionsDetailsPlaceholder,
  StyledSubscriptionsDetailsCardExtra,
  StyledSubscriptionsDetailsMoreButton
} from './StyledSubscriptionsDetails';
import {StyledOverviewPageCard} from '../../../../pages/OverviewPage/StyledOverviewPage';
import SpinSmall from '../../../SpinSmall';
import Counter from '../Counter';
import SubscriptionsCounter from '../SubscriptionsCounter';
import UpcomingSpending from '../UpcomingSpending';
import Variations from '../Variations';
import {ChevronDownIcon} from '../../../../icons';
import routes from '../../../../routes/routes.json';

const subscriptionsListRoute = routes.subscriptionsList;

const defaultDetails = {
  data: [],
  isEnableMoreButton: false,
  moreButtonCounter: 0
}

const defaultMaxDisplayedItems = 5;
const moreButtonDisplayedItemsStep = 50;


const SubscriptionsDetails = ({
  loading,
  subscriptionsTotals,
  variations,
  upcoming
}) => {
  const [t] = useTranslation('main');
  const [variationsDetails, setVariationsDetails] = useState(defaultDetails);
  const [upcomingDetails, setUpcomingDetails] = useState(defaultDetails);

  const navigate = useNavigate();

  const isEmpty = useMemo(() => !subscriptionsTotals.loading && !subscriptionsTotals?.total, [subscriptionsTotals]);

  const subscriptionsExtra = useMemo(() => {
    let extra;
    let dataLength = !subscriptionsTotals?.loading && subscriptionsTotals?.total;
    if (Boolean(dataLength)) {
      extra = (
        <Counter
          variant='default'
          value={dataLength}
        />
      );
    }
    return extra;
  }, [subscriptionsTotals]);

  const getVariationsExtraCounter = ({data, emptyText = '', loading}) => {
    let extra;
    let dataLength = data.length;
    let aboveLength = data.filter(d => d.actual > d.budget).length;
    let belowLength = data.filter(d => d.actual < d.budget).length;

    if (!loading) {
      if (Boolean(aboveLength)) {
        extra = (
          <Counter
            variant={'error'}
            value={`+${aboveLength}`}
          />
        );
      } else if (!Boolean(aboveLength) && Boolean(belowLength)) {
        extra = (
          <Counter
            variant={'success'}
            value={belowLength}
          />
        );
      } else if (!Boolean(dataLength)) {
        extra = (
          <StyledSubscriptionsDetailsCardExtra>
            {emptyText}
          </StyledSubscriptionsDetailsCardExtra>
        );
      }
    }
    return extra;
  }

  const updateDetails = ({data, itemsCount = defaultMaxDisplayedItems, setDetails}) => {
    const dataLength = data.length;
    const isEnableMoreButton = data.length > itemsCount;
    setDetails({
      data: data.slice(0, itemsCount),
      isEnableMoreButton,
      moreButtonCounter: isEnableMoreButton ? dataLength - itemsCount : 0
    });
  }

  useEffect(() => {
    updateDetails({data: variations, setDetails: setVariationsDetails});
  }, [variations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateDetails({data: upcoming, setDetails: setUpcomingDetails});
  }, [upcoming]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUpcomingExtraCounter = ({data, emptyText = '', loading}) => {
    let extra;
    let dataLength = data.length;
    const isNotEmptyData = Boolean(dataLength);

    if (!loading) {
      if (isNotEmptyData) {
        extra = (
          <Counter
            variant={'success'}
            value={dataLength}
          />
        );
      } else if (!isNotEmptyData) {
        extra = (
          <StyledSubscriptionsDetailsCardExtra>
            {emptyText}
          </StyledSubscriptionsDetailsCardExtra>
        );
      }
    }
    return extra;
  }

  const getMoreButton = ({data, details, setDetails}) => {
    const displayedData = details?.data || [];
    const dataLength = data.length;
    const displayedDataLength = displayedData.length;
    const unVisibleDataLength = dataLength - displayedDataLength;
    const moreCounter = unVisibleDataLength > moreButtonDisplayedItemsStep ? moreButtonDisplayedItemsStep : unVisibleDataLength;

    if (unVisibleDataLength <= 0) return ;

    return (
      <StyledSubscriptionsDetailsMoreButton
        onClick={() => handleMoreClick({data, details, setDetails})}
        type='link'
      >
        More (+{moreCounter}) <ChevronDownIcon />
      </StyledSubscriptionsDetailsMoreButton>
    );
  }

  const handleMoreClick = ({data, details, setDetails}) => {
    const displayedData = details?.data || [];
    const displayedDataLength = displayedData.length;
    const itemsCount = displayedDataLength + moreButtonDisplayedItemsStep;
    updateDetails({data, itemsCount, setDetails});
  }

  const variationsExtra = useMemo(() => getVariationsExtraCounter({
    data: variations,
    emptyText: t('noVariations'),
    loading
  }), [loading, t, variations]); // eslint-disable-line react-hooks/exhaustive-deps

  const upcomingSpendingExtra = useMemo(() => getUpcomingExtraCounter({
    data: upcoming,
    emptyText: t('noSpending'),
    loading
  }), [loading, t, variations]); // eslint-disable-line react-hooks/exhaustive-deps

  const variationMoreButton = getMoreButton({data: variations, details: variationsDetails, setDetails: setVariationsDetails});

  const upcomingMoreButton = getMoreButton({data: upcoming, details: upcomingDetails, setDetails: setUpcomingDetails});

  const handleAddSubscriptions = () => navigate(`${subscriptionsListRoute}#add-single-subscription`);

  const handleOpenSubscriptionPage = (e, item) => {
    let subscriptionId = item?.subscription_id;
    if (subscriptionId) navigate(`${subscriptionsListRoute}/${subscriptionId}`);
  }

  return (
    <StyledSubscriptionsDetails
      direction='vertical'
      size='middle'
    >
      {isEmpty ? (
        <StyledSubscriptionsDetailsPlaceholder
          enableButton={true}
          onClick={handleAddSubscriptions}
        />
      ) : (
        <>
          <SpinSmall spinning={subscriptionsTotals?.loading || false}>
            <StyledOverviewPageCard
              bordered={false}
              extra={subscriptionsExtra}
              title={t('subscriptions')}
            >
              <SubscriptionsCounter data={subscriptionsTotals} />
            </StyledOverviewPageCard>
          </SpinSmall>
          <SpinSmall spinning={loading}>
            <StyledSubscriptionsDetails
              direction='vertical'
              size='large'
            >
              <StyledOverviewPageCard
                bordered={false}
                extra={variationsExtra}
                title={t('variations')}
              >
                <Variations
                  data={variationsDetails.data}
                  handleButtonClick={handleOpenSubscriptionPage}
                />
                {variationMoreButton}
              </StyledOverviewPageCard>
              <StyledOverviewPageCard
                bordered={false}
                extra={upcomingSpendingExtra}
                title={t('upcomingSpending')}
              >
                <UpcomingSpending data={upcomingDetails.data} />
                {upcomingMoreButton}
              </StyledOverviewPageCard>
            </StyledSubscriptionsDetails>
          </SpinSmall>
        </>
      )}
    </StyledSubscriptionsDetails>
  );
}

SubscriptionsDetails.propTypes = {
  loading: PropTypes.bool,
  subscriptionsTotals: PropTypes.shape({
    discovered: PropTypes.number,
    free: PropTypes.number,
    loading: PropTypes.bool,
    paid: PropTypes.number,
    total: PropTypes.number
  }),
  variations: PropTypes.arrayOf(
    PropTypes.shape({
      actual: PropTypes.number,
      budget: PropTypes.number,
      expected: PropTypes.number,
      service: PropTypes.shape({
        logo: PropTypes.string,
        name: PropTypes.string
      }),
      subscription_id: PropTypes.string
    })
  ),
  upcoming: PropTypes.arrayOf(
    PropTypes.shape({
      actual: PropTypes.number,
      budget: PropTypes.number,
      expected: PropTypes.number,
      service: PropTypes.shape({
        logo: PropTypes.string,
        name: PropTypes.string
      }),
      subscription_id: PropTypes.string
    })
  )
}

SubscriptionsDetails.defaultProps = {
  loading: false
}

export default SubscriptionsDetails;
