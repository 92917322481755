export const mainConstants = {
  GET_CURRENCIES: 'GET_CURRENCIES',
  SET_INITIAL_LOADING: 'SET_INITIAL_LOADING',
  SET_LOADING: 'SET_LOADING',
  COLLAPSE_NAVIGATION_MENU: 'COLLAPSE_NAVIGATION_MENU',
  CLOSE_KYC_WINDOW: 'CLOSE_KYC_WINDOW',
  CLOSE_LIGHT_KYC_WINDOW: 'CLOSE_LIGHT_KYC_WINDOW',
  OPEN_KYC_WINDOW: 'OPEN_KYC_WINDOW',
  OPEN_LIGHT_KYC_WINDOW: 'OPEN_LIGHT_KYC_WINDOW'
};
