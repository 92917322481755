import {expenseConstants} from '../constants';

const expenseListData = {
  expenses: [],
  totalPrice: 0,
  searchQuery: {},
  pagination: {
    next_page: null,
    cursor: null,
  },
};

const defaultQuery = {
  statuses: undefined,
  employees: undefined
};

const expenseState = {
  expenses: expenseListData,
  expense: {},
  statusesCount: {},
  query: defaultQuery
};

const expenseReducer = (state = expenseState, action) => {
  const {type, data, statusesCount, query} = action;
  switch (type) {
    case expenseConstants.GET_EXPENSES:
      return {
        ...state,
        expenses: data,
        statusesCount: statusesCount || {},
        query
      }
    case expenseConstants.GET_EXPENSES_TOTAL:
      return {
        ...state,
        expenses: data
      }
    case expenseConstants.GET_EXPENSE:
      return {
        ...state,
        expense: data
      }
    case expenseConstants.UPDATE_EXPENSE:
      return {
        ...state,
        expense: data,
        expenses: action.expenses
      }
    case expenseConstants.UPDATE_EXPENSE_STATUS:
      return {
        ...state,
        expenses: action.expenses,
        expense: data
      }
    default:
      return state
  }
};

export default expenseReducer;
