import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Alert, Button, Space} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {Collapse} from 'react-collapse';
import AntdModal from '../../../AntdModal';
import {StyledUploadInvoicesModal, StyledFilePreview} from './StyledUploadInvoicesModal';
import Upload from '../../../Upload';
import InvoiceAttachmentPreview from '../../TransactionsPage/InvoiceAttachmentPreview';
import {helpers} from '../../../../helpers';

const UploadInvoicesModal = ({
  loading,
  onOk,
  open,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!open && files.length) {
      setFiles([]);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const trans = (key, props) => t(`invoices:modal.uploadInvoices.${key}`, props);

  const handleOnFileChange = async ({fileList}) => {
    let createdFiles = [];
    for (const file of fileList) {
      const fileBase64 = await helpers.getBase64(file.originFileObj);
      createdFiles.push({
        date: new Date(),
        file: fileBase64,
        name: file.name
      });
    }
    setFiles([...files, ...createdFiles]);
  }

  const handleSubmitFiles = () => {
    if (onOk) {
      const data = files.map(file => ({base64_file: file.file, file_name: file.name}));
      onOk(
        data,
        null,
        (error) => setError(error?.message || error)
      );
    }
  }

  const cancelButtonProps = {
    style: {display: 'none'}
  };

  const okButtonProps = {
    disabled: files.length === 0,
    loading,
    type: 'primary',
    size: 'large'
  };

  const handleRemoveFile = (index) => {
    const copyFiles = [...files];
    copyFiles.splice(index, 1);
    setFiles(copyFiles);
  }

  return (
    <AntdModal
      cancelButtonProps={cancelButtonProps}
      okButtonProps={okButtonProps}
      okText={t('upload')}
      onOk={handleSubmitFiles}
      open={open}
      title={trans('title')}
      {...rest}
    >
      <StyledUploadInvoicesModal>
        <Upload
          accept='.jpeg,.png,.pdf'
          disabled={loading}
          customRequest={() => null}
          fileList={[]}
          multiple={true}
          onChange={handleOnFileChange}
          showUploadList={false}
        />
        <Space
          direction='vertical'
          size='small'
          style={{width: '100%'}}
        >
          {files.map((file, index) => (
            <StyledFilePreview key={index}>
              <Space align='center' size='small'>
                <InvoiceAttachmentPreview
                  attachment={file}
                  className='attachment-preview'
                />
                <div className='file-preview-details'>
                  {file.name}
                </div>
              </Space>
              <Button
                icon={<CloseOutlined />}
                onClick={() => handleRemoveFile(index)}
                size='small'
              />
            </StyledFilePreview>
          ))}
        </Space>

        <Collapse isOpened={error}>
          <Alert message={error} type='error' showIcon />
        </Collapse>
      </StyledUploadInvoicesModal>
    </AntdModal>
  );
}

UploadInvoicesModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired
}

export default UploadInvoicesModal;
