import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {Avatar, Button} from 'antd';
import {SettingsIcon} from '../../icons';
import {helpers} from '../../helpers';
import {
  StyledNavigationCompanyDetails,
  StyledNavigationDropdownContent,
  StyledNavigationDropdownMenu,
  StyledNavigationCompaniesList
} from './StyledNavigation';
import {PlusOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import useIsEmployeeChanged from '../../hooks/useIsEmployeeChanged';
import {userActions} from '../../state/actions';
import routes from '../../routes/routes.json';

const DropdownNavigation = ({
  activeCompany,
  companies,
  handleAddCompany,
  isAdmin,
  onCompanyChange,
  onMenuClick,

  changeCompany,
  disableEmployeeFromCompany,
  getCompanies,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const isEmployeeChanged = useIsEmployeeChanged();

  const navItems = [
    {key: 'settings', label: 'settings', path: routes.settings, icon: <SettingsIcon />},
  ]
    .map(i => ({...i, label: t(`bottomMenuToggle.navigation.${i.label}`)}));

  useEffect(() => {
    if (isEmployeeChanged) {
      onCompanyChange && onCompanyChange();
      setSelectedKeys([]);
    }
  }, [isEmployeeChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    helpers.updateMenuItems({location, items: navItems, selectedKeys, setSelectedKeys});
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuClick = (e) => {
    const {key} = e;
    const item = navItems.find(i => i.key === key);
    if (item) navigate(item.path);
    setSelectedKeys([key]);
    onMenuClick && onMenuClick();
  }

  const getCompanyDetails = ({company, ...rest}) => {
    const logo = helpers.getObjProp(company, 'logo_url');
    const name = helpers.getObjProp(company, 'name');
    return (
      <StyledNavigationCompanyDetails {...rest}>
        <Avatar
          src={logo}
          size={32}
        >
          {helpers.getInitials(name)}
        </Avatar>
        {name}
      </StyledNavigationCompanyDetails>
    )
  }

  const onCompanyClick = (id) => {
    onMenuClick && onMenuClick();
    changeCompany(
      id,
      (company) => {
        disableEmployeeFromCompany(true, company);
        getCompanies();
      }
    );
  }

  return (
    <StyledNavigationDropdownContent {...rest}>
      <header>
        {getCompanyDetails({company: activeCompany})}
        {isAdmin && (
          <StyledNavigationDropdownMenu
            items={navItems}
            onClick={handleMenuClick}
            selectedKeys={selectedKeys}
          />
        )}
      </header>
      <footer>
        {companies.length > 0 && (
          <StyledNavigationCompaniesList>
            {companies.map(company =>
              getCompanyDetails({
                company,
                key: company.company_id,
                onClick: () => onCompanyClick(company.company_id)
              }))}
          </StyledNavigationCompaniesList>
        )}
        <Button
          onClick={(e) => handleAddCompany && handleAddCompany(e)}
          icon={<PlusOutlined />}
          size='large'
        >
          {t('add')} {t('company')}
        </Button>
      </footer>
    </StyledNavigationDropdownContent>
  );
}

DropdownNavigation.propTypes = {
  handleAddCompany: PropTypes.func,
  onCompanyChange: PropTypes.func,
  onMenuClick: PropTypes.func
}

const mapStateToProps = state => {
  const {companies, isAdmin, employee} = state.user;
  let activeCompany;
  let userCompanies = [];
  if (employee.hasOwnProperty('company_id')) {
    userCompanies = companies
      .filter(c => c.account_enabled)
      .filter(c => {
        if (c.company_id !== employee.company_id) {
          return c;
        } else {
          activeCompany = c;
          return false
        }
      });
  }

  return {
    activeCompany,
    companies: userCompanies,
    isAdmin
  }
}

const mapDispatchToProps = {
  changeCompany: userActions.changeCompany,
  disableEmployeeFromCompany: userActions.disableEmployeeFromCompany,
  getCompanies: userActions.getCompanies
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownNavigation);
