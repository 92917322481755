import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider as MuiThemeProvider} from '@mui/material';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import './index.css';
import App from './app/App';
import { store } from './state/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from './translations';
import styledTheme, {antdTheme, materialTheme} from './theme';
import './snippets/sentry';


ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <MuiThemeProvider theme={materialTheme}>
        <StyledThemeProvider theme={styledTheme}>
          <ConfigProvider theme={antdTheme}>
            <App />
          </ConfigProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
