const transactionTypesConstants = Object.freeze({
  CARD_TRANSACTION: 'Card transaction',
  PAY_IN: 'Payin'
});

const cardPaymentStatusesConstants = Object.freeze({
  AUTHORIZATION_ACCEPTED: 'A',
  REFUND: 'R',
  SETTLED: 'S',
  CLEARED: 'C',
  DECLINED: 'I',
  REVERSED: 'V'
});

export {
  transactionTypesConstants,
  cardPaymentStatusesConstants
};
