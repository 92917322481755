import styled from 'styled-components';
import {QRCode} from 'antd';

const StyledKeylessQRCodeContainer = styled.div`
  min-height: 352px;
  position: relative;
  
  .loader {
    img {
      width: 120px;
    }
  }
`;

const StyledKeylessQRCode = styled(QRCode)`
  margin: 0 auto 16px auto;
  padding: 0;
`;


export {
  StyledKeylessQRCode,
  StyledKeylessQRCodeContainer
}