import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Alert, Button, Form, Input} from 'antd';
import {Collapse} from 'react-collapse';
import AntdModal from '../../../AntdModal';
import {helpers} from '../../../../helpers';


const {Item} = Form;

const defaultFormValues = {
  old_password: '',
  first_password: '',
  second_password: ''
};

const ChangePasswordModal = ({
  open,
  handleOk,
  handleCancel,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!open) {
      form.setFieldsValue(defaultFormValues);
      error && setError(null);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (fields) => {
    if (handleOk) {
      setLoading(true);
      handleOk(
        fields,
        () => setLoading(false),
        (error) => {
          if (error.message) {
            setError(error.message);
          } else {
            const fields = helpers.getFormServerErrorFields(error);
            form.setFields(fields);
          }
          setLoading(false);
        }
      )
    }
  }

  const validationT = (key, options) => t(`validation.${key}`, options);

  const onCancel = () => handleCancel && handleCancel();

  const lengthFieldMessage = (value, type = 'min') => validationT(`${type}LengthValidation`, {value});

  const inputRules = [
    {required: true, message: validationT('fieldIsRequired')},
    {min: 6, message: lengthFieldMessage(6, 'min')},
    {max: 6, message: lengthFieldMessage(6, 'max')},
    ({ getFieldValue }) => ({
      validator(_, value) {
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(value) || value === '') {
          return Promise.resolve();
        }
        return Promise.reject(new Error(validationT('decimalValidation')));
      },
    })
  ];

  const inputProps = {
    maxLength: 6,
    size: 'large',
    type: 'number'
  }

  const password = t('password');
  const title = `${t('change')} ${password}`;

  const footer = [
    <Button
      key='cancel'
      disabled={loading}
      onClick={onCancel}
      size='large'
    >
      {t('cancel')}
    </Button>,
    <Button
      key='submit'
      disabled={loading}
      onClick={() => form.submit()}
      size='large'
      type='primary'
    >
      {t('save')}
    </Button>
  ];


  return (
    <AntdModal
      forceRender
      footer={footer}
      onCancel={onCancel}
      open={open}
      title={title}
      data-testid='change-password-modal'
      {...rest}
    >
      <Form
        initialValues={defaultFormValues}
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
      >
        <Item
          label={`${t('current')} ${password}`}
          name='old_password'
          rules={inputRules}
          required
        >
          <Input.Password {...inputProps} />
        </Item>
        <Item
          label={`${t('new')} ${password}`}
          name='first_password'
          rules={inputRules}
          required
        >
          <Input.Password {...inputProps} />
        </Item>
        <Item
          label={`${t('confirm')} ${password}`}
          name='second_password'
          rules={[
            ...inputRules,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('first_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(validationT('passwordsDoNotMatch')));
              },
            }),
          ]}
          required
        >
          <Input.Password {...inputProps} />
        </Item>

        <Collapse isOpened={error}>
          <Alert message={error} type='error' showIcon />
        </Collapse>
      </Form>

    </AntdModal>
  );
}

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
}

export default ChangePasswordModal;
