import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import VerifyPageContainer from '../../components/VerifyPageContainer';

const VerifySuccessPage = () => {
  const [t] = useTranslation(['main', 'quickStart']);
  const trans = (key) => t(`quickStart:verifySuccess.${key}`);
  const [successProps, setSuccessProps] = useState({isChecked: false, isUboInvite: false});
  const location = useLocation();

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    setSuccessProps({
      isChecked: true,
      isUboInvite: searchParams.hasOwnProperty('ubo-invite')
    })
  }, [location]);

  return (
    <VerifyPageContainer>
      <h1>{trans('title1')}<br />{trans('title2')}</h1>
      <p>{trans('description1')}<br />{trans('description2')}</p>
      {successProps.isChecked && !successProps.isUboInvite && (
        <div className='redirect-description'>
          {trans('description3')} <a href='/'>{trans('linkText')}</a>.
        </div>
      )}
    </VerifyPageContainer>
  );
}

export default VerifySuccessPage;
