import styled from 'styled-components';
import {Button} from 'antd';

const StyledMenuCollapseButton = styled(Button)`
  margin-right: 8px;
  padding: 0;
  width: 24px;
  height: 24px;
  & path {
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:hover {
    & path {
      stroke: #8C8C8C;
    }
  }
  & > div {
    left: 50%;
    position: absolute !important;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
  }
`;

export {
  StyledMenuCollapseButton
}