import { useState, useEffect } from 'react';
import {helpers} from '../helpers';

export default function useContentLoaded (data) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!helpers.isEmptyObject(data)) setIsLoaded(true);
  }, [data]);

  return isLoaded;
}

