import styled from 'styled-components';
import {Space} from 'antd';

const DETAILS_PANEL_WIDTH = '472px';

const StyledCardPageContainer = styled.div`
  overflow-x: hidden;
  padding: 16px 0 24px 24px;
`;

const StyledCardPageContainerSpace = styled(Space)`
  width: 100%;
  & > .ant-space-item {
    &:nth-of-type(1) {
      width: calc(100%);
    } 
    &:nth-of-type(2) {
      min-width: ${DETAILS_PANEL_WIDTH};
      width: ${DETAILS_PANEL_WIDTH};
    }
    & > div > .ant-card {
      height: calc(100vh - 66px - 16px - 24px);
      
      .ant-card-body {
        overflow: auto;
        max-height: calc(100% - 55px);
      }
    }
  }
`;

const StyledCardPageTransactionContainer = styled.div`
  position: relative;
  
  & > div {
    position: absolute;
    right: 24px;
    
    & > .ant-card {
      height: calc(100vh - 66px - 16px - 24px);
      
      .ant-card-body {
        overflow: hidden auto;
        max-height: calc(100% - 55px);
      }
    }
  }
  
  .transaction-details-panel-content {
    height: calc(100vh - 66px - 16px - 24px);
    margin-left: 0;
    
    & > div {
      &:nth-of-type(2) {
        max-height: calc(100vh - (66px + 82px + 28px));
      }
    }
  }
  
`

export {
  StyledCardPageContainer,
  StyledCardPageContainerSpace,
  StyledCardPageTransactionContainer
};
