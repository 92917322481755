import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Radio, Space} from 'antd';
import {
  StyledSelectCardTypeModal,
  StyledSelectCardTypeModalRadio,
  StyledSelectCardTypeModalRadioImgSpace
} from './StyledSelectCardTypeModal';
import {cardTypesConstants} from '../../../../constants';
import {ReactComponent as ForPersonUseOptionImg} from '../../../../static/images/pages/cards/for-personal-use-option.svg';
import {ReactComponent as ForSubscriptionsOptionImg} from '../../../../static/images/pages/cards/for-subscriptions-option.svg';

const defaultVariant = cardTypesConstants.FOR_PERSONAL_USE;

const options = [
  {
    img: <ForPersonUseOptionImg />,
    label: 'forPersonalUser',
    value: cardTypesConstants.FOR_PERSONAL_USE
  },
  {
    img: <ForSubscriptionsOptionImg />,
    label: 'forSubscriptions',
    value: cardTypesConstants.FOR_SUBSCRIPTION
  },
];

const SelectCardTypeModal = ({
  onOk,
  open,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'cards']);
  const [variant, setVariant] = useState(cardTypesConstants.FOR_PERSONAL_USE);

  const t = (key, options) => trans(`cards:modal.selectCardType.${key}`, options);

  const tOption = (key) => t(`variant.${key}`);


  useEffect(() => {
    if (!open && variant !== defaultVariant) setVariant(defaultVariant);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleContinue = () => onOk && onOk(variant);

  const footer = [
    <Button
      key='submit'
      onClick={handleContinue}
      type='primary'
      size='large'
    >
      {trans('continue')}
    </Button>
  ];

  return (
    <StyledSelectCardTypeModal
      centered={true}
      footer={footer}
      open={open}
      width={533}
      {...rest}
    >
      <Space size={27}>
        {options.map(option => {
          const label = option?.label;
          const value = option?.value;
          const checked = variant === value;
          return (
            <StyledSelectCardTypeModalRadio
              key={`option-${value}`}
              direction='vertical'
              size={18}
            >
              <StyledSelectCardTypeModalRadioImgSpace
                align='end'
                className={checked && 'checked'}
                direction='vertical'
                size='small'
                onClick={() => setVariant(value)}
              >
                <Radio key={label} value={option.value} checked={checked} />
                {option.img}
              </StyledSelectCardTypeModalRadioImgSpace>
              <Space direction='vertical' size='small'>
                <h3>{tOption(`${label}.title`)}</h3>
                <p>{tOption(`${label}.description`)}</p>
              </Space>
            </StyledSelectCardTypeModalRadio>
          )
        })}
      </Space>
    </StyledSelectCardTypeModal>
  );
}

SelectCardTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func
}

export default SelectCardTypeModal;
