import styled from 'styled-components';
import Card from '../../../../Card';

const StyledInvoiceDetailsForm = styled.div`
  display: flex;
  flex-direction: column;
  
  .ant-picker {
    width: 100%;
  }
`;

const StyledInvoiceDetailsFormCard = styled(Card)`
  .ant-select-selection-item {
    .ant-avatar {
      margin-right: 8px;
    }

    .ant-select-selection-item-content {
      font-size: 14px;
    }
  }
`;

export {
  StyledInvoiceDetailsFormCard,
  StyledInvoiceDetailsForm
};
