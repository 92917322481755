import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button} from 'antd';
import {StyledEditableAvatar} from './StyledEditableAvatar';
import {EditIcon} from '../../../../../../icons';

const EditableAvatar = ({
  avatarProps,
  disabled,
  initials,
  onEdit,
  value,
  ...rest
}) => {

  const handleEdit = (e) => (!disabled && onEdit) && onEdit(e);

  return (
    <StyledEditableAvatar
      size='middle'
      {...rest}
    >
      <Avatar
        size={180}
        src={value}
        {...avatarProps}
      >
        {initials}
      </Avatar>
      <Button
        disabled={disabled}
        icon={<EditIcon />}
        onClick={handleEdit}
        type='primary'
        shape='circle'
      />
    </StyledEditableAvatar>
  );
}

EditableAvatar.propTypes = {
  avatarProps: PropTypes.object,
  disabled: PropTypes.bool,
  initials: PropTypes.string,
  onEdit: PropTypes.func,
  value: PropTypes.string
}

EditableAvatar.defaultProps = {
  disabled: false,
  enabledActions: true
}

export default EditableAvatar;
