import styled from 'styled-components';
import {Space} from 'antd';
import Card from '../../components/Card';
import Spin from '../../components/Spin';
import {borders, typography} from '../../utils/css';

const PANEL_HEIGHT = 'calc(100vh - 66px - 24px)';

const StyledOverviewPage = styled(Space)`
  width: 100%;
  
  .overview-main-spin {
    display: flex;
    
    & > .ant-spin-container {
      display: flex;
      width: 100%;
      & > .ant-space {
        width: 100%;
      }
    }
  }
  
  .spending-selectors {

    .ant-dropdown-trigger {
      font-size: 14px;
    }
    
    .ant-select {
      min-width: 152px;
      .ant-select-selector {
        border: 1px solid ${props => props.theme.colors.neutral_5};
      }
      
      
      &:hover,
      &.ant-select-focused,
      &.ant-select-open {
        &:not(.ant-select-disabled):not(.ant-pagination-size-changer) {
          .ant-select-selector {
            background-color: ${props => props.theme.colors.neutral_1};
            border-color: ${props => props.theme.colors.primary_3};
          }
        }
      }
    }
  }
`;

const StyledOverviewPageContent = styled(Space)`
  padding: 0 24px 24px 24px;
  width: 100%;
  
  & > .ant-space-item {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100%;
    
    & > .ant-space {
      width: 100%;
    }
    
    &:nth-of-type(2) {
      min-width: 212px;
      width: 212px;
      overflow: auto;
      max-height: ${PANEL_HEIGHT};
    }
  }
`;

const StyledOverviewPageSpin = styled(Spin)`
  display: flex;
  min-height: ${PANEL_HEIGHT};
  
  & > .ant-spin-container {
    display: flex;
  }
`;


const StyledOverviewPageCard = styled(Card)`
  background-color: unset;
  
  &:not(.ant-card-bordered) {
    box-shadow: none;
  }
  
  .ant-card-body {
    padding: 0;
  }
  
  .ant-card-head {
    border-bottom: none;
    font-weight: 500;
    padding: 0;
    justify-content: start;
  }
  
  &.cost-center-chart-card {
    .ant-empty-description {
      p {
        max-width: 220px;
      }
    }
    
    &.no-padding {
      & > .ant-card-body {
        padding: 0;
      }
    }
  }
  
  .cost-center-chart {
    height: 243px;
    padding: 8px 24px;
    overflow: auto;
  }
`;

const StyledOverviewPageCardRightSideSpace = styled(Space)`
  & > .ant-space-item {
    display: flex;
  }
`;

const StyledOverviewPageSpendingPlaceholder = styled.div`
  ${borders.default};
  background-color: ${props => props.theme.colors.neutral_1};
  height: ${props => props.fullheight ? PANEL_HEIGHT : '442px'};
  
  .ant-space {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    
    h3, p {
      margin: 0;
    }
    
    h3 {
      ${typography.heading.h5};

      color: ${props => props.theme.colors.neutral_10};
    }
    
    p {
      ${typography.body.regular};
      
      color: ${props => props.theme.colors.neutral_8};
    }
  }
  
  svg {
    height: 100%;
    width: 100%;
  }
`;

export {
  StyledOverviewPage,
  StyledOverviewPageContent,
  StyledOverviewPageSpin,
  StyledOverviewPageCard,
  StyledOverviewPageCardRightSideSpace,
  StyledOverviewPageSpendingPlaceholder
}