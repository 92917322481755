import styled from 'styled-components';
import {Button, Form} from 'antd';

const MD_WIDTH = '1024px';
const LG_HEIGHT = '56px';
const MD_HEIGHT = '48px';

const StyledCompleteProfileForm = styled(Form)`
  input {
    padding: 12px 16px;
    height: ${LG_HEIGHT};
    
    @media screen and (max-width: ${MD_WIDTH}) {
      padding: 10px 13px;
      height: ${MD_HEIGHT};
    }
  }
  .ant-form-item-control-input {
    min-height: ${LG_HEIGHT};
  }
  button {
    height: ${LG_HEIGHT};
  }
  @media screen and (max-width: ${MD_WIDTH}) {
    .ant-form-item-control-input {
      min-height: ${MD_HEIGHT};
    }
    button {
      height: ${MD_HEIGHT};
    }
  }
`;


const StyledButton = styled(Button)`
  &.ant-btn {
    height: ${LG_HEIGHT};
    @media screen and (max-width: ${MD_WIDTH}) {
      height: ${MD_HEIGHT};
    }
  }
  width: 100%;
`;

export {
  StyledCompleteProfileForm,
  StyledButton
}
