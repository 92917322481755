import React from 'react';
import Spin from '../Spin';

const SpinSmall = ({
  children,
  size,
  ...rest
}) => {

  return (
    <Spin
      {...rest}
      size='small'
    >
      {children}
    </Spin>
  );
}


export default SpinSmall;
