import styled from 'styled-components';
import {Upload} from 'antd';

const StyledUpload = styled(Upload)`
  &:hover {
    cursor: pointer;
  }

  .ant-upload-select {
    align-items: center;
    background: transparent;
    border-radius: ${props => props.theme.border_radius.standard};
    display: flex;
    justify-content: center;
    padding: 24px;
    width: 100%;

    background: linear-gradient(to right, ${props => props.theme.colors.neutral_4} 50%, transparent 0%) top repeat-x,
                linear-gradient(${props => props.theme.colors.neutral_4} 50%, transparent 0%) right repeat-y,
                linear-gradient(to right, ${props => props.theme.colors.neutral_4} 50%, transparent 0%) bottom repeat-x,
                linear-gradient(${props => props.theme.colors.neutral_4} 50%, transparent 0%) left repeat-y;
    background-size: 16px 1px, 1px 16px;

  }

  .ant-upload {
    & > div {
      background: transparent;
    }
  }
`;

const DefaultUploadChildren = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  img, svg {
    margin-bottom: 16px;
  }

  p {
    font-weight: 500;
    margin-bottom: 8px;

    a {
      color: ${props => props.theme.colors.primary_6};
      text-decoration: none;
    }
  }

  span {
    color: ${props => props.theme.colors.neutral_6};
    font-weight: 300;
  }
`;

export {
  StyledUpload,
  DefaultUploadChildren
};
