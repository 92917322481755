import React from 'react';
import {
  StyledCard
} from './StyledCard';

const Card = ({
  children,
  ...rest
}) => {

  return (
    <StyledCard {...rest}>
      {children}
    </StyledCard>
  );
}

export default Card;
