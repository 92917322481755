import React from 'react';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import PropTypes from 'prop-types';
import {
  StyledSubscriptionsCounter,
  StyledSubscriptionsCounterItem,
  StyledSubscriptionsCounterItemDot
} from './StyledSubscriptionsCounter';

const statuses = ['free', 'paid', 'discovered'];

const SubscriptionsCounter = ({data, ...rest}) => {
  const [t] = useTranslation('main');

  return (
    <StyledSubscriptionsCounter
      direction='vertical'
      size={4}
      {...rest}
    >
      {statuses.map(status => (
        <StyledSubscriptionsCounterItem key={status}>
          <Space align='center' size='small'>
            <StyledSubscriptionsCounterItemDot variant={status} />
            {t(`subscriptionReviewTypesConstants.${status}`)}
          </Space>
          <span className='count'>
            {data?.[status] || 0}
          </span>
        </StyledSubscriptionsCounterItem>
      ))}
    </StyledSubscriptionsCounter>
  );
}

SubscriptionsCounter.propTypes = {
  data: PropTypes.shape({
    discovered: PropTypes.number,
    free: PropTypes.number,
    paid: PropTypes.number
  })
}

SubscriptionsCounter.defaultProps = {
  data: []
}

export default SubscriptionsCounter;
