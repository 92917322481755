import React from 'react';

const CheckmarkCircleFilledIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.9995" cy="16.001" r="13" fill="#000"/>
    <circle cx="16" cy="16" r="3" fill="white"/>
  </svg>
);

export default CheckmarkCircleFilledIcon;
