import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import {EditFilled, PlusOutlined} from '@ant-design/icons';
import {
  StyledInvoiceDetailsCard,
  StyledInvoiceDetailsCloseButton,
  StyledInvoiceDetailsFilePreview,
  StyledInvoiceDetailsAttachmentPreview,
  StyledInvoiceDetailsDownloadButton
} from './StyledInvoiceDetails';
import InvoiceAttachmentPreview from '../InvoiceAttachmentPreview';
import ImagePreviewTooltip from '../ImagePreviewTooltip';
import {CloseIcon, DownloadIcon} from '../../../../icons';


const InvoiceDetails = ({
  attachment,
  isDisableClose,
  isDisableEdit,
  isEnableDownloadButton,
  transaction,
  onClose,
  onDownload,
  onEdit,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const isExistAttachment = attachment && attachment.file;

  const handleOnEdit = () => onEdit && onEdit(transaction);

  const handleDownload = (e) => onDownload && onDownload(e);

  const extra = useMemo(() => {
    let element = null;
    if (isExistAttachment) {
      if (!isDisableEdit) {
        element = (
          <Button
            icon={<EditFilled />}
            onClick={handleOnEdit}
            size='small'
            type='text'
          />
        )
      } else if (isEnableDownloadButton) {
        element = (
          <StyledInvoiceDetailsDownloadButton
            onClick={handleDownload}
            type='text'
          >
            <DownloadIcon /> {t('download')}
          </StyledInvoiceDetailsDownloadButton>
        )
      }
    }
    return element;
  }, [isExistAttachment, isEnableDownloadButton, isDisableEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const invoiceAttachment = (
    <InvoiceAttachmentPreview
      attachment={attachment}
    />
  );

  return (
    <>
      <StyledInvoiceDetailsCard
        title={t('invoice')}
        type='inner'
        extra={extra}
        {...rest}
      >
        {isExistAttachment ? (
          <ImagePreviewTooltip
            overlayClassName='image-preview-tooltip-overlay single'
            trigger='hover'
            title={invoiceAttachment}
          >
            <StyledInvoiceDetailsFilePreview>
              <StyledInvoiceDetailsAttachmentPreview
                attachment={attachment}
                onClick={(e) => !isEnableDownloadButton && handleDownload(e)}
              />
              {!isDisableClose && (
                <StyledInvoiceDetailsCloseButton
                  icon={<CloseIcon />}
                  onClick={(e) => onClose && onClose(e)}
                  shape='circle'
                  type='text'
                />
              )}
            </StyledInvoiceDetailsFilePreview>
          </ImagePreviewTooltip>
        ) : isDisableEdit ? null : (transaction && transaction.expense) && (
          <Button
            icon={<PlusOutlined />}
            onClick={handleOnEdit}
            type='text'
          >
            {t('add')}
          </Button>
        )}

      </StyledInvoiceDetailsCard>
    </>
  );
}

InvoiceDetails.propTypes = {
  attachment: PropTypes.shape({
    file: PropTypes.string,
    name: PropTypes.string
  }),
  isDisableEdit: PropTypes.bool,
  isDisableClose: PropTypes.bool,
  isEnableDownloadButton: PropTypes.bool,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  onEdit: PropTypes.func,
  transaction: PropTypes.object
}

InvoiceDetails.defaultProps = {
  isDisableEdit: false,
  isDisableClose: false,
  isEnableDownloadButton: false
}

export default InvoiceDetails;
