import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import {ReactComponent as BankingSvgImage} from '../../../../static/images/pages/subscriptions/empty-banking.svg';
import {ReactComponent as EmptySvgImage} from '../../../../static/images/pages/subscriptions/empty-subscriptions.svg';
import Empty from '../../../Empty';

const variants = {
  BANKING: 'banking',
  EMPTY: 'empty'
}

const {BANKING, EMPTY} = variants;

const EmptySubscriptions = ({
  description,
  enableButton,
  onClick,
  variant,
  ...rest
}) => {
  const [trans] = useTranslation('subscriptions');
  const t = (key) => trans(`empty.variants.${key}`);

  const getProps = (variant) => {
    return {
      buttonText: t(`${variant}.buttonText`),
      description: t(`${variant}.description`),
      title: t(`${variant}.title`)
    };
  }

  const getImage = (variant) => {
    const images = {
      [BANKING]: <BankingSvgImage />,
      [EMPTY]: <EmptySvgImage />
    };
    return images[variant] || undefined;
  }

  const {
    buttonText,
    description: variantDescription,
    image,
    title
  } = useMemo(() => ({
    ...getProps(variant),
    image: getImage(variant)
  }), [variant]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Empty
      description={description || variantDescription}
      image={image}
      title={title}
      {...rest}
    >
      {enableButton && (
        <Button
          onClick={onClick}
          size='large'
          type='primary'
        >
          {buttonText}
        </Button>
      )}
    </Empty>
  );
}

EmptySubscriptions.propTypes = {
  description: PropTypes.any,
  enableButton: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([BANKING, EMPTY])
}

EmptySubscriptions.defaultProps = {
  enableButton: false,
  variant: EMPTY
}

export default EmptySubscriptions;
