import React from 'react';

const InvoicePendingIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4634_17128)">
      <path
        d="M13.3333 10.7667H21.3333C21.687 10.7667 22.0261 10.6244 22.2761 10.3713C22.5262 10.1181 22.6667 9.77471 22.6667 9.41667C22.6667 9.05862 22.5262 8.71525 22.2761 8.46207C22.0261 8.2089 21.687 8.06667 21.3333 8.06667H13.3333C12.9797 8.06667 12.6406 8.2089 12.3905 8.46207C12.1405 8.71525 12 9.05862 12 9.41667C12 9.77471 12.1405 10.1181 12.3905 10.3713C12.6406 10.6244 12.9797 10.7667 13.3333 10.7667ZM10.6667 16.1667H13.3333C13.687 16.1667 14.0261 16.0244 14.2761 15.7713C14.5262 15.5181 14.6667 15.1747 14.6667 14.8167C14.6667 14.4586 14.5262 14.1152 14.2761 13.8621C14.0261 13.6089 13.687 13.4667 13.3333 13.4667H10.6667C10.313 13.4667 9.97391 13.6089 9.72386 13.8621C9.47381 14.1152 9.33333 14.4586 9.33333 14.8167C9.33333 15.1747 9.47381 15.5181 9.72386 15.7713C9.97391 16.0244 10.313 16.1667 10.6667 16.1667ZM10.6667 21.5667C11.0203 21.5667 11.3594 21.4244 11.6095 21.1713C11.8595 20.9181 12 20.5747 12 20.2167C12 19.8586 11.8595 19.5152 11.6095 19.2621C11.3594 19.0089 11.0203 18.8667 10.6667 18.8667C10.313 18.8667 9.97391 19.0089 9.72386 19.2621C9.47381 19.5152 9.33333 19.8586 9.33333 20.2167C9.33333 20.5747 9.47381 20.9181 9.72386 21.1713C9.97391 21.4244 10.313 21.5667 10.6667 21.5667Z"
        fill="#BFBFBF"/>
      <path
        d="M26.6667 2.66667H5.33333C4.97971 2.66667 4.64057 2.8089 4.39052 3.06207C4.14048 3.31525 4 3.65863 4 4.01667V28.3167C4 28.5674 4.06895 28.8131 4.19913 29.0264C4.32931 29.2397 4.51557 29.412 4.73705 29.5241C4.95852 29.6363 5.20646 29.6837 5.45307 29.6612C5.69969 29.6387 5.93524 29.5471 6.13333 29.3967L8.89333 27.3042L10.4419 28.3357C11.2962 28.9048 12.44 28.2923 12.44 27.2659C12.44 26.8647 12.2527 26.4865 11.9335 26.2433L9.69333 24.5367C9.46254 24.3614 9.18183 24.2667 8.89333 24.2667C8.60484 24.2667 8.32413 24.3614 8.09333 24.5367L6.66667 25.6167V5.36667H25.3333V10.6905C25.3333 11.4269 25.9303 12.0238 26.6667 12.0238C27.403 12.0238 28 11.4269 28 10.6905V4.01667C28 3.65863 27.8595 3.31525 27.6095 3.06207C27.3594 2.8089 27.0203 2.66667 26.6667 2.66667Z"
        fill="#BFBFBF"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.6666 32C27.8213 32 32 27.8213 32 22.6667C32 17.512 27.8213 13.3333 22.6666 13.3333C17.512 13.3333 13.3333 17.512 13.3333 22.6667C13.3333 27.8213 17.512 32 22.6666 32ZM22.2857 24H27.0476C27.3002 24 27.5424 23.9063 27.721 23.7396C27.8996 23.573 28 23.3469 28 23.1111C28 22.8754 27.8996 22.6493 27.721 22.4826C27.5424 22.3159 27.3002 22.2222 27.0476 22.2222H23.2381V16.8889C23.2381 16.6531 23.1377 16.427 22.9591 16.2603C22.7805 16.0936 22.5383 16 22.2857 16C22.0331 16 21.7909 16.0936 21.6123 16.2603C21.4337 16.427 21.3333 16.6531 21.3333 16.8889V23.1111C21.3333 23.3469 21.4337 23.573 21.6123 23.7396C21.7909 23.9063 22.0331 24 22.2857 24Z"
            fill="#FAAD14"/>
    </g>
    <defs>
      <clipPath id="clip0_4634_17128">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default InvoicePendingIcon;
