import styled from 'styled-components';
import {Button} from 'antd';
import Card from '../../../Card';
import InvoiceAttachmentPreview from '../InvoiceAttachmentPreview';

const StyledInvoiceDetailsCard = styled(Card)`
  margin-top: 16px;

  .ant-card-body {
    position: relative;
    text-align: center;

    button:not(.ant-btn-icon-only) {
      color: ${props => props.theme.colors.primary_6};

      &:hover {
        color: ${props => props.theme.colors.primary_6};
      }
    }
  }
`;

const StyledInvoiceDetailsCloseButton = styled(Button)`
  background-color: ${props => props.theme.colors.neutral_5};
  color: ${props => props.theme.colors.neutral_10};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 30px !important;
  position: absolute;
  right: 12px;
  top: 4px;
  width: 30px !important;
  
  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: ${props => props.theme.colors.neutral_6};
  }
  
  &:not(:disabled):not(.ant-btn-disabled):hover,
  &:not(:disabled):not(.ant-btn-disabled):active,
  &:not(:disabled):not(.ant-btn-disabled):focus {
    background-color: ${props => props.theme.colors.neutral_6};
  }
  
  &.ant-btn-icon-only {
    svg {
      height: 24px;
      width: 24px;
      
      path {
        transition: .2s ease;
        fill: ${props => props.theme.colors.neutral_10};
      }
    }
  }
`;

const StyledInvoiceDetailsFilePreview = styled.div`
  position: relative;
`;

const StyledInvoiceDetailsAttachmentPreview = styled(InvoiceAttachmentPreview)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledInvoiceDetailsDownloadButton = styled(Button)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary_6};
  padding: 0;
  
  svg {
    margin-right: 4px;
    path {
      fill: ${props => props.theme.colors.primary_6};
      transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }
  
  &.ant-btn-text:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
      background-color: unset;
      color: ${props => props.theme.colors.primary_7};
      
      svg {
        path {
          fill: ${props => props.theme.colors.primary_7};
        }
      }
    }
    
    &:active,
    &:focus {
      background-color: unset;
      color: ${props => props.theme.colors.primary_6};
      
      svg {
        path {
          fill: ${props => props.theme.colors.primary_6};
        }
      }
    }
  }
`;

export {
  StyledInvoiceDetailsFilePreview,
  StyledInvoiceDetailsCard,
  StyledInvoiceDetailsCloseButton,
  StyledInvoiceDetailsAttachmentPreview,
  StyledInvoiceDetailsDownloadButton
};
