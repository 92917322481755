import styled from 'styled-components';
import {typography} from '../../../../utils/css';

const StyledBalanceDetails = styled.span`
  ${typography.body.regular};
  color: ${props => props.theme.colors.neutral_10};
  margin-right: 6px;
  
  .balance-value {
    ${typography.body.bold};
  }
  
  .ant-space-item {
    display: flex;
  }
  
  svg {
    height: 24px;
    width: 24px;
    
    path {
      fill: ${props => props.theme.colors.neutral_10};
    }
  }
`;

export {
  StyledBalanceDetails
};
