import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {StyledOwnerSelect, StyledOwnerSelectMenuItem} from './StyledOwnerSelect';
import Paragraph from 'antd/es/typography/Paragraph';
import {SearchOutlined} from '@ant-design/icons';
import {
  StyledSelectDropdownContent,
  StyledSelectDropdownSearchContainer
} from '../SelectDropdown/StyledSelectDropdown';
import {helpers} from '../../../../../helpers';

const OwnerSelect = ({
  emptyText,
  options,
  mode,
  ...rest
}) => {

  const [t] = useTranslation('main');
  const [searchedOptions, setSearchedOptions] = useState(options);
  const [searchedValue, setSearchedValue] = useState('');

  useEffect(() => {
    setSearchedOptions(options);
    if (searchedValue) setSearchedValue('');
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = (e) => {
    let {value} = e.target;
    setSearchedValue(value);

    value = value.toLowerCase();
    setSearchedOptions(options.filter(o => o.label.toLowerCase().indexOf(value) >= 0));
  }

  const searchedOptionsLength = searchedOptions.length;

  return (
    <StyledOwnerSelect
      {...rest}
      mode='default'
      showSearch={false}
      options={searchedOptions.map(option => ({
        ...option,
        label: (
          <StyledOwnerSelectMenuItem size='small'>
            <Avatar
              src={option.image || undefined}
            >
              {helpers.getInitials(option.label)}
            </Avatar>
            {option.label}
          </StyledOwnerSelectMenuItem>
        )
      }))}
      dropdownStyle={{minWidth: 212}}
      dropdownRender={(menu) => (
        <StyledSelectDropdownContent>
          <StyledSelectDropdownSearchContainer className='search'>
            <Input
              allowClear={true}
              size='large'
              placeholder={t('search')}
              suffix={<SearchOutlined />}
              onChange={onSearch}
              value={searchedValue}
            />
          </StyledSelectDropdownSearchContainer>
          <div className='dropdown-searched-content'>
            {searchedOptionsLength > 0 && menu}

            {searchedOptionsLength === 0 && searchedOptionsLength !== options.length && (
              <Paragraph>
                {emptyText}
              </Paragraph>
            )}
          </div>
        </StyledSelectDropdownContent>
      )}
    />
  );
};

OwnerSelect.propTypes = {
  emptyText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
};

OwnerSelect.defaultProps = {
  emptyText: 'No results found',
  options: []
}

export default OwnerSelect;
