const overviewState = {
};

const overviewReducer = (state = overviewState, action) => {
  const {type} = action;
  switch (type) {
    default:
      return state
  }
};

export default overviewReducer;
