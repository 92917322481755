import React from 'react';

const CheckmarkCloseCircleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.9995" cy="16.001" r="13" fill="#000"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M21.6797 10.3658C22.0697 10.7568 22.0689 11.39 21.6779 11.78L17.4306 16.0161L21.6573 20.2429C22.0479 20.6334 22.0479 21.2666 21.6573 21.6571C21.2668 22.0476 20.6337 22.0476 20.2431 21.6571L16.0145 17.4285L11.7654 21.6665C11.3744 22.0565 10.7412 22.0557 10.3512 21.6646C9.96118 21.2736 9.96201 20.6404 10.353 20.2504L14.6003 16.0143L10.3436 11.7576C9.95311 11.3671 9.95311 10.7339 10.3436 10.3434C10.7342 9.95285 11.3673 9.95285 11.7578 10.3434L16.0164 14.6019L20.2655 10.3639C20.6566 9.9739 21.2897 9.97473 21.6797 10.3658Z"
          fill="white"/>
  </svg>
);


export default CheckmarkCloseCircleIcon;
