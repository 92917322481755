import styled from 'styled-components';
import {Button, Space} from 'antd';

const StyledSubscriptionRowActions = styled(Space)`
  position: relative;
`;

const StyledSubscriptionRowActionsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transition: .2s;
    height: 24px;
    width: 24px;
  }
  
  &:hover {
    svg {
      opacity: 0.7;
    }
  }
  
  &.submit {
    svg {
      path {
        fill: ${props => props.theme.colors.primary_6};
      }
    }
  }
  
  &.ignore,
  &.restrict {
    svg {
      path {
        fill: ${props => props.theme.colors.neutral_7};
      }
    }
  }
  
  &.delete {
    svg {
      path {
        fill: ${props => props.theme.colors.functional_danger};
      }
    }
  }
`;


export {
  StyledSubscriptionRowActions,
  StyledSubscriptionRowActionsButton
}