import {subscriptionConstants} from '../constants';

const defaultSubscriptions = {
  pagination: {
    next_page: null,
    cursor: null
  },
  subscriptions: []
}

const subscriptionState = {
  cardHold: false,
  cardHoldMessage: undefined,
  subscriptions: defaultSubscriptions,
  subscription: null,
  storedSubscriptions: null,
  viewsStatuses: null
};

const subscriptionReducer = (state = subscriptionState, action) => {
  const {type, data} = action;
  switch (type) {
    case subscriptionConstants.GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptions: data
      }
    case subscriptionConstants.GET_SUBSCRIPTION_DETAILS:
    case subscriptionConstants.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: data
      }
    case subscriptionConstants.CLEAR_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: defaultSubscriptions
      }
    case subscriptionConstants.CLEAR_SUBSCRIPTION:
      return {
        ...state,
        cardHold: false,
        cardHoldMessage: undefined,
        subscription: null
      }
    case subscriptionConstants.STORE_SUBSCRIPTIONS:
      return {
        ...state,
        storedSubscriptions: data
      }
    case subscriptionConstants.SET_SUBSCRIPTION_CARD_HOLD:
      return {
        ...state,
        cardHold: data.hold,
        cardHoldMessage: data.message,
      }
    case subscriptionConstants.GET_SUBSCRIPTION_VIEWS_STATUSES:
      return {
        ...state,
        viewsStatuses: data
      }
    default:
      return state;
  }
};

export default subscriptionReducer;
