import React from 'react';

const MenuExpandIcon = () => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H10M1 5H17M17 5L14 2M17 5L14 8M1 9H10" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>
);

export default MenuExpandIcon;
