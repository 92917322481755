import { alertConstants } from '../constants';

const alertState = {
  type: 'success',
  message: ''
}

const alertReducer = (state = alertState, action) => {
  const {type, message} = action;
  switch (type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message
      };
    case alertConstants.WARNING:
      return {
        type: 'warning',
        message
      };
    case alertConstants.ERROR:
      return {
        type: 'error',
        message
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
};

export default alertReducer;
