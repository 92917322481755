import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {companyActions} from '../../../../state/actions';
import TagSelect from '../../../TagSelect';

const CompanyTagSelect = ({
  addNewTag,
  getTags,
  isAdmin,
  tags,
  ...rest
}) => {

  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    if (tags.length !== tagOptions.length) {
      setTagOptions(tags.map(tag => {
        const {tag_name: name} = tag;
        return {
          label: name,
          value: name
        }
      }));
    }
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddNewTag = (value) => isAdmin && addNewTag(value, () => getTags());

  return (
    <TagSelect
      {...rest}
      onAdd={handleAddNewTag}
      options={tagOptions}
      isEnableAdd={isAdmin}
    />
  );
}

const mapDispatchToProps = {
  addNewTag: companyActions.addNewTag,
  getTags: companyActions.getTags
}

const mapStateToProps = state => {
  const {tags} = state.company;
  let {isAdmin} = state.user;
  return {
    isAdmin,
    tags
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTagSelect);
