import React from 'react';
import PropTypes from 'prop-types';
import {StyledDirectionLine} from './StyledDirectionLine';
import downLine from '../../../../static/images/pages/subscriptions/error-line.png';
import upLine from '../../../../static/images/pages/subscriptions/success-line.png';

const DirectionLine = ({
  direction,
  ...rest
}) => {
  return (
    <StyledDirectionLine {...rest}>
      <img alt={direction} src={direction === 'up' ? upLine : downLine} />
    </StyledDirectionLine>
  );
}

DirectionLine.propTypes = {
  direction: PropTypes.oneOf(['down', 'up']),
}

DirectionLine.defaultProps = {
  direction: 'up'
}
export default DirectionLine;
