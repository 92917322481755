import React from 'react';
import {connect} from 'react-redux';
import {StyledCompanyInfo} from './StyledCompanyInfo';

const CompanyInfo = ({
  dispatch,
  logo,
  name,
  ...rest
}) => {
  return (
    <StyledCompanyInfo
      logo={logo}
      name={name}
      {...rest}
    />
  );
}

const mapStateToProps = state => {
  const {company_logo_url: logo, company_name: name} = state.company.settings;

  return {
    logo,
    name
  }
}


export default connect(mapStateToProps, null)(CompanyInfo);
