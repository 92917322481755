import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Dropdown, Space} from 'antd';
import {
  StyledTableSettingsButton,
  StyledTableSettingsButtonDivider,
  StyledTableSettingsButtonSpace,
  StyledTableSettingsButtonDropdownSpace
} from './StyledTableSettingsButton';
import {SettingsIcon} from '../../../../icons';

const TableSettingsButton = ({
  children,
  defaultSelectedKeys,
  options,
  onChange
}) => {
  const [selectedItems, setSelectedItems] = useState(defaultSelectedKeys);

  const menu = useMemo(() => ({
    items: options,
    multiple: true,
    selectable: true
  }), [options]);

  const handleOnCheck = (e, option) => {
    const {checked} = e.target;
    let items = checked ? [...selectedItems, option] : selectedItems.filter(o => o !== option);
    setSelectedItems(items);
    onChange && onChange(items);
  }

  const dropdownRender = () => {
    return (
      <StyledTableSettingsButtonDropdownSpace direction='vertical' size={0}>
        {options.map((option, index) => {
          const {value, label} = option;
          return (
            <Space key={index}>
              <Checkbox
                checked={selectedItems.includes(value)}
                onChange={(e) => handleOnCheck(e, value)}
              >
                {label}
              </Checkbox>
            </Space>
          )
        })}
      </StyledTableSettingsButtonDropdownSpace>
    );
  }

  return (
    <StyledTableSettingsButtonSpace size={0} split={<StyledTableSettingsButtonDivider type='vertical' />}>
      <Dropdown
        dropdownRender={dropdownRender}
        menu={menu}
        placement='bottomRight'
        trigger='click'
      >
        <StyledTableSettingsButton
          icon={<SettingsIcon />}
          size='large'
          type='default'
        />
      </Dropdown>
      {children}
    </StyledTableSettingsButtonSpace>
  )
}

TableSettingsButton.propTypes = {
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.array,
  onChange: PropTypes.func
}

TableSettingsButton.defaultProps = {
  defaultSelectedKeys: []
}

export default TableSettingsButton;
