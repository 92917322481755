import React from 'react';

const ArrowRightIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M13.138 8.47149C13.3983 8.21114 13.3983 7.78903 13.138 7.52868L9.13799 3.52868C8.87764 3.26833 8.45553 3.26833 8.19518 3.52868C7.93483 3.78903 7.93483 4.21114 8.19518 4.47149L11.0571 7.33342L3.33325 7.33342C2.96506 7.33342 2.66658 7.63189 2.66658 8.00008C2.66658 8.36827 2.96506 8.66675 3.33325 8.66675L11.0571 8.66675L8.19518 11.5287C7.93483 11.789 7.93483 12.2111 8.19518 12.4715C8.45553 12.7318 8.87764 12.7318 9.13799 12.4715L13.138 8.47149Z"
          fill="#000"/>
  </svg>
);

export default ArrowRightIcon;
