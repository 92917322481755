import React from 'react';

const ArrowLeftIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2.86189 8.47149C2.60154 8.21114 2.60154 7.78903 2.86189 7.52868L6.86189 3.52868C7.12224 3.26833 7.54435 3.26833 7.8047 3.52868C8.06505 3.78903 8.06505 4.21114 7.8047 4.47149L4.94277 7.33341L12.6666 7.33341C13.0348 7.33341 13.3333 7.63189 13.3333 8.00008C13.3333 8.36827 13.0348 8.66675 12.6666 8.66675L4.94277 8.66675L7.8047 11.5287C8.06505 11.789 8.06505 12.2111 7.8047 12.4715C7.54435 12.7318 7.12224 12.7318 6.86189 12.4715L2.86189 8.47149Z"
          fill="#000" />
  </svg>
);

export default ArrowLeftIcon;
