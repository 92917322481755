import React from 'react';
import PropTypes from 'prop-types';
import {Radio, Space} from 'antd';
import {
  StyledRadioGroupIcon,
  StyledRadioSpace
} from './StyledRadioGroupIcon';


const RadioGroupIcon = ({
  options,
  ...rest
}) => {
  return (
    <StyledRadioGroupIcon {...rest}>
      <StyledRadioSpace
        size='middle'
      >
        {options.map(({value, label, icon, ...rest}, key) => (
          <Radio
            key={`option-${key}`}
            value={value}
            {...rest}
          >
            <Space size='small' direction='vertical'>
              <span className='icon'>
                {icon}
              </span>
              {label}
            </Space>
          </Radio>
        ))}
      </StyledRadioSpace>

    </StyledRadioGroupIcon>
  );
}

RadioGroupIcon.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  )
}

RadioGroupIcon.defaultProps = {
  options: []
}

export default RadioGroupIcon;
