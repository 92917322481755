const KYCModalModeTypes = Object.freeze({
  COMPANY: 'company',
  KYC: 'kyc'
});

const KYCCompanyStageConstants = Object.freeze({
  LEGAL_PERSON: 'legal_person_stage',
  LEGAL_REPRESENTATIVE: 'legal_representative_stage',
  ULTIMATE_BENEFICIARY: 'ultimate_beneficiary_stage',
  EMPLOYEE: 'employee_stage',
  ACCOUNT: 'account_stage',
  REVIEW: 'review_stage',
  CARD: 'card_stage'
});

const KYCLevelStatusesConstants = Object.freeze({
  NONE: 0,
  LIGHT: 1,
  REGULAR: 2,
  STRONG: 3,
  REFUSED: 4
});

const KYCReviewStatusesConstants = Object.freeze({
  NONE: 0,
  PENDING: 1,
  VALIDATED: 2,
  REFUSED: 3
});

export {
  KYCCompanyStageConstants,
  KYCModalModeTypes,
  KYCLevelStatusesConstants,
  KYCReviewStatusesConstants
};
