import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import dayjs from 'dayjs';
import {Button, DatePicker, Form, Input, Upload} from 'antd';
import {FileOutlined} from '@ant-design/icons';
import {StyledContractDetails, StyledUploadSpace} from './StyledContractDetails';
import DetailsTable from '../../../../TransactionsPage/DetailsTable';
import {helpers} from '../../../../../../helpers';
import {
  subscriptionBillTypesConstants,
  subscriptionDurationTypesConstants,
  subscriptionStatusesConstants
} from '../../../../../../constants';
import {subscriptionActions} from '../../../../../../state/actions';
import {subscriptionsHelpers} from '../../../../SubscriptionsPage/subscriptionsHelpers';

const gObjProp = helpers.getObjProp;

const {Item} = Form;

const {INACTIVE} = subscriptionStatusesConstants;
const {MONTHS} = subscriptionDurationTypesConstants;

const datePickerFormat = 'DD/MM/YYYY';

const contractDurationFieldName = 'contract_duration';
const contractDurationTypeFieldName = 'contract_duration_type';
const contractFileFieldName = 'contract_file';

const cardRelatedFields = [];
const dateFields = ['contract_renewal_date'];

const ContractDetails = ({
  subscription,
  dispatch,
  getSubscriptionAttachment,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    [contractFileFieldName]: '',
    contract_renewal_date: '',
    purchase_order: '',
  });
  const [isExportProgress, setIsExportProgress] = useState(false);
  const [allowFileClear, setAllowFileClear] = useState(false);
  const [file, setFile] = useState(null);

  const fileType = Form.useWatch(contractFileFieldName, form);

  useEffect(() => {
    setAllowFileClear(fileType && fileType !== '');
  }, [fileType]);

  const gDataProp = (subscription, key, defaultValue) => gObjProp(subscription, key, defaultValue);

  const trans = (key) => t(`subscriptions:${key}`);
  const placeholder = (key) => trans(`placeholders.${key}`);

  const {purchase_order: purchaseOrder } = initialFormValues;

  const contractFile = gDataProp(subscription, contractFileFieldName);
  const statusCode = gDataProp(subscription, 'status');
  const isPaused = statusCode === INACTIVE;
  const isEnableEdit = subscriptionsHelpers.isEnablePanelEdit(statusCode);

  useEffect(() => {
    let contractRenewalDate = getDate(subscription, 'contract_renewal_date');
    // Renewal Reminder Set automatically to:
    // - 7 days before payment for month
    // - 90 days before for Year
    if (contractRenewalDate === undefined) {
      const nextPayment = gDataProp(subscription, 'next_payment');
      const billed = gDataProp(subscription, 'billed');
      if (nextPayment) {
        contractRenewalDate = dayjs(helpers.getTimestampDateObject(nextPayment));
        if (billed === subscriptionBillTypesConstants.MONTHLY) {
          contractRenewalDate = contractRenewalDate.subtract(7, 'days');
        } else if (billed === subscriptionBillTypesConstants.YEARLY) {
          contractRenewalDate = contractRenewalDate.subtract(90, 'days');
        }
      }
    }
    
    const fieldValues = {
      ...initialFormValues,
      contract_renewal_date: contractRenewalDate,
      purchase_order: gDataProp(subscription, 'purchase_order'),
      signing_date: getDate(subscription, 'signing_date'),
    };

    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    isEditMode && setIsEditMode(false);
  }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContractDate = (key) => {
    const value = gDataProp(initialFormValues, key);
    return value ? helpers.getDateWithGMTFormat(value, 'DD/MM/YY') : '-'
  }

  const getDate = (data, key) => {
    const value = gDataProp(data, key);
    return value ? dayjs(helpers.getTimestampDateObject(value)) : undefined
  }

  const handleDownloadFile = () => {
    const id = gDataProp(subscription, 'id');
    if (id) {
      setIsExportProgress(true);
      getSubscriptionAttachment(
        id,
        (data) => {
          helpers.saveFile(data);
          setIsExportProgress(false)
        },
        () => setIsExportProgress(false)
      );
    }
  }

  const details = [
    {
      key: 'renewalReminder',
      label: trans('renewalReminder'),
      value: getContractDate('contract_renewal_date')
    },
    {
      key: 'purchase-order',
      label: trans('purchaseOrder'),
      value: purchaseOrder || '-'
    },
    {
      key: 'contract',
      label: t('contract'),
      value: contractFile ? (
        <Button
          className='contract-btn'
          icon={<FileOutlined />}
          loading={isExportProgress}
          onClick={handleDownloadFile}
          type='link'
        >
          {helpers.getUrlFilename(contractFile)}
        </Button>
      ) : '-'
    },
  ];

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const getTimestamp = (value) => value ? value.unix() : undefined;

  const handleSubmit = async (data) => {
    if (onSubmit) {
      let contractFile = data[contractFileFieldName];
      let submitData = helpers.getUpdatedFormValues({
        initialValues: initialFormValues,
        submittedValues: data,
        excludedFields: isPaused ? cardRelatedFields : []
      });

      submitData = {
        ...submitData,
        [contractDurationFieldName]: data[contractDurationTypeFieldName] === '' || data[contractDurationFieldName] === '' ? undefined : data[contractDurationFieldName]
      }

      dateFields.forEach(key => {
        if (submitData[key]) submitData = {...submitData, [key]: getTimestamp(submitData[key])}
      });

      if (contractFile === '') {
        submitData = {...submitData, [contractFileFieldName]: null};
      } else if (file) {
        submitData = {...submitData, [contractFileFieldName]: helpers.cutFileData(file.file)};
      }

      if (Object.keys(submitData).length > 0) {
        onSubmit(
          submitData,
          handleOnCancel,
          handleOnCancel
        );
      } else {
        handleOnCancel();
      }
    } else {
      handleOnCancel();
    }
  }

  const handleOnFieldsChange = (changedFields, allFields) => {
    if (changedFields.length > 0) {
      const field = changedFields[0];
      const fieldName = field.name[0];
      const fieldValue = field.value;
      let fields = {};
      allFields.forEach(f => fields[f.name[0]] = f.value);
      if ([contractDurationTypeFieldName, contractDurationFieldName].includes(fieldName)) {
        const durationType = fieldName === contractDurationTypeFieldName ? fieldValue : fields[contractDurationTypeFieldName];
        const duration = fieldName === contractDurationFieldName ? fieldValue : fields[contractDurationFieldName];
        const autoPopulateFromDate = fields.signing_date || fields.expected_first_payment;
        let renewalDate;
        if (autoPopulateFromDate) {
          renewalDate = dayjs(autoPopulateFromDate);
          renewalDate = renewalDate.add(duration, durationType === MONTHS ? 'M' : 'y')
        } else {
          renewalDate = dayjs();
        }
        fields = {
          ...fields,
          contract_renewal_date: renewalDate
        }
        form.setFieldsValue(fields);
      }
    }
  }

  const extra = subscriptionsHelpers.getDetailsFormExtra(({t, isEnableEdit, isEditMode, setIsEditMode, handleOnSave}));

  const handleOnFileChange = async ({file}) => {
    const fileBase64 = await helpers.getBase64(file.originFileObj);
    setFile({
      file: fileBase64,
      name: file.name
    });
    form.setFieldValue(contractFileFieldName, file.name);
  }

  return (
    <StyledContractDetails
      title={trans('contractTerms')}
      {...rest}
      extra={extra}
    >
      <Form
        className={!isEditMode && 'd-none'}
        initialValues={initialFormValues}
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        onFieldsChange={handleOnFieldsChange}
        requiredMark={false}
      >
        <Item
          label={trans('renewalReminder')}
          name='contract_renewal_date'
        >
          <DatePicker
            format={datePickerFormat}
            size='large'
          />
        </Item>
        <Item
          label={trans('purchaseOrder')}
          name='purchase_order'
        >
          <Input
            placeholder={placeholder('purchaseOrder')}
            size='large'
          />
        </Item>
        <Item label={t('contract')}>
          <StyledUploadSpace>
            <Item
              name={contractFileFieldName}
              noStyle
            >
              <Input
                allowClear={allowFileClear}
                size='large'
                disabled={true}
              />
            </Item>
            <Upload
              accept='.jpeg,.png,.pdf'
              customRequest={() => null}
              onChange={handleOnFileChange}
              showUploadList={false}
            >
              <Button>
                {t('select')} {t('file')}
              </Button>
            </Upload>
          </StyledUploadSpace>
        </Item>
      </Form>
      <DetailsTable
        className={isEditMode && 'd-none'}
        data={details}
      />
    </StyledContractDetails>
  );
}

ContractDetails.propTypes = {
  subscription: PropTypes.shape({
    contract_renewal_date: PropTypes.number,
    purchase_order: PropTypes.string,
    signing_date: PropTypes.number
  }),
  onSubmit: PropTypes.func
}

const mapDispatchToProps = {
  getSubscriptionAttachment: subscriptionActions.getSubscriptionAttachment,
}

export default connect(null, mapDispatchToProps)(ContractDetails);

