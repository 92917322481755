import styled from 'styled-components';
import Search from '../../../../Search';

const StyledTableFilters = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledTableFiltersSearch = styled(Search)`
  max-width: 264px;
  width: 100%;
`;

const StyledFilterElements = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledRightSideContent = styled.div`
`;

export {
  StyledTableFiltersSearch,
  StyledTableFilters,
  StyledFilterElements,
  StyledRightSideContent
};
