import styled from 'styled-components';
import {Space} from 'antd';

const StyledInvoiceUpload = styled.div`
  &:hover {
    cursor: unset;
  }

  background: linear-gradient(to right, ${props => props.theme.colors.neutral_4} 50%, transparent 0%) top repeat-x,
              linear-gradient(${props => props.theme.colors.neutral_4} 50%, transparent 0%) right repeat-y,
              linear-gradient(to right, ${props => props.theme.colors.neutral_4} 50%, transparent 0%) bottom repeat-x,
              linear-gradient(${props => props.theme.colors.neutral_4} 50%, transparent 0%) left repeat-y;
  background-size: 16px 1px, 1px 16px;
  background-color: ${props => props.theme.colors.neutral_2};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  .ant-upload-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .ant-upload-select {
    align-items: center;
    background: transparent;
    border-radius: ${props => props.theme.border_radius.standard};
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .ant-upload {
    width: 100%;
    & > div {
      background: transparent;
    }
  }
`;

const StyledInvoiceUploadChildren = styled(Space)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  
  .ant-space-item {
    display: flex;
  }

  p {
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .ant-btn-link {
    color: ${props => props.theme.colors.primary_6};
    font-weight: 500;
    pointer-events: auto;
  }
`;

const StyledInvoiceUploadChildrenContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  .ant-btn {
    padding: 0;
  }
  
  & > span {
    color: ${props => props.theme.colors.neutral_6};
  }
`;

const StyledInvoiceUploadFilePreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  max-width: 80%;
  max-height: 80%;
  border: 1px solid ${props => props.theme.colors.neutral_6};
  border-radius: 8px;
  margin: 16px;
  padding: 8px;
  
  img {
    max-height: 272px;
    padding: 8px;
    max-width: inherit;
  }
  
  .ant-btn.ant-btn-circle {
    min-width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(8px, -8px);
    z-index: 2;
    
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: ${props => props.theme.colors.neutral_1};
      }
    }
  }
  
  > * {
    max-width: 100%;
  }
  .react-pdf__Page {
    > * {
      height: auto !important;
      width: 100% !important;
    }
  }
  
`;

export {
  StyledInvoiceUpload,
  StyledInvoiceUploadChildren,
  StyledInvoiceUploadChildrenContent,
  StyledInvoiceUploadFilePreview
};
