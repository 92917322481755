import styled from 'styled-components';
import {Modal} from 'antd';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    & > div {
      padding: 20px 24px;
    }
  }
  .ant-modal-header {
    border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
    margin-bottom: 0;
  }

  .ant-modal-footer {
    border-top: 1px solid ${props => props.theme.colors.neutral_4};
  }
`;

export { StyledModal };
