import {mainConstants} from '../constants';
import {request} from '../services/request';
import {backendEndpoints} from '../../api';

const setLoading = (loading, dispatch) => {
  return dispatch({
    type: mainConstants.SET_LOADING,
    loading
  })
}

const setInitialLoading = (loading, dispatch) => {
  return dispatch({
    type: mainConstants.SET_INITIAL_LOADING,
    loading
  })
}

const getCurrencies = () => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_CURRENCIES,
      successCallback: (data) => {
        dispatch({
          type: mainConstants.GET_CURRENCIES,
          data: data.sort()
        });
      }
    });
  }
}

const collapseNavigationMenu = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.COLLAPSE_NAVIGATION_MENU,
    })
  }
}

const openKycWindow = ({mode, closeIcon = true}) => {
  return (dispatch) => {
    dispatch({
      data: {closeIcon, mode},
      type: mainConstants.OPEN_KYC_WINDOW,
    })
  }
}

const closeKycWindow = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.CLOSE_KYC_WINDOW,
    })
  }
}

const openLightKycWindow = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.OPEN_LIGHT_KYC_WINDOW,
    })
  }
}

const closeLightKycWindow = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.CLOSE_LIGHT_KYC_WINDOW,
    })
  }
}

export const mainActions = {
  collapseNavigationMenu,
  setLoading,
  setInitialLoading,
  getCurrencies,
  closeKycWindow,
  closeLightKycWindow,
  openKycWindow,
  openLightKycWindow
};
