export const userConstants = {
  GET_COMPANIES: 'GET_COMPANIES',
  GET_EMPLOYEE_PROFILE: 'GET_EMPLOYEE_PROFILE',
  GET_EMPLOYEE_NOTIFICATION_SETTINGS: 'GET_EMPLOYEE_NOTIFICATION_SETTINGS',
  GET_EMPLOYEE_SETTINGS: 'GET_EMPLOYEE_SETTINGS',
  GET_NOTIFICATIONS_LIST: 'GET_NOTIFICATIONS_LIST',
  GET_NOTIFICATIONS_COUNT: 'GET_NOTIFICATIONS_COUNT',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  READ_NOTIFICATIONS: 'READ_NOTIFICATIONS',
  READ_ALL_NOTIFICATIONS: 'READ_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  SET_CHECKED_AUTH: 'SET_CHECKED_AUTH',
  SET_AUTH: 'SET_AUTH',
  DISABLED_FROM_COMPANY: 'DISABLED_FROM_COMPANY',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA',

  SUCCESS_SEND_USER_EMAIL_VERIFICATION: 'SUCCESS_SEND_USER_EMAIL_VERIFICATION',
  ERROR_SEND_USER_EMAIL_VERIFICATION: 'ERROR_SEND_USER_EMAIL_VERIFICATION',
  SUCCESS_FINISH_USER_EMAIL_VERIFICATION: 'SUCCESS_FINISH_USER_EMAIL_VERIFICATION',
  ERROR_FINISH_USER_EMAIL_VERIFICATION: 'ERROR_FINISH_USER_EMAIL_VERIFICATION',
  SUCCESS_GET_UNREAD_NOTIFICATIONS_BY_COMPANY: 'SUCCESS_GET_UNREAD_NOTIFICATIONS_BY_COMPANY',
  ERROR_GET_UNREAD_NOTIFICATIONS_BY_COMPANY: 'ERROR_GET_UNREAD_NOTIFICATIONS_BY_COMPANY'
};
