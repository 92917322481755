import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Space} from 'antd';
import {StyledHeaderInformation} from './StyledHeaderInformation';
import {helpers} from '../../helpers';
import {ExternalLinkIcon} from '../../icons';

const HeaderInformation = ({
  description,
  logo,
  name,
  extra,
  url,
  ...rest
}) => {
  return (
    <Space
      {...rest}
      size='middle'
    >
      <Avatar
        style={{fontSize: 28}}
        size={86}
        src={logo}
      >
        {helpers.getInitials(name)}
      </Avatar>
      <StyledHeaderInformation>
        <Space align='center' size='small'>
          {name && <h3>{name}</h3>}
          {url && (
            <a
              href={url}
              onClick={(e) => e.stopPropagation()}
              rel='noreferrer'
              target='_blank'
            >
              <ExternalLinkIcon />
            </a>
          )}
        </Space>
        {description && <p>{description}</p>}
        {extra && extra}
      </StyledHeaderInformation>
    </Space>
  );
}

HeaderInformation.propTypes = {
  description: PropTypes.string,
  extra: PropTypes.any,
  logo: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string
}

export default HeaderInformation;

