import styled from 'styled-components';

const StyledEditableAvatar = styled.div`
  position: relative;
  display: inline-block;
  
  .ant-btn {
    align-items: center;
    border: 1px solid ${props => props.theme.colors.neutral_1};
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 38px;
    width: 38px;

    svg {
      height: 16px;
      width: 16px;
      
      path {
        fill: ${props => props.theme.colors.neutral_1};
      }
    }
  }
`;

export {
  StyledEditableAvatar
};
