import styled from 'styled-components';
import {Select, Space} from 'antd';


const StyledOwnerSelect = styled(Select)`
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px !important;
    height: 34px !important;
    .ant-select-selector {
      border-radius: 50%;
      width: 34px !important;
      height: 34px !important;
      padding: 0;
    }
    .ant-select-arrow {
      display: none;
    }
    .ant-select-selection-item {
      padding-inline-end: 0;
      & > div {
        gap: 0 !important;
        & > div:nth-of-type(1) {
          display: flex;
        }
        & > div:nth-of-type(2) {
          display: none;
        }
      }
      &:after {
        content: none;
      }
    }
  }
`;

const StyledOwnerSelectMenuItem = styled(Space)`
  display: flex;
  font-size: 14px;
`;

export {
  StyledOwnerSelect,
  StyledOwnerSelectMenuItem,
};
