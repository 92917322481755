import React from 'react';
import {Alert, Space} from 'antd';
import toast from 'react-hot-toast';
import {alertConstants} from '../constants';

const displayAlert = (message, variant, duration = 4000) => {
  toast.custom(
    (t) => (
      <Space direction='vertical' style={{ width: 256 }}>
        <Alert
          message={message}
          onClose={() => toast.dismiss(t.id)}
          type={variant}
          closable
          showIcon
        />
      </Space>
    ),
    {duration}
  );
}

const success = (message, duration) => displayAlert(message, 'success', duration);

const warning = (message, duration) => displayAlert(message, 'warning', duration);

const error = (message, duration) => displayAlert(message, 'error', duration);

const clear = () => {
  return {
    type: alertConstants.CLEAR
  }
}

export const alertActions = {
  success,
  warning,
  error,
  clear
};
