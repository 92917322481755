import React, {useEffect, useMemo, useState} from 'react';
import {Form, Input, Select, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {UnmountClosed} from 'react-collapse';
import {
  StyledLegalPersonInformation,
  StyledLegalPersonInformationAddon,
  StyledLegalPersonInformationText
} from './StyledLegalPersonInformation';
import SpinSmall from '../../../../../SpinSmall';
import PhoneNumberInput from '../../../../../PhoneNumberInput';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import {kycHelpers} from '../../kycHelpers';
import {EuroIcon} from '../../../../../../icons';
import {bankingActions, companyActions} from '../../../../../../state/actions';
import {helpers} from '../../../../../../helpers';
import useLocation from '../../../../../../hooks/useLocation';
import {bankingUserTypesConstants} from '../../../../../../constants';

const gObjProp = helpers.getObjProp;

const {LEGAL_PERSON} = bankingUserTypesConstants;

const {Item} = Form;

const annualTurnoverOptions = [
  {label: '€0 - €39.000', value: '0-39'},
  {label: '€40.000 - €99.000', value: '40-99'},
  {label: '€100.000 - €249.000', value: '100-249'},
  {label: '€250.000 - €999.000', value: '250-999'},
  {label: '€1.000.000 - €2.999.000', value: '1000-2999'},
  {label: '€3.000.000 - €9.999.000', value: '3000-9999'},
  {label: '€10.000.000 - €99.999.000', value: '10000-99999'},
  {label: '€100.000.000 - *', value: '100000-*'},
];

const incomeOptions = [
  {label: '€0 - €4.000', value: '0-4'},
  {label: '€5.000 - €9.000', value: '5-9'},
  {label: '€10.000 - €49.000', value: '10-49'},
  {label: '€50.000 - €149.000', value: '50-149'},
  {label: '€150.000 - €499.000', value: '150-499'},
  {label: '€500.000 - *', value: '500-*'},
];

const employeesOptions = [
  {label: '0', value: '0'},
  {label: '1-9', value: '1-9'},
  {label: '10-99', value: '10-99'},
  {label: '100-249', value: '100-249'},
  {label: '250-*', value: '250-*'},
];

const initialFormValues = {
  email: '',
  legal_annual_turn_over: annualTurnoverOptions[0].value,
  legal_form: undefined,
  legal_net_income_range: incomeOptions[0].value,
  legal_number_of_employee_range: employeesOptions[0].value,
  phone: ''
}

const LegalPersonInformation = React.forwardRef(({
  dispatch,
  companyCountry,
  companyEmail,
  companyName,
  editedUser,

  createLegalPerson,
  getCompanySettings,

  onSubmit,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();
  const [countryCode, setCountryCode] = useState(undefined);
  const [error, setError] = useState(undefined);

  const {phone_code: defaultPhoneCode} = useLocation();

  const companyTypeOptions = useMemo(() => kycHelpers.getLegalFormOptions(countryCode), [countryCode]);

  const {
    label,
    placeholder,
    validationPhoneRules,
    validationRequiredRules,
    title,
    tooltip
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'lpInformation'}), [t]);

  useEffect(() => {
    if (companyCountry) {
      const countryCode = helpers.getCountryCodeByName(companyCountry);
      setCountryCode(countryCode.toUpperCase());
    } else {
      getCompanySettings();
    }
  }, [getCompanySettings, companyCountry]);

  useEffect(() => {
    if (companyEmail) form.setFieldValue('email', companyEmail);
  }, [companyEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (fields) => {
    let data = {
      ...fields,
      country: countryCode,
      legal_name: companyName,
      legal_sector: '6201',
      user_type: LEGAL_PERSON
    };

    setLoading(true);

    createLegalPerson(
      data,
      (user) => {
        error && setError(undefined);
        setLoading(false);
        onSubmit && onSubmit({userId: gObjProp(user, 'id')});
      },
      (data) => {
        const formErrors = helpers.getFormServerErrorFields(data);
        if (formErrors.length === 0 && data.detail.length > 0) {
          const {detail} = data;
          setError(typeof detail === 'string' ? detail : detail[0].msg);
        }
        form.setFields(formErrors);
        setLoading(false);
      }
    );
  }

  return (
    <StyledLegalPersonInformation
      direction='vertical'
      size={32}
      {...rest}
    >
      <StyledKYCModalContentStepTitle>
        {title}
      </StyledKYCModalContentStepTitle>
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onFinish={onFinish}
          ref={ref}
          requiredMark={false}
        >
          <Item
            label={label('companyType')}
            name='legal_form'
            rules={validationRequiredRules}
          >
            <Select
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={companyTypeOptions}
              placeholder={placeholder('companyType')}
              size='large'
              showSearch
            />
          </Item>
          <Item
            label={label('businessEmail')}
            name='email'
            rules={validationRequiredRules}
            tooltip={tooltip('businessEmail')}
          >
            <Input
              placeholder={placeholder('businessEmail')}
              size='large'
              type='email'
            />
          </Item>
          <Item
            label={label('phoneNumber')}
            name='phone'
            rules={validationPhoneRules}
          >
            <PhoneNumberInput
              phoneCode={defaultPhoneCode}
              placeholder={placeholder('phoneNumber')}
              size='large'
            />
          </Item>
          <Item
            label={label('annualTurnover')}
            tooltip={tooltip('annualTurnover')}
          >
            <Space.Compact style={{width: '100%'}}>
              <StyledLegalPersonInformationAddon>
                <EuroIcon />
              </StyledLegalPersonInformationAddon>
              <Item
                name='legal_annual_turn_over'
                rules={validationRequiredRules}
                noStyle
              >
                <Select
                  options={annualTurnoverOptions}
                  placeholder={placeholder('annualTurnover')}
                  size='large'
                />
              </Item>
            </Space.Compact>
          </Item>
          <Item
            label={label('annualIncome')}
            tooltip={tooltip('annualIncome')}
          >
            <Space.Compact style={{width: '100%'}}>
              <StyledLegalPersonInformationAddon>
                <EuroIcon />
              </StyledLegalPersonInformationAddon>
              <Item
                name='legal_net_income_range'
                rules={validationRequiredRules}
                noStyle
              >
                <Select
                  options={incomeOptions}
                  placeholder={placeholder('annualIncome')}
                  size='large'
                />
              </Item>
            </Space.Compact>
          </Item>
          <Item
            label={label('numberOfEmployees')}
            name='legal_number_of_employee_range'
            rules={validationRequiredRules}
            tooltip={tooltip('numberOfEmployees')}
          >
            <Select
              options={employeesOptions}
              placeholder={placeholder('numberOfEmployees')}
              size='large'
            />
          </Item>
          <UnmountClosed isOpened={error !== undefined}>
            <StyledLegalPersonInformationText>
              {error}
            </StyledLegalPersonInformationText>
          </UnmountClosed>
        </Form>
      </SpinSmall>
    </StyledLegalPersonInformation>
  );
});

const mapStateToProps = state => {
  const {name, settings} = state.company;
  const companyCountry = gObjProp(settings, 'country');
  const companyEmail = gObjProp(settings, 'email');

  return {
    companyCountry,
    companyEmail,
    companyName: name
  }
}

const mapDispatchToProps = {
  createLegalPerson: bankingActions.createUser,
  getCompanySettings: companyActions.getCompanySettings
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegalPersonInformation);
