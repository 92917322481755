import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {StyledLoadMoreButton} from './StyledLoadMoreButton';

const LoadMoreButton = ({
  children,
  className,
  isEnabled,
  onClick,
  loading,
  modalTitle,
  ...rest
}) => {

  const onClickAction = () => (isEnabled && !loading && onClick) && onClick();

  return (
    <StyledLoadMoreButton className={className}>
      <Button
        loading={loading}
        onClick={onClickAction}
        disabled={!isEnabled}
        {...rest}
      >
        {children}
      </Button>
    </StyledLoadMoreButton>
  )
}

LoadMoreButton.propTypes = {
  isEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  modalTitle: PropTypes.string,
  onClick: PropTypes.func,
}

LoadMoreButton.defaultProps = {
  isEnabled: true,
  loading: false
}

export default LoadMoreButton;
