import {Tabs} from 'antd';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav {
    margin-bottom: 0;
    
    &::before {
      border-bottom-color: ${props => props.theme.colors.neutral_3};
    }
    
    .ant-tabs-tab {
      font-weight: 500;
      padding: 9px 32px;

      &.ant-tabs-tab-active {
        background-color: ${props => props.theme.colors.neutral_1};
        border-color: ${props => props.theme.colors.neutral_3};
        border-bottom-color: ${props => props.theme.colors.neutral_1};
      }

      &:not(.ant-tabs-tab-active) {
        background: unset;
        border-color: transparent;
      }
    }
  }
  .ant-tabs-content-holder {
    background-color: ${props => props.theme.colors.neutral_1};
    border: 1px solid ${props => props.theme.colors.neutral_3};
    border-top: unset;
    padding: 24px;
    min-height: calc(100vh - 64px - (16px * 2) - (24px * 2) - 40px);
  }
`;


export {
  StyledTabs
};
