import styled from 'styled-components';

const BARCHART_WIDTH = '69.5%';

const StyledUsageReportTab = styled.div`
  position: relative;
`;

const StyledCharts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    &:nth-of-type(1) {
      width: ${BARCHART_WIDTH};
    }
    &:nth-of-type(2) {
      width: calc(100% - ${BARCHART_WIDTH} - 24px);
    }
  }
`;

const StyledEmptyState = styled.div`
  padding: 24px;
  text-align: center;

  img {
    height: 240px;
    margin-bottom: 32px;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    margin: auto;
    max-width: 550px;
    font-size: 16px;
    color: ${props => props.theme.colors.neutral_7};
  }
`;

export {
  StyledCharts,
  StyledEmptyState,
  StyledUsageReportTab
}
