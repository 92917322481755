import styled from 'styled-components';
import {StyledAddSingleSubscriptionModal} from '../AddSingleSubscriptionModal/StyledAddSingleSubscriptionModal';

const StyledAuthorizeSubscriptionModal = styled(StyledAddSingleSubscriptionModal)`
  form {
    & > div {
      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }
  .ant-segmented {
    margin-bottom: 16px;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-divider {
    margin-top: 0;
  }
`;


export {
  StyledAuthorizeSubscriptionModal
}
