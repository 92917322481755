import React from 'react';

const EditIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9093 2.40002C11.7843 2.27504 11.6147 2.20483 11.438 2.20483C11.2612 2.20483 11.0917 2.27504 10.9666 2.40002L10.024 3.34336L12.852 6.17136L13.7946 5.22869C13.9196 5.10367 13.9898 4.93413 13.9898 4.75736C13.9898 4.58058 13.9196 4.41104 13.7946 4.28602L11.9093 2.40002ZM11.9093 7.11469L9.08064 4.28602L3.1473 10.2194C3.02268 10.3443 2.95267 10.5136 2.95264 10.69V12.576C2.95264 12.7528 3.02287 12.9224 3.1479 13.0474C3.27292 13.1725 3.44249 13.2427 3.6193 13.2427H5.50464C5.68143 13.2427 5.85097 13.1724 5.97597 13.0474L11.9093 7.11402V7.11469Z"
      fill="#000"/>
  </svg>
);

export default EditIcon;
