import styled from 'styled-components';

const StyledLoadMoreButton = styled.div`
  min-height: 60px;
  padding-top: 32px;
  text-align: center;

  button {
    opacity: 1;
    transition: .2s ease;

    &:disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export {
  StyledLoadMoreButton
};
