import styled from 'styled-components';
import AntdModal from '../../../AntdModal';

const StyledAddMultipleSubscriptionsModal = styled(AntdModal)`
  .form-container {
    min-width: calc(820px - 32px * 2);
    width: 80vw;
    max-width: 940px;
    &.form-d-none {
      min-width: 0;
      width: 0;
    }
  }

  .transfer-list {
    & > div {
      &:nth-child(2n) {
        .list {
          & > div {
            width: 100%;
            &:nth-child(2n) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .selected-services-list {
    overflow-y: auto;
    max-height: 500px;
    margin-top: -16px;
  }

  .ant-modal-footer {
    justify-content: space-between;
    display: flex;

    button {
      &:last-of-type {
        margin-left: auto;
      }
    }
  }
`;

export {
  StyledAddMultipleSubscriptionsModal
}
