import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Tooltip} from 'antd';
import moment from 'moment';
import {StyledInvoiceTabContainer, StyledButton} from './StyledInvoicesTab';
import CreditCardDetails from '../../../CardsPage/CreditCardDetails';
import TableFilters from '../../tabComponents/TableFilters';
import NoSearchResults from '../../../../NoSearchResults';
import Table from '../../../../Table';
import UserDetails from '../../../../UserDetails';
import {AddInvoiceIcon, Invoice2Icon, InvoicePendingIcon, InvoiceSuggestIcon, LoadingQuartersIcon, TransactionDeclinedIcon} from '../../../../../icons';
import {helpers} from '../../../../../helpers';
import {invoiceMatchTypeConstants, invoiceStatusConstants} from '../../../../../constants';

const gObjProp = helpers.getObjProp;

const {MATCHED, PENDING} = invoiceStatusConstants;

const {AUTOMATED} = invoiceMatchTypeConstants;

const treeDaysAgoDate = moment().subtract(3, 'days').startOf('day');

const checkIfInvoiceHasError = (invoice) => {
  let error = gObjProp(invoice, 'has_parse_errors') === true;
  if (!error) {
    const status = gObjProp(invoice, 'status');
    if (status === PENDING) {
      const createdAt = gObjProp(invoice, 'created_at');
      const date = moment(createdAt).startOf('day');
      error = date.isBefore(treeDaysAgoDate);
    }
  }
  return error;
}

const InvoicesTab = ({
  dispatch,
  data,
  employees,
  filtersRightSideContent,
  onDeleteRow,
  onMatchClick,
  onFilter,
  openDetails,
  tableProps,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);
  const [query, setQuery] = useState({
    search: ''
  });
  const [emptyDetails, setEmptyDetails] = useState({
    text: '',
    enabled: false
  });

  const trans = (key) => t(`invoices:${key}`);

  useEffect(() => {
    const isEmptyList = data.length === 0;
    const search = query?.search || '';
    if (search !== '' && isEmptyList) {
      setEmptyDetails({
        text: search,
        enabled: true
      });
    } else if (emptyDetails.enabled && !isEmptyList) {
      setEmptyDetails({...emptyDetails, enabled: false});
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnFilter = (filterParams) => {
    setQuery({...query, ...filterParams});
    onFilter && onFilter(filterParams);
  }

  const getColumns = () => {
    let columns = [
      {
        dataIndex: 'date',
        title: t('date'),
        sorter: true,
        render: (_, record) => {
          let date = gObjProp(record, 'date');
          return date ? helpers.getDateWithMonth(date) : '';
        },
        width: 120
      },
      {
        dataIndex: 'vendor',
        title: t('vendor')
      },
      {
        dataIndex: 'uploaded_by',
        title: trans('uploadedBy')
      },
      {
        dataIndex: 'type',
        title: t('type'),
        render: (_, record) => {
          const cardMaskedPan = gObjProp(record, 'card_masked_pan');
          const status = gObjProp(record, 'status');
          const matched = status === MATCHED;
          if (matched) {
            return (
              <CreditCardDetails cardNumber={cardMaskedPan} />
            )
          }
        }
      },
      {
        dataIndex: 'expense_owner',
        title: t('Owner'),
        render: (owner) => {
          if (owner) {
            let employee = employees.find(f => f.email === owner);
            if (employee) {
              return (
                <UserDetails
                  email={false}
                  user={employee}
                />
              );
            }
          }
          return '';
        }
      },
      {
        align: 'right',
        dataIndex: 'amount',
        title: t('amount'),
        render: (amount) => (
          <span className='amount'>
            {amount ? helpers.getMoneyView(amount) : ''}
          </span>
        )
      },
      {
        dataIndex: 'match',
        title: '',
        render: (_, record) => {
          const matchType = gObjProp(record, 'match_type');
          const status = gObjProp(record, 'status');
          const hasParseErrors = checkIfInvoiceHasError(record);
          let matchElement;
          if (hasParseErrors) {
            matchElement = <TransactionDeclinedIcon />;
          } else if (status === MATCHED) {
            matchElement = matchType === AUTOMATED ? <InvoiceSuggestIcon /> : <Invoice2Icon />;
          } else if (status === PENDING) {
            matchElement = <InvoicePendingIcon />;
          } else {
            matchElement = (
              <Tooltip title={`${t('add')} ${t('transaction')}`}>
                <StyledButton
                  icon={matchType === AUTOMATED ? <InvoiceSuggestIcon /> : <AddInvoiceIcon />}
                  onClick={(e) => onMatchClick(e, record)}
                  type='text'
                />
              </Tooltip>
            )
          }
          return (
            <div className='invoice-match'>
              {matchElement}
            </div>
          );
        },
        width: 64
      },
      {
        align: 'center',
        dataIndex: 'action',
        title: '',
        render: (_, record) => {
          const status = gObjProp(record, 'status');
          const hasParseErrors = checkIfInvoiceHasError(record);
          if (status === PENDING && !Boolean(hasParseErrors)) {
            return (
              <div className='flex-center-center'>
                <LoadingQuartersIcon />
              </div>
            );
          } else {
            return (
              <Button
                danger
                onClick={(e) => onDeleteRow(e, record.id)}
                type='text'
              >
                {t('delete')}
              </Button>
            );
          }
        },
        width: 70
      }
    ];
    if (openDetails) columns = columns.filter(c => !['action', 'expense_owner'].includes(c.dataIndex));
    return columns;
  }

  const columns = getColumns();

  return (
    <StyledInvoiceTabContainer
      {...rest}
      size='large'
      direction='vertical'
    >
      <TableFilters
        onFilter={handleOnFilter}
        rightSideContent={filtersRightSideContent}
      />
      {emptyDetails.enabled ? (
        <NoSearchResults
          spinning={tableProps?.loading}
          value={emptyDetails.text}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          rowKey='id'
          {...tableProps}
        />
      )}
    </StyledInvoiceTabContainer>
  );
}

InvoicesTab.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  filtersRightSideContent: PropTypes.any,
  onDeleteRow: PropTypes.func,
  onMatchClick: PropTypes.func,
  onFilter: PropTypes.func,
  openDetails: PropTypes.bool,
  tableProps: PropTypes.object
}

InvoicesTab.defaultProps = {
  columns: [],
  data: [],
  openDetails: false
}

const mapStateToProps = state => {
  const {employees} = state.company;
  return {
    employees,
  }
}

export default connect(mapStateToProps, null)(InvoicesTab);
