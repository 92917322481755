import styled from 'styled-components';
import Card from '../../../../Card';

const StyledLegalInformation = styled(Card)`
  .ant-card-body {
    color: ${props => props.theme.colors.neutral_8};
    a {
      color: ${props => props.theme.colors.neutral_8};
      text-decoration: underline;
    }
  }
  .ant-card-head {
    padding: 0 24px;
    flex-direction: unset;
  }
`;

export {
  StyledLegalInformation
};
