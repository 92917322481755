import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export default function useSearchParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [params, setParams] = useState({params: searchParams, allParams: Object.fromEntries(searchParams)});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setParams({params, allParams: Object.fromEntries(params)})
  }, [location.search]);

  return params;
}

