import React from 'react';

const FilePdfIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8 24.24H15.0691V27.4582H15.7745C16.7527 27.4618 17.2473 26.9236 17.2473 25.8436C17.2473 24.7709 16.7527 24.2364 15.8 24.24Z"
      fill="#000"/>
    <path
      d="M11.7782 24.2144H10.9891V25.9125H11.7782C12.4109 25.9089 12.6945 25.5525 12.6945 25.0616C12.6945 24.5598 12.4109 24.2144 11.7782 24.2144Z"
      fill="#000"/>
    <path
      d="M19.1273 1.09082H6.37091C5.66545 1.09082 5.09091 1.67264 5.09091 2.38537V29.4363C5.09091 30.149 5.66545 30.7308 6.37091 30.7308H25.4473C26.1527 30.7308 26.7273 30.149 26.7273 29.4363V8.69082L19.1273 1.09082ZM11.8945 26.6035H10.9891V28.1854H10.1491V23.509H11.9055C12.9782 23.509 13.5491 24.1599 13.5527 25.0581C13.5491 25.9526 12.9709 26.6035 11.8945 26.6035ZM15.8182 28.1854H14.2291V23.509H15.8436C17.24 23.509 18.08 24.3854 18.0836 25.8399C18.0836 27.3054 17.24 28.1854 15.8182 28.1854ZM21.8255 24.2145H19.6691V25.4945H21.6182V26.1999H19.6691V28.189H18.8291V23.509H21.8255V24.2145ZM25.68 20.5926H6.13818V2.38537C6.13818 2.25446 6.24364 2.149 6.37091 2.149H18.7273V8.44718C18.7273 8.90173 19.0982 9.27264 19.5527 9.27264H25.68V20.5926Z"
      fill="#000"/>
  </svg>
);

export default FilePdfIcon;
