import styled from 'styled-components';
import {typography} from '../../../../utils/css';

const StyledTransactionDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTransactionDetailsHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  
  a {
    color: ${props => props.theme.colors.neutral_10};
    
    &:hover {
      color: ${props => props.theme.colors.neutral_7};
    }
  }

  .date,
  .category {
    ${typography.body.regular};
  }
  
  .date,
  .category {
    color: ${props => props.theme.colors.neutral_7};
  }
  
  .date,
  .category {
    color: ${props => props.theme.colors.neutral_10};
  }

  .price {
    ${typography.heading.h4};
    
    &.returned {
      color: ${props => props.theme.colors.neutral_7};
      text-decoration: line-through;
    }
  }
`;

export {
  StyledTransactionDetailsHeader,
  StyledTransactionDetailsHeaderItem
};
