import { authConstants } from '../constants';


const authState = {
  phoneNumber: null,
  verifyErrors: null,
  session: null,
  invitationDetails: null,
  emailVerificationToken: null,
  companyInviteToken: null,
  phoneVerifyErrors: null,
  isInvitation: false,
  showWelcome: false,
  showRegisterModal: true,
  uboInviteKey: null
};


const authReducer = (state = authState, action) => {
  const {type, data} = action;
  switch (type) {
    case authConstants.ERROR_VERIFY_CODE:
      return {
        ...state,
        verifyErrors: data
      };
    case authConstants.SUCCESS_PHONE_VERIFICATION:
      return {
        ...state,
        phoneVerifyErrors: data,
        verifyErrors: null
      }
    case authConstants.ERROR_PHONE_VERIFICATION:
      return {
        ...state,
        phoneVerifyErrors: null
      }
    case authConstants.SUCCESS_FINISH_REGISTRATION:
    case authConstants.ERROR_FINISH_REGISTRATION:
      return {
        ...state,
        showWelcome: action.showWelcome || false
      }
    case authConstants.SUCCESS_LOGIN:
      return {
        ...state,
        verifyErrors: null,
        phoneNumber: action.phoneNumber,
        session: data
      };
    case authConstants.SUCCESS_VERIFY_CODE:
      return {
        ...state,
        verifyErrors: null,
        showWelcome: action.showWelcome || false
      };
    case authConstants.SUCCESS_PHONE_VERIFY_CODE:
      return {
        ...state,
        verifyErrors: null
      }
    case authConstants.ERROR_PHONE_VERIFY_CODE:
      return {
        ...state,
        verifyErrors: data
      }
    case authConstants.GET_INVITATION_DETAILS:
      return {
        ...state,
        invitationDetails: data
      };
    case authConstants.SET_EMAIL_VERIFICATION_TOKEN:
      return {
        ...state,
        emailVerificationToken: data,
        isInvitation: action.isInvitation
      }
    case authConstants.SET_COMPANY_INVITE_TOKEN:
      return {
        ...state,
        companyInviteToken: data
      }
    case authConstants.SET_SHOW_COMPANY_INVITE_REGISTER:
      return {
        ...state,
        showRegisterModal: data
      }
    case authConstants.LOGOUT:
      return {
        ...state,
        showWelcome: false,
        companyInviteToken: null
      }
    case authConstants.SET_UBO_INVITE_KEY:
      return {
        ...state,
        uboInviteKey: data
      }
    default:
      return state
  }
};

export default authReducer;
