import styled from 'styled-components';
import {Space} from 'antd';

const StyledLegalRepresentativeVerifyIdentity = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLegalRepresentativeVerifyIdentitySpace = styled(Space)`
  max-width: 440px;
  width: 100%;
`;

const StyledLegalRepresentativeVerifyIdentityInformation = styled(Space)`
  width: 100%;
  
  color: ${props => props.theme.colors.neutral_7};
  
  p {
    margin-bottom: 0;
    font-size: 16px;
  }
`;


export {
  StyledLegalRepresentativeVerifyIdentity,
  StyledLegalRepresentativeVerifyIdentitySpace,
  StyledLegalRepresentativeVerifyIdentityInformation
};
