import styled from 'styled-components';

const StyledStatusLine = styled.div`
  background-color: ${props => props.color};
  border-radius: 8px;
  color: ${props => props.theme.colors.neutral_1};
  display: inline-block;
  font-size: 14px;
  height: 24px;
  min-width: 6px;
  width: 6px;
`;

export {
  StyledStatusLine
};
