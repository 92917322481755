import React from 'react';

const ArrowUpIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.52827 2.86189C7.78862 2.60154 8.21073 2.60154 8.47108 2.86189L12.4711 6.86189C12.7314 7.12224 12.7314 7.54435 12.4711 7.8047C12.2107 8.06505 11.7886 8.06505 11.5283 7.8047L8.66634 4.94277V12.6666C8.66634 13.0348 8.36786 13.3333 7.99967 13.3333C7.63148 13.3333 7.33301 13.0348 7.33301 12.6666V4.94277L4.47108 7.8047C4.21073 8.06505 3.78862 8.06505 3.52827 7.8047C3.26792 7.54435 3.26792 7.12224 3.52827 6.86189L7.52827 2.86189Z"
          fill="#000" />
  </svg>
);

export default ArrowUpIcon;
