import {Table} from 'antd';
import styled from 'styled-components';
import {borders, typography} from '../../utils/css';

const StyledTable = styled(Table)`
  ${borders.light};
  
  .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${props => props.theme.colors.neutral_3};
  }

  .ant-table-container {
    overflow: auto;
  }

  .ant-table-thead > tr {
    & > th, & > td {
      ${typography.body.medium};
      
      background-color: ${props => props.theme.colors.neutral_2};
      border-color: ${props => props.theme.colors.neutral_3};
      color: ${props => props.theme.colors.neutral_7};
      
      &.ant-table-column-sort {
        background-color: ${props => props.theme.colors.neutral_2};
      }
    }
  }

  tbody {
    tr {
      ${typography.body.regular};
      color: ${props => props.theme.colors.neutral_10};
      
      &:hover {
        &:not(.grouped-row) {
          cursor: pointer;
        }
        
        &.grouped-row {
          td {
            background-color: unset !important;
          }
        }
      }
      &:last-of-type {
        td {
          border-bottom: none;
        }
      }
    }
  }

  thead {
    th {
      &:last-of-type {
        &:before {
          content: none !important;
        }
      }
    }
  }

  .ant-empty {
    .ant-empty-image {
      svg {
        g {
          transition: .3s;
        }
      }
    }
    &:hover {
      .ant-empty-image {
        svg {
          ellipse + g {
            stroke: ${props => props.theme.colors.primary_6};
          }
        }
      }
    }
  }

  .danger-text {
    color: ${props => props.theme.colors.functional_danger} !important;
  }

  .total-amount {
    ${typography.body.bold};
    color: ${props => props.theme.colors.neutral_9};
    text-wrap: nowrap;
  }

  .grouped-row {
    background-color: ${props => props.theme.colors.neutral_2};
    border-color: ${props => props.theme.colors.neutral_3};
    border-bottom: 1px solid;
    border-top: 1px solid;
    color: ${props => props.theme.colors.neutral_7};
  }

  .grouped-date {
    align-items: center;
    color: ${props => props.theme.colors.neutral_7};
    display: flex;
    justify-content: space-between;
    text-wrap: nowrap;
  }
  
  .download-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    
    .ant-spin {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-100%, 2px);
    }

    button {
      background-color: transparent;
      border: none;

      &:hover {
        background-color: ${props => props.theme.colors.light_darker};

        svg {
          path {
            fill: ${props => props.theme.colors.primary_6};
          }
        }
      }

      svg {
        path {
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          fill: ${props => props.theme.colors.neutral_6};
        }
      }
    }
  }
  
  .ant-pagination.ant-table-pagination {
    margin: 16px;
  }
  
  .amount,
  .date {
    text-wrap: nowrap;
  }
`;


export {
  StyledTable
};
