import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  StyledCompanyDisableModal,
  StyledCompanyDisableModalButton,
  StyledCompanyDisableModalSpace
} from './StyledCompanyDisableModal';
import {ReactComponent as AccessDisabledSvgImage} from '../../../static/images/access-disabled.svg';
import {helpers} from '../../../helpers';

const gObjProp = helpers.getObjProp;

const CompanyDisableModal = ({
  dispatch,
  open,
  handleClose,
  handleOk,
  user,
  company,
  ...rest
}) => {
  const [trans] = useTranslation(['quickStart', 'main', 'auth']);

  const t = (key, props) => trans(`disable.${key}`, props);

  const tMain = (key) => trans(`main:${key}`);

  const companyName = gObjProp(company, 'name');
  const userName = gObjProp(user, 'full_name');

  return (
    <StyledCompanyDisableModal
      centered={true}
      closeIcon={false}
      header={null}
      footer={null}
      open={open}
      width={408}
      {...rest}
    >
      <StyledCompanyDisableModalSpace
        direction='vertical'
        size='large'
      >
        <AccessDisabledSvgImage />
        <StyledCompanyDisableModalSpace
          direction='vertical'
          size='middle'
        >
          <h1>
            {t('title', {user_name: userName})}
          </h1>
          <p>
            {t('description1', {company_name: companyName})}
            <br />
            {t('description2')}
          </p>
        </StyledCompanyDisableModalSpace>
        <StyledCompanyDisableModalSpace
          direction='vertical'
          size='middle'
        >
          <StyledCompanyDisableModalButton
            onClick={handleOk}
            type='primary'
            size='large'
          >
            {t('handleOk')}
          </StyledCompanyDisableModalButton>
          <StyledCompanyDisableModalButton
            onClick={handleClose}
            size='large'
          >
            {tMain('close')}
          </StyledCompanyDisableModalButton>
        </StyledCompanyDisableModalSpace>
      </StyledCompanyDisableModalSpace>
    </StyledCompanyDisableModal>
  );
}

CompanyDisableModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOk: PropTypes.func,
  company: PropTypes.object
}

const mapStateToProps = state => {
  const {user} = state.user;
  return {
    user
  }
}

export default connect(mapStateToProps, null)(CompanyDisableModal);
