import styled from 'styled-components';
import {Space} from 'antd';
import TotalCounter from '../../SubscriptionPage/tabComponents/Transactions/TotalCounter';
import {typography} from '../../../../utils/css';

const StyledOverviewCounters = styled(Space)`
  display: flex;
  justify-content: space-between;
`;

const StyledTotalCounter = styled(TotalCounter)`
  border: none;
  padding: 0;
  
  &.budget-total-counter {
    
    &.over-budged {
      .title {
        color: ${props => props.theme.colors.functional_danger};
      }
      
      svg {
        height: 14px;
        width: 14px;
        
        path {
          fill: ${props => props.theme.colors.functional_danger};
        }
      }
    }
    
    .title {
      ${typography.body.medium};
      color: ${props => props.theme.colors.functional_success};
      
      &.over {
        color: ${props => props.theme.colors.functional_danger};
      }
    }
  }
    
  .title {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
    
    .gray {
      color: ${props => props.theme.colors.neutral_7};
      font-weight: 500;
    }
  }
  
  .value {
    ${typography.heading.h5};
    
    color: ${props => props.theme.colors.neutral_10};
    display: flex;
    line-height: unset;
    
    .ant-space-item {
      display: flex;
      align-items: center;
    }
    
    img {
      height: 10px;
    }
  }
`;

export {
  StyledOverviewCounters,
  StyledTotalCounter
}
