import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import KYCModalScreen from '../../../KYCModalScreen';
import LegalPersonInformation from '../../substeps/LegalPersonInformation';
import LegalPersonDocuments from '../../substeps/LegalPersonDocuments';
import {bankingActions} from '../../../../../../state/actions';
import {cardsHelpers} from '../../../../../pages/CardsPage/cardsHelpers';
import {helpers} from '../../../../../../helpers';
import {bankingDocumentTypes, bankingUserTypesConstants} from '../../../../../../constants';

const LegalPerson = ({
  dispatch,
  companyState,
  createDocument,
  getCardUsers,
  getCompanyState,
  onFinish,
  ...rest
}) => {
  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState(null);
  const [t] = useTranslation('main');
  const [document, setDocument] = useState(undefined);

  const informationFormRef = useRef(null);
  const documentsFormRef = useRef(null);

  const okDisabled = useMemo(() => step === 1 ? false : helpers.isNaV(document), [step, document])

  useEffect(() => {
    const state = helpers.getObjProp(companyState, 'state');
    if (state) {
      const states = {
        'lp_null': 1,
        'lp_creating': 2
      }
      const activeStep = states[state] || 2;
      if (activeStep !== step) setStep(activeStep);

      if (userId === null && activeStep !== 1) {
        getCardUsers(
          {user_type: bankingUserTypesConstants.LEGAL_PERSON},
          (users) => {
            if (users.length > 0) setUserId(users[0].id);
          }
        )
      }
    }
  }, [companyState]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOk = () => {
    const formsRef = {
      1: informationFormRef,
      2: documentsFormRef
    }
    const formRef = formsRef[step];
    if (formRef && formRef.current) formRef.current.submit();
  }

  const handleInformationSubmit = ({userId}) => {
    getCompanyState();
    setStep(2);
    setUserId(userId);
  }

  const handleDocumentsSubmit = async (document, successCallback, errorCallback) => {
    if (document) {
      const data = await cardsHelpers.getUploadedFilePayload(
        userId,
        document.originFileObj,
        bankingDocumentTypes.COMPANY_REGISTRATION
      );

      createDocument(
        data,
        (resp) => {
          successCallback && successCallback(resp);
          onFinish && onFinish();
        },
        (resp) => errorCallback && errorCallback(resp)
      );
    } else {
      errorCallback && errorCallback(t('validation.pleaseUploadDocument'));
    }
  }

  const getStepContent = () => {
    const screens = {
      1: (
        <LegalPersonInformation
          className='left-side-content'
          onSubmit={handleInformationSubmit}
          ref={informationFormRef}
        />
      ),
      2: (
        <LegalPersonDocuments
          onChange={setDocument}
          onSubmit={handleDocumentsSubmit}
          ref={documentsFormRef}
        />
      )
    }
    return screens[step] || null;
  }

  const stepContent = getStepContent();

  return (
    <KYCModalScreen
      enabledCancel={false}
      onOk={(props) => !okDisabled && onOk(props)}
      okProps={{disabled: okDisabled}}
      {...rest}
    >
      {stepContent}
    </KYCModalScreen>
  );
}

LegalPerson.propTypes = {
  onFinish: PropTypes.func
}

const mapStateToProps = state => {
  const {companyState} = state.banking;

  return {
    companyState,
  }
}

const mapDispatchToProps = {
  createDocument: bankingActions.createDocument,
  getCardUsers: bankingActions.getCardsUsers,
  getCompanyState: bankingActions.getCompanyState,
}

export default connect(mapStateToProps, mapDispatchToProps)(LegalPerson);
