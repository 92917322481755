import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyledLegalDocuments} from './StyledLegalDocuments';
import DetailsTable from '../../../TransactionsPage/DetailsTable';

const links = {
  cashBackRegulations: {
    eng: 'https://19956429.fs1.hubspotusercontent-na1.net/hubfs/19956429/01%20-%20WEBSITE/Legal/(ENG)%20Cashback%20Regulation.pdf',
    ita: 'https://19956429.fs1.hubspotusercontent-na1.net/hubfs/19956429/01%20-%20WEBSITE/Legal/(ITA)%20Regolamento%20Cashback.pdf'
  },
  employeeControlGuidelines: {
    eng: 'https://19956429.fs1.hubspotusercontent-na1.net/hubfs/19956429/01%20-%20WEBSITE/Legal/(ENG)%20H1%20-%20Employee%20control%20guidelines.pdf',
    ita: 'https://19956429.fs1.hubspotusercontent-na1.net/hubfs/19956429/01%20-%20WEBSITE/Legal/(ITA)%20H1%20-%20Linee%20guida%20controllo%20dipendenti.pdf'
  }
}

const LegalDocuments = ({
  ...rest
}) => {
  const [trans] = useTranslation(['settings', 'main']);
  const t = (key) => trans(`tabs.legalDocuments.${key}`);

  const label = (key) => t(`tableItems.${key}`);

  const getTableData = () => {
    const keys = ['cashBackRegulations', 'employeeControlGuidelines'];
    return keys.map(key => {
      let value;
      if (links.hasOwnProperty(key) && typeof links[key] === 'object') {
        let countriesKeys = links[key];
        let link;
        const linksKeys = Object.keys(links[key]);
        if (linksKeys.length > 0) {
          linksKeys.forEach(key => {
            link = (
              <a href={countriesKeys[key]} target='_blank' rel='noreferrer'>
                {key.toUpperCase()}
              </a>
            );
            value = value ? (
              <>
                {value}
                &nbsp;-&nbsp;
                {link}
              </>
            ) : link;
          });
        }
      }
      return {
        key,
        label: label(key),
        value
      }
    })
  }

  const tableData = getTableData();

  return (
    <StyledLegalDocuments
      title={t('title')}
      {...rest}
    >
      <p>{t('description')}</p>
      <DetailsTable data={tableData} />
    </StyledLegalDocuments>
  );
}

export default LegalDocuments;
