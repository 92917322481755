import React from 'react';
import PropTypes from 'prop-types';
import {ArrowDownIcon, ArrowUpIcon} from '../../../../icons';
import {StyledArrowIcon} from './StyledArrowIcon';

const ArrowIcon = ({direction, ...rest}) => {
  return (
    <StyledArrowIcon
      {...rest}
      direction={direction}
    >
      {direction === 'up' ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </StyledArrowIcon>
  )
}

ArrowIcon.propTypes = {
  direction: PropTypes.oneOf(['up', 'down'])
}

ArrowIcon.defaultProps = {
  direction: 'up'
}

export default ArrowIcon;
