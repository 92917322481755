import React from 'react';
import PropTypes from 'prop-types';
import {StyledEmpty, StyledDescription} from './StyledEmpty';

const Empty = ({
  children,
  description,
  image,
  title,
  ...rest
}) => {
  return (
    <StyledEmpty
      description={
        <StyledDescription>
          {title && <h3>{title}</h3>}
          {description && <p>{description}</p>}
          {children}
        </StyledDescription>
      }
      image={image}
      {...rest}
    />
  );
}

Empty.propTypes = {
  description: PropTypes.any,
  image: PropTypes.any,
  title: PropTypes.any
}
export default Empty;
