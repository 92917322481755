import React from 'react';
import {StyledTable} from './StyledTable';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';

const Table = ({
  loading,
  ...rest
}) => {
  return (
    <StyledTable
      {...rest}
      loading={{
        indicator: <Spinner size='small' />,
        spinning: loading
      }}
    />
  );
}

Table.propTypes = {
  loading: PropTypes.bool
}

Table.defaultProps = {
  loading: false
}

export default Table;
