import { useState, useEffect } from 'react';
import {localStorageKeysConstants} from '../constants';

export default function useAvailableDataLoading() {
  const [hasId, setHasId] = useState(false);

  useEffect(() => {
    const company = localStorage.getItem(localStorageKeysConstants.COMPANY_ID);
    setHasId(company !== null);
  }, []);

  return hasId;
}

