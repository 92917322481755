import React, {useMemo} from 'react';
import {Alert, Space} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledActivateAccount,
  StyledActivateAccountCopyFieldsContainer
} from './StyledActivateAccount';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import CopyField from '../../../../../CopyField';
import KYCModalScreen from '../../../KYCModalScreen';
import {kycHelpers} from '../../kycHelpers';
import {alertActions} from '../../../../../../state/actions';
import {transactionsHelpers} from '../../../../../pages/TransactionsPage/transactionsHelpers';

const ActivateAccount = ({
  dispatch,
  companyName,
  error,
  warning,
  wireDetails,
  onFinish,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'quickStart']);

  const {
    description,
    info,
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'activateAccount'}), [t]);

  const copyFieldProps = {
    buttonType: 'icon',
    onCopy: () => alertActions.success(`${t('copied')}!`)
  }

  const {bic, iban}  = useMemo(() => transactionsHelpers.getWireDetails(wireDetails), [wireDetails]);

  const onOk = (e) => onFinish && onFinish(e);

  return (
    <KYCModalScreen
      enabledCancel={false}
      onOk={onOk}
      okText={t('done')}
    >
      <StyledActivateAccount
        direction='vertical'
        size='large'
        {...rest}
      >
        {warning && (
          <Alert
            className='warning-alert'
            message={warning}
            type='warning'
          />
        )}
        <Space
          direction='vertical'
          size='middle'
        >
          <StyledKYCModalContentStepTitle>
            {title}
          </StyledKYCModalContentStepTitle>
          <p>
            {description}
            <br/>
            {info('hereAreDetails')}
          </p>
        </Space>
        <Space
          direction='vertical'
          size='middle'
        >
          <StyledActivateAccountCopyFieldsContainer>
            <CopyField
              {...copyFieldProps}
              label={`${t('accountName')}:`}
              value={companyName}
              data-testid='account-name'
            />
            <CopyField
              {...copyFieldProps}
              label='IBAN:'
              value={iban}
              data-testid='iban'
            />
            <CopyField
              {...copyFieldProps}
              label='SWIFT / BIC:'
              value={bic}
              data-testid='bic'
            />
          </StyledActivateAccountCopyFieldsContainer>
          <Alert
            message={error || info('alertMessage', {name: companyName})}
            type={error ? 'error' : 'warning'}
          />
        </Space>
      </StyledActivateAccount>
    </KYCModalScreen>
  );
}

ActivateAccount.propTypes = {
  error: PropTypes.any,
  warning: PropTypes.any
}

const mapStateToProps = state => {
  const {wireDetails} = state.transaction;
  const {name} = state.company;

  return {
    companyName: name,
    wireDetails
  }
}


export default connect(mapStateToProps, null)(ActivateAccount);
