import styled from 'styled-components';

const StyledVerifyPageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.neutral_10};
  background-image: url(${props => props.bgImg});
  background-position: center right;
  background-repeat: no-repeat;
  background-size: min(614px, 70vh);
`;

const StyledVerifyPageContainerContent = styled.div`
  padding-top: 60px;
  padding-left: 80px;
  color: #fff;
  & a {
    display: inline-block;
    & > svg {
      height: 90px;
      width: 301px;
    }
  }
  & > h1 {
    font-size: 96px;
    line-height: 96px;
    margin-top: 214px;
    margin-bottom: 32px;
    font-weight: 600;
  }
  & > p {
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 32px;
    line-height: 32px;
  }
  
  .redirect-description {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 24px;
    line-height: 32px;
    padding-top: 16px;
    
    a {
      color: ${props => props.theme.colors.primary_6};
    }
  }
  
  @media (max-width: 900px) {
    padding-left: 5%;
    a {
      & > svg {
        height: 70px;
        width: 234px;
      }
    }
    & > h1 {
      font-size: 52px;
      line-height: 58px;
    }
    & > p {
      font-size: 26px;
      line-height: 28px;
    }
    .redirect-description {
      font-size: 22px;
      line-height: 26px;
    }
  }
  @media (max-width: 600px) {
    a {
      & > svg {
        height: 55px;
        width: 184px;
      }
    }
    & > h1 {
      font-size: 42px;
      line-height: 44px;
    }
    & > p {
      font-size: 22px;
      line-height: 26px;
    }
    .redirect-description {
      font-size: 18px;
      line-height: 20px;
    }
  }
  @media (max-width: 767px) {
    padding-top: 30px;
    & > img {
      height: 42px
    }
    & > h1 {
      font-size: 36px;
      line-height: 38px;
    }
    & > p {
      font-size: 22px;
      line-height: 26px;
    }
  }
`;

export {
  StyledVerifyPageContainer,
  StyledVerifyPageContainerContent
}