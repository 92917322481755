import React from 'react';

const CreditCardWarningIcon = ({...rest}) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M29.6405 20.4383L24.7035 15.4923C24.2999 15.1087 23.7647 14.8948 23.2083 14.8948C22.6519 14.8948 22.1167 15.1087 21.713 15.4923L16.776 20.4383C16.3798 20.8358 16.1572 21.3745 16.1572 21.9362C16.1572 22.498 16.3798 23.0367 16.776 23.4342L21.713 28.3801C22.1098 28.7771 22.6476 29 23.2083 29C23.769 29 24.3067 28.7771 24.7035 28.3801L29.6405 23.4342C30.0367 23.0367 30.2593 22.498 30.2593 21.9362C30.2593 21.3745 30.0367 20.8358 29.6405 20.4383Z"
      fill="#FAAD14"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M25.2589 6.6665H7.05851C5.59491 6.6665 4.4043 7.85712 4.4043 9.32072V21.4543C4.4043 22.9179 5.59491 24.1085 7.05851 24.1085H15.8C15.2753 23.5092 14.9833 22.7372 14.9833 21.936C14.9833 21.0635 15.329 20.2262 15.9451 19.6082L20.8936 14.6508L20.9048 14.6402C21.5267 14.049 22.3517 13.7191 23.2098 13.7191C24.0679 13.7191 24.893 14.049 25.5149 14.6402L25.5261 14.6508L27.9131 17.0421V9.32072C27.9131 7.85712 26.7225 6.6665 25.2589 6.6665ZM11.1612 20.3168H8.84821C8.21881 20.3168 7.71069 19.8086 7.71069 19.1792C7.71069 18.5498 8.21881 18.0417 8.84821 18.0417H11.1612C11.7906 18.0417 12.2987 18.5498 12.2987 19.1792C12.2987 19.8086 11.7906 20.3168 11.1612 20.3168ZM25.638 10.4582H6.67934V9.32072C6.67934 9.10836 6.84615 8.94155 7.05851 8.94155H25.2589C25.4712 8.94155 25.638 9.10836 25.638 9.32072V10.4582Z"
          fill="#BFBFBF"/>
  </svg>
);

export default CreditCardWarningIcon;
