const rewardStatusesConstants = Object.freeze({
  READY: 'READY',
  CLAIMED: 'CLAIMED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  VOIDED: 'VOIDED'
});

export {
  rewardStatusesConstants
};

