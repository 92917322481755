import styled from 'styled-components';

const StyledHeaderDetails = styled.div`
  align-items: self-start;
  background: ${props => props.theme.colors.neutral_1};
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid ${props => props.theme.colors.neutral_3};
  border-top: none;
  background: linear-gradient(0deg, ${props => props.theme.colors.neutral_1}, ${props => props.theme.colors.neutral_1}),
              linear-gradient(0deg, ${props => props.theme.colors.neutral_3}, ${props => props.theme.colors.neutral_3});
`;

export {
  StyledHeaderDetails
}
