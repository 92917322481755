import styled from 'styled-components';
import {typography} from '../../utils/css';

const StyledUserDetailsEmail = styled.span`
  ${typography.footnote.description};
  color: ${props => props.theme.colors.neutral_7};
`;

const StyledUserDetailsName = styled.span`
  ${typography.body.regular};
  color: ${props => props.theme.colors.neutral_10};
`;

export {
  StyledUserDetailsEmail,
  StyledUserDetailsName
};
