import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledKYCModalScreen,
  StyledKYCModalScreenContent,
  StyledKYCModalScreenFooter
} from './StyledKYCModalScreen';
import {Button, Space} from 'antd';

const KYCModalScreen = ({
  children,
  cancelProps,
  cancelText,
  enabledCancel,
  enabledOk,
  onCancel,
  onOk,
  okText,
  okProps,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'quickStart']);

  return (
    <StyledKYCModalScreen {...rest}>
      <StyledKYCModalScreenContent>
        {children}
      </StyledKYCModalScreenContent>
      <StyledKYCModalScreenFooter>
        <Space size='large'>
          {/*<Button*/}
          {/*  onClick={handleNext}*/}
          {/*  size='large'*/}
          {/*  type='text'*/}
          {/*>*/}
          {/*  {trans('skip')}*/}
          {/*</Button>*/}
          {enabledOk && (
            <Button
              size='large'
              type='primary'
              onClick={onOk}
              {...okProps}
            >
              {okText || trans('next')}
            </Button>
          )}
        </Space>
        {enabledCancel && (
          <Button
            size='large'
            onClick={onCancel}
            {...cancelProps}
          >
            {cancelText || trans('back')}
          </Button>
        )}
      </StyledKYCModalScreenFooter>
    </StyledKYCModalScreen>
  );
}

KYCModalScreen.propTypes = {
  cancelProps: PropTypes.object,
  cancelText: PropTypes.string,
  enabledCancel: PropTypes.bool,
  enabledOk: PropTypes.bool,
  okProps: PropTypes.object,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func
}

KYCModalScreen.defaultProps = {
  enabledCancel: true,
  enabledOk: true
}

export default KYCModalScreen;

