import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledTableFiltersSearch, StyledTableFilters, StyledFilterElements, StyledRightSideContent} from './StyledTableFilters';

const TableFilters = ({
  dispatch,
  onFilter,
  rightSideContent,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const handleOnFilter = (key, value) => onFilter && onFilter({[key]: value});

  return (
    <StyledTableFilters {...rest}>
      <StyledFilterElements>
        <StyledTableFiltersSearch
          allowClear
          onSearch={(value) => handleOnFilter('search', value)}
          placeholder={t('search')}
          size='large'
        />
        {rightSideContent && <StyledRightSideContent>{rightSideContent}</StyledRightSideContent>}
      </StyledFilterElements>
    </StyledTableFilters>
  );
}

TableFilters.propTypes = {
  onFilter: PropTypes.func,
  rightSideContent: PropTypes.any
}

export default TableFilters;
