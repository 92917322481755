import styled from 'styled-components';
import {Space, Upload} from 'antd';

const StyledUserNationalityTaxDetailsForm = styled.div`
  position: relative;
  width: 100%;
`;

const StyledUserNationalityTaxDetailsFormUpload = styled(Upload)`
  display: inline-block;
  width: 100%;
  
  .ant-upload-select {
    width: 100%;
  }
`;

const StyledUserNationalityTaxDetailsFormUploadSpace = styled(Space)`
  width: 100%;
  
  .ant-space-item {
    &:nth-of-type(1) {
      width: 80%;
      
      input {
        pointer-events: none;
      }
    }
  }
`;

export {
  StyledUserNationalityTaxDetailsForm,
  StyledUserNationalityTaxDetailsFormUpload,
  StyledUserNationalityTaxDetailsFormUploadSpace
};
