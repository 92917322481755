import styled from 'styled-components';
import PageLoading from '../../components/PageLoading';


const StyledQuickGoogleConnectPageLoading = styled(PageLoading)`
  left: 0;
  width: 100%;
`;

export {
  StyledQuickGoogleConnectPageLoading
}