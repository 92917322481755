import styled from 'styled-components';
import {Form} from 'antd';

const StyledSupportForm = styled(Form)`
  .ant-form-item {
    &:last-of-type {
      margin-bottom: 0;
      text-align: right;
      
      .ant-btn {
        min-width: 120px;
      }
    }
  }
`;

export {
  StyledSupportForm
}
