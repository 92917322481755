import styled from 'styled-components';
import {Space} from 'antd';

const MD_WIDTH = '1024px';
const LG_HEIGHT = '56px';
const MD_HEIGHT = '48px';

const StyledForgotPinPage = styled.div`
  .image-content-container {
    & > div {
      text-align: center;
      width: 100%;
    }
  }
`;

const StyledSuccessSpace = styled(Space)`
  .ant-space-item {
    width: 100%;
    button {
      height: ${LG_HEIGHT};
      width: 100%;
      @media screen and (max-width: ${MD_WIDTH}) {
        height: ${MD_HEIGHT};
      }
    }
    h3 {
      margin-bottom: 0;
    }
  }
`;

const StyledPhoneAuthSpace = styled(Space)`
  width: 100%;
  
  button {
    font-weight: 500;
  }
  
  .ant-space-item {
    &:last-of-type {
      button {
        &:hover {
          background: unset;
          color: ${props => props.theme.colors.primary_6};
        }
      }
    }
  }
`;

export {
  StyledForgotPinPage,
  StyledPhoneAuthSpace,
  StyledSuccessSpace
}
