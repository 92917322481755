import styled from 'styled-components';

const StyledCopyFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 16px;

  & button.ant-btn {
    & svg path {
      fill: ${props => props.theme.colors.primary_6};
    }
  }
`;

const StyledCopyFieldLabel = styled.label`
  color: ${props => props.theme.colors.neutral_7};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;

const StyledCopyField = styled.div`
  color: ${props => props.theme.colors.neutral_10};
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 22px;
  overflow-wrap: anywhere;
`;

const StyledCopyFieldContent = styled.div`
  display: flex;
  flex-direction: column;
`;


export {
  StyledCopyFieldContainer,
  StyledCopyFieldContent,
  StyledCopyField,
  StyledCopyFieldLabel
};
