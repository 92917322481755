import React from 'react';
import {Space} from 'antd';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledDocumentUploadRules,
  StyledDocumentUploadRulesList,
  StyledDocumentUploadRulesListItem
} from './StyledDocumentUploadRules';
import {CheckIcon, CloseIcon} from '../../../../../../icons';

const DocumentUploadRules = ({
  rules,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'quickStart']);

  const trans = (key) => t(`quickStart:kyc.${key}`);

  return (
    <StyledDocumentUploadRules
      direction='vertical'
      size='large'
      {...rest}
    >
      {rules.length && (
        <StyledDocumentUploadRulesList>
          {rules.map(({label, variant, ...rest}, key) => (
            <StyledDocumentUploadRulesListItem
              key={key}
              variant={variant}
              {...rest}
            >
              <Space size='middle'>
                {variant === 'success' ? <CheckIcon /> : <CloseIcon />}
                {label}
              </Space>
            </StyledDocumentUploadRulesListItem>
          ))}
        </StyledDocumentUploadRulesList>
      )}
      <p>
        {trans('maxFileSize')}<br/>{trans('supportedFormats')}
      </p>
    </StyledDocumentUploadRules>
  );
}

DocumentUploadRules.propTypes = {
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      variant: PropTypes.oneOf(['error', 'success'])
    })
  )
}

DocumentUploadRules.defaultProps = {
  rules: []
}

export default DocumentUploadRules;
