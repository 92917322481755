import {Collapse, Drawer, Segmented, Space} from 'antd';
import styled from 'styled-components';

const StyledAddNewCardFormModal = styled(Drawer)`
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    button {
      margin-inline-end: 0;
      margin-inline-start: 12px;
    }
  }
  .ant-drawer-title {
    font-weight: 500;
  }
  .ant-drawer-footer {
    display: flex;
    justify-content: end;
    padding: 16px 24px;
  }

  .d-none-label {
    .ant-form-item-label {
      display: none;
    }
  }
  
  .ant-divider {
    margin-top: 12px;
  }
  
  .card-banner {
    margin-top: 8px;
  }
`;

const StyledAddSingleSubscriptionModalSegmented = styled(Segmented)`
  &.ant-segmented.ant-segmented-lg {
    padding: 4px;
    .ant-segmented-item-label {
      line-height: 40px;
      min-height: 40px;
    }
  }
`;

const StyledAddSingleSubscriptionModalSelectOptionSpace = styled(Space)`
  .ant-space-item {
    display: flex;
  }
  
  svg {
    height: 24px;
    width: 24px;
    path {
      fill: ${props => props.theme.colors.neutral_6};
    }
  }
`;

const StyledAddSingleSubscriptionModalCardInfo = styled(Space)`
  margin-bottom: 24px;
  color: ${props => props.theme.colors.neutral_7};
`;

const StyledAddSingleSubscriptionModalAccordion = styled(Collapse)`
  font-size: 16px;
  > .ant-collapse-item {
    > .ant-collapse-header {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .ant-collapse-content {
    > .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .ant-collapse-arrow {
    color: ${props => props.theme.colors.primary_6} !important;
    font-size: 16px !important;
  }
`;

const StyledAddNewCardFormModalSwitchSpace = styled(Space)`
  .anticon-info-circle {
    color: ${props => props.theme.colors.neutral_6};
    
    &:hover {
      cursor: help;
    }
  }
`;

export {
  StyledAddNewCardFormModal,
  StyledAddSingleSubscriptionModalSegmented,
  StyledAddSingleSubscriptionModalSelectOptionSpace,
  StyledAddSingleSubscriptionModalCardInfo,
  StyledAddSingleSubscriptionModalAccordion,
  StyledAddNewCardFormModalSwitchSpace
}
