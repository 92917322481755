import styled from 'styled-components';
import {Card, Space} from 'antd';
import Upload from '../../stepsComponents/Upload';

const StyledLegalPersonDocuments = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLegalPersonDocumentsSpace = styled.div`
  display: inline-flex;
  width: 100%;
  gap: 48px;
  
  & > * {
    width: 100%;
  }
  
  & > div {
    max-width: 390px;
  }
  
  & > form {
    display: flex;
    flex-direction: column;
    
    .ant-form-item,
    .ant-form-item-row,
    .ant-form-item-control,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content,
    .ant-form-item-control-input-content > *,
    .ant-form-item-control-input-content .kyc-upload {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
    }
    
    .ant-form-item {
      margin: 0;
    }
  }
`;

const StyledLegalPersonDocumentsInformation = styled(Space)`
  width: 100%;
  max-width: 390px;
  
  color: ${props => props.theme.colors.neutral_7};
  
  p {
    margin-bottom: 0;
    font-size: 16px;
    &.description {
      max-width: 300px;
    }
  }
`;

const StyledLegalPersonDocumentsImageCard = styled(Card)`
  border-color: ${props => props.theme.colors.neutral_5};
  
  .ant-card-head {
    background-color: ${props => props.theme.colors.neutral_4};
    border-color: ${props => props.theme.colors.neutral_5};
    color: ${props => props.theme.colors.neutral_7};
    font-weight: 400;
    font-size: 16px;
    min-height: 40px;
    padding: 0 8px;
  }
  
  .ant-card-body {
    padding: 4px 24px;
    text-align: center;
  }
`;

const StyledLegalPersonDocumentsUpload = styled(Upload)`
  display: flex;
  width: 100%;
`;


export {
  StyledLegalPersonDocuments,
  StyledLegalPersonDocumentsSpace,
  StyledLegalPersonDocumentsInformation,
  StyledLegalPersonDocumentsImageCard,
  StyledLegalPersonDocumentsUpload
};
