import styled from 'styled-components';
import {Form} from 'antd';

const MD_WIDTH = '1024px';
const LG_HEIGHT = '56px';
const MD_HEIGHT = '48px';

const StyledCreatePinForm = styled(Form)`
  .ant-input-password {
    padding: 12px 16px;
    height: ${LG_HEIGHT};
    @media screen and (max-width: ${MD_WIDTH}) {
      padding: 10px 13px;
      height: ${MD_HEIGHT};
    }
  }
  
  button {
    &.ant-btn {
      height: ${LG_HEIGHT};
      @media screen and (max-width: ${MD_WIDTH}) {
        height: ${MD_HEIGHT};
      }
    }
    font-weight: 500;
    width: 100%;
  }
`;

export {
  StyledCreatePinForm
}