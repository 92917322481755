import React from 'react';
import PropTypes from 'prop-types';
import {StyledUsageDot, StyledUsage} from './StyledUsage';
import {colors} from '../../../../utils/css';

const defaultColor = colors.neutral_6;

const filled = {
  'empty': 0,
  'low': 1,
  'medium': 2,
  'high': 3
}

const variantColors = {
  'empty': colors.neutral_6,
  'low': colors.functional_danger,
  'medium': colors.functional_warning,
  'high': colors.functional_success
}

const Usage = ({variant, ...rest}) => {
  const filledCount = filled[variant] || 0;
  const filledColor = variantColors[variant] || defaultColor;
  return (
    <StyledUsage
      {...rest}
    >
      {Array.from({length: 3}, (value, index) => index).map(i => (
        <StyledUsageDot key={i} color={filledCount < i + 1 ? defaultColor : filledColor} />
      ))}
    </StyledUsage>
  );
}

Usage.propTypes = {
  variant: PropTypes.oneOf(['empty', 'low', 'medium', 'high']).isRequired
}

Usage.defaultProps = {
  data: []
}

export default Usage;

