import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledWelcomeModal,
  StyledWelcomeModalButton,
  StyledWelcomeModalSpace
} from './StyledWelcomeModal';
import {ReactComponent as InvitedSvgImage} from '../../../static/images/auth/invited.svg';

const WelcomeModal = ({
  open,
  handleOk,
  ...rest
}) => {
  const [trans] = useTranslation(['quickStart', 'main', 'auth']);

  const t = (key, options) => trans(`welcome.${key}`, options);

  return (
    <StyledWelcomeModal
      centered={true}
      closeIcon={false}
      header={null}
      footer={null}
      open={open}
      width={408}
      wrapClassName='blur-bg'
      {...rest}
    >
      <StyledWelcomeModalSpace
        direction='vertical'
        size='large'
      >
        <InvitedSvgImage />
        <StyledWelcomeModalSpace
          direction='vertical'
          size='middle'
        >
          <h1>{t('title')}</h1>
          <p>{t('description')}</p>
          <StyledWelcomeModalButton
            onClick={handleOk}
            type='primary'
            size='large'
          >
            {t('getStarted')}
          </StyledWelcomeModalButton>
          <p>{t('description2')}</p>
        </StyledWelcomeModalSpace>
      </StyledWelcomeModalSpace>
    </StyledWelcomeModal>
  );
}

WelcomeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOk: PropTypes.func
}

export default WelcomeModal;
