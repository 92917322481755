import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Space} from 'antd';
import {StyledDeleteCardModal,} from './StyledDeleteCardModal';
import TrashIcon from '../../../../icons/TrashIcon';

const DeleteCardModal = ({
  cardNumber,
  open,
  loading,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards']);

  const trans = (key, options) => t(`cards:modal.delete.${key}`, options);

  return (
    <StyledDeleteCardModal
      open={open}
      okText={t('delete')}
      loading={loading}
      cancelButtonProps={{size: 'large'}}
      okButtonProps={{loading, type: 'primary', size: 'large'}}
      width={370}
      title={trans('title')}
      {...rest}
    >
      <Space
        align='center'
        direction='vertical'
        size={10}
      >
        <TrashIcon />
        <span>{trans('question', {number: cardNumber})}</span>
      </Space>
    </StyledDeleteCardModal>
  );
}

DeleteCardModal.propTypes = {
  cardNumber: PropTypes.string,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
}

DeleteCardModal.defaultProps = {
  loading: false,
}

export default DeleteCardModal;
