import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import firebaseApp from './initialize';

let analytics;
try {
  analytics = getAnalytics(firebaseApp);
} catch (e) {
  analytics = undefined;
}

const analyticsDecorator = (func) => {
  if (firebaseAnalytics.analyticsExist) {
    func();
  }
}

const firebaseAnalytics = {
  analyticsExist: analytics !== undefined,
  logEvent: (eventName, eventParams) => {
    analyticsDecorator(
      () => logEvent(analytics, eventName, eventParams)
    );
  },
  setUserProperties: (properties) => {
    analyticsDecorator(
      () => setUserProperties(analytics, properties)
    );
  }
}

export default firebaseAnalytics;
