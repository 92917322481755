import { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {helpers} from '../helpers';

export default function useIsEmployeeChanged () {
  const currentUser = useSelector(state => state.user.employee);
  const [company, setCompany] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!helpers.isEmptyObject(currentUser)) {
      const companyId = currentUser.company_id;
      if (company !== companyId) {
        if (company !== null) {
          setIsChanged(true);
        }
        setCompany(companyId);
      } else {
        setIsChanged(false);
      }
    } else {
      setIsChanged(false);
    }
  }, [currentUser, company]);

  return isChanged;
}

