import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {
  StyledSubscriptionEmailsModalCounter,
  StyledSubscriptionEmailsModalSpace,
  StyledSubscriptionEmailsModalUserCard,
  StyledSubscriptionEmailsModalUsersCardsSpace
} from './StyledSubscriptionEmailsModal';
import AntdModal from '../../../../AntdModal';
import {helpers} from '../../../../../helpers';


const SubscriptionEmailsModal = ({
  dispatch,
  emails,
  employees,
  subscription,

  handleClose,
  open,
  ...rest
}) => {

  const [t] = useTranslation('main');

  const title = useMemo(() => {
    const getServiceProp = (key) => helpers.getObjProp(subscription?.service || undefined, key);
    const subscriptionName = getServiceProp('name') || getServiceProp('url')
    return `${subscriptionName} ${t('users')}`;
  }, [subscription, t]);

  return (
    <AntdModal
      footer={null}
      onCancel={handleClose}
      open={open}
      title={title}
      width={502}
      {...rest}
    >
      <StyledSubscriptionEmailsModalSpace direction='vertical' size='small'>
        <StyledSubscriptionEmailsModalCounter>
          {emails.length} {t('users').toLowerCase()}
        </StyledSubscriptionEmailsModalCounter>
        <StyledSubscriptionEmailsModalUsersCardsSpace direction='vertical' size='small'>
          {emails.map(email => {
            const employee = employees.find(e => e.email === email);

            return (
              <StyledSubscriptionEmailsModalUserCard key={email}>
                <Space size='small'>
                  <Avatar size={32} src={employee?.logo || undefined} />
                  <div className='user-details'>
                    {employee && (
                      <span className='user-name'>
                        {employee?.full_name || ''}
                      </span>
                    )}
                    <span className='user-email'>
                      {email}
                    </span>
                  </div>
                </Space>
              </StyledSubscriptionEmailsModalUserCard>
            )
          })}
        </StyledSubscriptionEmailsModalUsersCardsSpace>

      </StyledSubscriptionEmailsModalSpace>
    </AntdModal>
  );
}

SubscriptionEmailsModal.propTypes = {
  handleClose: PropTypes.func,
  emails: PropTypes.arrayOf(PropTypes.string),
  subscription: PropTypes.shape({
    service: PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  })
}

SubscriptionEmailsModal.defaultProps = {
  emails: []
}

const mapStateToProps = state => {
  const {employees} = state.company;
  return {
    employees,
  }
}

export default connect(mapStateToProps, null)(SubscriptionEmailsModal);

