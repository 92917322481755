import styled from 'styled-components';
import AntdModal from '../../AntdModal';

const StyledEmailVerificationModal = styled(AntdModal)`
  .ant-modal-body {
    text-align: center;

    svg {
      margin-bottom: 24px;
    }

    h3 {
      color: ${props => props.theme.colors.neutral_10};
      font-size: 21px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
    }

    p {
      color: ${props => props.theme.colors.neutral_7};
      font-size: 14px;
      font-weight: 400;

      b {
        color: ${props => props.theme.colors.primary_6};
        font-weight: 500;
      }
    }
  }
`;

export {
  StyledEmailVerificationModal
}
