import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {
  StyledTransactionDetailsPanel,
  StyledTransactionDetailsPanelContainer,
  StyledTransactionDetailsContent,
  StyledPanelHeader,
  StyledPanelBody
} from './StyledTransactionDetailsPanel';
import {ChevronRightIcon} from '../../../../icons';


const TransactionDetailsPanel = ({
  backButtonText,
  children,
  contentClassName,
  fixed,
  hiddenOverflow,
  onBack,
  ...rest
}) => {
  const panelRef = useRef(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (panelRef.current) {
      let domElement = panelRef.current;
      let offset = 0;
      let parentContainer = document.getElementById('app-content-container');
      if (parentContainer && !fixed) offset -= parentContainer.scrollTop;
      while (domElement) {
        offset += domElement.offsetTop;
        domElement = domElement.offsetParent;
      }
      setOffset(offset);
    }
  }, [panelRef, fixed]);

  return (
    <StyledTransactionDetailsPanel {...rest}>
      <StyledTransactionDetailsPanelContainer
        ref={panelRef}
        hiddenOverflow={hiddenOverflow}
        fixed={fixed}
      >
        <StyledTransactionDetailsContent className={contentClassName}>
          <StyledPanelHeader>
            <Button
              icon={<ChevronRightIcon />}
              onClick={(e) => onBack && onBack(e)}
              size='small'
              type='text'
            >
              {backButtonText}
            </Button>
          </StyledPanelHeader>
          <StyledPanelBody fixed={fixed} offset={offset}>
            {children}
          </StyledPanelBody>
        </StyledTransactionDetailsContent>
      </StyledTransactionDetailsPanelContainer>
    </StyledTransactionDetailsPanel>
  );
}

TransactionDetailsPanel.propTypes = {
  backButtonText: PropTypes.string,
  contentClassName: PropTypes.string,
  hiddenOverflow: PropTypes.bool,
  fixed: PropTypes.bool,
  onBack: PropTypes.func
}

TransactionDetailsPanel.defaultProps = {
  backButtonText: '',
  hiddenOverflow: false,
  fixed: false
}

export default TransactionDetailsPanel;
