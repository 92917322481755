import React from 'react';

const CloseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M11.7863 4.24368C12.0464 4.50437 12.0458 4.92648 11.7851 5.18649L8.95359 8.01059L11.7714 10.8284C12.0318 11.0888 12.0318 11.5109 11.7714 11.7712C11.5111 12.0316 11.089 12.0316 10.8286 11.7712L8.00955 8.95216L5.1768 11.7775C4.9161 12.0375 4.49399 12.037 4.23399 11.7763C3.97398 11.5156 3.97453 11.0935 4.23522 10.8335L7.06674 8.00935L4.22895 5.17156C3.9686 4.91121 3.9686 4.4891 4.22895 4.22876C4.4893 3.96841 4.91141 3.96841 5.17176 4.22876L8.01078 7.06778L10.8435 4.24244C11.1042 3.98244 11.5263 3.98299 11.7863 4.24368Z"
          fill="#000"/>
  </svg>
);

export default CloseIcon;
