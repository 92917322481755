import i18next from 'i18next';
import authenticationEn from './en/authentication.json';
import cardsEn from './en/cards.json';
import invoicesEn from './en/invoices.json';
import mainEn from './en/main.json';
import quickStartEn from './en/quickStart.json';
import profileEn from './en/profile.json';
import settingsEn from './en/settings.json';
import subscriptionsEn from './en/subscriptions.json';
import supportEn from './en/support.json';
import teamsEn from './en/teams.json';
import transactionsEn from './en/transactions.json';
import userInviteEn from './en/userInvite.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      auth: authenticationEn,
      cards: cardsEn,
      invoices: invoicesEn,
      main: mainEn,
      quickStart: quickStartEn,
      profile: profileEn,
      settings: settingsEn,
      subscriptions: subscriptionsEn,
      support: supportEn,
      teams: teamsEn,
      transactions: transactionsEn,
      userInvite: userInviteEn,
    }
  }
});

export default i18next;
