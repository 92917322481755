import React from 'react';

const ChevronDownIcon = ({...rest}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M11.8744 5.69706C11.8347 5.65725 11.7875 5.62567 11.7355 5.60412C11.6835 5.58258 11.6278 5.57148 11.5715 5.57148C11.5153 5.57148 11.4596 5.58258 11.4076 5.60412C11.3556 5.62567 11.3084 5.65725 11.2687 5.69706L7.99994 8.96523L4.7312 5.69706C4.69143 5.65729 4.64421 5.62574 4.59224 5.60422C4.54027 5.58269 4.48458 5.57161 4.42833 5.57161C4.37208 5.57161 4.31638 5.58269 4.26441 5.60422C4.21245 5.62574 4.16523 5.65729 4.12545 5.69706C4.08568 5.73684 4.05413 5.78406 4.0326 5.83602C4.01108 5.88799 4 5.94369 4 5.99994C4 6.05619 4.01108 6.11188 4.0326 6.16385C4.05413 6.21582 4.08568 6.26304 4.12545 6.30281L7.69706 9.87442C7.73682 9.91423 7.78403 9.94581 7.836 9.96736C7.88797 9.98891 7.94368 10 7.99994 10C8.0562 10 8.11191 9.98891 8.16388 9.96736C8.21584 9.94581 8.26306 9.91423 8.30281 9.87442L11.8744 6.30281C11.9142 6.26306 11.9458 6.21584 11.9674 6.16388C11.9889 6.11191 12 6.0562 12 5.99994C12 5.94368 11.9889 5.88797 11.9674 5.836C11.9458 5.78403 11.9142 5.73682 11.8744 5.69706Z"
      fill="#262626" stroke="#262626" strokeWidth="0.4"/>
  </svg>
);

export default ChevronDownIcon;
