import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {StyledQuickGoogleConnectPageLoading} from './StyledQuickGoogleConnectPage';
import VerifyPageContainer from '../../components/VerifyPageContainer';
import {serviceStatsActions} from '../../state/actions';

const QuickGoogleConnectPage = ({
  generatePublicAuthUrl
}) => {
  const [t] = useTranslation(['quickStart']);
  const trans = (key) => t(`quickStart:quickGoogleConnect.${key}`);

  const [verification, setVerification] = useState({loading: true, success: false});

  const errorUrlGeneration = () => setVerification({loading: false, success: false});

  useEffect(() => {
    generatePublicAuthUrl(
      'google',
      (data) => {
        const url = data?.url;
        url ? window.open(url, '_self') : errorUrlGeneration();
      },
      errorUrlGeneration
    );
  }, [generatePublicAuthUrl]);

  return (
    <VerifyPageContainer>
      {verification.loading ? (
        <StyledQuickGoogleConnectPageLoading />
      ) : !verification.success && (
        <div className='redirect-description'>
          {trans('errorDescription')}.
        </div>
      )}
    </VerifyPageContainer>
  );
}

const mapDispatchToProps = {
  generatePublicAuthUrl: serviceStatsActions.generatePublicAuthUrl,
}

export default connect(null, mapDispatchToProps)(QuickGoogleConnectPage);

