const invoiceStatusConstants = Object.freeze({
  MATCHED: 'MATCHED',
  PARSED: 'PARSED',
  PENDING: 'PENDING'
});

const invoiceMatchTypeConstants = Object.freeze({
  AUTOMATED: 'AUTOMATED',
  MANUAL: 'MANUAL',
});

export {
  invoiceStatusConstants,
  invoiceMatchTypeConstants,
};
