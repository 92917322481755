import React from 'react';

const ExternalLinkIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.44444 4.44444C9.2971 4.44444 9.15579 4.50298 9.05161 4.60716C8.94742 4.71135 8.88889 4.85266 8.88889 5V8.33333C8.88889 8.48068 8.83036 8.62198 8.72617 8.72617C8.62198 8.83036 8.48068 8.88889 8.33333 8.88889H1.66667C1.51932 8.88889 1.37802 8.83036 1.27383 8.72617C1.16964 8.62198 1.11111 8.48068 1.11111 8.33333V1.66667C1.11111 1.51932 1.16964 1.37802 1.27383 1.27383C1.37802 1.16964 1.51932 1.11111 1.66667 1.11111H5C5.14734 1.11111 5.28865 1.05258 5.39284 0.948393C5.49702 0.844206 5.55556 0.702898 5.55556 0.555556C5.55556 0.408213 5.49702 0.266905 5.39284 0.162718C5.28865 0.0585316 5.14734 0 5 0H1.66667C1.22464 0 0.800716 0.175595 0.488155 0.488155C0.175595 0.800716 0 1.22464 0 1.66667V8.33333C0 8.77536 0.175595 9.19928 0.488155 9.51185C0.800716 9.82441 1.22464 10 1.66667 10H8.33333C8.77536 10 9.19928 9.82441 9.51185 9.51185C9.82441 9.19928 10 8.77536 10 8.33333V5C10 4.85266 9.94147 4.71135 9.83728 4.60716C9.7331 4.50298 9.59179 4.44444 9.44444 4.44444Z"
      fill="#BFBFBF"/>
    <path
      d="M7.22192 1.11111H8.09969L4.60525 4.6C4.55318 4.65165 4.51185 4.71309 4.48364 4.78079C4.45544 4.84849 4.44092 4.9211 4.44092 4.99444C4.44092 5.06778 4.45544 5.1404 4.48364 5.2081C4.51185 5.2758 4.55318 5.33724 4.60525 5.38889C4.6569 5.44096 4.71834 5.48229 4.78604 5.5105C4.85374 5.5387 4.92635 5.55322 4.99969 5.55322C5.07303 5.55322 5.14565 5.5387 5.21335 5.5105C5.28105 5.48229 5.34249 5.44096 5.39414 5.38889L8.88858 1.9V2.77778C8.88858 2.92512 8.94711 3.06643 9.0513 3.17061C9.15549 3.2748 9.2968 3.33333 9.44414 3.33333C9.59148 3.33333 9.73279 3.2748 9.83698 3.17061C9.94116 3.06643 9.9997 2.92512 9.9997 2.77778V0.555556C9.9997 0.408213 9.94116 0.266905 9.83698 0.162718C9.73279 0.0585316 9.59148 0 9.44414 0H7.22192C7.07457 0 6.93327 0.0585316 6.82908 0.162718C6.72489 0.266905 6.66636 0.408213 6.66636 0.555556C6.66636 0.702898 6.72489 0.844206 6.82908 0.948393C6.93327 1.05258 7.07457 1.11111 7.22192 1.11111Z"
      fill="#BFBFBF"/>
  </svg>
);

export default ExternalLinkIcon;
