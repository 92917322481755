import React from 'react';

const DownloadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2222 4C11.9865 4 11.7604 4.09365 11.5937 4.26035C11.427 4.42705 11.3333 4.65314 11.3333 4.88889V13.3591L7.584 9.60533C7.49789 9.51911 7.39564 9.45071 7.28307 9.40404C7.17051 9.35738 7.04985 9.33336 6.928 9.33336C6.80615 9.33336 6.68549 9.35738 6.57293 9.40404C6.46036 9.45071 6.35811 9.51911 6.272 9.60533C6.09798 9.77965 6.00024 10.0159 6.00024 10.2622C6.00024 10.5085 6.09798 10.7448 6.272 10.9191L11.52 16.1724C11.7129 16.3653 11.9698 16.456 12.2222 16.4436C12.4747 16.456 12.7316 16.3653 12.9244 16.1724L18.1724 10.9191C18.3465 10.7448 18.4442 10.5085 18.4442 10.2622C18.4442 10.0159 18.3465 9.77965 18.1724 9.60533C18.0863 9.51911 17.9841 9.45071 17.8715 9.40404C17.759 9.35738 17.6383 9.33336 17.5164 9.33336C17.3946 9.33336 17.2739 9.35738 17.1614 9.40404C17.0488 9.45071 16.9466 9.51911 16.8604 9.60533L13.1111 13.3591V4.88889C13.1111 4.65314 13.0175 4.42705 12.8508 4.26035C12.6841 4.09365 12.458 4 12.2222 4ZM6 19.1111C6 18.8754 6.09365 18.6493 6.26035 18.4826C6.42705 18.3159 6.65314 18.2222 6.88889 18.2222H17.5556C17.7913 18.2222 18.0174 18.3159 18.1841 18.4826C18.3508 18.6493 18.4444 18.8754 18.4444 19.1111C18.4444 19.3469 18.3508 19.573 18.1841 19.7397C18.0174 19.9064 17.7913 20 17.5556 20H6.88889C6.65314 20 6.42705 19.9064 6.26035 19.7397C6.09365 19.573 6 19.3469 6 19.1111Z"
      fill="#000"/>
  </svg>
);

export default DownloadIcon;
