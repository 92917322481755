import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import SubscriptionTooltipSpendingDetails from '../SubscriptionTooltipSpendingDetails';
import {StyledSubscriptionTooltip} from './StyledSubscriptionTooltip';

const SubscriptionTooltip = ({
  active,
  actualSpend,
  coordinate,
  expectedSpend,
  emptyText,
  loading,
  payload,
  services,
  viewBox
}) => {
  const {width} = viewBox;
  const isPositiveTransform = width - coordinate.x > 140;
  const [t] = useTranslation(['main', 'subscriptions']);
  if (!active && payload.length === 0) return null;

  const trans = (key) => t(`subscriptions:${key}`);

  const activeServices = services.find(s => s.name === 'actual')?.data || [];
  const expectedServices = services.find(s => s.name === 'expected')?.data || [];

  return (
    <StyledSubscriptionTooltip isPositiveTransform={isPositiveTransform}>
      {loading ? `${t('Loading')}...` : (
        <>
          <SubscriptionTooltipSpendingDetails
            emptyText={emptyText?.actual}
            services={expectedServices}
            name={trans('totPrevious')}
            total={expectedSpend}
            type='dashed'
          />
          <SubscriptionTooltipSpendingDetails
            emptyText={emptyText?.expected}
            services={activeServices}
            name={trans('totActual')}
            total={actualSpend}
            type='solid'
          />
        </>
      )}
      <div className={isPositiveTransform ? 'left-arrow' : 'right-arrow'} />
    </StyledSubscriptionTooltip>
  );
}

SubscriptionTooltip.propTypes = {
  active: PropTypes.bool,
  coordinate: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  emptyText: PropTypes.any,
  loading: PropTypes.bool,
  payload: PropTypes.array,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          logo: PropTypes.string,
          name: PropTypes.string,
          value: PropTypes.number
        })
      )
    })
  )
}

SubscriptionTooltip.defaultProps = {
  active: false,
  loading: false
}

export default SubscriptionTooltip;
