import React from 'react';

const ChevronRightIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5456 17.8116C9.48588 17.752 9.43851 17.6812 9.40618 17.6032C9.37386 17.5253 9.35723 17.4417 9.35723 17.3573C9.35723 17.2729 9.37386 17.1894 9.40618 17.1114C9.43851 17.0335 9.48588 16.9626 9.5456 16.903L14.4478 11.9999L9.5456 7.0968C9.48594 7.03714 9.43861 6.96631 9.40632 6.88836C9.37403 6.81041 9.35742 6.72686 9.35742 6.64249C9.35742 6.55812 9.37403 6.47457 9.40632 6.39662C9.43861 6.31867 9.48594 6.24784 9.5456 6.18818C9.60526 6.12852 9.67609 6.08119 9.75404 6.04891C9.83199 6.01662 9.91553 6 9.99991 6C10.0843 6 10.1678 6.01662 10.2458 6.04891C10.3237 6.08119 10.3946 6.12852 10.4542 6.18818L15.8116 11.5456C15.8713 11.6052 15.9187 11.676 15.951 11.754C15.9834 11.832 16 11.9155 16 11.9999C16 12.0843 15.9834 12.1679 15.951 12.2458C15.9187 12.3238 15.8713 12.3946 15.8116 12.4542L10.4542 17.8116C10.3946 17.8713 10.3238 17.9187 10.2458 17.951C10.1679 17.9834 10.0843 18 9.99991 18C9.91552 18 9.83195 17.9834 9.754 17.951C9.67604 17.9187 9.60523 17.8713 9.5456 17.8116Z"
      fill="#000" stroke="#000" strokeWidth="0.6"/>
  </svg>

);

export default ChevronRightIcon;
