import styled from 'styled-components';
import {typography} from '../../../../utils/css';

const StyledDetailsTable = styled.table`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 40px;

  tr, th {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 42px;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
  
  tr {
    &.vertical {
      align-items: self-start;
      flex-direction: column;
      
      td {
        &:nth-child(1) {
          margin-right: 0;
          margin-bottom: 12px;
        }
        &:nth-child(2) {
          text-align: unset;
        }
      }
    }
  }

  td {
    ${typography.body.regular};
    &:nth-child(1) {
      color: ${props => props.theme.colors.neutral_7};
      margin-right: 6px;
    }
    &:nth-child(2) {
      color: ${props => props.theme.colors.neutral_10};
      text-align: right;
    }

    .ant-tag {
      margin-bottom: 4px;
    }
  }
`;

export {StyledDetailsTable};
