import React, {useEffect, useState} from 'react';
import {Input, Select} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  StyledSubscriptionTableEditableCell,
  StyledSubscriptionTableSelectOptionSpace,
  StyledSubscriptionTableEditableCellFormItem
} from './StyledSubscriptionTableEditableCell';
import {subscriptionsHelpers} from '../../subscriptionsHelpers';
import TagSelect from '../TagSelect';
import OwnerSelect from '../OwnerSelect';
import StatusLine from '../../StatusLine';
import {helpers} from '../../../../../helpers';
import {subscriptionStatusesConstants} from '../../../../../constants';

const {ACTIVE, INACTIVE, TRIAL} = subscriptionStatusesConstants;

const gObjProp = helpers.getObjProp;

const availableStatuses = [ACTIVE, INACTIVE, TRIAL];

const getStatusOptions = (t) => {
  return [
    {value: ACTIVE, label: t('subscriptionStatuses.active')},
    {value: TRIAL, label: t('subscriptionStatuses.trial')},
    {value: INACTIVE, label: t('subscriptionStatuses.inactive')},
  ].map(o => ({
    ...o,
    image: <StatusLine color={subscriptionsHelpers.getSubscriptionStatusColor(o.value)} />
  }));
}

const SubscriptionTableEditableCell = ({
  editing,
  dataIndex,
  inputType,
  record,
  index,
  children,
  employees,
  tags,
  disabled,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [tagOptions, setTagOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [statusOptions, ] = useState(getStatusOptions(t));
  const [enabledEdit, setEnabledEdit] = useState(true);

  const selectRulesMessage = (name) => t('validation.pleaseSelect', {name});

  useEffect(() => {
    const options = employees
      .map(option => {
        return {
          image: option.logo,
          label: option.full_name,
          value: option.email
        }
      });
    setEmployeeOptions(options);
  }, [employees]);

  useEffect(() => {
    if (dataIndex === 'status') {
      const status = gObjProp(record, 'status');
      const enabled = availableStatuses.includes(status);
      if (enabled !== enabledEdit) setEnabledEdit(enabled);
    }
  }, [record, dataIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => tags && setTagOptions(tags.map(tag => ({label: tag.tag_name, value: tag.tag_name}))), [tags]);

  const inputNodes = {
    owner: (
      <OwnerSelect
        className='owner-select'
        disabled={disabled}
        size='large'
        options={employeeOptions}
      />
    ),
    status: (
      <Select
        disabled={disabled}
        dropdownStyle={{minWidth: 180}}
        options={statusOptions.map(option => ({
          ...option,
          label: (
            <StyledSubscriptionTableSelectOptionSpace>
              {option.image}
              {option.label}
            </StyledSubscriptionTableSelectOptionSpace>
          )
        }))}
        size='large'
      />
    ),
    tags: (
      <TagSelect
        disabled={disabled}
        maxTagCount='responsive'
        mode='multiple'
        size='large'
        options={tagOptions}
      />
    )
  }
  const rules = {
    owner: [
      {
        required: true,
        message: selectRulesMessage(t('owner')),
      },
    ],
    status: [
      {
        required: true,
        message: selectRulesMessage(t('status')),
      },
    ],
  }

  const inputNode = inputNodes[dataIndex] || <Input disabled={disabled} />;

  const itemRules = enabledEdit ? rules[dataIndex] || [] : [];

  return (
    <StyledSubscriptionTableEditableCell {...rest}>
      {enabledEdit && editing ? (
        <StyledSubscriptionTableEditableCellFormItem
          name={dataIndex}
          onClick={(e) => e.stopPropagation()}
          style={{
            margin: 0,
            textAlign: 'center'
          }}
          rules={itemRules}
        >
          {inputNode}
        </StyledSubscriptionTableEditableCellFormItem>
      ) : (
        children
      )}
    </StyledSubscriptionTableEditableCell>
  );
}

SubscriptionTableEditableCell.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      editable: PropTypes.bool
    })
  ),
  data: PropTypes.array,
  editable: PropTypes.bool,
}

SubscriptionTableEditableCell.defaultProps = {
  columns: [],
  data: [],
  editable: false
}

const mapStateToProps = state => {
  const {employees, tags} = state.company;
  return {
    employees,
    tags,
  }
}

export default connect(mapStateToProps, null)(SubscriptionTableEditableCell);
