import React from 'react';
import {Space} from 'antd';
import PropTypes from 'prop-types';
import {
  StyledSelectionPanel,
  StyledSelectionPanelItem
} from './StyledSelectionPanel';
import {ChevronRightIcon} from '../../../../../../icons';

const SelectionPanel = ({
  items,
  onSelect,
  ...rest
}) => {
  return (
    <StyledSelectionPanel
      direction='vertical'
      size='middle'
      {...rest}
    >
      {items.map(({description, key, image, title, ...rest}, index) => (
        <StyledSelectionPanelItem
          key={index}
          onClick={(e) => onSelect && onSelect(key, e)}
          {...rest}
        >
          <Space size='middle'>
            {image}
            <Space.Compact direction='vertical'>
              {title && <span className='title'>{title}</span>}
              {description && <span className='description'>{description}</span>}
            </Space.Compact>
          </Space>
          <span className='move'>
            <ChevronRightIcon />
          </span>
        </StyledSelectionPanelItem>
      ))}
    </StyledSelectionPanel>
  );
}

SelectionPanel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      image: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func
}

SelectionPanel.defaultProps = {
  items: []
}

export default SelectionPanel;
