import styled from 'styled-components';
import {Space} from 'antd';
import Card from '../../../../../Card';

const StyledBudgetDetails = styled(Card)`
  .edit-btn {
    color: ${props => props.theme.colors.primary_7};
    padding: 4px 12px;
  }

  .ant-space-item {
    display: flex;
  }
`;

const StyledBudgetDetailsFormSpace = styled(Space)`
  width: 100%;
  & > div {
    &:nth-of-type(1) {
      width: 60%;
    }
    &:nth-of-type(2) {
      width: 40%;
    }
  }
  
  .ant-form-item {
    width: 100%;
  }
`;

export {
  StyledBudgetDetails,
  StyledBudgetDetailsFormSpace
}
