import styled from 'styled-components';
import {Dropdown} from 'antd';

const StyledOverviewDropdown = styled(Dropdown)`
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_5};
  border-radius: ${props => props.theme.border_radius.standard};
  color: ${props => props.theme.colors.neutral_10};
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 7px 12px;
  width: 100%;
  transition: .2s ease-in-out;
  
  svg {
    path {
      transition: .2s ease-in-out;
    }
  }

  &:hover {
    cursor: pointer;
  }
  
  &:hover,
  &.ant-dropdown-open {
    background-color: ${props => props.theme.colors.neutral_1};
    border-color: ${props => props.theme.colors.primary_3};
    color: ${props => props.theme.colors.primary_7};
    
    svg {
      path {
        fill: ${props => props.theme.colors.primary_7};
      }
    }
  }
  
  & > .ant-space-item {
    display: flex;
    align-items: center;
  }
`;

export {
  StyledOverviewDropdown,
}