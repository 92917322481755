import styled from 'styled-components';
import {Link} from 'react-router-dom';
import Steps from '../../components/Steps';

const MD_WIDTH = '1024px';

const StyledSignUpPage = styled.div`
  .form-content-container {
    justify-content: start;
    flex-direction: column;
    margin-top: 56px;
    padding-bottom: 0;
    & > div {
      justify-content: space-between;
    }
  }
  .image-content {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    width: 100%;
    
    & > div {
      text-align: center;
    }

    .action-image {
      max-width: 500px;
      width: 75%;
    }
  }
  
  .terms-of {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    max-width: 410px;
    margin: 0 auto;
    a {
      color: ${props => props.theme.colors.neutral_7};
      transition: .2s ease;
      
      &:hover {
        color: ${props => props.theme.colors.secondary};
      }
    }
    @media screen and (max-width: ${MD_WIDTH}) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const StyledSteps = styled(Steps)`
  display: flex;
  justify-content: space-between !important;
  margin: 0 auto 104px auto;
  max-width: 610px;
  width: 100%;
  
  .filled {
    background-color: ${props => props.theme.colors.primary_6};
  }
`;

const StyledFormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  
  .form-content-header {
    display: flex;
    flex-direction: column;
  }
  
  .form-content {
    margin: auto;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 24px;
`;


export {
  StyledSignUpPage,
  StyledSteps,
  StyledFormContent,
  StyledLink
}