import styled from 'styled-components';
import AntdModal from '../../../AntdModal';

const StyledDeleteCardModal = styled(AntdModal)`
  .ant-modal-body {
    align-items: center;
    color: ${props => props.theme.colors.neutral_10};
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 40px 24px !important;
    
    svg {
      height: 32px;
      width: 32px;

      path {
        fill: ${props => props.theme.colors.functional_danger};
        transition: .2s ease-in-out;
      }
    }
  }
`;

export {
  StyledDeleteCardModal
};
