import styled from 'styled-components';
import {typography} from "../../../../utils/css";

const StyledCardBanner = styled.div`
  background-color: ${props => props.theme.colors.additional_sky};
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: bottom right 22px;
  //background-size: ;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: 400px;
  
  & > * {
    max-width: 257px;
  }
`;

const StyledCardBannerDescription = styled.p`
  ${typography.footnote.description};
  
  color: ${props => props.theme.colors.neutral_9};
  margin: 0;
  padding: 0;
`;

const StyledCardBannerTitle = styled.div`
  ${typography.body.regular};
  color: ${props => props.theme.colors.neutral_10};
  margin-bottom: 5px;
  
  span.fw-500 {
    font-weight: 500;
  }
`;

export {
  StyledCardBanner,
  StyledCardBannerDescription,
  StyledCardBannerTitle
};
