import React from 'react';
import PropTypes from 'prop-types';
import {StyledStatusLine} from './StyledStatusLine';

const StatusLine = ({
  color,
  ...rest
}) => {
  return (
    <StyledStatusLine color={color} {...rest} />
  );
}

StatusLine.propTypes = {
  color: PropTypes.string.isRequired,
}

export default StatusLine;
