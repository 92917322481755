import styled from 'styled-components';
import AntdModal from '../../../AntdModal';

const StyledSmsAuthModalContainer = styled.div`
  padding-top: 48px;

  h3 {
    font-weight: 700;
    font-size: 24px;
  }
  > p {
    margin-top: 56px;
    margin-bottom: 0;
    b {
      font-size: 16px;
    }
  }
  > div {
    position: relative;

    .error-landing {
      top: 4px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .inputs {
    justify-content: center;
    margin-top: 40px;

    input {
      height: 48px;
      width: 48px;
    }
  }

  .help-text {
    min-height: 48px;
    position: relative;
  }

  .resend {
    border-top: 1px solid ${props => props.theme.colors.neutral_5};
    justify-content: space-between;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px !important;

    & > div {
      align-items: center;
      border: 1px solid ${props => props.theme.colors.neutral_5};
      border-radius: ${props => props.theme.border_radius.standard};
      color: ${props => props.theme.colors.neutral_10};
      display: flex;
      font-size: 16px;
      font-weight: 400;
      height: 40px;
      padding: 8px 16px 8px 16px;
      text-decoration: none;
      transition: .3s ease;

      &:hover,&:focus,&:active {
        border-color: ${props => props.theme.colors.primary_6};
        color: ${props => props.theme.colors.primary_6};
      }
    }

    & > span {
      color: ${props => props.theme.colors.primary_8};
      font-size: 24px;
      font-weight: 700;
    }
  }
`;

const StyledSmsAuthModalSuccessContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  padding: 48px;
  text-align: center;

  .ant-result {
    padding: 0;
  }

  .ant-result-title {
    font-size: 20px;
  }
`;

const StyledSmsAuthModal = styled(AntdModal)`
  &.disabled {
    opacity: 1;
    .ant-modal-body {
      opacity: 0.4;
      cursor: unset;
      pointer-events: none;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      text-align: center;
      padding: 0;
    }
  }
;
`;

export {
  StyledSmsAuthModal,
  StyledSmsAuthModalContainer,
  StyledSmsAuthModalSuccessContainer
};
