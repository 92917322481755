import styled from 'styled-components';

const StyledEmailVerificationPage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  
  min-height: calc(100vh - 100px);
  padding-top: 100px;
  
  & h3 {
    font-size: 2rem;
    text-align: center;
  }
  
  & a {
    font-size: 0.875rem;
    color: ${props => props.theme.colors.secondary};
  }
`;

export {
  StyledEmailVerificationPage
}