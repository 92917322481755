import styled from 'styled-components';
import {Input, Select, Space} from 'antd';

const StyledPhoneNumberInput = styled(Input)`
  &.ant-input-group-wrapper {
    &:not(.ant-input-group-wrapper-disabled) {
      .ant-input-group-addon {
        background-color: ${props => props.theme.colors.neutral_1};
      }
    }
  }
`;

const StyledPhoneInputSelect = styled(Select)`
  min-width: 86px;
`;

const StyledPhoneInputSelectOptionContent = styled(Space)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export {
  StyledPhoneNumberInput,
  StyledPhoneInputSelect,
  StyledPhoneInputSelectOptionContent
}
