import styled from 'styled-components';
import Card from '../../../../Card';

const StyledBankingInfo = styled(Card)`
  .ant-card-body {
    padding: 24px 0;

    & > * {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .ant-card-head {
    flex-direction: unset;
    padding: 24px;
  }
  .user-info {
    padding: 0;
  }
`;

const StyledTitle = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
`;

const StyledEmptyContainer = styled.div`
  border-bottom: 1px solid ${props => props.isEditMode ? props.theme.colors.neutral_3 : 'unset'};
  display: flex;
  justify-content: space-between;
  padding: ${props => props.isEditMode ? '10px 0 32px 0' : '10px 0 8px 0'};
  margin-bottom: ${props => props.isEditMode ? '24px' : 0};

  h3 {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
  }
`;

const StyledEmptySpinContainer = styled.div`
  padding: 24px;
  text-align: center;
`;

export {
  StyledBankingInfo,
  StyledTitle,
  StyledEmptyContainer,
  StyledEmptySpinContainer
}
