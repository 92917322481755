import styled from 'styled-components';
import {Segmented, Space} from 'antd';
import AntdModal from '../../../../../AntdModal';

const StyledChangeMethodModal = styled(AntdModal)`
  .ant-space:nth-of-type(2) {
    width: 100%;
    .ant-space-item {
      width: 100%;
      &:first-of-type {
        text-align: center;
      }
    }
  }
`;

const StyledSegmented = styled(Segmented)`
  &.ant-segmented.ant-segmented-lg {
    padding: 4px;
    .ant-segmented-item-label {
      line-height: 40px;
      min-height: 40px;
    }
  }
`;

const StyledCardDetailsSpace = styled(Space)`
  .ant-space-item {
    &:nth-child(2) {
      width: 40%;
    }
  }
`;

export {
  StyledChangeMethodModal,
  StyledSegmented,
  StyledCardDetailsSpace
}
