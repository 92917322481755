import styled from 'styled-components';
import {typography} from '../../../utils/css';

const StyledVerticalBarChart = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledVerticalBarChartItem = styled.div`
  flex-direction: column;
  display: flex;
  
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
  
  &.pointer {
    & * {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const StyledVerticalBarChartItemHeader = styled.div`
  ${typography.footnote.description};
  
  align-items: center;
  color: ${props => props.theme.colors.neutral_7};
  display: flex;
  justify-content: space-between;
  
  .price {
    color: ${props => props.theme.colors.neutral_10};
    text-wrap: nowrap;
    
    &.danger {
      color: ${props => props.theme.colors.functional_danger};
    }
  }
`;

const StyledVerticalBarChartItemLine = styled.div`
  background: ${props => props.isDanger ? props.theme.colors.functional_danger : props.barBackgroundColor};
  border-radius: ${props => typeof props.barRadius === "number" ? `${props.barRadius}px` : props.barRadius};
  color: ${props => props.theme.colors.neutral_1};
  font-size: 10px;
  line-height: 12px;
  height: ${props => typeof props.barWidth === "number" ? `${props.barWidth}px` : props.barWidth};
  position: relative;
  text-align: right;
  width: 100%;
  
  &:after {
    background: ${props => props.isDanger ? '#ED254E' : props.barColor};
    border-radius: ${props => typeof props.barRadius === "number" ? `${props.barRadius}px` : props.barRadius};
    content: ' ';
    display: block;
    height: ${props => typeof props.barWidth === "number" ? `${props.barWidth}px` : props.barWidth};
    width: ${props => props.fill};
  }
  
  .unlimited-percent {
    position: absolute;
    right: 6px;
    top: 0;
  }
`;

export {
  StyledVerticalBarChart,
  StyledVerticalBarChartItem,
  StyledVerticalBarChartItemHeader,
  StyledVerticalBarChartItemLine
}