import React from 'react';
import PropTypes from 'prop-types';
import {Bar, BarChart as BChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis} from 'recharts';

const axisProps = {
  axisLine: false,
  fontSize: 12,
  tickLine: false
};

const BarChart = ({
  barBackgroundColor,
  barColor,
  barRadius,
  barWidth,
  data,
  height,
  isAnimationActive,
  width,
  ...rest
}) => {

  return (
    <div style={{ height }} {...rest}>
      <ResponsiveContainer height={height} width='100%'>
        <BChart
          width={width}
          height={height}
          data={data}
        >
          <XAxis
            {...axisProps}
            dataKey='date'
            dy={12}
          />
          <YAxis {...axisProps} />
          <CartesianGrid
            strokeDasharray='10'
            strokeWidth='0.5'
            vertical={false}
          />
          <Bar
            background={{fill: barBackgroundColor, radius: barRadius}}
            barSize={barWidth}
            radius={barRadius}
            dataKey='value'
            fill={barColor}
          />
        </BChart>
      </ResponsiveContainer>
    </div>
  );
}

BarChart.propTypes = {
  activeColor: PropTypes.string,
  barBackgroundColor: PropTypes.string,
  barColor: PropTypes.string,
  barRadius: PropTypes.number,
  barWidth: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  height: PropTypes.number,
  isAnimationActive: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

BarChart.defaultProps = {
  barBackgroundColor: '#F0F3FF',
  barColor: '#7A8AFF',
  barRadius: 4,
  barWidth: 24,
  data: [],
  isAnimationActive: true,
  height: 300,
  width: 500
}

export default BarChart;
