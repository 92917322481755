import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import WireDetailsModal from '../../TransactionsPage/WireDetailsModal';
import {StyledButton} from './StyledAddFundsDetails';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import {bankingActions, transactionActions} from '../../../../state/actions';
import {SCAActionsConstants} from '../../../../constants';
import {scaHelpers} from '../../../../scaHelpers';

const SCA_OPERATION_NAME = SCAActionsConstants.ADD_FUNDS_LOADING;

const AddFundsDetails = ({
  companyBankingUserId,
  details,
  getUserWallets,
  storeWireDetails
}) => {
  const [t] = useTranslation('main');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});

  const loadWalletDetails = () => {
    getUserWallets({
      headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
      id: companyBankingUserId,
      successCallback: (wallets) => {
        if (wallets.length > 0) {
          const wallet = wallets[0];
          storeWireDetails(wallet, true);
        } else {
          storeWireDetails(undefined);
        }
        setOpenDetailsModal(true);
      },
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps})
        });
      }
    });
  }

  const handleCloseModal = () => setOpenDetailsModal(false);

  const handleCloseAuthModal = () => setAuthWindowProps({...authWindowProps, open: false});

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    loadWalletDetails();
  }

  return (
    <>
      <StyledButton
        className='primary-outline'
        onClick={loadWalletDetails}
      >
        {t('add')} {t('funds')}
      </StyledButton>

      <WireDetailsModal
        details={details}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        open={openDetailsModal}
      />

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={SCA_OPERATION_NAME}
      />
    </>
  );
}


AddFundsDetails.propTypes = {
  details: PropTypes.shape({
    bic: PropTypes.string,
    iban: PropTypes.string
  })
}

AddFundsDetails.defaultProps = {
  details: {
    bic: null,
    iban: null
  }
}

const mapStateToProps = state => {
  const {companyBankingUserId} = state.transaction;

  return {
    companyBankingUserId
  }
}

const mapDispatchToProps = {
  getUserWallets: bankingActions.getUserWallets,
  storeWireDetails: transactionActions.storeWireDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFundsDetails);
