import styled from 'styled-components';

const StyledLoginPage = styled.div`
  a.ant-input-password-icon {
    font-size: 14px;
  }
`;

const StyledLoginPageImageContent = styled.div`
  background: ${props => props.backgroundImage ? `url(${props.backgroundImage}) top left repeat` : 'unset'};
  
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
  
  & > div {
    text-align: center;
  }

  &.step-2 {
    align-items: end;
    
    svg {
      max-width: 551px;
    }
  }
  
  .background-step-1 {
    position: absolute;
    svg {
      height: 100%;
      max-width: unset;
      width: 100%;
    }
  }
`;


export {
  StyledLoginPage,
  StyledLoginPageImageContent
}
