import React from 'react';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Button, Tooltip } from 'antd';
import {
  StyledCopyField,
  StyledCopyFieldContainer,
  StyledCopyFieldContent,
  StyledCopyFieldLabel
} from './StyledCopyField';
import CopyIcon from '../../icons/CopyIcon';
import {helpers} from '../../helpers';


const CopyField = ({
  enabledEmpty,
  emptyMessage,
  label,
  noCopiedLabel,
  onCopy,
  value,
  ...rest
}) => {
  const handleCopy = () => onCopy && onCopy(value);

  const textContent = noCopiedLabel ? `${noCopiedLabel} ${value}` : value;

  const isEmptyValue = helpers.isNaV(value) || value === '';

  const isEnabledCopy = enabledEmpty && !isEmptyValue;

  const displayedText = isEmptyValue ? enabledEmpty && emptyMessage ? emptyMessage : textContent : textContent;

  return (
    <StyledCopyFieldContainer {...rest}>
      <StyledCopyFieldContent>
        {label && (
          <StyledCopyFieldLabel>
            {label}
          </StyledCopyFieldLabel>
        )}
        <StyledCopyField>
          {displayedText}
        </StyledCopyField>
      </StyledCopyFieldContent>
      {isEnabledCopy && (
        <Tooltip title='Copy'>
          <CopyToClipboard text={value}>
            <Button
              type='text'
              icon={<CopyIcon />}
              onClick={handleCopy}
            />
          </CopyToClipboard>
        </Tooltip>
      )}
    </StyledCopyFieldContainer>
  );
}

CopyField.propTypes = {
  enabledEmpty: PropTypes.bool,
  emptyMessage: PropTypes.any,
  label: PropTypes.string,
  noCopiedLabel: PropTypes.string,
  value: PropTypes.string,
  onCopy: PropTypes.func
}

CopyField.defaultProps = {
  enabledEmpty: true
}

export default CopyField;
