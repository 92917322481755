import React from 'react';
import {Tooltip} from 'antd';
import './ImagePreviewTooltip.css';

const ImagePreviewTooltip = ({
  children,
  ...rest
}) => {

  return (
    <Tooltip
      arrow={false}
      overlayClassName='image-preview-tooltip-overlay'
      placement='left'
      {...rest}
    >
      {children}
    </Tooltip>
  );
}

export default ImagePreviewTooltip;
