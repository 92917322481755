import styled from 'styled-components';
import {Card} from 'antd';
import {typography} from '../../utils/css';

const StyledCard = styled(Card)`
  &.ant-card-bordered {
    border-color: ${props => props.theme.colors.neutral_4};
  }
  .ant-card-head {
    background-color: unset;
    border-color: ${props => props.theme.colors.neutral_4};
    padding: 0 16px;
  }
  .ant-card-head-title {
    ${typography.heading.h5};
    
    color: ${props => props.theme.colors.neutral_10};
  }
`;

export {
  StyledCard
};
