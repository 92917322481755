import styled from 'styled-components';
import {Space} from 'antd';
import AntdModal from '../../../AntdModal';

const StyledKeylessAuthModal = styled(AntdModal)`
  &.disabled {
    opacity: 1;
    .ant-modal-body {
      opacity: 0.4;
      cursor: unset;
      pointer-events: none;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 50px 24px 48px 24px;
      text-align: center;
    }
  }
  
  .result-container {
    padding: 0;
  }
;
`;

const StyledKeylessAuthModalSpace = styled(Space)`
  text-align: center;
  width: 100%;
  
  h3, p {
    max-width: 336px;
    margin: auto;
  }
  
  h3 {
    color: ${props => props.theme.colors.neutral_10};
    font-weight: 500;
    font-size: 24px;
    margin: auto;
  }
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }
  
  .ant-btn.ant-btn-lg {
    height: 48px;
    width: 100%;
  }
`;

export {
  StyledKeylessAuthModal,
  StyledKeylessAuthModalSpace
};
