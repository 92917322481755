import styled from 'styled-components';
import HeaderInformation from '../../../HeaderInformation';

const StyledSubscriptionInfo = styled(HeaderInformation)`
  p {
    margin-bottom: ${props => Boolean(props.enabledstatus) ? '4px' : 0};
  }
`;

export {
  StyledSubscriptionInfo
}