import React from 'react';

const InvoiceSuggestIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 8.075H16C16.2652 8.075 16.5196 7.96833 16.7071 7.77844C16.8946 7.58856 17 7.33103 17 7.0625C17 6.79397 16.8946 6.53643 16.7071 6.34655C16.5196 6.15667 16.2652 6.05 16 6.05H10C9.73478 6.05 9.48043 6.15667 9.29289 6.34655C9.10536 6.53643 9 6.79397 9 7.0625C9 7.33103 9.10536 7.58856 9.29289 7.77844C9.48043 7.96833 9.73478 8.075 10 8.075ZM8 12.125H13C13.2652 12.125 13.5196 12.0183 13.7071 11.8284C13.8946 11.6386 14 11.381 14 11.1125C14 10.844 13.8946 10.5864 13.7071 10.3966C13.5196 10.2067 13.2652 10.1 13 10.1H8C7.73478 10.1 7.48043 10.2067 7.29289 10.3966C7.10536 10.5864 7 10.844 7 11.1125C7 11.381 7.10536 11.6386 7.29289 11.8284C7.48043 12.0183 7.73478 12.125 8 12.125ZM8 16.175H11C11.2652 16.175 11.5196 16.0683 11.7071 15.8784C11.8946 15.6886 12 15.431 12 15.1625C12 14.894 11.8946 14.6364 11.7071 14.4466C11.5196 14.2567 11.2652 14.15 11 14.15H8C7.73478 14.15 7.48043 14.2567 7.29289 14.4466C7.10536 14.6364 7 14.894 7 15.1625C7 15.431 7.10536 15.6886 7.29289 15.8784C7.48043 16.0683 7.73478 16.175 8 16.175Z"
      fill="#66C0DD"/>
    <path
      d="M20 2H4C3.73478 2 3.48043 2.10667 3.29289 2.29655C3.10536 2.48643 3 2.74397 3 3.0125V21.2375C3 21.4255 3.05171 21.6098 3.14935 21.7698C3.24698 21.9298 3.38668 22.059 3.55279 22.1431C3.71889 22.2272 3.90484 22.2628 4.08981 22.2459C4.27477 22.229 4.45143 22.1603 4.6 22.0475L6.67 20.4781L8.73 22.0475C8.9031 22.1789 9.11363 22.25 9.33 22.25C9.54637 22.25 9.7569 22.1789 9.93 22.0475L12 20.4781L12.5126 20.8803C13.1161 21.3539 14 20.9239 14 20.1568C14 19.9091 13.9001 19.6718 13.7228 19.4987L12.6 18.4025C12.4269 18.2711 12.2164 18.2 12 18.2C11.7836 18.2 11.5731 18.2711 11.4 18.4025L9.33 19.9719L7.27 18.4025C7.0969 18.2711 6.88637 18.2 6.67 18.2C6.45363 18.2 6.2431 18.2711 6.07 18.4025L5 19.2125V4.025H19V9.01786C19 9.57014 19.4477 10.0179 20 10.0179C20.5523 10.0179 21 9.57014 21 9.01786V3.0125C21 2.74397 20.8946 2.48643 20.7071 2.29655C20.5196 2.10667 20.2652 2 20 2Z"
      fill="#66C0DD"/>
    <path
      d="M15.7941 11.1487C15.8084 11.1055 15.836 11.0678 15.873 11.0411C15.9099 11.0144 15.9544 11 16 11C16.0456 11 16.0901 11.0144 16.127 11.0411C16.164 11.0678 16.1916 11.1055 16.2059 11.1487L16.5928 12.3104C16.7658 12.8283 17.1717 13.2342 17.6896 13.4072L18.8513 13.7941C18.8945 13.8084 18.9322 13.836 18.9589 13.873C18.9856 13.9099 19 13.9544 19 14C19 14.0456 18.9856 14.0901 18.9589 14.127C18.9322 14.164 18.8945 14.1916 18.8513 14.2059L17.6896 14.5928C17.4341 14.6779 17.2021 14.8213 17.0117 15.0117C16.8213 15.2021 16.6779 15.4341 16.5928 15.6896L16.2059 16.8513C16.1916 16.8945 16.164 16.9322 16.127 16.9589C16.0901 16.9856 16.0456 17 16 17C15.9544 17 15.9099 16.9856 15.873 16.9589C15.836 16.9322 15.8084 16.8945 15.7941 16.8513L15.4072 15.6896C15.3221 15.4341 15.1787 15.2021 14.9883 15.0117C14.7979 14.8213 14.5659 14.6779 14.3104 14.5928L13.1487 14.2059C13.1055 14.1916 13.0678 14.164 13.0411 14.127C13.0144 14.0901 13 14.0456 13 14C13 13.9544 13.0144 13.9099 13.0411 13.873C13.0678 13.836 13.1055 13.8084 13.1487 13.7941L14.3104 13.4072C14.5659 13.3221 14.7979 13.1787 14.9883 12.9883C15.1787 12.7979 15.3221 12.5659 15.4072 12.3104L15.7941 11.1487Z"
      fill="#66C0DD"/>
    <path
      d="M21.5952 10.1098C21.6063 10.0777 21.6271 10.0499 21.6548 10.0302C21.6824 10.0106 21.7155 10 21.7494 10C21.7834 10 21.8165 10.0106 21.8441 10.0302C21.8718 10.0499 21.8926 10.0777 21.9037 10.1098L22.1943 10.9812C22.3238 11.3707 22.6289 11.6758 23.0186 11.8053L23.8902 12.0958C23.9223 12.1069 23.9501 12.1277 23.9697 12.1553C23.9894 12.183 24 12.2161 24 12.25C24 12.2839 23.9894 12.317 23.9697 12.3447C23.9501 12.3723 23.9223 12.3931 23.8902 12.4042L23.0186 12.6947C22.8266 12.7586 22.6521 12.8663 22.509 13.0094C22.3659 13.1524 22.2581 13.3269 22.1943 13.5188L21.9037 14.3902C21.8926 14.4223 21.8718 14.4501 21.8441 14.4698C21.8165 14.4894 21.7834 14.5 21.7494 14.5C21.7155 14.5 21.6824 14.4894 21.6548 14.4698C21.6271 14.4501 21.6063 14.4223 21.5952 14.3902L21.3046 13.5188C21.2407 13.3269 21.133 13.1524 20.9899 13.0094C20.8468 12.8663 20.6723 12.7586 20.4803 12.6947L19.6098 12.4042C19.5777 12.3931 19.5499 12.3723 19.5303 12.3447C19.5106 12.317 19.5 12.2839 19.5 12.25C19.5 12.2161 19.5106 12.183 19.5303 12.1553C19.5499 12.1277 19.5777 12.1069 19.6098 12.0958L20.4814 11.8053C20.8711 11.6758 21.1762 11.3707 21.3057 10.9812L21.5952 10.1098Z"
      fill="#66C0DD"/>
    <path
      d="M19.1028 15.367C19.2036 15.0834 19.6307 15.0834 19.7315 15.367L20.3227 17.0316C20.4529 17.3975 20.6721 17.73 20.9631 18.0027C21.2541 18.2754 21.6088 18.4807 21.9991 18.6026L23.7736 19.1569C24.0761 19.2514 24.0761 19.6519 23.7736 19.7464L21.9982 20.3007C21.6079 20.4227 21.2533 20.6283 20.9625 20.9011C20.6717 21.1739 20.4526 21.5065 20.3227 21.8725L19.7315 23.5362C19.7098 23.5983 19.6677 23.6524 19.6113 23.6908C19.5548 23.7291 19.4869 23.7497 19.4172 23.7497C19.3475 23.7497 19.2795 23.7291 19.2231 23.6908C19.1666 23.6524 19.1245 23.5983 19.1028 23.5362L18.5116 21.8716C18.3816 21.5058 18.1625 21.1734 17.8716 20.9007C17.5808 20.628 17.2263 20.4226 16.8361 20.3007L15.0607 19.7464C14.9945 19.726 14.9368 19.6865 14.8959 19.6336C14.855 19.5807 14.833 19.517 14.833 19.4516C14.833 19.3863 14.855 19.3226 14.8959 19.2696C14.9368 19.2167 14.9945 19.1772 15.0607 19.1569L16.8361 18.6026C17.2263 18.4806 17.5808 18.2752 17.8716 18.0025C18.1625 17.7299 18.3816 17.3974 18.5116 17.0316L19.1028 15.367Z"
      fill="#66C0DD"/>
  </svg>
);

export default InvoiceSuggestIcon;
