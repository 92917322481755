import styled from 'styled-components';
import {Space} from 'antd';
import {borders} from '../../../../utils/css';

const StyledUpcomingSpending = styled(Space)`
  width: 100%;
`;

const StyledUpcomingSpendingItem = styled(Space)`
  width: 100%;
  
  .date {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }
`;

const StyledUpcomingSpendingSpendItem = styled.div`
  ${borders.default};
  
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 12px 8px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .price {
    margin-left: 4px;
    text-wrap: nowrap;
  }
  
  .ant-space-item {
    word-break: break-word;
  }
`;

export {
  StyledUpcomingSpending,
  StyledUpcomingSpendingItem,
  StyledUpcomingSpendingSpendItem
}