import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Avatar, Space} from 'antd';
import {ChevronDownIcon} from '../../icons';
import {
  StyledNavigationBottomNavigation,
  StyledNavigationCompanyDetails,
  StyledNavigationDropdownContainer,
  StyledNavigationDropdown,
  StyledNavigationMenu,
  StyledNavigation
} from './StyledNavigation';
import DropdownNavigation from './DropdownNavigation';
import {helpers} from '../../helpers';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import './Navigation.css';
import {mainActions} from '../../state/actions';
import {KYCModalModeTypes} from '../../constants';
import {ReactComponent as WithLessIconLogo} from '../../static/images/withless-icon-logo.svg';
import {ReactComponent as WithLessLogo} from '../../static/images/withless-logo.svg';

const Navigation = ({
  company,
  dividedKeys,
  items,
  isOpenMenu,
  openKycWindow,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const menuItems = useMemo(() => {
    const topItems = items.filter(i => !dividedKeys.includes(i.key));
    const bottomItems = items.filter(i => dividedKeys.includes(i.key));
    let menuItems = [...topItems];
    if (Boolean(bottomItems)) {
      menuItems = [
        ...menuItems,
        {type: 'divider'},
        ...bottomItems
      ];
    }
    return menuItems;
  }, [dividedKeys, items]);

  useEffect(() => {
    if (isOpenMenu === collapsed) setCollapsed(!isOpenMenu);
  }, [isOpenMenu]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    helpers.updateMenuItems({location, items, selectedKeys, setSelectedKeys});
  }, [location, items]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCompanyChange = () => {
    navigate('/');
  }

  const onDropdownNavigationClick = () => {
    setIsOpenDropdown(false);
  }

  const handleMenuClick = (e) => {
    const {key} = e;
    const item = items.find(i => i.key === key);
    if (item) navigate(item.path);
    setSelectedKeys([key]);
  }

  const onClickOutside = (e) => {
    const parentSelector = '.ant-dropdown';
    if (!e.target.closest(parentSelector)) setIsOpenDropdown(false);
  }

  const collapsedClassName = collapsed ? 'collapsed' : '';

  const {logo, name} = company;

  const onAddCompany = () => {
    onDropdownNavigationClick();
    openKycWindow({mode: KYCModalModeTypes.COMPANY});
  }

  const getCompanyDetails = (avatarSize) => (
    <StyledNavigationCompanyDetails>
      <Avatar
        src={logo}
        size={avatarSize}
      >
        {helpers.getInitials(name)}
      </Avatar>
      <span className='navigation-company-name'>
        {name}
      </span>
    </StyledNavigationCompanyDetails>
  );

  return (
    <StyledNavigation {...rest}>
      <div>
        <StyledNavigationDropdownContainer
          className={collapsedClassName}
          ref={useOnClickOutside(onClickOutside, isOpenDropdown)}
        >
          <StyledNavigationDropdown
            className={collapsedClassName}
            disabled={collapsed}
            dropdownRender={() => (
              <DropdownNavigation
                onCompanyChange={onCompanyChange}
                onMenuClick={onDropdownNavigationClick}
                handleAddCompany={onAddCompany}
              />
            )}
            onClick={() => setIsOpenDropdown(!isOpenDropdown)}
            open={isOpenDropdown}
            overlayClassName='navigation-dropdown-overlay'
            trigger={['click']}
          >
            <Space className='d-flex justify-space-between'>
              {getCompanyDetails(24)}
              <ChevronDownIcon />
            </Space>
          </StyledNavigationDropdown>
        </StyledNavigationDropdownContainer>
        <StyledNavigationMenu
          inlineCollapsed={collapsed}
          items={menuItems}
          mode='inline'
          onClick={handleMenuClick}
          selectedKeys={selectedKeys}
        />
      </div>
      <StyledNavigationBottomNavigation>
        <Link to='/' className='d-inline-flex'>
          {collapsed ? <WithLessIconLogo /> : <WithLessLogo />}
        </Link>
      </StyledNavigationBottomNavigation>
    </StyledNavigation>
  );
}

Navigation.propTypes = {
  dividedKeys: PropTypes.array,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disable: PropTypes.bool,
      key: PropTypes.any.isRequired,
      icon: PropTypes.object,
      isUnread: PropTypes.bool,
      label: PropTypes.string.isRequired,
      path: PropTypes.string || null
    })
  ).isRequired
}

Navigation.defaultProps = {
  dividedKeys: [],
  items: []
}

const mapStateToProps = state => {
  const {company, main} = state;
  const {isOpenMenu} = main;
  return {
    company,
    isOpenMenu
  }
}

const mapDispatchToProps = {
  openKycWindow: mainActions.openKycWindow
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
