import styled from 'styled-components';
import {Drawer} from 'antd';

const StyledLightKYCModal = styled(Drawer)`
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    button {
      margin-inline-end: 0;
      margin-inline-start: 12px;
    }
  }
  .ant-drawer-title {
    font-weight: 500;
  }
  .ant-drawer-footer {
    display: flex;
    justify-content: end;
    padding: 16px 24px;
  }
  
  .ant-picker {
    width: 100%;
  }
`;

export {
  StyledLightKYCModal
};
