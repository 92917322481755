import React from 'react';

const BookIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
    d="M4.73333 24.2727V8.72727H12.5333C13.2229 8.72727 13.8842 9.00024 14.3718 9.48613C14.8594 9.97202 15.1333 10.631 15.1333 11.3182V24.2727H4.73333Z"
    fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd"
        d="M27.2667 7H19.4667C18.7939 7 18.1304 7.15608 17.5287 7.45588C16.927 7.75568 16.4036 8.19097 16 8.72727C15.5964 8.19097 15.073 7.75568 14.4713 7.45588C13.8696 7.15608 13.2061 7 12.5333 7H4.73333C4.27362 7 3.83274 7.18198 3.50768 7.50591C3.18262 7.82983 3 8.26917 3 8.72727V24.2727C3 24.7308 3.18262 25.1702 3.50768 25.4941C3.83274 25.818 4.27362 26 4.73333 26H15.1333C15.4385 25.9968 15.7375 25.9134 16 25.7582C16.2625 25.9134 16.5615 25.9968 16.8667 26H27.2667C27.7264 26 28.1673 25.818 28.4923 25.4941C28.8174 25.1702 29 24.7308 29 24.2727V8.72727C29 8.26917 28.8174 7.82983 28.4923 7.50591C28.1673 7.18198 27.7264 7 27.2667 7ZM4.73333 8.72727V24.2727H15.1333V11.3182C15.1333 10.631 14.8594 9.97202 14.3718 9.48613C13.8842 9.00024 13.2229 8.72727 12.5333 8.72727H4.73333ZM16.8667 24.2727H27.2667V8.72727H19.4667C18.7771 8.72727 18.1158 9.00024 17.6282 9.48613C17.1406 9.97202 16.8667 10.631 16.8667 11.3182V24.2727Z"
        fill="white"/>
    <path
    d="M27.2667 24.2727H16.8667V11.3182C16.8667 10.631 17.1406 9.97202 17.6282 9.48613C18.1158 9.00024 18.7771 8.72727 19.4667 8.72727H27.2667V24.2727Z"
    fill="white"/>
    <path
    d="M27.2667 7H19.4667C18.7939 7 18.1304 7.15608 17.5287 7.45588C16.927 7.75568 16.4036 8.19097 16 8.72727C15.5964 8.19097 15.073 7.75568 14.4713 7.45588C13.8696 7.15608 13.2061 7 12.5333 7H4.73333C4.27362 7 3.83274 7.18198 3.50768 7.50591C3.18262 7.82983 3 8.26917 3 8.72727V24.2727C3 24.7308 3.18262 25.1702 3.50768 25.4941C3.83274 25.818 4.27362 26 4.73333 26H15.1333C15.4385 25.9968 15.7375 25.9134 16 25.7582C16.2625 25.9134 16.5615 25.9968 16.8667 26H27.2667C27.7264 26 28.1673 25.818 28.4923 25.4941C28.8174 25.1702 29 24.7308 29 24.2727V8.72727C29 8.26917 28.8174 7.82983 28.4923 7.50591C28.1673 7.18198 27.7264 7 27.2667 7ZM4.73333 24.2727V8.72727H12.5333C13.2229 8.72727 13.8842 9.00024 14.3718 9.48613C14.8594 9.97202 15.1333 10.631 15.1333 11.3182V24.2727H4.73333ZM27.2667 24.2727H16.8667V11.3182C16.8667 10.631 17.1406 9.97202 17.6282 9.48613C18.1158 9.00024 18.7771 8.72727 19.4667 8.72727H27.2667V24.2727Z"
    fill="#BFBFBF"/>
    <path
    d="M12.5335 12.1836H7.33346C7.10361 12.1836 6.88317 12.2746 6.72064 12.4365C6.55811 12.5985 6.4668 12.8182 6.4668 13.0472C6.4668 13.2763 6.55811 13.4959 6.72064 13.6579C6.88317 13.8199 7.10361 13.9109 7.33346 13.9109H12.5335C12.7633 13.9109 12.9838 13.8199 13.1463 13.6579C13.3088 13.4959 13.4001 13.2763 13.4001 13.0472C13.4001 12.8182 13.3088 12.5985 13.1463 12.4365C12.9838 12.2746 12.7633 12.1836 12.5335 12.1836ZM12.5335 15.6381H7.33346C7.10361 15.6381 6.88317 15.7291 6.72064 15.8911C6.55811 16.0531 6.4668 16.2727 6.4668 16.5018C6.4668 16.7308 6.55811 16.9505 6.72064 17.1125C6.88317 17.2744 7.10361 17.3654 7.33346 17.3654H12.5335C12.7633 17.3654 12.9838 17.2744 13.1463 17.1125C13.3088 16.9505 13.4001 16.7308 13.4001 16.5018C13.4001 16.2727 13.3088 16.0531 13.1463 15.8911C12.9838 15.7291 12.7633 15.6381 12.5335 15.6381ZM12.5335 19.0927H7.33346C7.10361 19.0927 6.88317 19.1837 6.72064 19.3456C6.55811 19.5076 6.4668 19.7273 6.4668 19.9563C6.4668 20.1854 6.55811 20.405 6.72064 20.567C6.88317 20.729 7.10361 20.82 7.33346 20.82H12.5335C12.7633 20.82 12.9838 20.729 13.1463 20.567C13.3088 20.405 13.4001 20.1854 13.4001 19.9563C13.4001 19.7273 13.3088 19.5076 13.1463 19.3456C12.9838 19.1837 12.7633 19.0927 12.5335 19.0927Z"
    fill="#BFBFBF"/>
    <path
    d="M18.5999 13.0472C18.5999 13.2763 18.6912 13.4959 18.8537 13.6579C19.0162 13.8199 19.2367 13.9109 19.4665 13.9109H24.6665C24.8964 13.9109 25.1168 13.8199 25.2793 13.6579C25.4419 13.4959 25.5332 13.2763 25.5332 13.0472C25.5332 12.8182 25.4419 12.5985 25.2793 12.4365C25.1168 12.2746 24.8964 12.1836 24.6665 12.1836H19.4665C19.2367 12.1836 19.0162 12.2746 18.8537 12.4365C18.6912 12.5985 18.5999 12.8182 18.5999 13.0472ZM24.6665 15.6381H19.4665C19.2367 15.6381 19.0162 15.7291 18.8537 15.8911C18.6912 16.0531 18.5999 16.2727 18.5999 16.5018C18.5999 16.7308 18.6912 16.9505 18.8537 17.1125C19.0162 17.2744 19.2367 17.3654 19.4665 17.3654H24.6665C24.8964 17.3654 25.1168 17.2744 25.2793 17.1125C25.4419 16.9505 25.5332 16.7308 25.5332 16.5018C25.5332 16.2727 25.4419 16.0531 25.2793 15.8911C25.1168 15.7291 24.8964 15.6381 24.6665 15.6381ZM24.6665 19.0927H19.4665C19.2367 19.0927 19.0162 19.1837 18.8537 19.3456C18.6912 19.5076 18.5999 19.7273 18.5999 19.9563C18.5999 20.1854 18.6912 20.405 18.8537 20.567C19.0162 20.729 19.2367 20.82 19.4665 20.82H24.6665C24.8964 20.82 25.1168 20.729 25.2793 20.567C25.4419 20.405 25.5332 20.1854 25.5332 19.9563C25.5332 19.7273 25.4419 19.5076 25.2793 19.3456C25.1168 19.1837 24.8964 19.0927 24.6665 19.0927Z"
    fill="#BFBFBF"/>
  </svg>
);

export default BookIcon;
