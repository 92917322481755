import styled from 'styled-components';

const StyledUsage = styled.div`
  display: flex;
  align-items: center;
  height: 14px;
`;

const StyledUsageDot = styled.span`
  display: inline-block;
  width: 6px;
  height: 4px;
  background-color: ${props => props.color};
  border-radius: 5px;

  &:not(:last-of-type) {
    margin-right: 2px;
  }
`;

export {StyledUsage, StyledUsageDot};
