import React from 'react';

const FolderFilledIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M1.99935 2C1.26297 2 0.666016 2.59695 0.666016 3.33333V13.3333C0.666016 14.0697 1.26298 14.6667 1.99935 14.6667H13.9993C14.7357 14.6667 15.3327 14.0697 15.3327 13.3333V5.33333C15.3327 4.59694 14.7357 4 13.9993 4H8.3116L6.84483 2.23988C6.71817 2.08788 6.53054 2 6.33268 2H1.99935Z"
          fill="#07132B" />
  </svg>
);

export default FolderFilledIcon;
