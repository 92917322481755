import styled from 'styled-components';
import {Space} from 'antd';

const StyledTableFilters = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledFilterElements = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledRightSideContent = styled.div`
`;

const StyledFilterElementsSpace = styled(Space).withConfig({
  shouldForwardProp: (prop) => !['enabledRightSideContent'].includes(prop),
})`
  width: 100%;
  flex-wrap: wrap;
  & > .ant-space-item {
    &:last-of-type {
      margin-left: ${props => props.enabledRightSideContent ? 'auto' : 'unset'};
      margin-right: ${props => props.enabledRightSideContent ? 0 : '8px'};
    }
  }
  
  .ant-select {
    &.ant-select-lg {
      .ant-select-selector {
        min-width: 210px;
        &::after {
          line-height: 30px;
        }
      }
    }
    &.table-filters__cards-select {
      min-width: 264px;
      
      .ant-select-selection-item-content {
        & > .ant-space {
          & > .ant-space-item {
            &:nth-of-type(1) {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export {
  StyledTableFilters,
  StyledFilterElements,
  StyledFilterElementsSpace,
  StyledRightSideContent
};
