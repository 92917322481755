const cardStatusesConstants = Object.freeze({
  ACTIVE: 0,
  PENDING: 1,
  FROZEN: 2,
  TERMINATED: 3
});

const cardBackendStatusesConstants = Object.freeze({
  UNLOCK: 0,
  LOCK: 1,
  LOST: 2,
  STOLEN: 3,
  DESTROYED: 4
});

const cardDigitalizationStatusConstants = Object.freeze({
  ACTIVE: 'A',
  SUSPENDED: 'S',
  UNMAPPED: 'U'
});

const cardTypesConstants = Object.freeze({
  FOR_PERSONAL_USE: 'users',
  FOR_SUBSCRIPTION: 'subscriptions'
});

const cardVerificationLightStatusesConstants = Object.freeze({
  NONE: 'light_none',
  PENDING: 'light_pending',
  PENDING_FILE_UPLOADED: 'light_pending_file_uploaded',
  VALIDATED: 'light_validated',
  REFUSED: 'light_refused'
});

const bankingUserTypesConstants = Object.freeze({
  EMPLOYEE: 'employee',
  LEGAL_PERSON: 'legal_person',
  LEGAL_REPRESENTATIVE: 'legal_representative',
  ULTIMATE_BENEFICIARY_OWNER: 'ultimate_beneficiary_owner'
});

const bankingDocumentTypes = Object.freeze({
  COMPANY_REGISTRATION: 4,
  IDENTIFY_CARD: 9,
  DRIVING_LICENSE: 16,
  PASSPORT: 17,
  TAX_STATEMENT: 24
});

const cardLimitPeriodConstants = Object.freeze({
  ALL: 'all',
  MONTH: 'month',
  YEAR: 'year'
});

const cardLimitTypesList = [
  'limit_payment_all',
  'limit_payment_month',
  'limit_payment_year',
];

export {
  bankingDocumentTypes,
  bankingUserTypesConstants,
  cardBackendStatusesConstants,
  cardDigitalizationStatusConstants,
  cardLimitPeriodConstants,
  cardLimitTypesList,
  cardStatusesConstants,
  cardTypesConstants,
  cardVerificationLightStatusesConstants,
};

