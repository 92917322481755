import styled from 'styled-components';
import {Collapse} from 'antd';
import {borders, typography} from '../../../../utils/css';

const StyledVariations = styled(Collapse)`
  width: 100%;
  
  .ant-collapse-item {
    ${borders.default};
    ${typography.footnote.description};
    
    background-color: ${props => props.theme.colors.neutral_1};
    padding: 12px 8px;
    transition: .2s ease-in-out;
    
    &.below-price {
      .ant-collapse-header {
        & > .ant-collapse-expand-icon {
          color: ${props => props.theme.colors.functional_success};
        }
      }
    }
    
    &.over-price {
      .ant-collapse-header {
        & > .ant-collapse-expand-icon {
          color: ${props => props.theme.colors.functional_danger};
        }
      }
    }
    
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
    
    &:last-child {
      ${borders.default};
    }
    
    &:active,
    &:focus,
    &:hover {
      border-color: ${props => props.theme.colors.primary_6};
      box-shadow: 0 4px 24px 0 #005E421A;
    }
    
    &.ant-collapse-item-active {
      border-color: ${props => props.theme.colors.primary_6};
      box-shadow: 0 4px 24px 0 #005E421A;
    }
    
    .ant-collapse-header {
      padding: 0;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      font-size: 12px;
      
      & > .ant-collapse-expand-icon {
        padding-inline-end: 0;
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 8px 0 0 0;
      }
    }
  }
  
  .price {
    color: unset;
    margin-left: 4px;
    text-wrap: nowrap;
    &.below-price {
      color: ${props => props.theme.colors.functional_success};
    }
    &.over-price {
      color: ${props => props.theme.colors.functional_danger};
    }
  }
`;

const StyledVariationsItemDescription = styled.div`
  ${typography.footnote.description};
  line-height: 16px;

  .ant-space,
  .ant-space-item {
    width: 100%;
  }
  
  .ant-space {
    padding: 0 16px 0 34px;
  }
  
  button {
    height: 30px;
    font-size: 14px;
    margin-top: 12px;
    padding: 3px 15px;
    text-align: center;
    width: 100%;
  }
`;

const StyledVariationsItemLabel = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  
  .ant-space-item {
    display: flex;
    word-break: break-word;
  }
`;

const StyledVariationsItemLabelDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 130px;
`;

const StyledVariationsItemLabelDetailsDescription = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 1;
  
  svg[width="25"] {
    width: 25px;
  }
  
  .ant-space {
    justify-content: end;
    width: 100%;
    .ant-space-item {
      line-height: 16px;
    }
  }

  svg + .ant-space {
    width: unset;
  }
`;

const StyledVariationsItemLabelDetailsTitle = styled.div`
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  padding-right: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledVariationsItemDescriptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export {
  StyledVariations,
  StyledVariationsItemDescription,
  StyledVariationsItemDescriptionItem,
  StyledVariationsItemLabel,
  StyledVariationsItemLabelDetails,
  StyledVariationsItemLabelDetailsDescription,
  StyledVariationsItemLabelDetailsTitle,
}