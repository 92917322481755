import styled from 'styled-components';
import {Space} from 'antd';

const StyledCardPaymentType = styled(Space)`
  height: 32px;
  display: flex;
  svg {
    height: 24px;
    width: 24px;
  }
  
  .ant-space-item {
    align-items: center;
    display: flex;
    height: 32px;
  }
  
`;

const StyledCardPaymentTypeCardIconSpace = styled(Space)`
  display: flex;
  & .ant-space-item {
    display: flex;
  }
  
  &.for-personal {
    &.warning {
      path {
        &:last-of-type {
          fill: ${props => props.theme.colors.additional_apple};
        }
      }
    }
    &:not(.warning) {
      path {
        &:first-of-type {
          fill: ${props => props.theme.colors.additional_apple};
        }
      }
    }
  }
  
  &.for-subscription {
    &.warning {
      svg {
        path {
          &:last-of-type {
            fill: ${props => props.theme.colors.primary_6};
          }
        }
      }
    }
    &:not(.warning) {
      svg {
        path {
          &:first-of-type {
            fill: ${props => props.theme.colors.primary_6};
          }
        }
      }
    }
  }
`;

const StyledCardPaymentTypeEyeIconSpace = styled(Space)`
  svg {
    path {
      fill: ${props => props.theme.colors.primary_7};
      transition: .2s ease;
    }
    &:hover {
      path {
        fill: ${props => props.theme.colors.primary_5};
        transition: .2s ease;
      }
    }
  }
`;

export {
  StyledCardPaymentType,
  StyledCardPaymentTypeCardIconSpace,
  StyledCardPaymentTypeEyeIconSpace
};
