import React from 'react';

const MenuCollapseIcon = () => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 1H8M17 5H1M1 5L4 2M1 5L4 8M17 9H8" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>
);

export default MenuCollapseIcon;
