import styled from 'styled-components';
import {Select} from 'antd';

const StyledCountriesSelect = styled(Select)`
  .ant-select-selection-item {
    .ant-space-item {
      display: flex;
    }
  }
`;

export {StyledCountriesSelect};
