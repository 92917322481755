import styled from 'styled-components';
import {Space} from 'antd';

const StyledSubscriptionTabContainer = styled(Space)`
  position: relative;
  width: 100%;
`;

export {
  StyledSubscriptionTabContainer,
};
