import styled from 'styled-components';
import {Form, Space} from 'antd';

const StyledSubscriptionTableEditableCell = styled.td`
  
`;

const StyledSubscriptionTableSelectOptionSpace = styled(Space)`
  .ant-space-item {
    display: flex;
  }
`;

const StyledSubscriptionTableEditableCellFormItem = styled(Form.Item)`
  .owner-select {
    margin: auto;
  }
`;


export {
  StyledSubscriptionTableEditableCell,
  StyledSubscriptionTableSelectOptionSpace,
  StyledSubscriptionTableEditableCellFormItem
}
