import { useEffect, useRef, useCallback } from 'react';

const useOnClickOutside = (onClickOutside, isOpen, parentSelector = undefined, isAntdElement = false) => {
  const myRef = useRef();
  const handleClickOutside = useCallback(e => {
    const isFeedbackClose = e.target.classList.contains('feedback-close');
    const isDialogModal = isAntdElement ? false : e.target.closest('div[role="dialog"]');
    const isAvailableClick = onClickOutside && myRef.current && !isFeedbackClose && !isDialogModal;
    if (parentSelector) {
      if (isAvailableClick && !e.target.closest(parentSelector)) {
        onClickOutside(e);
      }
    } else {
      if (isAvailableClick && !myRef.current.contains(e.target)) {
        onClickOutside(e);
      }
    }
  }, [myRef, onClickOutside]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    isOpen && document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isOpen, handleClickOutside]);
  return myRef;
};

export default useOnClickOutside;
