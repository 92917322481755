const backendWebsocketActionsConstants = Object.freeze({
  AUTH: 'auth',
  CARD_ACTIVATE: 'card_activate',
  CARD_CREATE: 'card_create',
  CARD_CREATE_ERROR: 'card_create_error'
});

const bankingWebsocketActionsConstants = Object.freeze({
  AUTH: 'auth',
  // enroll actions
  KEYLESS_ENROLL: 'keylessEnroll',
  KEYLESS_ENROLLMENT_SUCCESS: 'keylessEnrollmentSuccess',
  KEYLESS_ENROLLMENT_FAILURE: 'keylessEnrollmentFailure',
  KEYLESS_ENROLL_SMS_VERIFICATION_FAILURE: 'keylessEnrollSMSVerificationFailure',
  // authenticate actions
  KEYLESS_AUTHENTICATE: 'keylessAuthenticate',
  KEYLESS_AUTHENTICATE_SUCCESS: 'keylessChallengeAuthenticationSuccess',
  KEYLESS_AUTHENTICATE_FAILED: 'keylessChallengeAuthenticationFailed'
});

export {
  backendWebsocketActionsConstants,
  bankingWebsocketActionsConstants
};
