import React  from 'react';
import PropTypes from 'prop-types';
import {
  StyledCreditCardDetails,
} from './StyledCreditCardDetails';
import {helpers} from '../../../../helpers';
import {WithLessCreditCardIcon} from '../../../../icons';


const CreditCardDetails = ({
  cardNumberClassName,
  cardNumber,
  ...rest
}) => {

  return (
    <StyledCreditCardDetails
      {...rest}
      align='center'
      size='small'
    >
      <WithLessCreditCardIcon />
      <span className={cardNumberClassName}>{helpers.hideCardNumber(cardNumber)}</span>
    </StyledCreditCardDetails>
  );
}

CreditCardDetails.propTypes = {
  cardNumberClassName: PropTypes.string,
  cardNumber: PropTypes.string
}

CreditCardDetails.defaultProps = {
  cardNumberClassName: ''
}

export default CreditCardDetails;
