import styled from 'styled-components';
import {Select} from 'antd';

const StyledTagSelect = styled(Select)`
  .ant-select-selection-item-content {
    .ant-space-item {
      &:first-of-type {
        display: none;
      }
    }
  }
`;

export {
  StyledTagSelect
}