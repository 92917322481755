import React from 'react';

const FAQIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M23.0207 23.0821C21.143 24.9411 18.6519 25.9643 16.0002 25.9643H15.9546C14.3394 25.9563 12.735 25.5545 11.318 24.7991L11.093 24.6786H7.32158V20.9071L7.20105 20.6821C6.44569 19.2652 6.0439 17.6607 6.03587 16.0455C6.02515 13.375 7.04569 10.8679 8.91801 8.97946C10.7877 7.09107 13.2868 6.04643 15.9573 6.03571H16.0028C17.3421 6.03571 18.6412 6.29554 19.8653 6.80982C21.06 7.31071 22.1314 8.03125 23.0528 8.95268C23.9716 9.87143 24.6948 10.9455 25.1957 12.1402C25.7153 13.3777 25.9752 14.6902 25.9698 16.0455C25.9537 18.7134 24.9064 21.2125 23.0207 23.0821ZM15.091 16.9091C14.8499 16.668 14.7144 16.341 14.7144 16C14.7144 15.659 14.8499 15.332 15.091 15.0909C15.3321 14.8497 15.6592 14.7143 16.0002 14.7143C16.3411 14.7143 16.6682 14.8497 16.9093 15.0909C17.1504 15.332 17.2859 15.659 17.2859 16C17.2859 16.341 17.1504 16.668 16.9093 16.9091C16.6682 17.1503 16.3411 17.2857 16.0002 17.2857C15.6592 17.2857 15.3321 17.1503 15.091 16.9091ZM20.4482 16.9091C20.207 16.668 20.0716 16.341 20.0716 16C20.0716 15.659 20.207 15.332 20.4482 15.0909C20.6893 14.8497 21.0163 14.7143 21.3573 14.7143C21.6983 14.7143 22.0253 14.8497 22.2664 15.0909C22.5075 15.332 22.643 15.659 22.643 16C22.643 16.341 22.5075 16.668 22.2664 16.9091C22.0253 17.1503 21.6983 17.2857 21.3573 17.2857C21.0163 17.2857 20.6893 17.1503 20.4482 16.9091ZM9.73387 16.9091C9.49275 16.668 9.3573 16.341 9.3573 16C9.3573 15.659 9.49275 15.332 9.73387 15.0909C9.97499 14.8497 10.302 14.7143 10.643 14.7143C10.984 14.7143 11.311 14.8497 11.5521 15.0909C11.7933 15.332 11.9287 15.659 11.9287 16C11.9287 16.341 11.7933 16.668 11.5521 16.9091C11.311 17.1503 10.984 17.2857 10.643 17.2857C10.302 17.2857 9.97499 17.1503 9.73387 16.9091Z"
          fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M15.091 16.9091C14.8499 16.668 14.7144 16.341 14.7144 16C14.7144 15.659 14.8499 15.332 15.091 15.0909C15.3321 14.8497 15.6592 14.7143 16.0002 14.7143C16.3411 14.7143 16.6682 14.8497 16.9093 15.0909C17.1504 15.332 17.2859 15.659 17.2859 16C17.2859 16.341 17.1504 16.668 16.9093 16.9091C16.6682 17.1503 16.3411 17.2857 16.0002 17.2857C15.6592 17.2857 15.3321 17.1503 15.091 16.9091ZM20.4482 16.9091C20.207 16.668 20.0716 16.341 20.0716 16C20.0716 15.659 20.207 15.332 20.4482 15.0909C20.6893 14.8497 21.0163 14.7143 21.3573 14.7143C21.6983 14.7143 22.0253 14.8497 22.2664 15.0909C22.5075 15.332 22.643 15.659 22.643 16C22.643 16.341 22.5075 16.668 22.2664 16.9091C22.0253 17.1503 21.6983 17.2857 21.3573 17.2857C21.0163 17.2857 20.6893 17.1503 20.4482 16.9091ZM9.73387 16.9091C9.49275 16.668 9.3573 16.341 9.3573 16C9.3573 15.659 9.49275 15.332 9.73387 15.0909C9.97499 14.8497 10.302 14.7143 10.643 14.7143C10.984 14.7143 11.311 14.8497 11.5521 15.0909C11.7933 15.332 11.9287 15.659 11.9287 16C11.9287 16.341 11.7933 16.668 11.5521 16.9091C11.311 17.1503 10.984 17.2857 10.643 17.2857C10.302 17.2857 9.97499 17.1503 9.73387 16.9091Z"
          fill="white"/>
    <path
      d="M27.068 11.35C26.4627 9.91161 25.5948 8.62054 24.4885 7.51161C23.39 6.4091 22.086 5.53274 20.6502 4.93214C19.1769 4.31339 17.6127 4 16.0002 4H15.9466C14.3234 4.00804 12.751 4.32946 11.2725 4.96161C9.84897 5.56836 8.55711 6.44628 7.4689 7.54643C6.37337 8.65268 5.51354 9.93839 4.9189 11.3714C4.30283 12.8554 3.99212 14.433 4.00015 16.0562C4.00924 17.9164 4.44933 19.7492 5.28587 21.4107V25.4821C5.28587 25.8089 5.41568 26.1223 5.64675 26.3534C5.87782 26.5845 6.19122 26.7143 6.51801 26.7143H10.5921C12.2536 27.5508 14.0864 27.9909 15.9466 28H16.0028C17.6073 28 19.1635 27.6893 20.6287 27.0813C22.0573 26.4878 23.3565 25.6217 24.4537 24.5312C25.56 23.4357 26.4305 22.1554 27.0385 20.7277C27.6707 19.2491 27.9921 17.6768 28.0002 16.0536C28.0082 14.4223 27.6921 12.8393 27.068 11.35ZM23.0207 23.0821C21.143 24.9411 18.6519 25.9643 16.0002 25.9643H15.9546C14.3394 25.9563 12.735 25.5545 11.318 24.7991L11.093 24.6786H7.32158V20.9071L7.20105 20.6821C6.44569 19.2652 6.0439 17.6607 6.03587 16.0455C6.02515 13.375 7.04569 10.8679 8.91801 8.97946C10.7877 7.09107 13.2868 6.04643 15.9573 6.03571H16.0028C17.3421 6.03571 18.6412 6.29554 19.8653 6.80982C21.06 7.31071 22.1314 8.03125 23.0528 8.95268C23.9716 9.87143 24.6948 10.9455 25.1957 12.1402C25.7153 13.3777 25.9752 14.6902 25.9698 16.0455C25.9537 18.7134 24.9064 21.2125 23.0207 23.0821Z"
      fill="#BFBFBF"/>
    <path
      d="M14.7883 17.276C14.7043 17.836 15.1803 18.298 15.7463 18.298H16.2163C16.3498 18.298 16.4783 18.2471 16.5757 18.1557C16.673 18.0643 16.7319 17.9393 16.7403 17.806C16.7973 17.28 17.0233 16.82 17.4163 16.427L18.0463 15.807C18.5383 15.312 18.8823 14.863 19.0793 14.46C19.2763 14.051 19.3743 13.618 19.3743 13.161C19.3743 12.156 19.0713 11.379 18.4653 10.831C17.8593 10.277 17.0073 10 15.9093 10C14.8213 10 13.9623 10.29 13.3293 10.871C12.9546 11.2185 12.6806 11.6608 12.5363 12.151C12.3523 12.755 12.8963 13.282 13.5263 13.282C14.0603 13.282 14.4593 12.847 14.8303 12.442C14.8823 12.385 14.9333 12.328 14.9843 12.274C15.2183 12.027 15.5263 11.904 15.9093 11.904C16.7173 11.904 17.1213 12.358 17.1213 13.266C17.1213 13.567 17.0433 13.855 16.8883 14.129C16.7333 14.398 16.4193 14.75 15.9483 15.185C15.4823 15.615 15.1613 16.054 14.9843 16.5C14.8973 16.721 14.8323 16.98 14.7883 17.276Z"
      fill="#BFBFBF"/>
    <path
      d="M14.8453 19.887C14.6123 20.118 14.4953 20.414 14.4953 20.774C14.4953 21.129 14.6093 21.422 14.8373 21.654C15.0703 21.884 15.3763 22 15.7543 22C16.1323 22 16.4343 21.884 16.6633 21.653C16.8963 21.422 17.0133 21.129 17.0133 20.774C17.0133 20.414 16.8933 20.118 16.6553 19.887C16.4223 19.65 16.1213 19.532 15.7543 19.532C15.3863 19.532 15.0843 19.65 14.8453 19.887Z"
      fill="#BFBFBF"/>
  </svg>
);

export default FAQIcon;
