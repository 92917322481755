import React from 'react';

const TeamsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33268 4.33301C3.33268 2.67616 4.67583 1.33301 6.33268 1.33301C7.98954 1.33301 9.33268 2.67616 9.33268 4.33301C9.33268 5.98986 7.98954 7.33301 6.33268 7.33301C4.67583 7.33301 3.33268 5.98986 3.33268 4.33301Z"
      fill="#262626"/>
    <path
      d="M11.2127 1.76193C10.8973 1.5719 10.4876 1.67351 10.2976 1.98887C10.1075 2.30423 10.2092 2.71393 10.5245 2.90396C11.0103 3.19665 11.3327 3.72748 11.3327 4.33294C11.3327 4.93841 11.0103 5.46924 10.5245 5.76193C10.2092 5.95196 10.1075 6.36166 10.2976 6.67702C10.4876 6.99238 10.8973 7.09399 11.2127 6.90396C12.0821 6.38005 12.6661 5.42501 12.6661 4.33294C12.6661 3.24088 12.0821 2.28584 11.2127 1.76193Z"
      fill="#262626"/>
    <path
      d="M7.09455 8.66634H5.57082C4.8483 8.66634 4.26552 8.66633 3.79361 8.70489C3.30771 8.74458 2.88091 8.82846 2.48605 9.02964C1.85883 9.34923 1.3489 9.85918 1.02933 10.4864C0.828137 10.8812 0.744262 11.308 0.704562 11.7939C0.666005 12.2658 0.66601 12.8486 0.666016 13.5711V13.9997C0.666016 14.3679 0.964493 14.6663 1.33268 14.6663H11.3327C11.7009 14.6663 11.9993 14.3679 11.9993 13.9997V13.5711C11.9994 12.8486 11.9994 12.2658 11.9608 11.7939C11.9211 11.308 11.8372 10.8812 11.6361 10.4864C11.3165 9.85917 10.8065 9.34921 10.1793 9.02963C9.78444 8.82845 9.35765 8.74458 8.87176 8.70489C8.39984 8.66633 7.81707 8.66634 7.09455 8.66634Z"
      fill="#262626"/>
    <path
      d="M13.5126 9.02967C13.1845 8.86252 12.7831 8.99296 12.6159 9.32101C12.4488 9.64907 12.5792 10.0505 12.9073 10.2177C13.2836 10.4094 13.5896 10.7154 13.7813 11.0917C13.8708 11.2673 13.9322 11.4991 13.9652 11.9025C13.9988 12.3138 13.9993 12.842 13.9993 13.5997V13.9997C13.9993 14.3679 14.2978 14.6664 14.666 14.6664C15.0342 14.6664 15.3326 14.3679 15.3326 13.9997V13.5712C15.3326 12.8487 15.3327 12.2659 15.2941 11.794C15.2544 11.3081 15.1705 10.8813 14.9693 10.4864C14.6498 9.85921 14.1398 9.34926 13.5126 9.02967Z"
      fill="#262626"/>
  </svg>
);

export default TeamsIcon;
