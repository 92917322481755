import {cardConstants} from '../constants';

const cardState = {
  storedCards: null,
};

const cardReducer = (state = cardState, action) => {
  const {type, data} = action;
  switch (type) {
    case cardConstants.STORE_CARDS:
      return {
        ...state,
        storedCards: data
      }
    default:
      return state;
  }
};

export default cardReducer;
