import React from 'react';

const CheckmarkCheckedCircleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.9995" cy="16.001" r="11.8" stroke="#000" strokeWidth="2.4"/>
    <path d="M16.3141 10C15.9996 10 15.698 10.1249 15.4756 10.3473C15.2532 10.5697 15.1282 10.8714 15.1282 11.1859V16.6291L11.9145 19.8309C11.7904 19.9372 11.6896 20.0681 11.6184 20.2152C11.5472 20.3623 11.5072 20.5226 11.5009 20.6859C11.4946 20.8492 11.5221 21.0121 11.5817 21.1642C11.6413 21.3164 11.7318 21.4546 11.8473 21.5702C11.9629 21.6858 12.1011 21.7762 12.2533 21.8358C12.4055 21.8954 12.5683 21.923 12.7316 21.9166C12.895 21.9103 13.0552 21.8703 13.2023 21.7992C13.3495 21.728 13.4803 21.6272 13.5866 21.503L17.1442 17.9454C17.3677 17.7264 17.4955 17.4281 17.5 17.1153V11.1859C17.5 10.8714 17.3751 10.5697 17.1527 10.3473C16.9303 10.1249 16.6286 10 16.3141 10Z" fill="#000"/>
  </svg>
);

export default CheckmarkCheckedCircleIcon;
