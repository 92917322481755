import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {StyledUserRoleSelectContainer, StyledUserRoleSelect} from './StyledUserRoleSelect';
import {userRolesConstants} from '../../../../../constants';

const {ADMIN, SUPPORT, USER} = userRolesConstants;

const UserRoleSelect = ({
  dispatch,
  defaultValue,
  email,
  editorEmail,
  onChange,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [value, setValue] = useState(defaultValue || USER);

  const options = useMemo(() => {
    let options = [
      {label: t('userRoles.admin'), value: ADMIN},
      {label: t('userRoles.user'), value: USER},
      {label: t('userRoles.support'), value: SUPPORT},
    ];
    if (defaultValue !== SUPPORT) options = options.filter(o => o.value !== SUPPORT);
    return options;
  }, [defaultValue, t]);

  const hideSelect = useMemo(() => {
    const isEditor = editorEmail === email;
    const isSupport = defaultValue === SUPPORT;
    return isEditor || isSupport;
  }, [defaultValue, editorEmail, email]);

  const handleOnChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };

  const option = options.find(o => o.value === value);
  const optionLabel = option?.label || '';

  return (
    <StyledUserRoleSelectContainer {...rest}>
      {
        hideSelect
          ? optionLabel
          : (
            <StyledUserRoleSelect
              bordered={false}
              onChange={handleOnChange}
              options={options}
              value={value}
            />
          )
      }
    </StyledUserRoleSelectContainer>
  );
}

UserRoleSelect.propTypes = {
  onChange: PropTypes.func,
}

const mapStateToProps = state => {
  const {employee} = state.user;
  return {
    editorEmail: employee.email,
  }
}

export default connect(mapStateToProps, null)(UserRoleSelect);
