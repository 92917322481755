import styled from 'styled-components';
import {Space} from 'antd';

const StyledFormSpace = styled(Space)`
  width: 100%;
  & > div {
    &:nth-of-type(1) {
      width: 60%;
    }
    &:nth-of-type(2) {
      width: 40%;
    }
  }
`;

export {StyledFormSpace};