import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Form, Select, Tooltip} from 'antd';
import {CreditCardIcon, TrashIcon} from '../../../../../icons';
import VendorDetails from '../../VendorDetails';
import {StyledSelectedServiceForm, StyledSelectedServiceFormItem} from './StyledSelectedServiceForm';
import {StyledAddSingleSubscriptionModalSelectOptionSpace} from '../../AddSingleSubscriptionModal/StyledAddSingleSubscriptionModal';
import {helpers} from '../../../../../helpers';
import {PaymentOptions, StatusOptions} from '../../subscriptionsHelpers';
import {subscriptionFormFields, subscriptionPaymentTypesConstants} from '../../../../../constants';

const {CARD} = subscriptionPaymentTypesConstants;

const {
  paymentTypeFieldName,
  statusFieldName
} = subscriptionFormFields;

const initialFormValues = {
  owner: null,
  [paymentTypeFieldName]: undefined,
  [statusFieldName]: 1
}

const getOptions = (options) => options.map(option => ({
  ...option,
  label: (
    <StyledAddSingleSubscriptionModalSelectOptionSpace>
      {option.image}
      {option.label}
    </StyledAddSingleSubscriptionModalSelectOptionSpace>
  )
}));

const SelectedServiceForm = ({
  dispatch,
  employees,
  employeeEmail,
  isEnabledBanking,
  errors,
  formValues,
  onChange,
  onDelete,
  service,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [defaultPaymentTypeOptions, ] = useState(
    PaymentOptions(initialFormValues[paymentTypeFieldName])
      .map(o => ({...o, image: o.value === CARD ? <CreditCardIcon /> : undefined}))
  );
  const [statusOptions, ] = useState(StatusOptions(initialFormValues.status));
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [form] = Form.useForm();

  const paymentTypeOptions = useMemo(() => {
    let options = [...defaultPaymentTypeOptions];
    if (!isEnabledBanking) options = options.filter(o => o.value !== CARD);
    return options;
  }, [defaultPaymentTypeOptions, isEnabledBanking]);

  const placeholderT = (key) => t(`subscriptions:modal.addMultipleSubscription.placeholder.${key}`);

  useEffect(() => {
    const options = helpers.getEmployeeOptions({employees, employeeEmail, t});
    setOwnerOptions(options);
  }, [employees, employeeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setFieldsValue({
      ...initialFormValues,
      owner: employeeEmail,
      ...formValues
    })
  }, [form, formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errors) {
      let fields = helpers.getFormServerErrorFields({errors});
      form.setFields(fields);
    }
  }, [errors, form]);

  const handleDelete = () => onDelete && onDelete(service);

  const updateFormStateValue = (value, stateName) => {
    let formFields = {};
    form.setFieldsValue({
      ...formFields,
      [stateName]: {value, errors: []}
    });

    if (onChange) {
      const values = form.getFieldsValue();
      onChange(
        service,
        {...values, ...formFields, [stateName]: value}
      );
    }
  };

  return (
    <StyledSelectedServiceForm
      {...rest}
      initialValues={initialFormValues}
      form={form}
    >
      <div>
        <VendorDetails
          data={{service}}
        />
      </div>
      <div>
        <StyledSelectedServiceFormItem
          name='owner'
        >
          <Select
            onChange={(value) => updateFormStateValue(value, 'owner')}
            options={ownerOptions}
            placeholder={placeholderT('owner')}
            size='large'
          />
        </StyledSelectedServiceFormItem>
      </div>
      <div>
        <StyledSelectedServiceFormItem
          name={statusFieldName}
        >
          <Select
            onChange={(value) => updateFormStateValue(value, statusFieldName)}
            options={getOptions(statusOptions)}
            size='large'
          />
        </StyledSelectedServiceFormItem>
      </div>
      <div>
        <StyledSelectedServiceFormItem
          name={paymentTypeFieldName}
        >
          <Select
            onChange={(value) => updateFormStateValue(value, paymentTypeFieldName)}
            options={getOptions(paymentTypeOptions)}
            placeholder={placeholderT('payment')}
            size='large'
          />
        </StyledSelectedServiceFormItem>
      </div>
      <div>
        <Tooltip title={t('delete')}>
          <Button
            icon={<TrashIcon />}
            onClick={handleDelete}
            shape='circle'
            type='text'
          />
        </Tooltip>
      </div>
    </StyledSelectedServiceForm>
  );
}

SelectedServiceForm.propTypes = {
  errors: PropTypes.object,
  onDelete: PropTypes.func,
  service: PropTypes.shape({
    category: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string.isRequired,
  })
}

SelectedServiceForm.defaultProps = {
  errors: null,
  services: [],
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {employee} = state.user;
  const {isEnabledBanking} = state.banking;
  return {
    employees,
    employeeEmail: employee.email,
    isEnabledBanking
  }
}

export default connect(mapStateToProps, null)(SelectedServiceForm);
