import styled from 'styled-components';

const StyledLegalRepresentativeInvitationForm = styled.div`
  position: relative;
  width: 100%;
`;

export {
  StyledLegalRepresentativeInvitationForm
};
