import {Button, Modal, Space} from 'antd';
import styled from 'styled-components';

const StyledWelcomeModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const StyledWelcomeModalSpace = styled(Space)`
  width: 100%;
  text-align: center;
  
  h1, p {
    margin: 0;
    padding: 0;
  }
  
  h1 {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
  }
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 14px;
    line-height: 22px;
  }
  
`;

const StyledWelcomeModalButton = styled(Button)`
  height: 48px !important;
  width: 100%;
`;

export {
  StyledWelcomeModal,
  StyledWelcomeModalSpace,
  StyledWelcomeModalButton
}