import {StyledModal} from './StyledModal';


const AntdModal = ({
  children,
  ...rest
}) => {

  return (
    <StyledModal {...rest}>
      {children}
    </StyledModal>
  );
}

export default AntdModal;
