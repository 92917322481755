import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Input, Space} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import Paragraph from 'antd/es/typography/Paragraph';
import {
  StyledSelectDropdownContent,
  StyledSelectDropdownSearchContainer
} from '../SelectDropdown/StyledSelectDropdown';
import {StyledTagSelect} from './StyledTagSelect';

const TagSelect = ({
  emptyText,
  mode,
  options,
  onChange,
  value,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [searchedOptions, setSearchedOptions] = useState(options);
  const [searchedValue, setSearchedValue] = useState('');

  useEffect(() => {
    setSearchedOptions(options);
    if (searchedValue) setSearchedValue('');
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = (e) => {
    let {value} = e.target;
    setSearchedValue(value);

    value = value.toLowerCase();
    setSearchedOptions(options.filter(o => o.label.toLowerCase().indexOf(value) >= 0));
  }

  const searchedOptionsLength = searchedOptions.length;

  return (
    <StyledTagSelect
      {...rest}
      onChange={onChange}
      mode={mode}
      options={searchedOptions.map(option => ({
        ...option,
        label: (
          <Space>
            <Checkbox checked={(value || []).includes(option.value)} />
            {option.label}
          </Space>
        )
      }))}
      showSearch={false}
      dropdownStyle={{minWidth: 256}}
      dropdownRender={(menu) => (
        <StyledSelectDropdownContent {...rest}>
          <StyledSelectDropdownSearchContainer className='search'>
            <Input
              allowClear={true}
              size='large'
              placeholder={t('search')}
              suffix={<SearchOutlined />}
              onChange={onSearch}
              value={searchedValue}
            />
          </StyledSelectDropdownSearchContainer>
          <div className='dropdown-searched-content'>
            {searchedOptionsLength > 0 && menu}

            {searchedOptionsLength === 0 && searchedOptionsLength !== options.length && (
              <Paragraph>
                {emptyText}
              </Paragraph>
            )}
          </div>
        </StyledSelectDropdownContent>
      )}
      value={value}
    />
  );
};

TagSelect.propTypes = {
  emptyText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
};

TagSelect.defaultProps = {
  emptyText: 'No results found',
  options: [],
}

export default TagSelect;
