import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import TeamsTab from '../TeamsTab';
import {teamsHelpers} from '../../teamsHelpers';

const ExternalTab = ({
  isEditorSupport,
  editorEmail,
  onActionClick,
  onRoleChange,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'teams']);

  const columns = [
    {
      dataIndex: 'name',
      defaultSortOrder: 'descend',
      title: t('name'),
      sorter: true,
      render: (_, record) => teamsHelpers.getEmployeeDetails(record)
    },
    {
      dataIndex: 'role',
      title: t('role'),
      render: (_, employee) => teamsHelpers.getRole({employee, onChange: onRoleChange})
    },
    // {
    //   dataIndex: 'extension',
    //   title: t('extension')
    // },
    {
      align: 'right',
      dataIndex: 'action',
      title: '',
      render: (_, record) => teamsHelpers.getActions({
        isEditorSupport,
        editorEmail,
        employee: record,
        t,
        onClick: onActionClick
      })
    }
  ];

  return (
    <TeamsTab
      {...rest}
      columns={columns}
    />
  );
}

ExternalTab.propTypes = {
  isEditorSupport: PropTypes.bool,
  editorEmail: PropTypes.string,
  onActionClick: PropTypes.func,
  onRoleChange: PropTypes.func
}

export default ExternalTab;
