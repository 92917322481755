import React from 'react';
import PropTypes from 'prop-types';
import {StyledPageLoading} from './StyledPageLoading';

const PageLoading = ({image, ...rest}) => {
  return (
    <StyledPageLoading {...rest} />
  );
};

PageLoading.propTypes = {
  image: PropTypes.string,
};

export default PageLoading;
