import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {Button, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {StyledTransactionsTable, StyledTransactionTableDownloadSpace} from './StyledTransactionsTable';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import {DownloadIcon} from '../../../../icons';
import {helpers} from '../../../../helpers';
import {transactionActions} from '../../../../state/actions';
import {transactionsHelpers} from '../transactionsHelpers';
import {expenseInvoicesStatusConstants, SCAActionsConstants} from '../../../../constants';
import {scaHelpers} from '../../../../scaHelpers';

const isGroupedRow = transactionsHelpers.isMonthlyGroupedRow;

const SCA_OPERATION_NAME = SCAActionsConstants.TRANSACTIONS_DOWNLOAD_PDF_AND_CSV_ACTION;

const TransactionsTable = ({
  data,
  isCollapsed,
  onInvoiceEdit,
  onRowClick,

  isAdmin,
  employeeId,
  downloadTransactions,
  downloadUserTransactions,

  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [selectedDate, setSelectedDate] = useState(null);

  const trans = (key) => t(`transactions:${key}`);

  const onAttachmentClick = ({e, transaction}) => {
    e.stopPropagation();
    if (transaction && transaction.expense) {
      const invoiceStatus = transaction.expense.expense_invoice_status;
      if (!(expenseInvoicesStatusConstants.PENDING === invoiceStatus)) return;
      onInvoiceEdit && onInvoiceEdit(transaction);
    }
  }

  const getColumns = () => {
    let columns = [
      {
        dataIndex: 'created_date',
        title: t('date'),
        render: (date, record) => {
          const isGrouped = isGroupedRow(record);
          const formattedDate = helpers.getDateWithMonth(date, isGrouped ? 'MMM YYYY' : 'DD MMM');
          return isGrouped ? (
            <span className='grouped-date'>
              {formattedDate}
            </span>
          ) : formattedDate;
        }
      },
      {
        dataIndex: 'type',
        title: t('type'),
        render: (_, record) => !isGroupedRow(record) && transactionsHelpers.getTransactionType(record)
      },
      {
        dataIndex: 'source',
        title: trans('source'),
      },
      {
        dataIndex: 'user',
        title: t('user'),
        render: (_, record) => transactionsHelpers.getTransactionAuthor(record),
      },
      {
        dataIndex: 'cashIn',
        align: 'right',
        title: trans('cashIn'),
        render: (_, record) => transactionsHelpers.getTransactionAmount({transaction: record, variant: 'income'})
      },
      {
        dataIndex: 'cashOut',
        align: 'right',
        title: trans('cashOut'),
        render: (_, record) => transactionsHelpers.getTransactionAmount({transaction: record, variant: 'outcome'})
      },
      {
        dataIndex: 'attachment',
        render: (_, transaction) => {
          const isGrouped = isGroupedRow(transaction);
          const date = transaction.created_date;
          const loading = selectedDate === date && !authWindowProps.open;
          return isGrouped ? (
            <div className='download-button'>
              <StyledTransactionTableDownloadSpace size={2} align='center'>
                <Spin indicator={<LoadingOutlined spin />} spinning={loading} />
                <Button
                  disabled={loading}
                  icon={<DownloadIcon />}
                  onClick={(e) => !loading && handleDownload(e, date)}
                  size='small'
                />
              </StyledTransactionTableDownloadSpace>
            </div>
          ) : transactionsHelpers.getTransactionAttachmentIcon({transaction, onClick: onAttachmentClick});
        },
        width: 32
      }
    ];
    if (isCollapsed) columns = columns.filter(c => !['user'].includes(c.dataIndex));
    return columns;
  }


  const handleOnRowClick = (e, record) => {
    const {id} = record;
    if (isGroupedRow(record)) return;

    onRowClick && onRowClick(id);
  };

  const handleDownload = (e, date) => {
    e.stopPropagation();
    setSelectedDate(date);
    handleExportTransactions(date);
  }

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    setSelectedDate(null);
  };

  const handleExportTransactions = (date = null) => {
    let transactionsDate = date || selectedDate;
    if (transactionsDate === null) return;
    let currentDate = moment(transactionsDate);
    const timeFormat = 'YYYY-MM-DD';
    let startMonthDate = currentDate.startOf('month').format(timeFormat);
    let endMonthDate = currentDate.endOf('month').format(timeFormat);
    const requestProps = {
      headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
      query: {
        date_gte: startMonthDate,
        date_lte: endMonthDate
      },
      successCallback: (data) => {
        window.open(data.url, 'download');
        setSelectedDate(null);
      },
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
          errorCallback: () => setSelectedDate(null)
        });
      }
    }
    isAdmin ? downloadTransactions(requestProps) : downloadUserTransactions({userId: employeeId, ...requestProps});
  }

  const handleOnSuccessAuth = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    handleExportTransactions();
  }

  const columns = getColumns();

  return (
    <>
      <StyledTransactionsTable
        className={isCollapsed && 'collapsed'}
        columns={columns}
        dataSource={data}
        onRow={(record) => ({
          onClick: (e) => handleOnRowClick(e, record)
        })}
        pagination={false}
        rowClassName={(record) => isGroupedRow(record) && 'grouped-row'}
        rowKey='id'
        {...rest}
      />

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={SCA_OPERATION_NAME}
      />
    </>
  );
}

TransactionsTable.propTypes = {
  data: PropTypes.array,
  isCollapsed: PropTypes.bool,
  onRowClick: PropTypes.func
}

TransactionsTable.defaultProps = {
  data: [],
  isCollapsed: false
}

const mapStateToProps = state => {
  const {employee, isAdmin} = state.user;
  return {
    employeeId: employee.id,
    isAdmin,
  }
}

const mapDispatchToProps = {
  downloadTransactions: transactionActions.downloadTransactions,
  downloadUserTransactions: transactionActions.downloadUserTransactions
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable);
