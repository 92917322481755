import styled from 'styled-components';
import {Space} from 'antd';
import Card from '../../../../../Card';

const UploadBtnWidth = '110px';
const UploadInputWidth = `calc(100% - ${UploadBtnWidth} - 8px)`;

const StyledContractDetails = styled(Card)`
  .edit-btn {
    color: ${props => props.theme.colors.primary_7};
    padding: 4px 12px;
  }

  a {
    color: rgba(0, 0, 0, 0.88);
  }

  .contract-btn {
    color: rgba(0, 0, 0, 0.88);
    padding: 0;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-input-affix-wrapper .ant-input-clear-icon-hidden {
    visibility: unset;
  }
`;

const StyledUploadSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    &:nth-of-type(1) {
      min-width: ${UploadInputWidth};
      max-width: ${UploadInputWidth};
      width: ${UploadInputWidth};
    }
    &:nth-of-type(2) {
      width: 120px;
      .ant-upload,
      .ant-upload-wrapper,
      .ant-upload-select {
        width: ${UploadBtnWidth};
        display: block;
        button {
          height: 38px;
          width: 100%;
        }
      }
    }
  }
`;

export {
  StyledContractDetails,
  StyledUploadSpace
}
