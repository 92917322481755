import {Space} from 'antd';
import styled from 'styled-components';
import {borders} from '../../../../../utils/css';

const StyledSubscriptionEmailsModalCounter = styled.span`
  font-weight: 500;
  color: ${props => props.theme.colors.neutral_7};
`;

const StyledSubscriptionEmailsModalSpace = styled(Space)`
  width: 100%;
`;

const StyledSubscriptionEmailsModalUserCard = styled.div`
  ${borders.light};
  background: ${props => props.theme.colors.neutral_2};
  color: ${props => props.theme.colors.neutral_7};
  padding: 8px 16px;
  
  span {
    display: block;
  }
  
  .user-email {
    font-size: 12px;
  }
  
  .user-name {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 14px;
    line-height: 22px;
  }
`;

const StyledSubscriptionEmailsModalUsersCardsSpace = styled(Space)`
  max-height: 70vh;
  overflow: auto;
  width: 100%;
`;

export {
  StyledSubscriptionEmailsModalCounter,
  StyledSubscriptionEmailsModalSpace,
  StyledSubscriptionEmailsModalUserCard,
  StyledSubscriptionEmailsModalUsersCardsSpace
}
