import styled from 'styled-components';
import AntdModal from '../../AntdModal';

const StyledVerifyModal = styled(AntdModal)`
  .ant-modal-body {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px 24px 32px 24px;

    svg {
      margin-bottom: 24px;
    }

    p {
      color: ${props => props.theme.colors.neutral_7};
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      .email {
        color: ${props => props.theme.colors.primary_6};
        font-weight: 500;
      }
    }
  }

`;

export default StyledVerifyModal;
