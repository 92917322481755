import styled, { css } from 'styled-components';

const imageWidth = {
  'xsmall': 32,
  'small': 46,
  'medium': 72,
  'large': 96
};

const getWidth = props => {
  const imageSize = `${imageWidth[props.size]}px !important`;
  return css`
    height: ${imageSize};
    width: ${imageSize};
  `;
};

const StyledSpinner = styled.div`
  & svg {
    transform: translate(-25%) !important;
    ${props => getWidth(props)};
  }
`;

export { StyledSpinner };
