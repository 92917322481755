import React from 'react';

const SettingsIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M8.63635 1C8.63635 0.447715 9.08406 0 9.63635 0H12.3637C12.9159 0 13.3637 0.447715 13.3637 1V2.59685C14.0356 2.78551 14.6746 3.05249 15.2701 3.38716L16.3998 2.25758C16.7903 1.86706 17.4234 1.86707 17.814 2.25759L19.7424 4.18604C20.1329 4.57656 20.1329 5.20972 19.7424 5.60025L18.6128 6.72985C18.9475 7.32539 19.2145 7.96441 19.4032 8.63635H21C21.5523 8.63635 22 9.08406 22 9.63635V12.3637C22 12.9159 21.5523 13.3637 21 13.3637H19.4032C19.2145 14.0356 18.9475 14.6746 18.6128 15.2701L19.7424 16.3998C20.1329 16.7903 20.1329 17.4234 19.7424 17.814L17.814 19.7424C17.4234 20.1329 16.7903 20.1329 16.3998 19.7424L15.2701 18.6128C14.6746 18.9475 14.0356 19.2145 13.3637 19.4032V21C13.3637 21.5523 12.9159 22 12.3637 22H9.63635C9.08406 22 8.63635 21.5523 8.63635 21V19.4032C7.96441 19.2145 7.32539 18.9475 6.72985 18.6128L5.60025 19.7424C5.20972 20.1329 4.57656 20.1329 4.18604 19.7424L2.25759 17.814C1.86707 17.4234 1.86706 16.7903 2.25758 16.3998L3.38716 15.2701C3.05249 14.6746 2.78551 14.0356 2.59685 13.3637H1C0.447715 13.3637 0 12.9159 0 12.3637V9.63635C0 9.08406 0.447715 8.63635 1 8.63635H2.59685C2.78551 7.96441 3.05249 7.32539 3.38716 6.72986L2.25758 5.60025C1.86706 5.20972 1.86707 4.57656 2.25759 4.18604L4.18604 2.25759C4.57656 1.86707 5.20972 1.86706 5.60025 2.25758L6.72986 3.38716C7.32539 3.05249 7.96441 2.78551 8.63635 2.59685V1ZM7.5 11C7.5 9.06701 9.06701 7.5 11 7.5C12.933 7.5 14.5 9.06701 14.5 11C14.5 12.933 12.933 14.5 11 14.5C9.06701 14.5 7.5 12.933 7.5 11Z"
          fill="#000"/>
  </svg>
);

export default SettingsIcon;
