import React from 'react';
import {StyledTabs} from './StyledTabs';
const Tabs = ({
  ...rest
}) => {
  return (
    <StyledTabs {...rest} />
  );
}
export default Tabs;
