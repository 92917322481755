import React from 'react';
import {Spin as AntdSpin} from 'antd'
import PropTypes from 'prop-types';
import Spinner from '../Spinner';

const Spin = ({
  size,
  ...rest
}) => {

  return (
    <AntdSpin
      {...rest}
      indicator={<Spinner size={size} />}
      size={size}
    />
  );
}

Spin.defaultProps = {
  size: 'medium'
}

Spin.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large'])
};

export default Spin;
