import React from 'react';

const HomeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.58291 1.47958C7.82639 1.2848 8.17236 1.2848 8.41584 1.47958L15.0825 6.81292C15.37 7.04292 15.4166 7.46245 15.1866 7.74996C14.9566 8.03747 14.5371 8.08408 14.2496 7.85407L13.666 7.38725V14.0002C13.666 14.3684 13.3676 14.6668 12.9994 14.6668H2.99937C2.63118 14.6668 2.33271 14.3684 2.33271 14.0002V7.38725L1.74917 7.85407C1.46166 8.08408 1.04213 8.03747 0.812128 7.74996C0.582121 7.46245 0.628736 7.04292 0.916243 6.81292L7.58291 1.47958ZM6.33268 9.00016C5.96449 9.00016 5.66602 9.29864 5.66602 9.66683V13.3335H10.3327V9.66683C10.3327 9.29864 10.0342 9.00016 9.66602 9.00016H6.33268Z"
          fill="#262626"/>
  </svg>
);

export default HomeIcon;
