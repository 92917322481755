import {Routes as DomRoutes, Route as DomRoute, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import CardPage from '../pages/CardPage';
import CardsPage from '../pages/CardsPage';
import OverviewPage from '../pages/OverviewPage';
import TeamPage from '../pages/TeamPage';
import TeamUserPage from '../pages/TeamUserPage';
import SettingsPage from '../pages/SettingsPage';
import ProfileSettingsPage from '../pages/ProfileSettingsPage';
import SignUpPage from '../pages/SignUpPage';
import LoginPage from '../pages/LoginPage';
import ForgotPinPage from '../pages/ForgotPinPage';
import TransactionsPage from '../pages/TransactionsPage';
import SubscriptionsPage from '../pages/SubscriptionsPage';
import SubscriptionPage from '../pages/SubscriptionPage';
import CompanyInvitePage from '../pages/CompanyInvitePage';
import EmailVerificationPage from '../pages/EmailVerificationPage';
import NotFoundPage from '../pages/NotFoundPage';
import VerifySuccessPage from '../pages/VerifySuccessPage';
import UserInvitePage from '../pages/UserInvitePage';
import OAuthCallbackPage from '../pages/OAuthCallbackPage';
import QuickGoogleConnectPage from '../pages/QuickGoogleConnectPage';
import InvoicesPage from '../pages/InvoicesPage';
import SupportPage from '../pages/SupportPage';

import {helpers} from '../helpers';
import routes from './routes.json';
import {availableModulesConstants} from '../constants';
import {StyledSpin} from '../app/StyledApp';

const {TRANSACTIONS, SUBSCRIPTIONS} = availableModulesConstants;

const isAvailableSubscriptionsModule = helpers.checkIfAvailableFeature(SUBSCRIPTIONS);
const isAvailableTransactionsModule = helpers.checkIfAvailableFeature(TRANSACTIONS);
const isRequiredCheckPath = isAvailableSubscriptionsModule || isAvailableTransactionsModule;

const bankingRoutes = ['cardDetails', 'cardsList', 'invoicesList', 'subscriptionsList', 'subscriptionDetails',
  'transactionsList'].map(key => routes[key]);

const Routes = ({
  companyState,
  isManager,
  isAdmin,
  availableModules,
  isAuth
}) => {
  const location = useLocation();

  const Route = (key, element) => {
    if (routes.hasOwnProperty(key)) {
      return (
        <DomRoute
          key={key}
          exact
          path={routes[key]}
          element={element}
        />
      )
    } else {
      return null;
    }
  };

  const getManagerRoutes = () => {
    return [
      Route('team', <TeamPage />),
      Route('teamUserDetails', <TeamUserPage />)
    ];
  }

  const getAdminRoutes = () => {
    return [Route('settings', <SettingsPage />)];
  }

  const getMainRoutes = () => {
    const isBankingRoute = bankingRoutes.includes(location.pathname);
    return [
      <DomRoute
        element={<CompanyInvitePage isAuth={isAuth} />}
        key='invite'
        exact
        path={routes.invite}
      />,
      Route('verification', <EmailVerificationPage />),
      Route('verifyIdentity', <VerifySuccessPage />),
      Route('userInvite', <UserInvitePage />),
      Route('oauthCallback', <OAuthCallbackPage />),
      Route('quickGoogleConnect', <QuickGoogleConnectPage />),
      <DomRoute
        // Display loaded while company state is defined if current route is banking
        element={
          isRequiredCheckPath && isBankingRoute
            ? companyState
              ? <NotFoundPage /> : (
                <StyledSpin
                  spinning={true}
                  wrapperClassName='full-width-spin'
                />
              )
            : <NotFoundPage />
        }
        path='*'
        key='not-found'
      />
    ];
  }

  const managerRoutes = getManagerRoutes();

  const adminRoutes = getAdminRoutes();

  const mainRoutes = getMainRoutes();

  if (!isAuth) {
    return (
      <DomRoutes>
        {Route('signUp', <SignUpPage />)}
        {Route('login', <LoginPage />)}
        {Route('forgotPin', <ForgotPinPage />)}
        {mainRoutes}
      </DomRoutes>
    )
  }

  const getAppRoutes = () => {
    let appRoutes = [
      Route('overview', <OverviewPage />),
      Route('profile', <ProfileSettingsPage />),
      Route('support', <SupportPage />),
    ];
    const isAvailableSubscriptions = availableModules.includes(SUBSCRIPTIONS);
    const isAvailableTransactions = availableModules.includes(TRANSACTIONS);

    if (isAdmin) appRoutes = [...appRoutes, ...adminRoutes];
    if (isManager) appRoutes = [...appRoutes, ...managerRoutes];

    if (isAvailableSubscriptions) {
      appRoutes = [
        ...appRoutes,
        Route('cardDetails', <CardPage />),
        Route('cardsList', <CardsPage />),
        Route('subscriptionsList', <SubscriptionsPage />),
        Route('subscriptionDetails', <SubscriptionPage />)
      ];
    }
    if (isAvailableTransactions && isAdmin) {
      appRoutes = [
        ...appRoutes,
        Route('transactionsList', <TransactionsPage />),
        Route('invoicesList', <InvoicesPage />)
      ];
    }

    appRoutes = [...appRoutes, ...mainRoutes];

    return appRoutes;
  }

  const appRoutes = getAppRoutes();

  return (
    <DomRoutes>
      {appRoutes}
    </DomRoutes>
  );
}

const mapStateToProps = state => {
  const {user} = state;
  const {isManager, isAdmin} = user;
  const {companyState} = state.banking;
  return {
    isManager,
    isAdmin,
    companyState
  }
}

Routes.propTypes = {
  availableModules: PropTypes.arrayOf(PropTypes.string),
  isAuth: PropTypes.bool
}

Routes.defaultProps = {
  availableModules: [],
  isAuth: false
}

export default connect(mapStateToProps, null)(Routes);
