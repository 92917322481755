import {Button, Space} from 'antd';
import styled from 'styled-components';
import AntdModal from '../../AntdModal';

const StyledCompanyInviteModal = styled(AntdModal)`
  .ant-spin-nested-loading .ant-spin-blur {
    opacity: 0;
  }
`;

const StyledCompanyInviteModalSpace = styled(Space)`
  width: 100%;
  text-align: center;
  
  h1, p {
    margin: 0;
    padding: 0;
  }
  
  h1 {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
  }
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 14px;
    line-height: 22px;
  }
  
`;

const StyledCompanyInviteModalButton = styled(Button)`
  height: 48px !important;
  width: 100%;
`;

const StyledCompanyInviteModalHeader = styled.div`
  margin: auto;
  position: relative;
  width: 180px;
  
  .ant-avatar {
    &:nth-of-type(1) {
      font-size: 48px !important;
    }
    &:nth-of-type(2) {
      background-color: ${props => props.theme.colors.primary_5};
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      
      img {
        height: 40px;
        width: 40px;
      }
    }
  }
`;

export {
  StyledCompanyInviteModal,
  StyledCompanyInviteModalSpace,
  StyledCompanyInviteModalButton,
  StyledCompanyInviteModalHeader
}