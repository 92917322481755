import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {StyledBreadCrumbs} from './StyledBreadCrumbs';

const BreadCrumbs = ({
  items,
  mode,
  onClick,
  separator,
  ...rest
}) => {
  const navigate = useNavigate();

  const handleOnClick = (e, e2) => {
    if (onClick) {
      onClick(e);
      return;
    }

    if (mode === 'external') {
      e.preventDefault();
      try {
        const {pathname} = new URL(e.target.href);
        navigate(pathname);
      } catch (e) {
      }
    }
  }

  return (
    <StyledBreadCrumbs
      items={items}
      separator={separator}
      onClick={handleOnClick}
      {...rest}
    />
  );
};

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  mode: PropTypes.oneOf(['external', 'internal']),
  onClick: PropTypes.func,
  separator: PropTypes.string,
};

BreadCrumbs.defaultProps = {
  mode: 'external',
  separator: '>'
}

export default BreadCrumbs;
