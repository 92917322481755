import styled from 'styled-components';
import {borders} from '../../../../utils/css';

const StyledWireDetailsModal = styled.div`
  margin-bottom: 24px;

  .ant-modal-content {
    padding: 0;
    & > div {
      padding: 20px 24px;
    }
  }
  .ant-modal-header {
    border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
    margin-bottom: 0;
  }

  .ant-modal-footer {
    border-top: 1px solid ${props => props.theme.colors.neutral_4};
  }

  .ant-alert {
    align-items: baseline;
  }
`;

const StyledCopyFieldsContainer = styled.div`
  ${borders.default};
  margin-bottom: 16px;
`;

export {
  StyledWireDetailsModal,
  StyledCopyFieldsContainer
};
