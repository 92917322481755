import React from 'react';
import PropTypes from 'prop-types';
import {Image} from 'antd';
import {Document, Page, pdfjs} from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import {
  FilePreview
} from './StyledInvoiceAttachmentPreview';
import {helpers} from '../../../../helpers';

if (Boolean(!pdfjs.GlobalWorkerOptions.workerSrc)) pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const InvoiceAttachmentPreview = ({
  attachment,
  ...rest
}) => {

  const isPdf = helpers.hasExtensions(attachment?.name, ['.pdf']);

  const file = attachment?.file || undefined;

  return (
    <FilePreview {...rest}>
      {isPdf ? (
        <Document
          file={file}
        >
          <Page
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      ) : (
        <Image
          preview={false}
          src={file}
        />
      )}
    </FilePreview>
  );
}

InvoiceAttachmentPreview.propTypes = {
  attachment: PropTypes.shape({
    file: PropTypes.string,
    name: PropTypes.string
  })
}

export default InvoiceAttachmentPreview;
