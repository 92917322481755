import React from 'react';

const DatePickerIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.6665 0.666626C6.03469 0.666626 6.33317 0.965103 6.33317 1.33329V1.99996H9.6665V1.33329C9.6665 0.965103 9.96498 0.666626 10.3332 0.666626C10.7014 0.666626 10.9998 0.965103 10.9998 1.33329V1.99996H13.9998C14.7362 1.99996 15.3332 2.59691 15.3332 3.33329V14C15.3332 14.7363 14.7362 15.3333 13.9998 15.3333H1.99984C1.26346 15.3333 0.666504 14.7363 0.666504 14V3.33329C0.666504 2.59691 1.26346 1.99996 1.99984 1.99996H4.99984V1.33329C4.99984 0.965103 5.29831 0.666626 5.6665 0.666626ZM4.99984 3.33329H1.99984V5.99996H13.9998V3.33329H10.9998V3.99996C10.9998 4.36815 10.7014 4.66663 10.3332 4.66663C9.96498 4.66663 9.6665 4.36815 9.6665 3.99996V3.33329H6.33317V3.99996C6.33317 4.36815 6.03469 4.66663 5.6665 4.66663C5.29831 4.66663 4.99984 4.36815 4.99984 3.99996V3.33329ZM13.9998 7.33329H10.9998V9.99996H13.9998V7.33329ZM13.9998 11.3333H10.9998V14H13.9998V11.3333ZM9.6665 14V11.3333H6.33317V14H9.6665ZM4.99984 14V11.3333H1.99984V14H4.99984ZM1.99984 9.99996H4.99984V7.33329H1.99984V9.99996ZM6.33317 7.33329V9.99996H9.6665V7.33329H6.33317Z"
          fill="#BFBFBF"/>
  </svg>
);

export default DatePickerIcon;
