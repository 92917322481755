import styled from 'styled-components';

const StyledTransactionsTabContainer = styled.div`
  position: relative;
`;

const StyledTableContainer = styled.div`
  display: flex;
  overflow-x: hidden;
  margin-top: 24px;
  margin-bottom: 54px;
`;

const StyledTable = styled.div`
`;

export {
  StyledTransactionsTabContainer,
  StyledTableContainer,
  StyledTable
};
