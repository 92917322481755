import styled from 'styled-components';
import {borders} from '../../utils/css';

const StyledTransactionsPageContainer = styled.div`
  display: flex;
  padding: 24px 24px 56px 24px;
  justify-content: center;
  overflow-x: hidden;
`;

const StyledTransactionsPageContent = styled.div`
  ${borders.light};
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 24px 24px 54px 24px;
  min-height: calc(100vh - 80px - 64px);
  overflow-x: auto;
`;

export {
  StyledTransactionsPageContainer,
  StyledTransactionsPageContent
};
