import styled from 'styled-components';
import {Space} from 'antd';
import AntdModal from '../../../../../AntdModal';

const StyledAddCardModal = styled(AntdModal)`
  .ant-space:nth-of-type(2) {
    width: 100%;
    .ant-space-item {
      width: 100%;
      &:first-of-type {
        text-align: center;
      }
    }
  }
`;

const StyledCardInfo = styled(Space)`
  color: ${props => props.theme.colors.neutral_7};
`;

const StyledAddCardModalFormSpace = styled(Space)`
  width: 100%;
  & > .ant-space-item {
    &:nth-of-type(2) {
      display: flex;
      justify-content: center;
    }
  }
`;

export {
  StyledAddCardModal,
  StyledCardInfo,
  StyledAddCardModalFormSpace
}
