import styled from 'styled-components';
import Card from '../../../../Card';
import {Switch} from "antd";

const StyledNotifications = styled(Card)`
  .ant-card-body {
    padding: 0;
    & > p {
      color: ${props => props.theme.colors.neutral_8};
    }
  }
  .ant-card-head {
    padding: 0 24px;
    flex-direction: unset;
  }
`;

const StyledToggle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  h3 {
    color: ${props => props.theme.colors.neutral_10};
    font-weight: 500;
    margin-bottom: 4px;
  }

  p {
    color: ${props => props.theme.colors.neutral_7};
    margin-bottom: 0;
  }
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background: ${props => props.theme.colors.primary_6};

    &:hover:not(.ant-switch-disabled) {
      background: ${props => props.theme.colors.primary_7};
    }
  }
`;


export {
  StyledNotifications,
  StyledToggle,
  StyledSwitch
};
