import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import useGeoLocation from 'react-ipgeolocation';
import {isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js';
import {StyledPhoneAuthForm, StyledPhoneAuthFormButton, StyledPhoneAuthFormCheckbox} from './StyledPhoneAuthForm';
import PhoneNumberInput from '../../../PhoneNumberInput';
import {helpers} from '../../../../helpers';

const {Item} = Form;

const initialFormValues = {
  confirm: false,
  phone_number: '',
  password: ''
}

const PhoneAuthForm = ({
  buttonText,
  confirmText,
  disabled,
  loading,
  enableConfirm,
  enablePassword,
  initialValues,
  passwordProps,
  phoneProps,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'auth']);
  const [form] = Form.useForm();
  const [defaultPhoneCode, setDefaultPhoneCode] = useState(undefined);
  const location = useGeoLocation();
  const [isLocated, setIsLocated] = useState(false);

  const confirm = Form.useWatch('confirm', form);
  const phoneNumber = Form.useWatch('phone_number', form);
  const password = Form.useWatch('password', form);

  const disabledSubmitButton = useMemo(() => {
    let isDisabled = disabled;
    if (!isDisabled && loading) {
      isDisabled = true;
    }
    if (!isDisabled) {
      let isEmptyPhoneNumber = phoneNumber === '';
      isDisabled = enablePassword ? (password === '' || isEmptyPhoneNumber) : isEmptyPhoneNumber;
      if (!isDisabled && enableConfirm) isDisabled = !confirm;
    }
    return isDisabled;
  }, [confirm, disabled, phoneNumber, password, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const validationT = (key, options) => t(`auth:validation.${key}`, options);

  const mainValidationT = (key, options) => t(`validation.${key}`, options);

  useEffect(() => {
    const {country, isLoading} = location;
    if (country && !isLocated && !isLoading) {
      if (initialValues && initialValues.phone_number) return;
      let countryObj = helpers.getCountryByCode(country);
      setIsLocated(true);
      setDefaultPhoneCode(countryObj ? countryObj.country_code : undefined)
    }
  }, [location.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnSubmit = (fields) => {
    let formFields = [];
    const {phone_number: phoneNumber} = fields;
    const isValidNum = isValidPhoneNumber(phoneNumber) && parsePhoneNumber(phoneNumber).number === phoneNumber;
    if (!isValidNum) formFields.push({name: 'phone_number', errors: [validationT('phoneIsIncorrect')]});

    if (enablePassword) {
      const {password} = fields;
      if (password.length < 6) formFields.push({name: 'password', errors: [validationT('incorrectPinFormat', {count: 6})]});
    }

    form.setFields(formFields);

    if (formFields.length) return;

    onSubmit && onSubmit(
      fields,
      null,
      (err) => {
        if (err.hasOwnProperty('message')) {
          const {message} = err;
          const fields = [
            {name: 'phone_number', errors: [enablePassword ? ' ' : message]}
          ];
          if (enablePassword) fields.push({name: 'password', errors: [err.message]});
          form.setFields(fields);
        } else if (err.hasOwnProperty('errors')) {
          const fields = helpers.getFormServerErrorFields(err);
          form.setFields(fields);
        }
      }
    );
  }

  const handleSubmitForm = () => form.submit();

  const handleKeyUp = (e) => e.key.toLowerCase() === 'enter' && handleSubmitForm();

  const onPasswordKeyPress = (e) => !/[0-9]/.test(e.key) && e.preventDefault();

  return (
    <StyledPhoneAuthForm
      initialValues={initialValues}
      onKeyUp={handleKeyUp}
      {...rest}
      form={form}
      onFinish={handleOnSubmit}
    >
      <Item
        name='phone_number'
        rules={[{required: true, message: mainValidationT('pleaseEnterYour', {name: t('phoneNumber').toLowerCase()})}]}
        required
      >
        <PhoneNumberInput
          disabled={disabled}
          phoneCode={defaultPhoneCode}
          placeholder={t('phoneNumber')}
          size='large'
          {...phoneProps}
        />
      </Item>

      {enablePassword && (
        <Item
          name='password'
          rules={[
            {required: true, message: t('auth:validation.pinIsEmpty')},
            {max: 6}
          ]}
          required
        >
          <Input.Password
            disabled={disabled}
            type='number'
            maxLength={6}
            onKeyPress={onPasswordKeyPress}
            placeholder={t('pin')}
            size='large'
            {...passwordProps}
          />
        </Item>
      )}

      {enableConfirm && (
        <Item
          className='confirm-item'
          name='confirm'
          valuePropName='checked'
        >
          <StyledPhoneAuthFormCheckbox>{confirmText}</StyledPhoneAuthFormCheckbox>
        </Item>
      )}

      <StyledPhoneAuthFormButton
        loading={loading}
        disabled={disabledSubmitButton}
        onClick={handleSubmitForm}
        size='large'
        type='primary'
      >
        {buttonText}
      </StyledPhoneAuthFormButton>

    </StyledPhoneAuthForm>
  );
}

PhoneAuthForm.propTypes = {
  buttonText: PropTypes.string,
  confirmText: PropTypes.any,
  disabled: PropTypes.bool,
  initialValues: PropTypes.shape({
    phone_number: PropTypes.string,
    password: PropTypes.string
  }),
  enableConfirm: PropTypes.bool,
  enablePassword: PropTypes.bool,
  loading: PropTypes.bool,
  passwordProps: PropTypes.object,
  phoneProps: PropTypes.object
}

PhoneAuthForm.defaultProps = {
  disabled: false,
  loading: false,
  initialValues: initialFormValues,
  enableConfirm: false,
  enablePassword: true
}

export default PhoneAuthForm;
