import React from 'react';

const FileZipIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2182 1.09082H6.46182C5.75637 1.09082 5.18182 1.67264 5.18182 2.38537V29.4363C5.18182 30.149 5.75637 30.7308 6.46182 30.7308H25.5382C26.2436 30.7308 26.8182 30.149 26.8182 29.4363V8.69082L19.2182 1.09082ZM14.8436 28.1854H11.3818V27.6545L13.7855 24.2108H11.3709V23.509H14.8327V24.0326L12.4291 27.4763H14.8436V28.1854ZM16.4436 28.1854H15.6036V23.509H16.4436V28.1854ZM19.0582 26.6035H18.1527V28.1854H17.3127V23.509H19.0691C20.1418 23.509 20.7127 24.1599 20.7164 25.0581C20.7127 25.9526 20.1382 26.6035 19.0582 26.6035ZM25.7709 20.5926H6.2291V2.38537C6.2291 2.25446 6.33455 2.149 6.46182 2.149H18.8182V8.44718C18.8182 8.90173 19.1891 9.27264 19.6436 9.27264H25.7709V20.5926Z"
      fill="#000"/>
    <path
      d="M18.9418 24.2144H18.1527V25.9125H18.9418C19.5746 25.9089 19.8582 25.5525 19.8582 25.0616C19.8582 24.5598 19.5746 24.2144 18.9418 24.2144Z"
      fill="#000"/>
  </svg>
);

export default FileZipIcon;
