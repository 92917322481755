import styled from 'styled-components';
import {Space} from 'antd';
import Card from '../../../Card';

const StyledUserSubscriptions = styled(Card)`
`;

const StyledUserSubscriptionsSpace = styled(Space)`
  width: 100%;
`;

const StyledUserSubscriptionsButtonContainer = styled.div`
  min-height: 40px;
  text-align: center;
`;


export {
  StyledUserSubscriptions,
  StyledUserSubscriptionsButtonContainer,
  StyledUserSubscriptionsSpace
}
