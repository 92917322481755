import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as LogoImg} from '../../static/images/withless-colorfull-big-logo.svg';
import BgImg from '../../static/images/pages/custom-page-bg.svg';
import {
  StyledVerifyPageContainer,
  StyledVerifyPageContainerContent,
} from './StyledVerifyPageContainer';

const VerifyPageContainer = ({children, ...rest}) => {
  return (
    <StyledVerifyPageContainer bgImg={BgImg} {...rest}>
      <StyledVerifyPageContainerContent>
        <a href='/'>
          <LogoImg />
        </a>
        {children}
      </StyledVerifyPageContainerContent>
    </StyledVerifyPageContainer>
  )
};

VerifyPageContainer.propTypes = {
  className: PropTypes.string
}

export default VerifyPageContainer;
