import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledVerticalBarChart,
  StyledVerticalBarChartItem,
  StyledVerticalBarChartItemHeader,
  StyledVerticalBarChartItemLine
} from './StyledVerticalBarChart';

const VerticalBarChart = ({
  barBackgroundColor,
  barColor,
  barRadius,
  barWidth,
  children,
  data,
  renderValue,
  onItemClick,
  ...rest
}) => {

  return (
    <StyledVerticalBarChart
      {...rest}
    >
      {data.map((item, index) => {
        const {actual, expected, name} = item;
        let isDanger = actual > expected;
        const fillPercent = (actual / expected * 100).toFixed(2);
        const unlimitedPercent = (fillPercent - 100).toFixed(2);
        return (
          <StyledVerticalBarChartItem
            className={onItemClick && 'pointer'}
            key={index}
            onClick={(e) => onItemClick && onItemClick(e, item)}
          >
            <StyledVerticalBarChartItemHeader>
              <label>{name}</label>
              <span className={`price && ${isDanger && 'danger'}`}>
                {renderValue ? renderValue(actual) : actual}
              </span>
            </StyledVerticalBarChartItemHeader>
            <StyledVerticalBarChartItemLine
              barBackgroundColor={barBackgroundColor}
              barColor={barColor}
              barRadius={barRadius}
              barWidth={barWidth}
              isDanger={isDanger}
              fill={isDanger ? '100%' : `${fillPercent}%`}
            >
              {isDanger && (
                <div className='unlimited-percent'>{unlimitedPercent}%</div>
              )}
            </StyledVerticalBarChartItemLine>
          </StyledVerticalBarChartItem>
        )
      })}
    </StyledVerticalBarChart>
  );
}

VerticalBarChart.propTypes = {
  activeColor: PropTypes.string,
  barBackgroundColor: PropTypes.string,
  barColor: PropTypes.string,
  barRadius: PropTypes.number,
  barWidth: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      actual: PropTypes.number.isRequired,
      expected: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onItemClick: PropTypes.func,
  renderValue: PropTypes.func
}

VerticalBarChart.defaultProps = {
  barBackgroundColor: '#F0F3FF',
  barColor: '#7A8AFF',
  barRadius: 4,
  barWidth: 12,
  data: [],
}

export default VerticalBarChart;
