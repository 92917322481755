import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {StyledUserSubscriptions, StyledUserSubscriptionsButtonContainer, StyledUserSubscriptionsSpace} from './StyledUserSubscriptions';
import {teamActions} from '../../../../state/actions';
import VendorDetails from '../../SubscriptionsPage/VendorDetails';
import {subscriptionsHelpers} from '../../SubscriptionsPage/subscriptionsHelpers';
import SubscriptionTable from '../../SubscriptionsPage/tabComponents/SubscriptionTable';
import {helpers} from '../../../../helpers';


const perPagePagination = 10;

const defaultQuery = {
  per_page: perPagePagination,
}

const UserSubscriptions = ({
  dispatch,
  getSubscriptions,
  email,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [pagination, setPagination] = useState({next_page: null, cursor: null});
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      dataIndex: 'vendor',
      title: t('vendor'),
      render: (_, record) => <VendorDetails data={record} />,
      sorter: true
    },
    {
      dataIndex: 'status',
      title: t('status'),
      render: (_, record) => !record.placeholder && subscriptionsHelpers.getSubscriptionStatusMark(record),
      sorter: true
    },
    {
      dataIndex: 'last_login',
      title: t('subscriptions:tableRowNames.lastLogIn'),
      render: (date) => date && helpers.getTimestampDate(date, 'DD/MM/YYYY'),
    }
  ];

  const loadData = ({email, initial = true, query}) => {
    !loading && setLoading(true);
    getSubscriptions(
      email,
      {...defaultQuery, ...query},
      ({subscriptions: data, pagination}) => {
        setSubscriptions(initial ? data : [...subscriptions, ...data]);
        setPagination(pagination);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }

  const finishLoading = () => setLoading(false);

  useEffect(() => {
    if (email) {
      loadData({email});
    }
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowMore = () => {
    if (email) {
      setLoading(true);
      loadData({
        email,
        initial: false,
        query: {cursor: pagination.cursor},
        successCallback: finishLoading,
        errorCallback: finishLoading
      });
    }
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    const {action} = extra;
    if (action === 'sort') {
      const {order, field} = sorter;
      loadData({
        email,
        initial: true,
        query: {
          order_by: order ? `${order === 'descend' ? '-' : ''}${field}` : undefined
        }
      });
    }
  }

  const isEnableLoadMore = pagination.next_page;

  return (
    <StyledUserSubscriptions
      {...rest}
    >
      <StyledUserSubscriptionsSpace
        direction='vertical'
        size='large'
      >
        <SubscriptionTable
          columns={columns}
          dataSource={subscriptions}
          loading={loading}
          onChange={onTableChange}
          pagination={false}
        />
        <StyledUserSubscriptionsButtonContainer>
          {isEnableLoadMore && (
            <Button
              loading={loading}
              onClick={() => !loading && handleShowMore()}
              size='large'
              type='default'
            >
              {t('showMore')}
            </Button>
          )}
        </StyledUserSubscriptionsButtonContainer>
      </StyledUserSubscriptionsSpace>
    </StyledUserSubscriptions>
  );
}

UserSubscriptions.propTypes = {
  email: PropTypes.string.isRequired
}

const mapDispatchToProps = {
  getSubscriptions: teamActions.getEmployeeSubscriptions
}

export default connect(null, mapDispatchToProps)(UserSubscriptions);


