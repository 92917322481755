import React, {useMemo, useState} from 'react';
import {Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {StyledLegalRepresentativeInvitationForm} from './StyledLegalRepresentativeInvitationForm';
import SpinSmall from '../../../../../SpinSmall';
import {StyledKYCModalContentColumnFormSpace} from '../../../../KYCModal/StyledKYCModal';
import {kycHelpers} from '../../kycHelpers';

const {Item} = Form;

const initialFormValues = {
  first_name: '',
  email: '',
  last_name: '',
}

const formSpaceProps = {
  align: 'start',
  size: 32
}

const LegalRepresentativeInvitationForm = React.forwardRef(({
  onSubmit,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();

  const {
    label,
    lengthFieldMessage,
    placeholder,
    validationRequiredRules,
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'lrInformation.tabs.invitation'}), [t]);

  const onFinish = (fields) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(
        fields,
        () => setLoading(false),
        (errors) => {
          form.setFields(errors);
          setLoading(false)
        }
      )
    }
  }

  return (
    <StyledLegalRepresentativeInvitationForm {...rest}>
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onFinish={onFinish}
          ref={ref}
          requiredMark={false}
        >
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('firstName')}
              name='first_name'
              rules={[
                ...validationRequiredRules,
                {min: 2, message: lengthFieldMessage(2, 'min')}
              ]}
            >
              <Input
                minLength={2}
                maxLength={50}
                placeholder={placeholder('firstName')}
                size='large'
              />
            </Item>
            <Item
              label={label('lastName')}
              name='last_name'
              rules={[
                ...validationRequiredRules,
                {min: 2, message: lengthFieldMessage(2, 'min')}
              ]}
            >
              <Input
                minLength={2}
                maxLength={50}
                placeholder={placeholder('lastName')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('email')}
              name='email'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('email')}
                size='large'
                type='email'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
        </Form>
      </SpinSmall>
    </StyledLegalRepresentativeInvitationForm>
  );
});

export default LegalRepresentativeInvitationForm;
