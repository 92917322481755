import styled from 'styled-components';
import {rgba} from 'polished';

const MD_WIDTH = '1024px';

const StyledPageContainer = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
`;

const StyledFormContainer = styled.div`
  background-color: ${props => props.theme.colors.additional_bg_2};
  display: flex;
  justify-content: end;
  width: 53.5vw;

  .form-content {
    max-width: 410px;
    width: 100%;
    text-align: center;

    .ant-form {
      .ant-form-item {
        &:last-of-type {
          margin-bottom: 32px;
        }
      }
    }

    h3 {
      color: ${props => props.theme.colors.neutral_10};
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 24px;
      
      @media screen and (max-width: ${MD_WIDTH}) {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 16px;
      }
    }

    p {
      color: ${props => props.theme.colors.neutral_7};
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 16px;
      
      @media screen and (max-width: ${MD_WIDTH}) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 12px;
      }

      &.help-text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;

        & + form {
          margin-top: 24px;
        }
        
        @media screen and (max-width: ${MD_WIDTH}) {
          font-size: 14px;
        }
      }
    }

    a {
      color: ${props => props.theme.colors.neutral_10};
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
    }

    .bold {
      color: ${props => props.theme.colors.neutral_10};
      font-weight: 700;
    }

    form {
      margin-top: 40px;

      & + p {
        margin-top: 24px;
      }
      
      @media screen and (max-width: ${MD_WIDTH}) {
        margin-top: 32px;
        & + p {
          margin-top: 16px;
        }
      }
    }
  }
`;

const StyledImageContainer = styled.div`
  align-items: center;
  background-color: ${props => rgba(props.theme.colors.additional_neon, 0.1)};
  justify-content: center;
  display: flex;
  width: 46.5vw;
  position: relative;
  img, svg {
    z-index: 2;
    max-width: 500px;
    width: 82%;
  }
`;

const StyledFadeElement = styled.div`
  transition: 0.3s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const StyledFormContainerChild = styled.div`
  padding: 48px;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;

  .main-page-link {
    display: inline-block;
    width: max-content;
  }
`;

const StyledFormContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  padding-bottom: 40px;
  width: 100%;
  margin: auto;
  
  & > div {
    display: flex;
    justify-content: center;
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`;

const StyledImageTransition = styled.div`
  height: 100%;
  width: 100%;
  
  & > div {
    height: 100%;
    width: 100%;
  }
`;

export {
  StyledPageContainer,
  StyledFormContainer,
  StyledImageContainer,
  StyledFormContent,
  StyledFormContainerChild,
  StyledFadeElement,
  StyledImageTransition
}
