import styled from 'styled-components';
import {Space} from 'antd';

const StyledTeamsTabContainer = styled(Space)`
  position: relative;
  width: 100%;
  
  .ant-avatar-group {
    display: flex;
  }
  
  .actions {
    .ant-btn {
      &.primary {
        color: ${props => props.theme.colors.primary_6};
      }
    }
  }
`;

export {
  StyledTeamsTabContainer,
};
